<div class="row">
  <div class="col-12">
    <mat-horizontal-stepper>
      <mat-step
        *ngFor="let step of field.fieldGroup; let index = index; let last = last;">
        <ng-template matStepLabel>{{ step.templateOptions.label }}</ng-template>
        <formly-field [field]="step"></formly-field>

        <div>
          <button matStepperPrevious *ngIf="index !== 0"
                  class="btn btn-primary"
                  type="button">
             {{'languages.buttons.back' | translate}}
          </button>

          <button matStepperNext *ngIf="!last"
                  class="btn btn-primary" type="button"
                  [disabled]="!isValid(step)">
             {{'languages.buttons.next' | translate}}
          </button>

          <button *ngIf="last" class="btn btn-primary"
                  [disabled]="!form.valid"
                  type="submit">
             {{'languages.buttons.enregistrer' | translate}}
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
