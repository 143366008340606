import { TicketNotification } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { DocumentProduit } from 'src/app/entity/Bpm/LigneProduits';
export class Taches {
  id: string = '';
  titre: string = '';
  description: string = '';
  statut: number = 0;
  user_full_name: string = '';
  affecte_a: string = '';
  date_limit: string = '';
  priorite: string = '';
  tags: string = '';
  prospect_id: string = '';
  id_ticket: string = null;
  entity: string = '';
  id_entity: string = '';
}

export class CategorieTicket {
  id: string = '';
  id_parent: string = '';
  libelle: string = '';
  libelle_cat_parent: string = '';
  document_list: DocumentProduit[] = [];
  notif_list: TicketNotification[] = [];
  taches: Taches[] = [];
  form: any = null ;
  delai_traitement: number;
  affect_a: string = '';
  icon: any;
  client : any = false;
  distributeur: any = false;
  claim : any =0;
  garanties:any[]=[];
  garanties_herite:any[]=[];
}
