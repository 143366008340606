import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
 
import { HistoriquesCommunications } from 'src/app/entity/HistoriquesCommunications';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { MatSort } from '@angular/material/sort';
import { UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-historique-communication',
  templateUrl: './historique-communication.component.html',
  styleUrls: ['./historique-communication.component.css'],
})
export class HistoriqueCommunicationComponent implements OnInit, OnDestroy {
  dataTable: any;
   
  historiquesCommunication: HistoriquesCommunications[] = [];
  displayedColumns: string[] = ['date', 'user', 'prospect', 'details', 'type', 'statut', 'fichier', 'url'];
  dataSource: MatTableDataSource<HistoriquesCommunications>;
  loading = true;
  unsbscribeGetHistoriqueCommunicationOpportunite: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  unsubscribeGetHistoriqueCommunicationOpportunite: Subscription;
  allowed_to_update :any;

  constructor(
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private apiCommunicationService: ApiComminucationService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.unsbscribeGetHistoriqueCommunicationOpportunite?.unsubscribe();
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.getHistorique();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHistorique() {
    this.unsbscribeGetHistoriqueCommunicationOpportunite = this.apiCommunicationService
      .GetHistoriqueCommunicationOpportunite(this.opportunitiesDetailsComponent.opportunity_id)
      .subscribe((data: HistoriquesCommunications[]) => {
        this.loading = false;
        this.historiquesCommunication.forEach((element, index) => {
          this.historiquesCommunication[index].date = UniformiserDateToLocal(element.date);
        });

        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();
      });
  }

  changeDate(date: string) {
    return date.substr(0, 10) + ' ' + date.substr(11, 8);
  }
}
