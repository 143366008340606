import { DatePipe } from '@angular/common';
import { Filtre_opp } from './../../../../../entity/Affaires/FilterAffaires';
import { Validators, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, map, tap, catchError } from 'rxjs/operators';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { campagne, cycle_de_vie, tags } from './../../../../../entity/Opportunity/FilterOpportunite';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { forkJoin, Observable } from 'rxjs';

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
 
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
@Component({
  selector: 'app-filter-opportunity-affaire',
  templateUrl: './filter-opportunity-affaire.component.html',
  styleUrls: ['./filter-opportunity-affaire.component.css'],
})
export class FilterOpportunityAffaireComponent implements OnInit, OnChanges {
  readonly rangeDateCreation = RangeDateComponent;

  readonly rangeDateMeeting = RangeDateComponent;

   
  listCampagneSelected: any[] = [];
  selectedcycle_de_vie: any[] = [];
  tagsSelected: any[] = [];
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filterOppAffaires: Filtre_opp = new Filtre_opp();

  date_creation: string = '';
  date_fin: string = '';
  datesDu: string = '';
  datesAu: string = '';

  // Mat Chips Campagne
  CampagnesFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCampagne: Observable<campagne[]>;
  ListCampagne: string[] = [];
  allCampagne: campagne[] = [];

  filtre: any;
  @ViewChild('CampagneInput') CampagneInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCampagne') matAutocompleteCampagne: MatAutocomplete;

  @ViewChild('picker') picker: MatDateRangePicker<any>;
  @ViewChild('Dates') Dates: MatDateRangePicker<any>;
  // Mat Chips cycle_de_vie
  cycleDevieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredcycleDevie: Observable<cycle_de_vie[]>;
  ListCycleDevie: string[] = [];
  allCycleDevie: cycle_de_vie[] = [];

  @ViewChild('CycleDevieInput') CycleDevieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCycleDevie') matAutocompleteCycleDevie: MatAutocomplete;

  // Mat Chips Tags
  tagsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTags: Observable<tags[]>;
  ListTags: string[] = [];
  allTags: tags[] = [];

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTags') matAutocompleteTags: MatAutocomplete;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterOpportunityAffaire: EventEmitter<Filtre_opp> = new EventEmitter();

  constructor(
    private apiOpportuniteService: ApiOpportunitService,
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  //  Campagne -------------------------------------------------------------------------------------

  clearDateCreation() {
    this.filterOppAffaires.date_debut_creation = null;
    this.filterOppAffaires.date_fin_creation = null;
    this.date_creation = null;
    this.date_fin = null;
    this.EmitFilterOpportunityAffaire();
  }

  openDateCreation() {
    if (this.picker) {
      this.picker.open();
    }
  }

  clearDateRDV() {
    this.filterOppAffaires.date_debut_rdv = null;
    this.filterOppAffaires.date_fin_rdv = null;
    this.datesDu = null;
    this.datesAu = null;
    this.EmitFilterOpportunityAffaire();
  }

  openDateRDV() {
    if (this.Dates) {
      this.Dates.open();
    }
  }

  selectedCampagne(event: MatAutocompleteSelectedEvent): void {
    if (!this.listCampagneSelected.includes(event.option.value)) {
      this.ListCampagne.push(event.option.viewValue);
      this.listCampagneSelected.push(event.option.value);
    }
    this.CampagneInput.nativeElement.value = '';
    this.CampagnesFromCtrl.setValue(null);
    this.EmitFilterOpportunityAffaire();
  }

  removeCampagne(Campagne: string): void {
    const index = this.ListCampagne.indexOf(Campagne);
    if (index >= 0) {
      this.ListCampagne.splice(index, 1);
      this.listCampagneSelected.splice(index, 1);
    }
  }

  private _filterCampagne(): campagne[] {
    let filterValue = this.CampagnesFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCampagne.filter((Campagne) =>
      Campagne.libelle != null ? Campagne.libelle.toLowerCase().includes(filterValue.toLowerCase()) : null
    );
  }

  // CycleDevie -----------------------------------------------------------------------------------------------

  selectedCycleDevie(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedcycle_de_vie.includes(event.option.value)) {
      this.ListCycleDevie.push(event.option.viewValue);
      this.selectedcycle_de_vie.push(event.option.value);
    }
    this.CycleDevieInput.nativeElement.value = '';
    this.cycleDevieFromCtrl.setValue(null);
    this.EmitFilterOpportunityAffaire();
  }

  removeCycleDevie(CycleDevie: string): void {
    const index = this.ListCycleDevie.indexOf(CycleDevie);
    if (index >= 0) {
      this.ListCycleDevie.splice(index, 1);
      this.selectedcycle_de_vie.splice(index, 1);
    }
  }

  private _filterCycleDevie(): cycle_de_vie[] {
    let filterValue = this.cycleDevieFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCycleDevie.filter((CycleDevie) => CycleDevie.libele.toLowerCase().includes(filterValue));
  }

  // Tags Contient -----------------------------------------------------------------------------------------------

  selectedTags(event: MatAutocompleteSelectedEvent): void {
    if (!this.tagsSelected.includes(event.option.value)) {
      this.ListTags.push(event.option.viewValue);
      this.tagsSelected.push(event.option.value);
    }
    this.tagsInput.nativeElement.value = '';
    this.tagsFromCtrl.setValue(null);
    this.EmitFilterOpportunityAffaire();
  }

  removeTags(Tags: string): void {
    const index = this.ListTags.indexOf(Tags);
    if (index >= 0) {
      this.ListTags.splice(index, 1);
      this.tagsSelected.splice(index, 1);
    }
  }

  private _filterTags(): tags[] {
    let filterValue = this.tagsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTags.filter((Tags) => Tags.libelle.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    this.getDetailsFilter();
    this.filteredCampagne = this.CampagnesFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Campagne: string | null) => (Campagne ? this._filterCampagne() : this.allCampagne.slice()))
    );

    this.filteredcycleDevie = this.cycleDevieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((CycleDevie: string | null) => (CycleDevie ? this._filterCycleDevie() : this.allCycleDevie.slice()))
    );

    this.filteredTags = this.tagsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Tags: string | null) => (Tags ? this._filterTags() : this.allTags.slice()))
    );
    this.filtre = localStorage.getItem('filtreAffaire');
    this.filtre = JSON.parse(this.filtre);
    if (this.filtre) {
      this.filterOppAffaires = this.filtre.opp?.filtre_opp;
      this.date_creation = this.filterOppAffaires?.date_debut_creation;
      this.date_fin = this.filterOppAffaires?.date_fin_creation;
      this.datesDu = this.filterOppAffaires?.date_debut_rdv;
      this.datesAu = this.filterOppAffaires?.date_fin_rdv;
      const allApi: Observable<any>[] = [this.apiOpportuniteService.getDetailsFilter()];
      forkJoin(allApi)
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            return [];
          })
        )
        .subscribe((responses) => {
          this.EmitFilterOpportunityAffaire();
        });
    }
  }

  getDetailsFilter() {
    this.apiOpportuniteService
      .getDetailsFilter()
      .pipe(
        tap(({ cycle_de_vie, tags, campagne }) => {
          this.allCycleDevie = cycle_de_vie;
          this.allTags = tags;
          this.allCampagne = campagne;
          if (this.filtre) {
            if (this.filterOppAffaires?.campagne.length > 0) {
              this.ListCampagne = this.allCampagne
                .filter((cmp) => this.filterOppAffaires.campagne.includes(cmp.id))
                .map((cmpAff) => {
                  this.listCampagneSelected.push(cmpAff.id);
                  return cmpAff.libelle;
                });
            }
            if (this.filterOppAffaires.cycle_de_vie.length > 0) {
              this.ListCycleDevie = this.allCycleDevie
                .filter((cycle) => this.filterOppAffaires.cycle_de_vie.includes(cycle.id))
                .map((cycleAff) => {
                  this.selectedcycle_de_vie.push(cycleAff.id);
                  return cycleAff.libele;
                });
            }
            if (this.filterOppAffaires.tags_opp.length > 0) {
              this.ListTags = this.allTags
                .filter((tag) => this.filterOppAffaires.tags_opp.includes(tag.id))
                .map((tagAff) => {
                  this.tagsSelected.push(tagAff.id);
                  return tagAff.libelle;
                });
            }
          }
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetOppAffaireForm(changes.Reset.currentValue);
  }

  resetOppAffaireForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListCampagne = [];
      this.filterOppAffaires.campagne = this.listCampagneSelected = [];
      this.ListCycleDevie = [];
      this.filterOppAffaires.cycle_de_vie = this.selectedcycle_de_vie = [];
      this.ListTags = [];
      this.filterOppAffaires.tags_opp = this.tagsSelected = [];
      this.filterOppAffaires.date_debut_creation = this.date_creation = null;
      this.filterOppAffaires.date_fin_creation = this.date_fin = null;
      this.filterOppAffaires.date_debut_rdv = this.datesDu = null;
      this.filterOppAffaires.date_fin_rdv = this.datesAu = null;
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterOpportunityAffaire.emit(this.filterOppAffaires);
    }
  }

  EmitFilterOpportunityAffaire() {
    this.filterOppAffaires.date_debut_creation = this.datePipe.transform(this.date_creation, 'yyyy-MM-dd');
    this.filterOppAffaires.date_fin_creation = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    this.filterOppAffaires.date_debut_rdv = this.datePipe.transform(this.datesDu, 'yyyy-MM-dd');
    this.filterOppAffaires.date_fin_rdv = this.datePipe.transform(this.datesAu, 'yyyy-MM-dd');
    this.filterOppAffaires.campagne = this.listCampagneSelected;
    this.filterOppAffaires.cycle_de_vie = this.selectedcycle_de_vie;
    this.filterOppAffaires.tags_opp = this.tagsSelected;
    this.onPushFilterOpportunityAffaire.emit(this.filterOppAffaires);
  }
}
