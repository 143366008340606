<!-- <div class="row p-3">
  <div class="col">
    <div class="card card-affaire bg-white text-center border-0 ">
     <p style="font-size: larger;">Informations complémentaires </p>
    </div>
  </div>

</div> -->

<div class="row">
  <div class="col-3 p-1 mt-2  ">
    <div class="sidebar-affaire ">
      <div class="card card-affaire bg-white text-center border-0 p-2">
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('infoSpecif')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.InfoComPhysique' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('infoSpecifEn')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{"languages.settings.infocCompMorale" | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('organisme')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text"> {{"languages.settings.infoCompOrganism" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('compagnie')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoCompCompagnie' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('gamme')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoGamme' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('instances')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text"> {{"languages.settings.Instance" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('quality')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoBlocQualite' | translate}}</span>
            </button>
          </div>

        </div>

        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('rules')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-balance-scale opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.Rules' | translate}}</span>
            </button>
          </div>

        </div>
    
      </div>

    </div>
  </div>


  <div class="col p-1 overflow-auto ">
    <div class="row">
      <div class="col p-0">
        <div class="p-2 mb-2" id="infoSpecif" *ngIf="infoSpecif">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder  row  d-flex justify-content-center align-items-center mt-2 mb-4 card-doc-title" >
              <div class="col-md-6">
<i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg mr-2"></i>
                {{'languages.settings.InfoComPhysique' | translate}}</div>
             
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
                <button type="button" class="btn-dt-save"  (click)="addConfig('physique')" [disabled]="formErrorphysique" [style.opacity]="formErrorphysique ? '0.5' : '1'">
                  <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
  
                  {{"languages.buttons.enregistrer" | translate}}
                </button>
              </div>
              
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataPhysique" (emitFormBuilder)="getDataPhysiqueForm($event)" (itemerror)="onGetItemErrorPHysique($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="infoSpecifEn" *ngIf="infoSpecifEn">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg mr-2" ></i>
                {{"languages.settings.infocCompMorale" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container  *ngIf="showLoading" >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('morale')"   [disabled]="formErrormorale" [style.opacity]="formErrormorale ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataMorale" (emitFormBuilder)="getDataMoraleForm($event)"  (itemerror)="onGetItemErrorMorale($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="organisme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompOrganism" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('organisme')" [disabled]="formErrororganisme" [style.opacity]="formErrororganisme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataOrganisme" (emitFormBuilder)="getDataOrganismeForm($event)" (itemerror)="onGetItemErrorOrganisme($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="compagnie" *ngIf="compagnie">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompCompagnie" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('compagnie')" [disabled]="formErrorCompagnie" [style.opacity]="formErrorCompagnie ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataCompagnie" (emitFormBuilder)="getDataCompagnieForm($event)" (itemerror)="onGetItemErrorCompagnie($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="instance">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.Instance" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="submit" class="btn-dt-save" style="float: right;" (click)="addConfig('instance')">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-12 ">
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.reposrtServer" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput type="url" [(ngModel)]="bodyInstance.report_server" #report_server="ngModel"
                        pattern="https?://.+">
                      <mat-error *ngIf="report_server.invalid && (report_server.dirty || report_server.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="report_server.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                           {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienSouscription" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.subscription_url" #souscription_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="souscription_url.invalid && (souscription_url.dirty || souscription_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="souscription_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienBackoffice" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.api_url" #api_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="api_url.invalid && (api_url.dirty || api_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="api_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.deviseActuelle" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.currentCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                 {{"languages.settings.formatDevise" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.formatCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                     {{"languages.prospect.pays" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.country">
                    </mat-form-field>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
        <div class="p-2 mb-2" id="quality" *ngIf="quality">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
                 {{'languages.settings.infoBlocQualite' | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('qualite')" [disabled]="formErrorQuality" [style.opacity]="formErrorQuality ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataQuality" (emitFormBuilder)="getDataQualityForm($event)" (itemerror)="onGetItemErrorQuality($event)"></shared-globalProduit-form>
          </div>
        </div>

        <div class="p-2 mb-2" id="gamme" *ngIf="gamme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{'languages.settings.infoGamme' | translate}} 
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('gamme')" [disabled]="formErrorGamme" [style.opacity]="formErrorGamme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataGamme" (emitFormBuilder)="getDataGammeForm($event)" (itemerror)="onGetItemErrorGamme($event)"></shared-globalProduit-form>
          </div>
        </div>


        <div class="p-2 mb-2" id="rules" *ngIf="rules">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder  row  d-flex justify-content-center align-items-center mt-2 mb-4 card-doc-title" >
              <div class="col-md-3">
<i class="fa-solid fa-balance-scale  opportunities-details-navbar-logo fa-lg mr-2"></i>
                {{'languages.settings.Rules' | translate}}
              </div>
             

        
                  <div class="col-md-9 d-flex align-items-center justify-content-end  pl-5 ">
                    <div *ngIf="isPasswordInputVisible" class="row d-flex passwordDiv">
                      <img class=" col-2" src="assets/icons/lock.svg">
                      <div class="col-10 d-flex  justify-content-end pl-0">
                        <mat-form-field class="full-width">
                          <input matInput [type]="showPassword ? 'text' : 'password'"
                            placeholder="{{'languages.affaire.writePassword' | translate}}" [(ngModel)]="password"
                            (change)="validPass()" autocomplete="new-password">
                          <mat-hint>{{'languages.alerts.ableToModify' | translate}}</mat-hint>
                        </mat-form-field>
                        <button type="button" (click)="togglePasswordVisibility()" [disabled]="allow_to_change" class="eyeBtn mb-2">
                          <i class="fa-solid fa-eye-slash fa fa-lg" *ngIf="!showPassword"></i>
                          <i class="fa-solid  fa-eye fa fa-lg" *ngIf="showPassword"></i>
                        </button>
            
                      </div>
                    </div>
                    <div class="col-1">
                      <button type="button" (click)="togglePasswordInput()" [disabled]="allow_to_change" class="btn-lock ">
                        <i class="fa-solid fa-lock-open fa fa-lg" *ngIf="allow_to_change"></i>
                        <i class="fa-solid fa-lock fa fa-lg" *ngIf=" !allow_to_change"></i>
                      </button>
                    </div>
                  </div>
            
            
           


           
              
            </div>
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-8 d-flex justify-content-end">
                <input class="search w-50" name="search" [(ngModel)]="search" (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
               
              </div>
              <div class="col-3 d-flex justify-content-end" >
                <button  type="button" class="add-btn-em"  [ngClass]="{'cover': !allow_to_change}" (click)="createNewRule()">
                  <img class="fa-icon-add" src="assets/icons/add-icon.svg">&nbsp;
                {{'languages.settings.createNewRule' | translate}}
              </button>
              </div>
             

            </div>
        
          <ng-container *ngIf="show_loading">
              <div class="row mb-3 mt-3">
                  <div class="text-center m-auto ">
                      <mat-spinner [diameter]="30"></mat-spinner>
                  </div>
              </div>
          </ng-container>
          <ng-containner class="py-3" *ngIf="!show_loading">
              <div  id="listRuless">
                  <table class="table align-items-center " id="dataTable1">
                      <thead class="head-table">
                          <tr>
                            <th> {{'languages.listing.id' | translate}}</th>
                              <th> {{'languages.alerts.Libelle' | translate}}</th>
                              <th> {{'languages.listing.Type' | translate}}</th>
                              <th> {{'languages.listing.actions' | translate}}</th>
                             
                          </tr>
                      </thead>
                      <tbody class="body-table">
                          <tr *ngFor="let rule of listRules">
                            <td>{{rule?.id}}</td>
                              <td>{{rule?.label}}</td>
                              <td>{{rule?.label_rule_type}}</td>
                              <td>
                                 <a type="button" class="btn-dt-list" [ngClass]="{'cover': !allow_to_change}" (click)=" getInfoRule(rule.id)">
                                  <img src="/assets/icons/edit-icon.svg">
                                </a>
                              </td>
                          </tr>
                      </tbody>
                  </table> 
                  <div *ngIf="listRules.length < 1" class="row">
                      <div class="col-12  d-flex justify-content-center">
                          <img src="assets/icons/nodata.svg">
      
                        </div>
                        <div class="col-12  d-flex justify-content-center">
                          <p class="noData"> {{'languages.affaire.noDataAvailable' | translate}}</p>
                        </div>
                  </div>
              </div>
          </ng-containner>




          <div class="navbar-center mb-4 row"  *ngIf="listRules.length >0">
            <div class="col-4 pt-1">
              <div class="row w-50 flex-nowrap" >
                <div  class="col mt-1">
                  <p>{{'languages.miniListing.Afficher' | translate}}</p>
                </div>
                <div  class="col mx-1">
                  <select [(ngModel)]="limit"  (change)="updateListRules()" 
                  class="bg-white border pt-1 rounded font-weight-bold">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="col mt-1 text-nowrap">
                  <p>{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p> 
                </div>
              </div>
            </div>
            <div class="col-4 d-flex justify-content-center">
              <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
                <ul class="pagination pg-blue d-flex justify-content-start  ">
                  <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                    <a class="page-link-symbol">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
                    <a class="page-link-symbol-chevron" aria-label="Previous">
                      <i class="fa fa-chevron-left "></i>
                    </a>
                  </li>
                  <ng-container *ngFor="let item of [].constructor(3); let i = index">
                    <li *ngIf="i+page-3>0" class="page-item">
                      <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let item of [].constructor(3); let i = index">
                    <li *ngIf="i+page<totalPages" class="page-item">
                      <a (click)="changepage(i+page)" class="page-link mt-2 border rounded" 
                      [ngClass]="{'active-page':page==(i+page)}">
                      {{i + page}}
                    </a>
                    </li>
                  </ng-container>
                  <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                  <li class="page-item">
                    <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                      [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                  </li>
                  <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                    <a class="page-link-symbol-chevron" aria-label="Next">
                      <i class="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                    <a class="page-link-symbol">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
                </ul>
              </nav>
          </div>
            <div class="col">
              <div class="row position-absolute flex-nowrap" style="right: 0px">
                <div class="col text-nowrap text-right mt-2">
                  <p >{{'languages.listing.label_goto' | translate}}</p>
                </div>
                <div class="col mt-1 ml-2">
                  <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" style="margin-left: 4px;"
                  size="1" class="page_input bg-white border rounded font-weight-bold" [value]="page" max="{{totalPages}}">
                </div>
              </div>
            </div>
          </div>






<!-- <div *ngIf="showDetails" class="detailsRules" [ngClass]="{'cover': !allow_to_change}">
  <h2 class="text-center title justify-content-center m-0 w-100" *ngIf="addRule" id="exampleModalLabel">{{'languages.settings.addRule' | translate}}</h2>
  <h2 class="text-center title justify-content-center m-0 w-100" *ngIf="!addRule" id="exampleModalLabel">{{'languages.settings.modifyRule' | translate}}</h2>
  <div class="row"> 
    <div class="col-6">
    
      <mat-form-field class="w-100" appearance="standard">
        <mat-label>{{'languages.settings.rule' | translate}} </mat-label>
        <input matInput 
        [(ngModel)]="modifyRule.label"
        type="text" 
        
        
        name="ruleLabel"
         placeholder="{{'languages.settings.rule' | translate}}" 
        >
         
    </mat-form-field>
    </div>
    
    <div class="col-6">
    
     <mat-form-field class="w-100" appearance="standard">
        <mat-label>{{'languages.settings.ruleType' | translate}}</mat-label>
        <mat-select [(ngModel)]="modifyRule.rule_type" #matRe required #type="ngModel" placeholder="{{'languages.settings.ruleType' | translate}}" name="type">
            <mat-option *ngFor="let rule of rulesTypes" [value]="rule.id">
                {{rule.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
  </div>

  <div class="row ">
    <ngx-monaco-editor class="monacoeditorparentdiv" [options]="editorOptions" (focus)="onEditorFocus()" 
    (blur)="onEditorBlur()" [(ngModel)]="ruleStr"></ngx-monaco-editor>
  </div>
        


  <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
    <div class="col-6">
        <ng-container *ngIf="show_loading_adding">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row text-center justify-content-center w-100">
        
            <button type="button" class="btn-dt-save-popup mr-2" (click)="addConfig('rules')">
<i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                {{'languages.buttons.ajouter' | translate}}
          </button>
            <button data-dismiss="modal" aria-label="Close"  type="button" class="btn-dt-cancel-popup ml-2" (click)="emptyRule()">
                <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
            {{'languages.buttons.annuler' | translate}}

          </button>
    
           
        
    </div>
    </div>
        
        <div> <button matTooltip="élargir l'éditeur"  (click)="openDialogmonaco()" style="float: right"
          class="btn btn-success d-flex"> <mat-icon>open_in_new</mat-icon> </button></div>

          <ng-template #monacoeditorpopuppre>
            
              <div class="row">
              <div class="col-lg-12" style="width:100%;border-radius: 5px;">
                <div style="width:70vw;height:400px;padding-right:15px;">
                        <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacobigger()"  matTooltip="Plein écran" ><mat-icon>zoom_out_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>
          
          <ng-template #monacoeditorpopupprebigger>
            
              <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <div style="width:95vw;height:85vh;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonaco()"  matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>
</div>
            -->
               
            
            <ng-template #showDetailsDialog>
              <div  [ngClass]="{'cover': !allow_to_change}">
                <div class="modal-header">
                <h2 class="text-center mb-3 title justify-content-center m-0 w-100" *ngIf="addRule" id="exampleModalLabel">{{'languages.settings.addRule' | translate}}</h2>
                <h2 class="text-center mb-3 title justify-content-center m-0 w-100" *ngIf="!addRule" id="exampleModalLabel">{{'languages.settings.modifyRule' | translate}}</h2>
                <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                <div class="row"> 
                  <div class="col-6">
                    <mat-form-field class="w-100" appearance="standard">
                      <mat-label>{{'languages.settings.rule' | translate}} </mat-label>
                      <input matInput [(ngModel)]="modifyRule.label" type="text" name="ruleLabel" placeholder="{{'languages.settings.rule' | translate}}">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="w-100" appearance="standard">
                      <mat-label>{{'languages.settings.ruleType' | translate}}</mat-label>
                      <mat-select [(ngModel)]="modifyRule.rule_type" #matRe required #type="ngModel" placeholder="{{'languages.settings.ruleType' | translate}}" name="type">
                        <mat-option *ngFor="let rule of rulesTypes" [value]="rule.id">
                          {{rule.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <ngx-monaco-editor class="monacoeditorparentdiv" [options]="editorOptions"  [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
                <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
                  <div class="col-6">
                    <ng-container *ngIf="show_loading_adding">
                      <div class="row mb-3 mt-3">
                        <div class="text-center m-auto">
                          <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row text-center justify-content-center w-100">
                    <button type="button" class="btn-dt-save-popup mr-2" (click)="addConfig('rules')">
                      <i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                      {{'languages.buttons.ajouter' | translate}}
                    </button>
                    <button type="button" class="btn-dt-cancel-popup ml-2" (click)="closeDialog()">
                      <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                      {{'languages.buttons.annuler' | translate}}
                    </button>
                  </div>
                </div>
                <div>
                  <button matTooltip="élargir l'éditeur" (click)="openDialogmonaco()" style="float: right" class="btn btn-success d-flex">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>

            <ng-template #monacoeditorpopuppre>
            
              <div class="row">
              <div class="col-lg-12" style="width:100%;border-radius: 5px;">
                <div style="width:70vw;height:400px;padding-right:15px;">
                        <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacobigger()"  matTooltip="Plein écran" ><mat-icon>zoom_out_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>
          
          <ng-template #monacoeditorpopupprebigger>
            
              <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <div style="width:95vw;height:85vh;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonaco()"  matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>

          </div>
        </div>
     </div>

    </div>
    </div>
  </div>

