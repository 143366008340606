export class StatusAutorise {
  listCategory: Category[];
}
export class Category {
  id;
  sup;
  label;
  listactions: status_cat[] = [];


  constructor(id, label, listactions: any[]) {
    this.id = id;
    this.label = label;
    this.listactions = listactions;
  }

}
export class status_cat {
  class_color: any;
  class_icon:any;
  color:any;
  couleur_police:any;
  dependance:any;
  etat:any;
  explication:any;
  id:any;
  sup:any;
  visa:any;
  access_sup:boolean= true;
    
}
export class StatutChild{
  affect_all_affaire: number;
  agenda:             number;
  alert:              number;
  api:                null;
  calcul_ca:          number;
  categorie:          number;
  categorie_libele:   null;
  cdvp_libele:        null;
  classe:             string;
  classe_libele:      null;
  color:              string;
  commissionable:     number;
  contrat:            null;
  couleur_police:     string;
  cycle_prod:         number;
  date_resiliation:   null;
  dependance:         null;
  etat:               string;
  explication:        string;
  fax_comm:           null;
  id:                 string;
  id_p:               string;
  level:              number;
  mail_comm:          null;
  msg_conf:           null;
  post_comm:          null;
  real_id:            number;
  sms_comm:           null;
  sup:                number;
  ticket:             number;
  visa:               null;
  access_sup:boolean= true;
}
export class StatutChildOpp{
  agenda:       number;
  alert:        number;
  cat:          number;
  categorie:    number;
  classe_color: string;
  dependance:   null;
  etat:         string;
  etat_color:   string;
  explication:  null;
  fiche_dec:    number;
  icon:         string;
  id:           string;
  msg:          null;
  sup:          number;
  visa:         null;
  access_sup:   boolean;
}