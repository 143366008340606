<div style="margin: auto;">
    <div class="row details-nav">
        <div class="col-4 p-2">
            <button (click)="EmitFilterfilterContratActuel()" (click)="typeContrat('0')" class="button-style left-btn">
                N/R
            </button>
        </div>
        <div class="col-4 p-2">
            <button (click)="EmitFilterfilterContratActuel()" (click)="typeContrat('0')"
                class="button-style middle-btn">
                Non Assuré
            </button>
        </div>
        <div class="col-4 p-2">
            <button (click)="EmitFilterfilterContratActuel()" class="button-style right-btn" (click)="typeContrat('1')">
                {{'communicationopportunite.label_insured' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="assure" class="row details-body">
        <div class="col-6">
            <div class="form-group">
                <mat-form-field appearance="fill" style="width: 100%;margin-top: -14px;">
                    <input (click)="EmitFilterfilterContratActuel()" matInput [matDatepicker]="echeance"
                        [(ngModel)]="date_ech" placeholder="Date d'échéance principale">
                    <mat-datepicker-toggle matSuffix [for]="echeance"></mat-datepicker-toggle>
                    <mat-datepicker (closed)="EmitFilterfilterContratActuel()" #echeance></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6">
            <ng-container *ngIf="allCompagnie.length > 0">
                <mat-form-field style="width: 100%;margin-top: -4px;">
                    <mat-label>Compagnie</mat-label>
                    <mat-chip-list #Compagnie>
                        <mat-chip *ngFor="let compagnie of ListCompagnies" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCompagnie(compagnie)"
                            (removed)="EmitFilterfilterContratActuel()">
                            {{compagnie}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>

                        </mat-chip>
                        <input (click)="EmitFilterfilterContratActuel()" placeholder="Selectionner Compagnie..."
                            #compagnieInput [formControl]="compagnieFromCtrl" [matAutocomplete]="autoCompagnies"
                            [matChipInputFor]="Compagnie" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCompagnies="matAutocomplete" (optionSelected)="selectedCompagnie($event)">
                        <mat-option *ngFor="let compagnie of filteredCompagnie | async" [value]="compagnie.siren">
                            {{compagnie.denomination}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-6" style="padding-left: 0px;">
                    <div class="form-group">
                        <label></label>
                        <mat-form-field appearance="fill" style="width: 100%;margin-top: -16px;">
                            <mat-select (selectionChange)="EmitFilterfilterContratActuel();getValue($event)"
                                [(ngModel)]="filterContratActuel.operateur_pan_ttc" placeholder="PAN TTC:">
                                <mat-option value="=">
                                    est égale à
                                </mat-option>
                                <mat-option value="<=">
                                    est inférieur ou égale à
                                </mat-option>
                                <mat-option value=">">
                                    est supérieur à
                                </mat-option>
                                <mat-option value=">=">
                                    est supérieur ou égale
                                </mat-option>
                                <mat-option>
                                    Non assigné
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6" style="padding-right: 0px;margin-top: -5px;">
                    <mat-form-field style="width: 100%;">
                        <input [disabled]="!noValue" (click)="EmitFilterfilterContratActuel()"
                            [(ngModel)]="filterContratActuel.PAN_TTC" matInput placeholder="Saisir nombre" type="number"
                            pattern="[-+]?[0-9]*[.,]?[0-9]+" name="num" #num="ngModel">
                    </mat-form-field>
                    <div *ngIf="num.invalid && (num.dirty || num.touched)" class="message-erreur">
                        <div *ngIf="num.errors.pattern">
                            <i class="fa-solid fa-triangle-exclamation"></i>Seulement des chiffres !
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
