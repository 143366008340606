<div class="row" style="margin-top: 15px">



    <div class="col-6">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em;margin-left: -6px;">
                <input (click)="EmitFilterTelephonie()" [(ngModel)]="filterTelephonie.argumentes_tout" class="checkbox"
                    type="checkbox" id="contient" (change)="checking($event)">
                <div class="checkbox-visual" style="margin-left: 10px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <label class="contien">Argumentés / Tout</label>

        </div>
    </div>
   
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="margin-top: -9px;width: 100%;">
                <mat-select (selectionChange)="EmitFilterTelephonie();getNombre($event)"
                    [(ngModel)]="filterTelephonie.operateur_telephonie" placeholder="Nombres d'appels :">
                    <mat-option value="=">
                        est égale à
                    </mat-option>
                    <mat-option value="!=">
                        n'est pas égale à
                    </mat-option>
                    <mat-option value="<">
                        est inférieure à
                    </mat-option>
                    <mat-option value="<=">
                        est inférieure ou égale à
                    </mat-option>
                    <mat-option value=">">
                        est supérieure à
                    </mat-option>
                    <mat-option value=">=">
                        est supérieure ou égale à
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;margin-top: 2px;">
                <input [disabled]="!noValue" (click)="EmitFilterTelephonie()"
                    [(ngModel)]="filterTelephonie.value_telephonie" matInput placeholder="Saisir nombre" type="number"
                    pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel">
            </mat-form-field>
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                <div *ngIf="nombre.errors.pattern">
                    <i class="fa-solid fa-triangle-exclamation"></i>Seulement des chiffres !
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">

            <mat-form-field appearance="fill" style="margin-top: -21px;width: 100%;">
                <mat-select (selectionChange)="EmitFilterTelephonie();getDuree($event)"
                    [(ngModel)]="filterTelephonie.operateur_duree" placeholder="Durée (sec) :">
                    <mat-option value="=">
                        est égale à
                    </mat-option>
                    <mat-option value="!=">
                        n'est pas égale à
                    </mat-option>
                    <mat-option value="<">
                        est inférieure à
                    </mat-option>
                    <mat-option value="<=">
                        est inférieure ou égale à
                    </mat-option>
                    <mat-option value=">">
                        est supérieure à
                    </mat-option>
                    <mat-option value=">=">
                        est supérieure ou égale à
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;margin-top: -9px;">
                <input [disabled]="!noDuree" (click)="EmitFilterTelephonie()" [(ngModel)]="filterTelephonie.value_duree"
                    matInput placeholder="Saisir durée" type="number" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="duree"
                    #duree="ngModel">
            </mat-form-field>
            <div *ngIf="duree.invalid && (nombre.dirty || duree.touched)" class="message-erreur">
                <div *ngIf="duree.errors.pattern">
                    <i class="fa-solid fa-triangle-exclamation"></i>Seulement des chiffres !
                </div>
            </div>
        </div>
    </div>
</div>