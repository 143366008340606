export class obj_assure {
  nom: string;
  icon: string;
  form: any;
}
export class Group {
  nom_group_contrat: string;
  id_goup_contrat: number;
}
export class DocumentProduit {
  id: number;
  real_id: string;
  libelle: string;
  oblig: number;
}
export class MotifRules {
  id_rule: any;
  id_rule_type: any;
  label_rule_type: any;
  motif_id: any;
  type: any;
}
export class LigneProduit {
  active: string = '1';
  etats_dossiers: any[] = [];
  form: any = null;
  description: string = '';
  id: string = '';
  real_id: string = '';
  object_contrat: string = '';
  type: string = '';
  cycle_prod: any;
  cdvp_libele:any
  bien: string = '0';
  vehicule = '0';
  group: Group[];
  object_assure: obj_assure[] = [];
  documents: DocumentProduit[];
  garanties: any;
  person: string = '1';
  motifs_avenant_lp:any[] = [];
}

export class Garantie {
  code: number;
  description: string;
  icon: string;
}
