import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormbuilderValidationService } from './../../../services/formbuilder-validation.service';
import { NotificationMessage,NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MonetiqueErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-monetique-type',
  templateUrl: './monetique-type.component.html',
  styleUrls: ['./monetique-type.component.scss'],
})
export class MonetiqueTypeComponent extends FieldType implements OnInit {
  monetiqueFormControl = new FormControl('', [Validators.required]);
  matcher = new MonetiqueErrorStateMatcher();
  fieldName: any;
  searchValue: any;
  urlIdentifiant: any;
  alerts: any;
  buttons: any;
  loader: boolean;
  tickets_id: any;

  constructor(private validationService: FormbuilderValidationService,
    private notificationsService: NotificationsService,
    private translate : TranslateService,
    private routes: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
      
    this.fieldName = this.field.key || `monetiqueField_${Math.random().toString(36).substr(2, 9)}`;

    const pattern: any = this.field.templateOptions?.pattern || '';
    this.urlIdentifiant =this.field.templateOptions?.urlIdentifiant 
    
    this.monetiqueFormControl.setValidators([Validators.required, this.monetiquePatternValidator(pattern)]);

    this.searchValue = this.field.templateOptions?.autofill;
    if (this.field.templateOptions?.autofill) {
      this.monetiqueFormControl.disable();
    } else {
      this.monetiqueFormControl.enable();
    }

    if (this.formControl.value) {
      this.monetiqueFormControl.setValue(this.formControl.value);
    }

    this.monetiqueFormControl.valueChanges.subscribe((value) => {
      this.formControl.setValue(value);
      this.emitValidationStatus();
    });

    this.emitValidationStatus();
  }

  private emitValidationStatus(): void {
    const isValid = this.monetiqueFormControl.disabled || this.monetiqueFormControl.valid;
    this.validationService.updateValidationStatus(this.fieldName, isValid, this.field.templateOptions?.label);
  }

  private monetiquePatternValidator(pattern: string | RegExp): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = new RegExp(pattern);
      if (control.value && !regex.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }

  updateValue(event: Event): void {
    const currentRoute = this.routes.url;
    if (currentRoute.includes('ticketsandsupport')) {
      this.route.paramMap.subscribe((params) => {
        this.tickets_id = params.get('tickets_id');
        this.loader=true
        this.validationService.getAllInfoTicket(this.tickets_id).subscribe(
          (response) => {
            this.loader=false
            if (this.urlIdentifiant) {
              this.loader=true
              const bodyTicket=response
              this.validationService.consumeAutoFillInputs(this.urlIdentifiant , bodyTicket).subscribe(
                (response) => {
                  this.loader=false
                    this.monetiqueFormControl.setValue(response.response.value);
                    const notificationMessage = new NotificationMessage();
                    notificationMessage.type = NotificationType.success;
                    notificationMessage.title = this.alerts.Successoperation;
                    notificationMessage.message = response?.message;
                    this.notificationsService.sendMessage(notificationMessage);
                },
                (error) => {
                  this.loader=false
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.error;
                notificationMessage.title = this.alerts.Operationechoue;
                if (error.status != "500"){
                  notificationMessage.message = error?.error?.message;
                }
                this.notificationsService.sendMessage(notificationMessage);
                }
              );
            } else {
              this.loader=false
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.Operationechoue;
              notificationMessage.message = this.alerts.urlServiceOblig;
              this.notificationsService.sendMessage(notificationMessage);
            }
             
          },
          (error) => {
          this.loader=false
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error?.message?.message;
          this.notificationsService.sendMessage(notificationMessage);
          }
        );

      });
    }


    
    }


    
}
