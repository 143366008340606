import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { IconPickerComponent } from 'src/app/components/icon-picker/icon-picker.component';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';

class GoupGarantie{
  libelle : string;
  descriotion : string;
}
@Component({
  selector: 'app-add-group-garantie',
  templateUrl: './add-group-garantie.component.html',
  styleUrls: ['./add-group-garantie.component.css']
})
export class AddGroupGarantieComponent implements OnInit {
  ModeAjout: boolean;
   
  grantieForm : FormGroup;
  group_id :any;
  group_garantie : GoupGarantie;
  listeOfUnsubscribeWebService: Subscription[] = [];

  icon: any ='';
  alerts: any;
  buttons: any;
  navbarTxt:string="";  show_loading_add: boolean;
  @ViewChild(IconPickerComponent) iconPickerComponent!: IconPickerComponent;
  constructor( private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private route: ActivatedRoute,
    private  apiGroupGarantieService : ApiGroupGarantieService ,
    private translate : TranslateService
    ) { }

    ngOnDestroy(): void {
      const currentRoute = this.routes.url;
      if (!currentRoute.includes('groupe-garantie')) {
        localStorage.removeItem('search');
        localStorage.removeItem('page');
      }
    
    }
    

  ngOnInit(): void {

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
        this.buttons = object.buttons;
      this.navbarTxt=object.navbar.gestionGroupGarantie +' ➜ '+ object.navbar.Ajouter
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {


      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
          this.buttons = object.buttons;
          this.navbarTxt=object.navbar.gestionGroupGarantie +' ➜ '+ object.navbar.Ajouter
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });});


    this.grantieForm = new FormGroup({
      description: new FormControl(),
      libelle: new FormControl('',[Validators.required]),
      id: new FormControl(),
      icon: new FormControl(),

 });
 this.showloader()
 this.route.paramMap.subscribe((params) => {
  if (params.get('id') !== 'nouveau') {

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.gestionGroupGarantie +' ➜ '+ object.navbar.Détails
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {

      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.gestionGroupGarantie +' ➜ '+ object.navbar.Détails
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      }); });
     this.ModeAjout = false;
    const unsubscribeGetCamapagneById = this.apiGroupGarantieService.getGroupGarantieById(params.get('id')).subscribe((data: any) => {
      this.group_garantie = data;
      this.group_id = params.get('id');
         this.grantieForm.get('libelle').setValue(this.group_garantie[0].libelle);
         this.grantieForm.get('description').setValue(this.group_garantie[0].description);
         this.grantieForm.get('id').setValue(this.group_garantie[0].id);
         this.grantieForm.get('icon').setValue(this.group_garantie[0].icon)
         this.icon =this.group_garantie[0].icon;

         this.hideloader()
    }); this.listeOfUnsubscribeWebService.push(unsubscribeGetCamapagneById);

  }else{
    this.ModeAjout = true;
    this.hideloader()
  }

});

  }


  retourToList(){
      this.routes.navigate(['/groupe-garantie']);

  }

  clearForm() {
    if(this.ModeAjout){
      this.grantieForm.reset();
      this.icon =""
    }else{
      this.grantieForm.get('libelle').setValue(this.group_garantie[0].libelle);
      this.grantieForm.get('description').setValue(this.group_garantie[0].description);
      this.grantieForm.get('id').setValue(this.group_garantie[0].id);
      if(this.group_garantie[0].icon !== '' && this.group_garantie[0].icon!== null){
      this.grantieForm.get('icon').setValue(this.group_garantie[0].icon)
      this.icon =this.group_garantie[0].icon;
      }else{
        this.icon = '';
      }
     
    }
    this.iconPickerComponent.simulateNgOnChanges(this.icon);
    // this.grantieForm.get('description').setValue('')
    // this.grantieForm.get('libelle').setValue('');
    // this.grantieForm.get('icon').setValue('')


  }

  checkedIcon =true;
  onCheckedNameIcon(event){
    this.checkedIcon =event;
  }

  add(){
    this.show_loading_add = true;
    this.grantieForm.get('libelle').markAsTouched();
    if (this.grantieForm.valid && this.checkedIcon){
      if (this.grantieForm.value.description ===null){
        this.grantieForm.value.description=""
      }
      if (!this.grantieForm.value.icon){
        this.grantieForm.value.icon=""
      }
    if (this.ModeAjout) {


      const unsubscribeInsertGarantie = this.apiGroupGarantieService.addGroupGarantie(this.grantieForm.value).subscribe(
        (Response) => {
       
           this.show_loading_add = false;
           this.alertSuccess(Response.message, Response.id, true);

        },
        (err) => {
          this.show_loading_add = false;
          this.alertError(this.alerts.error, this.alerts.errorAddingGroupeGarantie);
         
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeInsertGarantie);
    } else {
      const unsubscribeUpdateGarantie = this.apiGroupGarantieService.UpdateGroupGarantie(this.grantieForm.value).subscribe(
        (Response) => {
  
          this.show_loading_add = false;
            this.alertSuccess(Response.message, Response.id, false);

        },
        (err) => {
          this.show_loading_add = false;
          this.alertError(this.alerts.error, this.alerts.errorUpdatingGroupeGarantie);
          
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeUpdateGarantie);
    } 
   }else if(this.grantieForm.invalid){
    this.show_loading_add = false;
      this.alertError(this.alerts.formulaireinvalide, this.alerts.mercideRemplir);
    }else if(this.grantieForm.valid && this.grantieForm.value.icon && !this.checkedIcon){
      this.show_loading_add = false;
      this.alertError(this.alerts.invalidIcon, this.alerts.fillIcon);

    }
  }




  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data, idGarantie, modeedit) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: this.buttons.newGroupGarantie,
        denyButtonColor: '833626',
        cancelButtonText: this.buttons.updateGroupeGarantie,
        confirmButtonText: this.buttons.listGroupeGarantie,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/groupe-garantie']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['groupe-garantie/add-groupe-garantie/' + idGarantie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['groupe-garantie/add-groupe-garantie/nouveau']).then(() => {
            window.location.reload();
          });
        }
      });
  }


  alertError(data, err?) {
    swal.fire({
      title: data,
      // text: "Quelque chose s'est mal passé!",
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }


  onSelectIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }

  onSelectNameIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }


}
