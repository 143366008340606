<div class="row" style="margin-top: -16px;">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="date_creation" matStartDate placeholder="{{'languages.affaire.creationDateDu' | translate}}" readonly  (click)="openDateCreation()" />
                    <input [(ngModel)]="date_fin" matEndDate placeholder="{{'languages.opportunite.label_a' | translate}}" readonly (click)="openDateCreation()"/>

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterOpportunityAffaire()" [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>


            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="Dates">
                    <input [(ngModel)]="datesDu" matStartDate placeholder="{{'languages.opportunite.label_date_du' | translate}}" (click)="openDateRDV()" />
                    <input [(ngModel)]="datesAu" matEndDate placeholder="{{'languages.opportunite.label_a' | translate}}" (click)="openDateRDV()"/>

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Dates">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateRDV()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterOpportunityAffaire()" [calendarHeaderComponent]="rangeDateMeeting"  #Dates></mat-date-range-picker>

            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group" style="padding: 0;">
            <ng-container>
                <mat-form-field style="width: 100%;">
                    <mat-label> {{'languages.organisme.Campagne' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allCampagne?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Campagne>
                        <mat-chip *ngFor="let Campagne of ListCampagne" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCampagne(Campagne)"
                            (removed)="EmitFilterOpportunityAffaire()">
                            {{Campagne}}
                            <i class="fa-solid fa-circle-xmark"  matChipRemove *ngIf="removable" style="color: gray;"></i>
            
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="{{'languages.opportunite.label_SelectionnerCampagne' | translate}}"
                            #CampagneInput [formControl]="CampagnesFromCtrl" [matAutocomplete]="autoCampagne"
                            [matChipInputFor]="Campagne" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCampagne="matAutocomplete" (optionSelected)="selectedCampagne($event)">
                        <mat-option *ngFor="let Campagne of filteredCampagne | async" [value]="Campagne.id">
                            {{Campagne.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'languages.opportunite.label_lifecycle' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allCycleDevie?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #CycleDevie>
                        <mat-chip *ngFor="let CycleDevie of ListCycleDevie" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCycleDevie(CycleDevie)"
                            (removed)="EmitFilterOpportunityAffaire()">
                            {{CycleDevie}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                     
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="{{'languages.opportunite.label_lifecycle_select' | translate}}"
                            #CycleDevieInput [formControl]="cycleDevieFromCtrl" [matAutocomplete]="autoCycleDevie"
                            [matChipInputFor]="CycleDevie" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCycleDevie="matAutocomplete" (optionSelected)="selectedCycleDevie($event)">
                        <mat-option *ngFor="let CycleDevie of filteredcycleDevie | async" [value]="CycleDevie.id">
                            {{CycleDevie.libele}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'languages.groupedactions.mots_cles' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Tags>
                        <mat-chip *ngFor="let Tags of ListTags" [selectable]="selectable" [removable]="removable"
                            (removed)="removeTags(Tags)" (removed)="EmitFilterOpportunityAffaire()">
                            {{Tags}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                     
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="{{'languages.opportunite.label_tags_select' | translate}}"  #tagsInput
                            [formControl]="tagsFromCtrl" [matAutocomplete]="autoTags" [matChipInputFor]="Tags"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTags="matAutocomplete" (optionSelected)="selectedTags($event)">
                        <mat-option *ngFor="let Tags of filteredTags | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
