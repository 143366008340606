import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FiltresProspectsComponent} from './filtres-prospects/filtres-prospects.component';
import {FiltresUtilisateursComponent} from './filtres-utilisateurs/filtres-utilisateurs.component';
import {FiltresActionsUtilisateursComponent} from './filtres-actions-utilisateurs/filtres-actions-utilisateurs.component';
import {FiltresTelephonieComponent} from './filtres-telephonie/filtres-telephonie.component';
import {FiltresStatusActuelComponent} from './filtres-status-actuel/filtres-status-actuel.component';
import {FiltresInfoAncienContratComponent} from './filtres-info-ancien-contrat/filtres-info-ancien-contrat.component';
import {FiltresOpportunitesComponent} from './filtres-opportunites/filtres-opportunites.component';
import {SharedModule} from '../../../../shared/shared.module';

import {MatTreeModule} from '@angular/material/tree';

@NgModule({
  declarations: [
    FiltresProspectsComponent,
    FiltresUtilisateursComponent,
    FiltresActionsUtilisateursComponent,
    FiltresTelephonieComponent,
    FiltresStatusActuelComponent,
    FiltresInfoAncienContratComponent,
    FiltresOpportunitesComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,

  ],
  exports: [
    FiltresProspectsComponent,
    FiltresUtilisateursComponent,
    FiltresActionsUtilisateursComponent,
    FiltresTelephonieComponent,
    FiltresStatusActuelComponent,
    FiltresInfoAncienContratComponent,
    FiltresOpportunitesComponent,
  ]
})
export class FiltresOpportuniteModule { }
