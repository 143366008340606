import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-timezone';

export function formatDateToLocal(DBdate: any) {
  return moment(DBdate).format('YYYY/MM/DD HH:mm').toString();
}
export function UniformiserDateToLocal(DBdate: any) {
  return moment(DBdate).format('DD/MM/YYYY HH:mm').toString();
}
export function UniformiserDateToLocalSansHeure(DBdate: any) {
  return moment(DBdate).format('DD/MM/YYYY').toString();
}
export function parseDate(dateStr: string, format: string = 'DD-MM-YYYYThh:mm:ssZ') {
  if (dateStr === (undefined || null)) {
    return null;
  } else {
    return moment(dateStr, format).toDate();
  }
}

export function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null;
  };
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
