import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';

import { Relations } from '../../../entity/Relations';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.css'],
})
export class RelationsComponent implements OnInit, OnChanges {
  dataTable: any;
  relations: Relations[] = [];
  show_loading_add: boolean = false;
  @Input() prospect: string = '';
  @Input() opportunite_id: string;
  @Output() emitProspectRelation: EventEmitter<string> = new EventEmitter<string>();
  miniListing: any;
  constructor(
    private apiProspectService: ApiProspectsService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.miniListing = object.miniListing;
        this.getRelations(this.prospect);
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.miniListing = object.miniListing;
      this.getRelations(this.prospect);
    });
  }

  getRelations(id) {
    this.apiProspectService.getProspectRelations(id).subscribe(
      (data: Relations[]) => {
        this.relations = data;
        this.chRef.detectChanges();
        const table: any = $('#TableRelations');
        this.dataTable = table.DataTable({
          destroy: true,
          stateSave: true,
          retrieve: true,
          language: {
            sProcessing: this.miniListing.traitement,
            searchPlaceholder: this.miniListing.search,
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
            sInfo:
              this.miniListing.displayingElement +
              '_START_' +
              this.miniListing.label_a +
              '_END_' +
              this.miniListing.label_sur +
              '_TOTAL_' +
              this.miniListing.element,
            sInfoEmpty: this.miniListing.sInfoEmpty,
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: this.miniListing.chargement,
            sZeroRecords: this.miniListing.noRecords,
            sEmptyTable:
              `<div class="col-12 mt-2 d-flex justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36" fill="none">
                <g clip-path="url(#clip0_1805_13925)">
                  <path d="M15.4954 0C14.2758 0 13.2905 0.996855 13.2905 2.23073V3.56916C8.2606 4.60087 4.47095 9.10415 4.47095 14.4997V15.8103C4.47095 19.0866 3.27894 22.2515 1.12917 24.7053L0.619293 25.2839C0.0405103 25.9392 -0.0972952 26.8802 0.254109 27.6819C0.605512 28.4836 1.39789 28.9994 2.26607 28.9994H28.7247C29.5929 28.9994 30.3784 28.4836 30.7367 27.6819C31.095 26.8802 30.9503 25.9392 30.3715 25.2839L29.8616 24.7053C27.7118 22.2515 26.5198 19.0936 26.5198 15.8103V14.4997C26.5198 9.10415 22.7302 4.60087 17.7003 3.56916V2.23073C17.7003 0.996855 16.715 0 15.4954 0ZM18.6167 34.388C19.4435 33.5515 19.9052 32.4152 19.9052 31.2302H15.4954H11.0856C11.0856 32.4152 11.5473 33.5515 12.3741 34.388C13.2009 35.2245 14.324 35.6916 15.4954 35.6916C16.6667 35.6916 17.7898 35.2245 18.6167 34.388Z" fill="#959494"/>
                </g>
                <defs>
                  <clipPath id="clip0_1805_13925">
                    <rect width="30.8684" height="35.6916" fill="white" transform="translate(0.0625)"/>
                  </clipPath>
                </defs>
              </svg>
          </div>
          <div class="col-12  d-flex justify-content-center">
            <p class="noData">` +
              this.miniListing.emptyTable +
              `</p>
          </div>`,
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: this.miniListing.sSortAscending,
              sSortDescending: this.miniListing.sSortDescending,
            },
            select: {
              rows: {
                _: '%d' + this.miniListing.selectedLines,
                0: this.miniListing.zeroselected,
                1: this.miniListing.oneselected,
              },
            },
          },
        });
        this.dataTable.page.len(10).draw();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onEmitRelationProspect(idProspect, IdOpportunite): void {
    this.emitProspectRelation.emit(idProspect);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/prospectClient/fiche-prospect/' + IdOpportunite + '/' + idProspect + '/opportunite'])
    );
    window.open(url, '_blank');
  }
}
