import { Observable, Subject } from 'rxjs';
/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiDocumentsService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  uploadDocument$ = new Subject<any>();
  loadDocument$ = new Subject<any>();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  uploadDocument(): Observable<any> {
    return this.uploadDocument$.asObservable();
  }

  loadDocuments(): Observable<any> {
    return this.loadDocument$.asObservable();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sert a afficher La liste des document suivant l&#x27;identifiant de contrat codé en md5
   *
   * @param uploadFile &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public UploadDocuments(uploadFile: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (uploadFile === null || uploadFile === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling listDocumentContratMobileGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/upload_document_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: uploadFile,
    });
  }

  /**
   * Sert a afficher La liste des document suivant l&#x27;identifiant de contrat codé en md5
   *
   * @param idAffaire &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadListDocumentAffaire(
    idAffaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling loadListDocumentAffaire.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/load_list_document_affaire/${idAffaire}?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher La liste des document suivant l&#x27;identifiant de contrat codé en md5
   *
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listDocumentContratMobileGet(idContrat: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listDocumentContratMobileGet(
    idContrat: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public listDocumentContratMobileGet(
    idContrat: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public listDocumentContratMobileGet(
    idContrat: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling listDocumentContratMobileGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_document_contrat_mobile`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Permet de retourner les informations spécifiques d'un document
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetInformationSpecifique(
    bodyinformation: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/type_document/get`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      body: bodyinformation,
    });
  }

  /**
   * Permet d'enregistrer les informations spécifiques d'un document
   *
   * @param idDoc Identifiant d'un document
   * @param idEntity Identifiant d'un document
   * @param model données du formulaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SaveInformationSpecifique(
    model: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/type_document/update`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: model,
    });
  }

  /**
   * Permet d'enregistrer les informations spécifiques d'un document
   *
   * @param idDoc Identifiant d'un document
   * @param idEntity Identifiant d'un document
   * @param model données du formulaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SaveInformationSpecifiqueA(
    model: any,
    accessToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    if (accessToken) {
      headers = headers.set('idSession', accessToken);
    } else {
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/type_document/update`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: model,
    });
  }


public generateDocument(
  _affaire_id: string,
  md5_id: string,
  observe: any = 'body',
  reportProgress: boolean = false
): Observable<any> {
  const queryParameters = new HttpParams();

  let headers = this.defaultHeaders;

  if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
    headers = headers.set('idSession', this.configuration.apiKeys.idSession);
  }

  // Determine the 'Accept' header
  const httpHeaderAccepts: string[] = ['application/json'];
  const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  if (httpHeaderAcceptSelected !== undefined) {
    headers = headers.set('Accept', httpHeaderAcceptSelected);
  }

  return this.httpClient.get<any>(`${this.basePath}/generate_document/${_affaire_id}/${md5_id}`, {
    params: queryParameters,
    withCredentials: this.configuration.withCredentials,
    headers,
    observe,
    reportProgress
  });
}







}
