import {FormField} from './FormField';
import {CustomWidget} from './SharedElementsFormly';

export class DateFormly extends FormField {
  description : string =""
  pattern=""
  urlValidation=""
  autofill="";
  urlIdentifiant=""
   default=""
  constructor() {
    super();
    super.icon="fa fa-calendar";
    super.name = 'date_' + super.generateRandomId();
    super.type = 'string';
    super.typename = 'Date';
    super.title='Date'
    super.format = 'date-time';
    this.widget.formlyConfig.templateOptions.type = 'date';
  }


}
