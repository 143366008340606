import { MailevaElement } from './MailevaElement';

export class MailEvaList {
  aaData: MailevaElement[] = [];
  iTotalDisplayRecords: number;
  iTotalCourrierRecords: number;
  iTotalEnvoyeRecords: number;
  iTotalplanifieRecords: number;
  iTotalEchoueRecords: number;
  min_date_depot : any;
  max_date_depot : any;

}
