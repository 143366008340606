<form  #myForm="ngForm" (ngSubmit)="EnregistrerBanque(myForm.form.valid, myForm._directives)">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9 mt-1" >
                <p>
                      {{'languages.affaire.Banque' | translate}}:
                    <label class="label-card-info">
            {{Banque.nom}}
          </label>
                </p>
                <p>
                    {{'languages.affaire.codeSwift' | translate}} :
                    <label class="label-card-info">
            {{Banque.code_swift}}
          </label>
                </p>
            </div>

        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
                <div class="icon-position">
                    <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
                </div>
            </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    <div class="col mt-1" *ngIf="ModeAjout" >
                        <button type="button" class="btn-load" (click)="clearForm()">
              <i class="fa-solid fa-rotate-right"></i>
            </button>
                    </div>
                </div>
                <button *ngIf="ModeAjout" class="btn-dt-save" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
           {{'languages.buttons.enregistrer' | translate}}
        </button>
            </div>
        </div>
    </div>
    <div class="card p-4 m-3" [ngStyle]="!ModeAjout ? {'pointer-events': 'none'} : {}">
        <div class="row">
            <div class="col-12">
                <span class="text-danger">( * ) {{'languages.affaire.champsOblig' | translate}} ! </span>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label> {{'languages.affaire.name' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.nom"  name="{{'languages.affaire.name' | translate}}"  required #nom="ngModel" [readonly]="!ModeAjout">
                        <mat-error><span>  {{'languages.produit.nomOblig' | translate}}
                            </span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label> {{'languages.affaire.codeSwift' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.code_swift"  name="{{'languages.affaire.codeSwift' | translate}}" required #code_swift="ngModel" [readonly]="!ModeAjout">
                        <mat-error><span> {{'languages.affaire.checkSwiftOblig' | translate}}
                        </span></mat-error>
                    </mat-form-field>


                </div>

            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label> {{'languages.affaire.codeBanque' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.code_banque"  name="{{'languages.affaire.codeBanque' | translate}}" required #code_banque="ngModel" [readonly]="!ModeAjout">
                        <mat-error   ><span>  {{'languages.affaire.checkCodeBanque' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>
        </div>



        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">

                    <app-auto-complete-adresses  [isReadOnlyAdresse]="ModeAjout"  (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                    </app-auto-complete-adresses>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label> {{'languages.prospect.pays' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.pays" required #pays="ngModel" name="{{'languages.prospect.pays' | translate}}" [readonly]="!ModeAjout">
                        <mat-error><span>  {{'languages.prospect.checkPays' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>{{'languages.prospect.CodePostal' | translate}}</mat-label>
                        <input  matInput [(ngModel)]="Banque.adresse.code_postal" required #cp="ngModel" name="{{'languages.prospect.CodePostal' | translate}}" [readonly]="!ModeAjout">
                        <mat-error><span>  {{'languages.prospect.checkCodePostal' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>



        </div>

        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>{{'languages.prospect.Ville' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.ville" required #ville="ngModel" name="{{'languages.prospect.Ville' | translate}}" [readonly]="!ModeAjout">
                        <mat-error><span> {{'languages.prospect.checkVille' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label> {{'languages.prospect.Rue' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.streetName" required #nom_de_rue="ngModel" name="{{'languages.prospect.Rue' | translate}}" [readonly]="!ModeAjout">
                        <mat-error><span> {{'languages.prospect.check_rue_Oblig' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>{{'languages.prospect.streetNumber' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.streetNumber" required #numero_de_rue="ngModel" name="{{'languages.prospect.streetNumber' | translate}}" [readonly]="!ModeAjout">
                        <mat-error><span>  {{'languages.prospect.check_streetNumber' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>
            </div>

            </div>


    </div>
    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content full-width">
                <div class="modal-header">
                    <h2 class="mt-2" >{{'languages.affaire.logoCompagnie' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row d-flex justify-content-center">
                        <img class="img" >
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>
