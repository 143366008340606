import { UserAdminList } from '../User/UserAdminList';
import { Acces } from '../User/UserAdmin';
import { OrganismeListe } from '../OrganismeListe';
import { Zone } from '../Zone';
import { GroupUser } from '../User/GroupUser';

export class classe {
  color: string;
  icon: string;
  id: string;
  libele: string;
}
export class campagne {
  id: string;
  libelle: string;
}
export class cycle_de_vie {
  id: string;
  libele: string;
  statut_depart: string;
}
export class tags {
  active: string;
  couleur: string;
  libelle: string;
  id: string;
}
export class type_relation {
  color: string;
  date_depot: string;
  icon: string;
  id: string;
  last_update: string;
  lexique: string;
  libelle: string;
}
export class tags_pros {
  id: string;
  libelle: string;
}
export class zones {
  id: string;
  nom: string;
}
export class FiltreProspects {
  nom_prenom: string = '';
  telephone: string = '';
  adresse_mail: string = '';
  ville_CP: string = '';
  RS: string = '';
  tags_propect: tags_pros[] = [];
  zones: zones[] = [];
}

export class RendezVous {
  etat_rdv: any[] = [];
}

export class ActionUser {
  dernier_action: string = '';
  date_creation_action: string = '';
  date_fin_action: string = '';
  heure_creation_action: string = '';
  heure_fin_action: string = '';
  date_debut_objectif: string = '';
  date_fin_objectif: string = '';
  heure_debut_objectif: string = '';
  heure_fin_objectf: string = '';
  list_user: any[] = [];
  groupes: any[] = [];
  enreg_simple: string = '';
  enreg_action: string = '';
  analyse: string = '';
  status_action: any[] = [];
  categorie: any[] = [];
  operateur: string = '';
}

export class Analyse {
  analyse: string = '';
  type_graphe: string = '';
  palier: string = '';
  critere_analyse: string = '';
}

export class Marketing {
  utm_source: string = '';
  utm_meduim: string = '';
  utml_campaign: string = '';
  utm_term: string = '';
}

export class Telephonie {
  contient: string = '';
  duree: string = '';
  date_debut_appel: string = '';
  date_fin_appel: string = '';
  heure_debut_appel: string = '';
  heure_fin_appel: string = '';
  list_user: string[] = [];
  groupes: string[] = [];
  operateur: string = '';
  operateur_duree: string = '';
  nb_appel: string = '';
  decision_appar: string = '';
  operateur_nb_appel: string = '';
}

export class Export {
  export: string = '';
  type_export: string = '';
  modele: string = '';
  list_feuilles: string = '';
}

export class InformationsAncienContrat {
  dossier_assure: string = '';
  operateur: string = '';
  pan_ttc: string = '';
  date_ech: string = '';
  list_cie: any[] = [];
}

export class Utilisateur {
  list_createurs: UserAdminList[] = [];
  organismes: OrganismeListe[] = [];
  list_affectation: UserAdminList[] = [];
  groupes_affecte: GroupUser[] = [];
  groupes_createurs: GroupUser[] = [];
  operateur_recherche_affect: string = '=';
  operateur_recherche_group: string = '';
  operateur_recherche_group_affet: string = '';
  zones: Zone[] = [];
  services: Acces[] = [];

  constructor() {}
}
export class Opportunite {
  campagne: campagne[] = [];
  produit: any[] = [];
  date_creation: string = null;
  date_fin: string = null;
  heure_creation: string = '';
  heure_fin: string = '';
  cycle_de_vie: cycle_de_vie[] = [];
  classe: classe[] = [];
  tags_contient: tags[] = [];
  type_opportuntie: any[] = [];
  type_relation: type_relation[] = [];
  date_debut_objectif: string = null;
  date_fin_objectif: string = null;
  heure_debut_objectif: string = '';
  heure_fin_objectif: string = '';
  desicion_contient?: string = '=';
}

export class FilterOpportunite {
  filtre_opp: Opportunite = new Opportunite();
  prospect: FiltreProspects = new FiltreProspects();
  utilisateur: Utilisateur = new Utilisateur();
  info_contrat: InformationsAncienContrat = new InformationsAncienContrat();
  rdv: RendezVous = new RendezVous();
  action_user: ActionUser = new ActionUser();
  telephonie: Telephonie = new Telephonie();
  marketing: Marketing = new Marketing();
  sSearch: string = '';
}
export class LastUpdate {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  months: number;
  years: number;
}
export class ListFilterCollection {
  collection: number;
  entity: string;
  input: FilterOpportunite[];
  date_creation: LastUpdate;
  libelle: string;
  user: number;
}
