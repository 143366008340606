<div class="row" style="margin-top: 15px">
  <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>{{'languages.campagne.Organisme' | translate}}</mat-label>
          <ng-container class="p-3" *ngIf="allOrganisme?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #organisme>
            <mat-chip *ngFor="let organisme of ListOrganisme" [selectable]="selectable" [removable]="removable"
              (removed)="removeOrganisme(organisme)" (removed)="EmitFilterUtilisateur()">
              {{organisme}}
              <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_organisme_select' | translate}} "  #OrganismeInput
              [formControl]="organismeFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="organisme"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOrganisme($event)">
            <mat-option *ngFor="let organisme of filteredOrganisme | async" [value]="organisme.id">
              {{organisme.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>{{'languages.opportunite.label_listcreators' | translate}}</mat-label>
          <ng-container class="p-3" *ngIf="allCreateur?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #ListeCreateur>
            <mat-chip *ngFor="let createur of ListCreateur" [selectable]="selectable" [removable]="removable"
              (removed)="removeCreateur(createur)" (removed)="EmitFilterUtilisateur()">
              {{createur}}
              <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_listcreators_select' | translate}}"  #CreateurInput
              [formControl]="CreateurFromCtrl" [matAutocomplete]="autoCreateur" [matChipInputFor]="ListeCreateur"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
            <mat-option *ngFor="let createur of filteredCreateur | async" [value]="createur.id">
              {{createur.nom +" "+ createur.prenom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<div class="row operateurContainer" >

  <div class="col-2 d-flex justify-content-center align-items-center">
    <button (click)="selectOptionAffect('=')" class="operateur mr-3" [ngClass]="{ 'active': Utilisateur?.operateur_recherche_affect === '=' }">
        <i class="fa-solid fa-equals"></i>
    </button>
<button (click)="selectOptionAffect('!=')"  class="operateur" [ngClass]="{ 'active': Utilisateur?.operateur_recherche_affect === '!=' }">
<i class="fa-solid fa-not-equal"></i>
</button>

</div>
  <div class="col-10 mt-2">
    <div class="ml-5">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>{{'languages.opportunite.label_listassignments' | translate}}</mat-label>
          <ng-container class="p-3" *ngIf="allListeAffectation?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #ListeAffectation>
            <mat-chip *ngFor="let affectation of ListAffectation" [selectable]="selectable" [removable]="removable"
              (removed)="removeListeAffectation(affectation)" (removed)="EmitFilterUtilisateur()">
              {{affectation}}
              <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input placeholder="{{'languages.opportunite.label_listassignments_select' | translate}}"
              #listeAffectationInput [formControl]="listeAffectationFromCtrl" [matAutocomplete]="autoListeAffectation"
              [matChipInputFor]="ListeAffectation" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoListeAffectation="matAutocomplete" (optionSelected)="selectedListeAffectation($event)">
            <mat-option *ngFor="let affectation of filteredListeAffectation | async" [value]="affectation.id">
              {{affectation.nom +" "+affectation.prenom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<!-- <div class="row" style="margin-top: -11px;display: none;">
  <div class="col-2">
    <div class="form-group" style="margin-bottom: 0px;">
      <label></label>
      <mat-form-field appearance="fill" style="width: 100%;margin-top: -11px;">
        <mat-select (selectionChange)="EmitFilterUtilisateur()"
          [(ngModel)]="Utilisateur.operateur_recherche_group_affet" placeholder="{{'languages.opportunite.label_operateur' | translate}}">
          <mat-option value="OU">
           {{'languages.opportunite.label_or' | translate}}
          </mat-option>
          <mat-option value="ET">
          {{'languages.opportunite.label_and' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <div class="col-10">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Groupes Affectés</mat-label>
          <ng-container class="p-3" *ngIf="allGroupeAffectee?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #GroupesAffectes>
            <mat-chip *ngFor="let groupe of ListGroupeAffectee" [selectable]="selectable" [removable]="removable"
              (removed)="removeGroupeAffectee(groupe)" (removed)="EmitFilterUtilisateur()">
              {{groupe}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_affectedgroups_select' | translate}}"  #groupeAffecteeInput
              [formControl]="groupeAffecteeFromCtrl" [matAutocomplete]="autoGroupeAffectee"
              [matChipInputFor]="GroupesAffectes" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoGroupeAffectee="matAutocomplete" (optionSelected)="selectedGroupeAffectee($event)">
            <mat-option *ngFor="let groupe of filteredGroupeAffectee | async" [value]="groupe.id">
              {{groupe.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<div class="row" style="margin-top: -11px;display: none;">
  <div class="col-2">
    <div class="form-group" style="margin-bottom: 0px;">
      <label></label>
      <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
        <mat-select (selectionChange)="EmitFilterUtilisateur()" [(ngModel)]="Utilisateur.operateur_recherche_group"
          placeholder="{{'languages.opportunite.label_operateur' | translate}}">
          <mat-option value="OU">
            {{'languages.opportunite.label_or' | translate}}
          </mat-option>
          <mat-option value="ET">
          {{'languages.opportunite.label_and' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-10">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Groupes créateurs</mat-label>
          <ng-container class="p-3" *ngIf="allGroupeAffectee?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #Groupescreateurs>
            <mat-chip *ngFor="let groupe of ListGroupeCreateur" [selectable]="selectable" [removable]="removable"
              (removed)="removeGroupeCreateur(groupe)" (removed)="EmitFilterUtilisateur()">
              {{groupe}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_creativegroups_select' | translate}}"
              #groupeCreateurInput [formControl]="groupeCreateurFromCtrl" [matAutocomplete]="autoGroupeCreateur"
              [matChipInputFor]="Groupescreateurs" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoGroupeCreateur="matAutocomplete" (optionSelected)="selectedGroupeCreateur($event)">
            <mat-option *ngFor="let groupe of filteredGroupeCreateur | async" [value]="groupe.id">
              {{groupe.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div> -->
<div class="row" style="margin-top: -12px;">
  <!-- <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Zone</mat-label>
          <ng-container class="p-3" *ngIf="allZones?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #Zone>
            <mat-chip *ngFor="let Zone of ListZones" [selectable]="selectable" [removable]="removable"
              (removed)="removeZones(Zone)" (removed)="EmitFilterUtilisateur()">
              {{Zone}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_zone_select' | translate}}"   #ZonesInput
              [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
            <mat-option *ngFor="let Zone of filteredZones | async" [value]="Zone.id">
              {{Zone.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div> -->
  <!-- <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Services</mat-label>
          <ng-container class="p-3" *ngIf="allService?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #Services>
            <mat-chip *ngFor="let service of ListService" [selectable]="selectable" [removable]="removable"
              (removed)="removeService(service)" (removed)="EmitFilterUtilisateur()">
              {{service}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_services_select' | translate}}"  #serviceInput
              [formControl]="serviceFromCtrl" [matAutocomplete]="autoService" [matChipInputFor]="Services"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoService="matAutocomplete" (optionSelected)="selectedService($event)">
            <mat-option *ngFor="let service of filteredService | async" [value]="service.id">
              {{service.libele}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div> -->
</div>
