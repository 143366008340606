export class Compagnie {
  mail_sav: string = '';
  mail_production: string = '';
  blob: string = '';
  url: string = '';
  logo_cie: any;
  siren: string = '';
  siren_md5: string = '';
  cp: string = '';
  ville: string = '';
  streetName: string = '';
  streetNumber: string = '';
  adresse: string = '';
  matricule: string = '';
  denomination: string = '';
  mail_commercial: string = '';
  types: string = '';
  tel_production: string = '';
  tel_sav: string = '';
  tel_commercial: string = '';
  id: string = '';
  libelle: string = '';
  form: any;
  constructor() {}
}
