<div class="flex-column bg-white card ">
    <div class="p-3 mb-3">
  
      <div class="row d-flex  justify-content-end align-items-end">
        <div class="col-md-6 d-flex align-items-center justify-content-end mb-3 pl-5 ">
          <div *ngIf="isPasswordInputVisible" class="row passwordDiv">
            <img class=" col-2" src="assets/icons/lock.svg">
            <div class="col-9 d-flex  justify-content-end pl-0">
              <mat-form-field class="full-width">
                <input matInput [type]="showPassword ? 'text' : 'password'"
                  placeholder="{{'languages.affaire.writePassword' | translate}}" [(ngModel)]="password"
                  (change)="validPass()" autocomplete="new-password">
                <mat-hint>{{'languages.alerts.ableToModify' | translate}}</mat-hint>
              </mat-form-field>
              <button type="button" (click)="togglePasswordVisibility()" [disabled]="allow_to_change" class="eyeBtn">
                <i class="fa-solid fa-eye-slash fa fa-lg" *ngIf="!showPassword"></i>
                <i class="fa-solid  fa-eye fa fa-lg" *ngIf="showPassword"></i>
              </button>
  
            </div>
          </div>
          <div class="col-1">
            <button type="button" (click)="togglePasswordInput()" [disabled]="allow_to_change" class="btn-lock ">
              <i class="fa-solid fa-lock-open fa fa-lg" *ngIf="allow_to_change"></i>
              <i class="fa-solid fa-lock fa fa-lg" *ngIf=" !allow_to_change"></i>
            </button>
          </div>
        </div>
  
  
      </div>



      <div class="row d-flex justify-content-around">
        <div class="col-2 selectBox d-flex  justify-content-center align-items-center"
          [ngClass]="{'selectedBox': type === 'affaire'}" (click)="changeType('affaire')">
          <div class="d-flex mt-2 flex-column justify-content-center align-items-center">
            <i class="fa-solid fa-file-contract mb-3 fa fa-lg"></i>
            <span> {{"languages.listing.Affaire" | translate}}</span>
          </div>
        </div>
        <div class="col-2 selectBox d-flex justify-content-center align-items-center"
          [ngClass]="{'selectedBox': type === 'ticket'}" (click)="changeType('ticket')">
          <div class="d-flex mt-2 flex-column justify-content-center align-items-center">
            <i class="fa-solid fa-ticket mb-3 fa fa-lg"></i>
            <span> {{"languages.navbar.ticketSupport" | translate}}</span>
          </div>
  
        </div>

      </div>
  
  
  
  
    </div>
  </div>



  <div class="flex-column bg-white card my-3">
    <div class="p-3 mb-3">
  
      <div class="row d-flex justify-content-evenly align-items-center">
  
        <div class="col-8 title d-flex align-items-center justify-content-start">
          <i class=" {{icon}} mr-3 fa fa-lg"></i>
          <span> {{title}}</span>
        </div>
  
        <div *ngIf="type=== 'affaire'|| type==='ticket' " class="col-4 w-50  p-2 mt-2 text-right">
          <input class="search" name="search" [(ngModel)]="search" (input)="onInputChange($event.target.value)"
            placeholder="{{'languages.miniListing.search' | translate}}">
          <i class="fa fa-search icon-search fa-md"></i>
        </div>
  
      </div>
  
      <ng-container class="mt-3" *ngIf="show_loading">
        <div class="row  ">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="customWidth">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </div>
        </div>
      </ng-container>
  
      <table id="Table" class="table mt-3" *ngIf="!show_loading && listFrais.length >0">
        <thead class="head-table">
          <tr>
            <th> # </th>
            <th> {{"languages.alerts.Libelle" | translate}} </th>
            <th> {{"languages.listing.Taxes" | translate}}</th>
          </tr>
        </thead>
  
  
        <tbody class="body-table">
          <tr *ngFor="let item of listFrais; let index = index">
            <td>{{ index+1 }}</td>
            <td>{{ item.type_frais }}</td>
            <td>
  
              <i class="fa-solid fa-lock fa fa-lg" *ngIf="!allow_to_change"></i>
              <div class="d-flex align-items-center justify-content-center" *ngIf="allow_to_change">
                <div class="col-10">
                    <mat-form-field class="w-100">

                       
                        <ng-container class="p-3" *ngIf="!showListTaxes">
                          <div class="row">
                            <div class="text-center m-auto">
                              <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                          </div>
                        </ng-container>
                        <mat-chip-list #chipList>
                          <mat-chip *ngFor="let tax of item.taxe" [selectable]="selectable" [removable]="removable"
                          (removed)="removeTaxes(tax, index)" >
                            {{tax.libelle}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                          </mat-chip>
                          <input placeholder="{{'languages.listing.selectTaxe' | translate}}" #taxesInput [formControl]="taxeFromCtrl"
                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedtaxes($event, index ,taxesInput)">
                          <mat-option *ngFor="let tax of filteredtaxes | async" [value]="tax">
                            {{tax.libelle}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
  
                </div>
  
                <div class="col-2 mb-3 d-flex justify-content-center align-items-center">
                  <button class="btn-dt-save d-flex justify-content-center align-items-center"
                  (click)="confirmUpdate(index, type)">
                    <i class="fa-solid fa-circle-check fa-lg mr-3"></i>
                    {{"languages.buttons.confirmer" | translate}}
                  </button>
                </div>
  
              </div>
  
            </td>
  
          </tr>
        </tbody>
      </table>
      <div class="navbar-center mb-4 row" *ngIf="listFrais.length >0"  >
        <div class="col pt-1">
          <div class="row w-50 flex-nowrap">
            <div class="col mt-1">
              <p>{{'languages.miniListing.Afficher' | translate}}</p>
            </div>
            <div class="col mx-1">
              <select [(ngModel)]="limit" (change)="updateListFrais()"
                class="bg-white border pt-1 rounded font-weight-bold">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col mt-1 text-nowrap">
              <p>{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' |
                translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-8">
          <nav aria-label="Page navigation example" *ngIf="totalPages>0">
            <ul class="pagination pg-blue">
              <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                <a class="page-link-symbol">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Previous">
                  <i class="fa fa-chevron-left "></i>
                </a>
              </li>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page-3>0" class="page-item">
                  <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
                </li>
              </ng-container>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page<totalPages" class="page-item">
                  <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                    [ngClass]="{'active-page':page==(i+page)}">
                    {{i + page}}
                  </a>
                </li>
              </ng-container>
              <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
              <li class="page-item">
                <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                  [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
              </li>
              <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Next">
                  <i class="fa fa-chevron-right"></i>
                </a>
              </li>
              <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                <a class="page-link-symbol">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col">
          <div class="row position-absolute flex-nowrap" style="right: 0px">
            <div class="col text-nowrap text-right mt-2">
              <p>{{'languages.listing.label_goto' | translate}}</p>
            </div>
            <div class="col mt-1 ml-2">
              <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
                size="1" class="page_input bg-white border rounded font-weight-bold" [value]="page" max="{{totalPages}}">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center justify-content-center" *ngIf="listFrais.length ==0">
        <p class="text-center justify-content-center">{{'languages.miniListing.noRecords' | translate}}</p>
      </div>
    </div>
  </div>