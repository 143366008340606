

<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="col-12">
                            <div class="spinner-style">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col mr-3">
                    <button type="button" class="btn-load" (click)="clearForm()">
          <i class="fa-solid fa-rotate-right"></i>
    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="add()" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
       {{'languages.buttons.enregistrer' | translate}}
  </button>
        </div>
    </div>
</div>
<!--
-->

<div class="card border-0 m-3 mb-5 p-3 ">
    <div class="row ">
        <div class="col-md-6">
            <h2 *ngIf="ModeAjout">{{'languages.garantie.addGroupGarantie' | translate}}</h2>
            <h2 *ngIf="!ModeAjout"> {{'languages.garantie.modifyGroupGarantie' | translate}}</h2>

        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <span class="text-danger">( * )  {{'languages.affaire.champsOblig' | translate}}</span>
        </div>
    </div>
    <hr>

    <div class="row ">
        <div class="col">
            <form [formGroup]="grantieForm" (ngSubmit)="add()">

                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{'languages.alerts.Libelle' | translate}}</mat-label>
                                <input type="text" matInput formControlName="libelle" required placeholder="{{'languages.alerts.Libelle' | translate}}">
                                <mat-error
                                *ngIf="grantieForm.get('libelle').hasError('required') && grantieForm.get('libelle').touched">
                               {{'languages.communication.libelleOblig' | translate}} 
                            </mat-error>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label> {{'languages.ticket.description' | translate}}</mat-label>
                                <textarea matInput placeholder="{{'languages.ticket.description' | translate}}" rows="1" formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                    <app-icon-picker 
                                    [searchText]="icon" 
                                    [req]="false" 
                                    (onChangeIcon)="onSelectIcon($event)" 
                                    (onChangeNameIcon)="onSelectNameIcon($event)"
                                    (checkedNameIcon)="onCheckedNameIcon($event)"
                                    ></app-icon-picker>
                        </div>
                    </div>
                </div>
            </form>

        </div>

    </div>



</div>
