import {Component, OnInit} from '@angular/core';
 

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.css']
})
export class FormFieldsComponent implements OnInit {
  show_list: boolean = false;
  show_loading: boolean = false;

  showList() {
    this.show_list = !this.show_list
  }

  code_form_group: any = `
<div class="row">
  <div class="col-7">
    <div class="form-group-container">
      <p class="form-group-container-title">Contact</p>
      <div class="row">
        <!--Email-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Email</mat-label>
            <input matInput type="email">
          </mat-form-field>

        </div>
        <!--Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--2ème Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>2ème Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--Mobile-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Mobile</mat-label>
            <input type="mobile" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
</div>
  `;

  cardbody: any = `
  <div class="card-body-details p-3">
      Content
    <p>
      Compagnie n° :
      <label class="label-card-info">
        1215183
      </label>
    </p>
    <div class="alert-erreur">
      <i class="fa-solid fa-triangle-exclamation"></i>
      Alert d'erreur
    </div>
  </div>`;


code_card_doc: any = `
<div class="col-md-6 col-sm-12 accordion" id="folder_tableaux_de_garanties_collapse">
  <div class=" mb-4 ">
      <div class=" documentDiv" id="headingFolder">
          <div class="row flex-nowrap">
              <div class="col-1 mt-1 pl-0">
                  <i class="fa-solid fa-folder folder-icon"></i>
              </div>
              <div class="col-10 text-left cursor" data-toggle="collapse"
                  data-target="#tableaux_de_garanties_collapse" aria-expanded="false"
                  aria-controls="collapseTwo">
                  <p class="text-dark ml-3 mt-2">
                      Tableaux de garanties (Fichier PDF)
                  </p>
              </div>
              <div class="col-1 text-right mt-0 position-relative pl-0">
                  <button type="button" mat-icon-button
                      [matMenuTriggerFor]="menu_tableaux_de_garanties"
                      aria-label="Example icon-button with a menu">
                      <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>

                  </button>
                  <mat-menu #menu_tableaux_de_garanties="matMenu">
                      <button mat-menu-item>
                          <input type="file" accept=".pdf" id="tableaux_de_garanties"
                              name="tableaux_de_garanties"
                              hidden ngModel #tableaux_de_garanties="ngModel" />
                          <label for="tableaux_de_garanties" class="add-file">
                              <i class="fa-solid fa-circle-plus"></i>
                              Ajouter un fichier
                          </label> </button>
                  </mat-menu>
              </div>
          </div>
      </div>
      <div id="tableaux_de_garanties_collapse" 
          class="collapse" aria-labelledby="headingFolder"
          data-parent="#folder_tableaux_de_garanties_collapse">
          <div class="">
              <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                 
                  <div class="col-11 pl-4">
                 
                          <div >
                              <a href="lienDoc" target="_blank" class="file-name ">
         nom doc

                              </a>
                          </div>

                  </div>
                  <div class="col-1">
                      <button type="button" class="deleteFileDoc " 
                         >
                          <img src="assets/icons/closeIcon.svg">
                      </button>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
`;

boxShadowCard: any = `
<div class="shadow-box-card-body">
  Content
</div>
`;

code_list_tache_and_file: any = `
<div class="row">
<div class="col-12">
<div class="fileContainer p-3">
  <div class="row">
    <h2 class="title-details-blue ml-1">
    <i class="fa-solid fa-bars-staggered"></i>
      Joindre des fichiers
    </h2>
    <button type="button" class="btn-document-add  ajoutBtnDoc" data-title="Ajouter un fichier">
      <input type="file" hidden #fileDropRef2 id="audioDropRef2" accept="application/pdf" multiple  total-max-size="2000" />
      <label for="audioDropRef2" class="add-file">
      <i class="fa-solid fa-paperclip fa fa-lg fa-1x mt-2"></i>
      </label>
    </button>
  </div> 
              <div class="row scrollbar fileContainerChild">
                    <div class=" col-4  fileComment py-2 m-2  d-flex  align-items-center " >
                    <button  type="button"  class="deleteFile">
                      <img  src='../../../assets/icons/deleteIcon.svg'>
                      </button>
               
                    <img  src='../../../assets/icons/FileIcon.svg'>
                        
                          
                   <span class="file-name  links ml-2">Nom du document  </span>
               </div>
               
           

      
       
        </div>
</div>
</div>
</div>
`;

code_card_file: any = `
<div class="row">
  <div class="col-md-4 col-sm-12">
    <div class="card-folder border border-primary mb-4 ">
      <div class="card-header">
        <div class="row flex-nowrap" >
            <div class="col-1 mt-2 pl-0">
            <i class="fa-solid fa-file file-icon"></i>
            </div>
            <div class="col-9 text-left">
                <p class="text-dark ml-3 mt-2">
                 Nom du Fichier
                </p>
            </div>
            <div class="col-2 text-right mt-2 position-relative pl-0" >
            <i class="fa-solid fa-trash file-icon"></i>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

code_porte_document: any = `
<div class="card-doc bg-white p-3 border-0">
  <div class="card-doc-title ml-3 row" >
  <i class="fa-solid fa-book mr-3 mx-1 fa-lg"></i>
      <label class="title-card-details">Porte Documents</label>
  </div>

  <div class="row">
      <div class="col-md-12 p-4 div-list-exist scrollbar">
  
          <div class="col-4 light" >
            <div class="row flex-nowrap" style="cursor: pointer;">
              
                <div class="col-1 m-2 pl-0 greyColor" >
                    <i class="fa-solid fa-folder folder-icon"></i>
                </div>
                <div class="col-9" [attr.data-toggle]="'collapse'">
                    <p class=" greyColor ml-3 mt-2">
                      Divers <span *>( * )</span>
                    </p>
                    <p class="ml-3 greyColor text-dark ">
                        1 fichier(s)
                    </p>
                </div>
                <div class="col-2 text-right mt-3 position-relative pl-0" >
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                        <input type="file" hidden multiple  />
                        <label f class="add-file">
                          <i class="fa-solid fa-circle-plus"></i>
                          Ajouter un fichier
                        </label>
                      </button>
          
                        <button mat-menu-item data-toggle="modal" data-target="#formModal" >
                        <label class="add-file">
                        <i class="fa-solid fa-table"></i>
                          Formulaire
                        </label>
                      </button>
                    </mat-menu>
          
                </div>
            </div>
          </div>
      </div>
</div>

</div>

<div class="card-bloc bg-white p-3">
  <div class="card-doc-title ml-3 row" >
    <h2>Documents relatifs à l'affaire</h2>
</div>
<div class="row">
  <div class="col-md-6 div-list-no-exist p-3 mb-2">
      <div class="row">
          <label class="label-title mb-2 ml-2 text-secondary">Documents à Ajouter</label>
      </div>
  </div>
  <div class="col-md-6 div-list-exist p-3">
    <div class="row">
        <label class="label-title mb-2 text-primary">Documents Rajoutés</label>
    </div>
</div>
</div>
</div>
`;

  constructor() {
  }

  ngOnInit(): void {
  }

}
