<div class="form-group">
    <mat-form-field class="full-width">
      <mat-label>{{field.templateOptions.label}}</mat-label>
      <textarea 
        matInput 
        [formControl]="jsonFormControl" 
        [errorStateMatcher]="matcher" 
        [formlyAttributes]="field" 
        placeholder="{{'languages.formbuilderTransaltion.EnterJson' | translate}}">
      </textarea>
      <button mat-icon-button *ngIf="searchValue" matSuffix (click)="updateValue()">
        <mat-icon>refresh</mat-icon>
      </button>
      <div class="description-wrapper" *ngIf="field.templateOptions.description">
        <span class="description-text">{{ field.templateOptions.description }}</span>
      </div>
  
      <!-- <mat-error *ngIf="jsonFormControl.hasError('required') && (jsonFormControl.dirty || jsonFormControl.touched) ">
        {{'languages.formbuilderTransaltion.jsonContentRequired' | translate}} 
      </mat-error> -->
      <mat-error *ngIf="jsonFormControl.hasError('invalidJson')">
        {{'languages.formbuilderTransaltion.invalidJson' | translate}} 
      </mat-error>
      <mat-error *ngIf="jsonFormControl.hasError('invalidPattern')">
         {{'languages.formbuilderTransaltion.jsonRegexError' | translate}} 
      </mat-error>
    </mat-form-field>
  </div>
  