import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ApiParametreService } from 'src/app/services/ApiParametre/api-parametre.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
@Component({
  selector: 'app-gestion-taxe',
  templateUrl: './gestion-taxe.component.html',
  styleUrls: ['./gestion-taxe.component.css']
})
export class GestionTaxeComponent implements OnInit {
  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string = '';

  inputSubject = new Subject<string>();
  isPasswordInputVisible: boolean;
  password: string;
  showPassword: boolean;
  alerts: any;
  unsbscribeGetListFrais: Subscription;
  listFrais = [];
  allow_to_change: boolean;
  originalIdModeles: Map<number, string> = new Map();
  language: any;
  title: any;
  show_loading: boolean;
  type: string ="affaire";
  icon: string = 'fa-solid fa-file-contract';
  listProduct: any[] = [];
  listTaxesSelected: any[] = [];
  taxeFromCtrl: FormControl =  new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });


  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredtaxes: Observable<any[]>;
  listTaxes: string[] = [];
  alltaxes: any[] = [];
  showListTaxes: boolean;
  
  constructor(
    private translate: TranslateService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private parametresServices: ApiParametreService,
    private apiProduitService: ApiProduitsService,
    private notificationsService: NotificationsService
  ) {
    this.inputSubject.pipe(debounceTime(1000)).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionTaxe);
      this.language = object;
      this.title = this.language.listing.Affaire;
      this.alerts = object.alerts;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.language = object;
        this.title = this.language.listing.Affaire;
        this.alerts = object.alerts;
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionTaxe);
      });
    });

this.getListTypeTaxe();
    this.filteredtaxes = this.taxeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((tax: string | null) => (tax ? this._filter() : this.alltaxes.slice()))
    );

  }

  getListFrais(type: string) {
    this.show_loading = false;
    this.showloader();
    if (localStorage.getItem('page')) this.page = parseInt(localStorage.getItem('page'));
    if (localStorage.getItem('search')) this.search = localStorage.getItem('search');
    this.parametresServices
      .getListFraisManagement(type, this.limit, this.page, this.search)
      .subscribe(
        (response) => {
          this.listFrais = response.result;
          this.totalLength = response.total;
          this.calculate_nb_pages(this.totalLength);
          this.show_loading = false;
          this.chRef.detectChanges();
          this.hideloader()
           this.listFrais.forEach((frais,fraisIndex) => {
                frais.taxe = frais.taxe.map((taxeItem) => {
                    const matchedTax = this.alltaxes.find(
                        (tax) => tax.md5_id === taxeItem.id 
                    );
                    return matchedTax ;
                });
                this.listTaxesSelected[fraisIndex] = frais.taxe.map(tax => tax.md5_id);
                
        })},
        () => {
           this.listFrais=[];
          this.show_loading = false;
          this.hideloader()
        }
      );
      
  }

  togglePasswordInput() {
    this.isPasswordInputVisible = !this.isPasswordInputVisible;
    this.password = '';
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  validPass() {
    if (this.password) {
      if (this.password === environment.passwordUnlock) {
        this.allow_to_change = true;
        this.isPasswordInputVisible = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = this.alerts.Successoperation;
        notificationMessage.message = this.alerts.passwordValid;
        this.notificationsService.sendMessage(notificationMessage);
      } else {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = this.alerts.incorrectPassword;
        this.notificationsService.sendMessage(notificationMessage);
      }
    }
  }

  changeType(type) {
    this.type = type;
    this.page=1
    localStorage.setItem('page', this.page.toString());
    this.search =""
    localStorage.setItem('search', this.search);
    if (type === 'affaire') {
      this.title =  this.language.listing.Affaire
      this.icon = 'fa-solid fa-file-contract';
    } else {
      this.title = this.language.navbar.ticketSupport;
      this.icon = 'fa-solid fa-ticket';
    }
    this.getListFrais(this.type);
  }

  getListTypeTaxe() {
    this.showListTaxes=false
   this.apiProduitService.getAllTypeTaxe().subscribe((Response) => {
          this.alltaxes = Response.Motifs;
          this.showListTaxes=true
          this.getListFrais(this.type);
        })

    
  }

  
  

 
  selectedtaxes(event: MatAutocompleteSelectedEvent, index: number ,input: HTMLInputElement): void {
  
    const selectedTax =  event.option.value
  
    if (selectedTax && !this.listFrais[index].taxe.some(t => t.id === selectedTax.id)) {
   
      this.listFrais[index].taxe.push(selectedTax); 
      this.listTaxesSelected[index].push(selectedTax.md5_id); 
    }
  
    this.taxeFromCtrl.setValue(null);
    this.taxeFromCtrl.reset();
    input.value = '';
    this.chRef.detectChanges();
   
  }

  removeTaxes(tax: any, index: number): void {
   
    const taxIndex = this.listFrais[index].taxe.findIndex(t => t.id === tax.id);
    if (taxIndex >= 0) {
      this.listFrais[index].taxe.splice(taxIndex, 1); 
      this.listTaxesSelected[index].splice(taxIndex, 1); 
    }
  }

  private _filter(): any[] {
    let filterValue = this.taxeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.alltaxes.filter((tax) => tax.libelle.toLowerCase().includes(filterValue));
  }

  confirmUpdate(index: number, type: string) {
    const selectedItem = this.listFrais[index];
    
   
  
    swal.fire({
      title: this.alerts.confirmChangesTaxes,
      showDenyButton: true,
      confirmButtonText: this.alerts.Oui,
      denyButtonText: this.alerts.Non,
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
       this.updateFrais(index)
      }
    });
  }

  updateFrais(index){
    const body ={
      id_frais:this.listFrais[index].id,
      taxe:this.listTaxesSelected[index]
    }
    this.showloader();
    if (this.type === 'affaire'){
      this.apiProduitService.InsertUpdateFraisTax(body).subscribe((Response) => {
        this.hideloader();
        const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = this.alerts.Successoperation;
          notificationMessage.message = Response.message;
          this.notificationsService.sendMessage(notificationMessage);
      },
      (error) => {
        this.hideloader();
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      })
    }else{
      this.apiProduitService.InsertUpdateTicketFraisTax(body).subscribe((Response) => {
        this.hideloader();
        const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = this.alerts.Successoperation;
          notificationMessage.message = Response.message;
          this.notificationsService.sendMessage(notificationMessage);
      },
      (error) => {
        this.hideloader();
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      })
    }
    
  }


  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
  
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page', this.page.toString());
    this.getListFrais(this.type);
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', this.page.toString());
    this.getListFrais(this.type);
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      localStorage.setItem('page', this.page.toString());
      if (this.page <= this.totalPages) {
        this.getListFrais(this.type);
      }
    }
  }

  updateListFrais() {
    this.page = 1;
    this.getListFrais(this.type);
  }

  filter(event) {
    this.page = 1;

    localStorage.setItem('search', this.search);
    localStorage.setItem('page', this.page.toString());
    this.getListFrais(this.type);
  }

}
