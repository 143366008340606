import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Compagnie } from 'src/app/entity/Compagnie';
 
import * as $ from 'jquery';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-gestion-companies',
  templateUrl: './gestion-companies.component.html',
  styleUrls: ['./gestion-companies.component.css'],
})
export class GestionCompaniesComponent implements OnInit {
  miniListing: any;
  currentLanguage: string;
  inputSubject = new Subject<string>();
  constructor(
    private chRef: ChangeDetectorRef,
    private apiCampagniesService: ApiCampagniesService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService,
    private routes: Router
  ) {
     this.inputSubject.pipe(
    debounceTime(1000) 
  ).subscribe((inputValue: string) => {
    this.filter(inputValue);
  });
}

onInputChange(value: string): void {
  this.inputSubject.next(value);
}

  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-companies')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
  }

   
  Companies: Compagnie[] = [];
  dataTable: any;
  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionCompagnies);
      this.miniListing = object.miniListing;
    });


    this.translate.onLangChange.subscribe((data) => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionCompagnies);
        this.miniListing = object.miniListing;
      });
    });
    this.getListCompanies();
  }

  getListCompanies() {
    this.showloader();
    if(localStorage.getItem("page")) this.page = parseInt (localStorage.getItem("page"));
    if(localStorage.getItem("search")) this.search = localStorage.getItem("search");
    this.apiCampagniesService.getListCompagniesDetailsV1(this.limit, this.page,this.search).subscribe((data) => {
      this.Companies = data.Data;
      this.totalLength = data.totalCount;
      this.calculate_nb_pages(this.totalLength)
      this.chRef.detectChanges();
      this.hideloader();
    });
  }




  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }



  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string="";

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);

  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page',this.page.toString())
    this.getListCompanies();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number; 
    localStorage.setItem('page',this.page.toString())
    this.getListCompanies();
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.getListCompanies();
      }
    }
  }

  updateListCompagnie() {
    this.page = 1;
    this.getListCompanies();
  }
  
  filter(event) {
    this.page = 1;

      localStorage.setItem('search',this.search);
      localStorage.setItem('page',this.page.toString())
      this.getListCompanies();
      
    }
  



}
