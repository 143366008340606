import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { AncienDossier } from 'src/app/entity/Affaires/AncienDossier';
import { Documents } from 'src/app/entity/Affaires/Documents';
import { Parrainage } from 'src/app/entity/Generic/Parrainage';
import { Entreprise } from 'src/app/entity/Opportunity/Entreprise';
import { InformationTicket } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { Prospect } from '../../../../entity/Prospect';

@Component({
  selector: 'app-ticket-coordonnees-prospect',
  templateUrl: './coordonnees-prospect.component.html',
  styleUrls: ['./coordonnees-prospect.component.css'],
})
export class CoordonneesProspectComponent implements OnInit, OnDestroy {
  @Input() opportuniteId;
  @Input() prospect: Prospect = new Prospect();
  tickets_id: string = '';
  affaire_id: string = '';
  prospect_id: string = '';
  ListUnsubscribe: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiprospectServices: ApiProspectsService,
    private apiTicketsServices: ApiTicketsService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnDestroy(): void {
    this.ListUnsubscribe.forEach((element) => {
      element?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.tickets_id = params.get('tickets_id');
    });

    this.getInformationsTicket();
  }

  getInformationsTicket() {
    const UnsubscribeGetInformationTicketGet = this.apiTicketsServices
      .getInformationTicketGet(this.tickets_id)
      .subscribe((data: InformationTicket) => {
        if (data.id_prospect) this.getPospect(data.id_prospect);
        this.affaire_id = data.id_affaire;
        this.prospect_id = data.id_prospect;
      });
    this.ListUnsubscribe.push(UnsubscribeGetInformationTicketGet);
  }

  updateProspect($event: any, number: number) {}
  getPospect(id) {
    const UnsubscribeSelectProspectWithIdGet = this.apiprospectServices.selectProspectWithIdGet(id).subscribe(
      (data: Prospect) => {
        if (typeof data.form === 'string') data.form = JSON.parse(data.form);
        this.prospect = data;
      },
      (err) => {
        console.error(err);
      }
    );
    this.ListUnsubscribe.push(UnsubscribeSelectProspectWithIdGet);
  }

  toFiche() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        '/prospectClient/fiche-prospect/' + this.tickets_id + '/' + this.prospect_id + '/ticket',
      ])
    );
    window.open(url, '_blank');
  }
}
