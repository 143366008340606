<div class="row">
    <div style="position: fixed;    z-index: 5;">
        <app-filter [collapseFromParent]="collapseFromParent" (collapseCloseToparent)="getCollapseValue($event)" (onSubmitFilter)="getDataValuee($event)" (onPushFilters)="EmitFilterOpportunite($event)" [onRefreshFilter]="filtre" [filterSsearch]="filterSsearch" ></app-filter>
    </div>
</div>

<div class="row" #myDiv>
    <!-- Datatables -->
    <div class="col-lg-12 p-0">
        <div class="navbar-center row">
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{ 'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48" (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;" size="1" class="page_input bg-white border rounded font-weight-bold"
                            [value]="pages" max="{{totalPages}}">
                    </div>
                </div>
            </div>

        </div>

        <div class="card mb-4 border-0" style="border-radius: 13px; ">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-3 ">
                    <button type="button" class="btn-dt-add  ml-4" (click)="toAdd()" [attr.data-title]="'languages.opportunite.addOpp' | translate">
                        <i class="fa-solid fa-plus"></i>
          </button>
                </div>
                <div class="col-6 blocDate mt-2">
                    <div class="row d-flex justify-content-center align-items-center">
                        <!-- <div class="col-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{ 'languages.listing.label_debutdate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                                <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDebut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{ 'languages.listing.label_enddate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin | date:'yyyy-MM-dd'">
                                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                        </div> -->
                        <div class="col-2">
                            <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
                    </div>
                        <div class="col-8">

                              <mat-form-field appearance="fill" class="w-100">
                                <mat-date-range-input [rangePicker]="picker"  >
                                  <input matStartDate [(ngModel)]="date_debut" readonly placeholder=" {{'languages.convention.DateDeb' | translate}}"  (click)="openDatePicker()" />
                                  <input matEndDate [(ngModel)]="date_fin" readonly placeholder="{{'languages.convention.DateFin' | translate}} " (click)="openDatePicker()"/>

                                </mat-date-range-input>

                                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                                    <img src="assets/icons/calendar.svg" alt="calendarIcon" >
                                </mat-icon></mat-datepicker-toggle>
                                <img  class="remouveDate" matSuffix (click)="clearDateCreation()"  alt="deleteIcon" src="assets/icons/delete-left.svg">
                                <mat-date-range-picker  [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
                              </mat-form-field>

                          </div>
                        <div class="col-2 w-100 pl-0 ">
                            <button type="button" class="filtrer" (click)="getDataValuee('filtrer')">
                                <img src="assets/icons/filtre.svg" class="mr-3" alt="">
                {{'languages.buttons.filtrer' | translate}}
              </button>
                        </div>
                    </div>
                </div>
                <div class="col-3 p-2  text-right">
                    <input class="search" name="" [(ngModel)]="filtres_opportuniteInit.sSearch"   (input)="onInputChange($event.target.value)" placeholder="{{'languages.buttons.rechercher' | translate}}...">
                    <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>
                </div>
                <!-- <div class="col-3  p-2 d-flex justify-content-center">
                    <button class="filtreFavoris">
                        <img class="mr-3" src="assets/icons/filtreFavoris.svg">
                        {{'languages.buttons.rechercheFavories' | translate}}
                    </button>
                </div> -->


            </div>
            <div class="row mt-3 " >
                <!--  <button  type="button" (click)="updateOpportunities()" class="btn-keygen ml-4" id="keygen" style="background-color:#fe8c96; width: 150px;">
            Actions groupées
          </button> -->

                <button type="button" *ngIf="actionGroupeAccess" class="btn-dt-actions-groupee ml-4" (click)="updateOpportunities()" [disabled]="!actionButton"
                >
                {{ 'languages.groupedactions.groupedactions' | translate}}
        </button>


            <div class="table-responsive p-3">
                <table class="table align-items-center" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th>

                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()" />
                            </th>
                            <th>
                                {{ 'languages.listing.actions' | translate}}
                            </th>
                            <th (click)="setSortField('opportunite_date_depot')">{{ 'languages.opportunite.label_creation' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='opportunite_date_depot'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th (click)="setSortField('organismes_nom')">
                                {{ 'languages.campagne.Organisme' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='organismes_nom'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>

                            <th (click)="setSortField('villes_postal_code')">
                                {{ 'languages.opportunite.label_zonecode' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='villes_postal_code'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th (click)="setSortField('prospects_surname')">
                                RS/Prospect
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='prospects_surname'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <!--     <th (click)="setSortField('rdv')">
                {{ 'languages.opportunite.label_rdv' | translate}}
                <div class="d-inline-block"
                  [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='rdv'}">
                <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                </div>
              </th> -->
                            <th (click)="setSortField('campagnes_libelle')">
                                {{ 'languages.organisme.Campagne' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='campagnes_libelle'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th (click)="setSortField('etat')">
                                {{ 'languages.opportunite.label_status' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type === 'asc' , 'sort-down': sort_type === 'desc' ,'sort-active': sort_field=='etat'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th style="width: 220px;"> {{ 'languages.groupedactions.mots_cles' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table" *ngFor="let opportunity of opportunityList.aaData; let i = index">
                        <tr>
                            <td>
                                <div class="pt-1">
                                    <label class="container">
                    <input type="checkbox" [(ngModel)]="opportunity.isSelected" name="list_name"
                      value="{{opportunity.id}}" (change)="isAllSelected()">
                  </label>


                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">

                                    <div class="pt-1 mr-2">
                                        <button type="button" class="btn-dt-list" data-toggle="modal" data-target="#ActionsPhone" (click)="getPhoneList(opportunity.opportunite_id)">
                      <img src="/assets/icons/phone-icon.svg">
                    </button>
                                    </div>
                                    <div class="pt-1 mr-2">
                                        <a type="button" class="btn-dt-list" (click)="goToEdit()" routerLink="/opportunities/details/{{opportunity.opportunite_id}}/">
                                            <img src="/assets/icons/edit-icon.svg">
                                        </a>
                                    </div>
                                    <div>
                                        <input type='checkbox' id="permanent_{{i}}" title="Add Favoris" class="star" [checked]="opportunity.add_to_fav" />
                                        <label for="permanent_{{i}}" (click)="add_suivi_opp(opportunity.opportunite_id)"></label>
                                    </div>
                                </div>



                            </td>
                            <td>{{opportunity.date_depot | date:'dd-MM-yyyy HH:mm'}}</td>
                            <td width="150px;">{{opportunity.organismes_nom}}</td>
                            <td width="150px;">{{opportunity.adresse}}</td>
                            <td>{{ opportunity.RS ? opportunity.RS : opportunity.prospects_name + " " +opportunity.prospects_surname}}</td>
                            <!-- <td>{{opportunity.opportunite_type_libelle}}</td> -->
                            <td width="150px;">{{opportunity.campagnes_libelle}}</td>
                            <td>
                                <div class="btn-status d-flex justify-content-center" [style.background]="opportunity.etat_background" [style.color]="opportunity.etat_color">{{opportunity.etat}}</div>
                            </td>


                            <td style="width: 290px; " *ngIf="opportunity.tags_list.length > 0">
                                <div class="tagsOpportunite pb-1" id="tagsList">
                                    <mat-chip-set class="example-chip">
                                        <mat-chip class="example-box mr-2 mt-2" [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}" *ngFor="let tag of opportunity.tags_list; let index = index ; let first = first;">
                                            {{tag.libelle}}
                                        </mat-chip>
                                    </mat-chip-set>
                                </div>
                            </td>
                            <td *ngIf="opportunity.tags_list.length == 0">-</td>
                        </tr>
                    </tbody>
                </table>
                <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="opportunityList.aaData?.length===0"  class="col-12 noOpp"> Pas des opportunités  </p>
                   </div>
            </div>
        </div>
        <div class="navbar-center mb-4 row">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{ 'languages.miniListing.Afficher' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListOpportunities()" class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
                    </div>
                    <div class="col mt-1">
                        <p class="text-nowrap">{{ 'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{ 'languages.miniListing.element' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+pages-3>0" class="page-item">
                                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+pages<totalPages" class="page-item">
                                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':pages==(i+pages)}">
                  {{i + pages}}
                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{ 'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48" (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;" size="1" class="page_input bg-white border rounded font-weight-bold"
                            [value]="pages" max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Datatables -->
</div>
<!-------------------------------------------Action Groupés------------------------------------------->
<div class="modal fade" id="ActionsGroupes" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
<i class="fa-solid fa-list-check fa-2x"></i>
                <h2 style="margin-left: 15px">{{ 'languages.groupedactions.groupedactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class='checkbox-container col-lg-12' style="font-size:2em; margin-bottom: 30px;">
                        <label for="toggle1" style="font-size: 20px;padding-left: 13px;">{{ 'languages.opportunite.label_changenegotiator' |
              translate}}</label>
                        <input class="checkbox" type="checkbox" id="toggle1" name="negociateur" value="negociateur" [(ngModel)]="Negociateur">
                        <div class="checkbox-visual" style="margin-left: 15px;">
                            <div></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="Negociateur">
                        <input type="search" name="negociateur" class="form-control" style="position: absolute; left: 30%; width: 30%;">
                    </div>
                    <div class='checkbox-container col-lg-12' style="font-size:2em; margin-bottom: 30px;">
                        <label for="toggle1" style="font-size: 20px;padding-left: 13px;">{{ 'languages.opportunite.label_changestatus' |
              translate}}</label>
                        <input class="checkbox" type="checkbox" id="toggle2" name="statut" value="statut" [(ngModel)]="Statut">
                        <div class="checkbox-visual" style="margin-left: 15px;">
                            <div></div>
                        </div>
                    </div>
                    <div class='checkbox-container col-lg-12' style="font-size:2em; margin-bottom: 30px;">
                        <label for="toggle1" style="font-size: 20px;padding-left: 13px;">{{ 'languages.opportunite.label_addopportunitytags'
              | translate}}</label>

                        <input class="checkbox" type="checkbox" id="toggle3" name="tags-opportunites" value="tags-opportunites" [(ngModel)]="TagsOpportunites">
                        <div class="checkbox-visual" style="margin-left: 15px;">
                            <div></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="TagsOpportunites">
                        <select class="form-control" style="margin: auto;margin-bottom: 15px; width: 30%">
              <option selected disabled>{{ 'languages.opportunite.label_add' | translate}}</option>
            </select>
                    </div>
                    <div class='checkbox-container col-lg-12' style="font-size:2em; margin-bottom: 30px;">
                        <label for="toggle1" style="font-size: 20px;padding-left: 13px;">{{ 'languages.opportunite.label_tagprospect' | translate}}</label>
                        <input class="checkbox" type="checkbox" id="toggle4" name="tags-prospects" value="tags-prospects" [(ngModel)]="TagsProspects">
                        <div class="checkbox-visual" style="margin-left: 15px;">
                            <div></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="TagsProspects">
                        <select class="form-control" style="margin: auto;margin-bottom: 15px; width: 30%">
              <option selected disabled>{{ 'languages.opportunite.label_add' | translate}}</option>
            </select>
                    </div>
                    <div class='checkbox-container col-lg-12' style="font-size:2em; margin-bottom: 30px;">
                        <label for="toggle1" style="font-size: 20px;padding-left: 13px;">{{ 'languages.opportunite.label_communication' | translate}}</label>
                        <input class="checkbox" type="checkbox" id="toggle5" name="communications" value="communications" [(ngModel)]="Communications">
                        <div class="checkbox-visual" style="margin-left: 15px;">
                            <div></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="Statut" style="margin: auto">
                        <div class="box">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="status" value="RDV Terrain">
                                <label class="form-check-label">{{ 'languages.opportunite.label_fieldmeeting' | translate}}</label> <br>
                                <input class="form-check-input" type="radio" name="status" value="VAD">
                                <label class="form-check-label">{{ 'languages.opportunite.label_vad' | translate}}</label><br>
                                <input class="form-check-input" type="radio" name="status" value="Vente Bureaux">
                                <label class="form-check-label">{{ 'languages.opportunite.label_salesoffices' | translate}}</label><br>
                                <input class="form-check-input" type="radio" name="status" value="TRANSFERT">
                                <label class="form-check-label">{{ 'languages.opportunite.label_transfert' | translate}}</label><br>
                                <input class="form-check-input" type="radio" name="status" value="Qualification">
                                <label class="form-check-label">{{ 'languages.opportunite.label_qualification' | translate}}</label><br>
                                <input class="form-check-input" type="radio" name="status" value="3C">
                                <label class="form-check-label">{{ 'languages.opportunite.label_3c' | translate}}</label><br>
                                <input class="form-check-input" type="radio" name="status" value="ASSUR-COVID-2020">
                                <label class="form-check-label">{{ 'languages.opportunite.label_covidinsurance' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="Communications">
                        <select class="form-control" style="margin: auto;margin-bottom: 15px; width: 30%">
              <option selected disabled>{{ 'languages.opportunite.label_mail' | translate}}</option>
            </select>
                        <select class="form-control" style="margin: auto;margin-bottom: 15px; width: 30%">
              <option selected disabled>{{ 'languages.opportunite.label_sms' | translate}}</option>
            </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" type="submit">{{ 'languages.opportunite.label_treat' | translate}}</button>
                    <button class="btn-style" data-dismiss="modal">{{ 'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------Action Phone----------------------------------->
<div class="modal fade" id="ActionsPhone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
<i class="fa-solid fa-phone-flip fa-2x"></i>
                <h2 style="margin-left: 15px">{{ 'languages.opportunite.label_phoneactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="row" *ngIf="list_tel.mobile!= null && list_tel.mobile!=''">
                            <div class="col-3 text-center">
                                {{ 'languages.prospect.Mobile' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.mobile}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.mobile)">
                  <i class="fa-solid fa-phone-flip fa-lg" style="margin-left: 5px; color: #43ac43;"></i>
                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel!= null && list_tel.tel!=''">
                            <div class="col-3 text-center">
                                {{ 'languages.prospect.phone' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.tel)">
                  <i class="fa-solid fa-phone-flip fa-lg" style="margin-left: 5px; color: #43ac43;"></i>
                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel2!= null && list_tel.tel2!=''">
                            <div class="col-3 text-center">
                                {{ 'languages.prospect.phone' | translate}} 2
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel2}}
                            </div>
                            <div class="col-3 text-center">
                                <button class="btn" (click)="callVoip(list_tel.tel2)">
                  <i class="fa-solid fa-phone-flip fa-lg" style="margin-left: 5px; color: #43ac43;"></i>
                    
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" data-dismiss="modal">{{ 'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------Action Vue----------------------------------->
<div class="modal fade" id="ActionsVue" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
<i class="fa-solid fa-eye fa-2x"></i>
                <h2 style="margin-left: 15px">{{ 'languages.opportunite.label_viewactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row">
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" type="submit">{{ 'languages.opportunite.label_treat' | translate}}</button>
                    <button class="btn-style" data-dismiss="modal">{{ 'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
