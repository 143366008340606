
export class Expediteur {
    nom_prenom_expediteur: string = "";
    adresse_expediteur: string = "";
    cp_expediteur: string = "";
    ville_expediteur: string = "";
    complement_adresse_expediteur: string = "";
}
export class Destinataire {
    nom_prenom_destinataire: string = "";
    adresse_destinataire: string = "";
    cp_destinataire: string = "";
    ville_destinataire: string = "";
    complement_adresse_destinataire: string = "";
}
export class Createur {
    recheche_group: any[] = [];
    recheche_create_user: any[] = [];
}
export class Courrier {
    ref_notif: string = "";
    ref_client: string = "";
    ref_preuve: string = "";
    sujet: string = "";
    type_courrier: any[] = [];
    sent_from: any[] = [];
    couleur_courrier: string = "";
    date_debut_creation: string = null;
    date_fin_creation: string = null;
    heure_debut_creation: string = "";
    heure_fin_creation: string = "";
    date_debut_envoie: string =null;
    date_fin_envoie: string = null;
    enveloppe_courrier: any[] = [];
    type_envoie: any[] = [];
    modele: any[] = [];
    date_debut_livraison: string = null;
    date_fin_livraison: string = null;
    heure_debut_livraison: string = "";
    heure_fin_livraison: string = "";
    date_debut_prevue: string =null;
    date_fin_prevue: string = null;
    heure_debut_prevue: string = "";
    heure_fin_prevue: string = "";

}
export class Statut {
    etat_courrier: any[] = [];
    statut_envoie: any[] = [];
}

export class FilterMaileva {
    expediteur: Expediteur = new Expediteur()
    destinataire: Destinataire = new Destinataire()
    createur: Createur = new Createur()
    courrier: Courrier = new Courrier()
    statut: Statut = new Statut()


}