<div class="card-doc bg-white p-3 border-0" [ngClass]="{'cover': !allowed_to_update}">

    <div class="card-doc-title ml-3 row">
<i class="fa-solid fa-file-import mr-3 mx-1 fa-lg mt-2"></i>
        {{"languages.document.PorteDocuments" | translate }}
    </div>

    <div class="row p-3">
        <div class="col-md-12 p-4 div-list-exist scrollbar scrolling">
            <div class="row d-flex justify-content-center mb-3">
                <ng-container *ngIf="show_loading">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="m-auto mb-2">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-12 accordion" *ngFor="let exist of listDocuments; let i=index" id="accordionFolder">
                    <app-shared-documents [exist]="exist" [portedoc]="true" [show_three_points]="true" [type]="'O'" [switch_upload_opportunite]="true" [prospect_id]="id_prospect" [opportunite_id]="opportunity_id" [i]="i" (shareDocument)="getSharedDocument($event)" [allowed_to_update]="allowed_to_update"></app-shared-documents>
                </div>
            </div>
        </div>
    </div>
</div>


