<div class="row  mt-2">

    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allGroupe.length > 0">
                <mat-form-field class="w-100">
                    <mat-label>Groupes</mat-label>
                    <mat-chip-list #Groupe>
                        <mat-chip *ngFor="let groupe of ListGroupe" [selectable]="selectable" [removable]="removable"
                            (removed)="removeGroupe(groupe)" (removed)="EmitFilterCreateur()">
                            {{groupe}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        </mat-chip>
                        <input (click)="EmitFilterCreateur()" placeholder="Selectionner Groupe..." #groupeInput
                            [formControl]="groupeFromCtrl" [matAutocomplete]="autoGroupe" [matChipInputFor]="Groupe"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupe="matAutocomplete" (optionSelected)="selectedGroupe($event)">
                        <mat-option *ngFor="let groupe of filteredGroupe | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>{{'languages.opportunite.label_listcreators' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allCreateur?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>  
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #ListeCreateur>
                        <mat-chip *ngFor="let createur of ListCreateur" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCreateur(createur)"
                            (removed)="EmitFilterCreateur()">
                            {{createur}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        </mat-chip>
                        <input (click)="EmitFilterCreateur()" placeholder="{{'languages.opportunite.label_listcreators_select' | translate}}"
                            #CreateurInput [formControl]="CreateurFromCtrl" [matAutocomplete]="autoCreateur"
                            [matChipInputFor]="ListeCreateur" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
                        <mat-option *ngFor="let createur of filteredCreateur | async" [value]="createur.id">
                            {{createur.nom}}  {{createur.prenom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>



</div>
