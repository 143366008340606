import { createAction, props } from '@ngrx/store';
import { Entreprise } from '../../../../entity/Opportunity/Entreprise';
import { addOpportuniteTypeProspectEnum } from './add-opportunite.reducers';
import { Prospect } from '../../../../entity/Prospect';
import { CycleVie } from '../../../../entity/CycleVie';
import { LigneProduit } from '../../../../entity/Opportunity/LigneProduit';
import { Campagne } from '../../../../entity/Opportunity/Campagne';
import { Process } from '../../../../entity/Process';
import { Details } from '../../../../entity/Opportunity/Details';
import { Calendar } from '../../../../entity/Opportunity/Calendar';
import { CampagneInfo } from '../../../../entity/Opportunity/CampagneInfo';
import { OpportunityAdd } from '../../../../entity/Opportunity/OpportunityAdd';
import { Commerciaux } from '../../../../entity/Opportunity/Commerciaux';


/*Global*/
export const RESET_ADD_OPPORTUNITE = '[ADD OPPORTUNITE] Réinitaliser le formulaire';
export const RESET_ADD_OPPORTUNITE_ERROR_STATE = '[ADD OPPORTUNITE] Réinitaliser l\'état d\'erreur';

export const SAVE_ADD_OPPORTUNITE_SUCCESS = '[ADD OPPORTUNITE] Enregistrer le formulaire success';
export const SAVE_ADD_OPPORTUNITE_FAIL = '[ADD OPPORTUNITE] Enregistrer le formulaire fail';
export const SAVE_ADD_OPPORTUNITE = '[ADD OPPORTUNITE] Enregistrer le formulaire';

export const ResetAddOpportunite = createAction(RESET_ADD_OPPORTUNITE);
export const ResetAddOpportuniteErrorState = createAction(RESET_ADD_OPPORTUNITE_ERROR_STATE);

export const SaveAddOpportunite = createAction(SAVE_ADD_OPPORTUNITE, props<{ payload: OpportunityAdd }>());
export const SaveAddOpportuniteSuccess = createAction(SAVE_ADD_OPPORTUNITE_SUCCESS, props<{ payload: string }>());
export const SaveAddOpportuniteFail = createAction(SAVE_ADD_OPPORTUNITE_FAIL, props<{ payload: string }>());


/*Prospect Actions*/
export const UPDATE_NAVIGATION = '[NAVIGATION] Update navigation';
export const UPDATE_PROSPECT = '[PROSPECT] Update Prospect';
export const UPDATE_SOCIETE = '[PROSPECT] Update Societe';
export const UPDATE_PROSPECT_SOCIETE = '[PROSPECT] Update prospect Societe';
export const UPDATE_TYPE_PROSPECT = '[PROSPECT] Update type Prospect';
export const ADD_PROSPECT = '[PROSPECT] add Prospect';
export const DELETE_PROSPECT = '[PROSPECT] delete Prospect';
export const SELECT_OLD_PROSPECT = '[PROSPECT] select old Prospect';
export const FILL_OLD_PROSPECT = '[PROSPECT] fill old Prospect';
export const FILL_OLD_ENTREPRISE = '[ENTREPRISE] fill old Prospect';



export const UpdateNavigationAction = createAction(UPDATE_NAVIGATION, props<{ payload: Process[] }>());
export const UpdateProspectAction = createAction(UPDATE_PROSPECT, props<{ payload: { index: number, info: Prospect } }>());
export const UpdateEntrepriseAction = createAction(UPDATE_SOCIETE, props<{ payload: Entreprise }>());
export const UpdateProspectEntrepriseAction = createAction(UPDATE_PROSPECT_SOCIETE, props<{ payload: { index: number, info: Prospect } }>());
export const UpdateTypeProspectAction = createAction(UPDATE_TYPE_PROSPECT, props<{ payload: addOpportuniteTypeProspectEnum }>());
export const AddProspectAction = createAction(ADD_PROSPECT);
export const DeleteProspectAction = createAction(DELETE_PROSPECT, props<{ payload: number }>());
export const SelectOldProspect = createAction(SELECT_OLD_PROSPECT, props<{ payload: string }>());
export const FillOldProspect = createAction(FILL_OLD_PROSPECT, props<{ payload: Prospect }>());
export const FillOldEntrprise = createAction(FILL_OLD_ENTREPRISE, props<{ payload: any }>());
/*Info Complementaires*/
export const GET_INFO_COMPLIMENTAIRES_SUCCESS = '[CAMPAGNIES] get info complementaires success';
export const GET_INFO_COMPLIMENTAIRES_FAIL = '[CAMPAGNIES] get info complementaires fail';
export const GetInfoComplementairesSuccess = createAction(GET_INFO_COMPLIMENTAIRES_SUCCESS, props<{ payload: CampagneInfo }>());
export const GetInfoComplementairesFail = createAction(GET_INFO_COMPLIMENTAIRES_FAIL, props<{ payload: string }>());


/*Campagne Actions */
export const GET_LIST_CAMPAGNIES = '[CAMPAGNIES] Get List campagnies';
export const GET_LIST_CAMPAGNIES_SUCCESS = '[CAMPAGNIES] Get List campagnies success';
export const GET_LIST_CAMPAGNIES_FAIL = '[CAMPAGNIES] Get List campagnies fail';
export const GetListCampagnes = createAction(GET_LIST_CAMPAGNIES);
export const GetListCampagnesSuccess = createAction(GET_LIST_CAMPAGNIES_SUCCESS, props<{ payload: CycleVie }>());
export const GetListCampagnesFail = createAction(GET_LIST_CAMPAGNIES_FAIL, props<{ payload: string }>());

export const UPDATE_CAMPAGNE = '[CAMPAGNIES] Update Campagne';

export const UpdateCampagne = createAction(UPDATE_CAMPAGNE, props<{ payload: Campagne }>());

export const SELECT_CAMPAGNIES = '[CAMPAGNIES] select compagne';

export const SelectCampagne = createAction(SELECT_CAMPAGNIES, props<{ payload: { campagneId: string } }>());

export const SELECT_PRODUCT = '[CAMPAGNIES] select product hors comp';

export const SelectProduct = createAction(SELECT_PRODUCT, props<{ payload: { product: any } }>());
/*List Lignes products*/
export const GET_LIST_LIGNE_PRODUCTS_SUCCESS = '[CAMPAGNIES] Get List ligne produits success';
export const GET_LIST_LIGNE_PRODUCTS_FAIL = '[CAMPAGNIES] Get List ligne produits fail';

export const GetListLigneProductsSuccess = createAction(GET_LIST_LIGNE_PRODUCTS_SUCCESS, props<{ payload: LigneProduit[] }>());
export const GetListLigneProductsFail = createAction(GET_LIST_LIGNE_PRODUCTS_FAIL, props<{ payload: string }>());

/*List Products*/
export const GET_LIST_PRODUCTS = '[CAMPAGNIES] Get List produits';
export const GET_LIST_PRODUCTS_SUCCESS = '[CAMPAGNIES] Get List produits success';
export const GET_LIST_PRODUCTS_FAIL = '[CAMPAGNIES] Get List produits fail';
export const GetListProducts = createAction(GET_LIST_PRODUCTS);
export const GetListProductsSuccess = createAction(GET_LIST_PRODUCTS_SUCCESS, props<{ payload: string }>());
export const GetListProductsFail = createAction(GET_LIST_PRODUCTS_FAIL, props<{ payload: string }>());
/*CALENDAR*/

export const UPDATE_CALENDAR = '[CALENDAR] update calendar';
export const UPDATE_STATUS = '[CALENDAR] update status';

export const UpdateCalendar = createAction(UPDATE_CALENDAR, props<{ payload: Calendar }>());
export const UpdateStatus = createAction(UPDATE_STATUS, props<{ payload: any }>());

export const GET_LIST_COMMERCIEAUX_SUCCESS = '[CALENDAR] Get List commercieaux success';
export const GET_LIST_COMMERCIEAUX_FAIL = '[CALENDAR] Get List commercieaux fail';

export const GetListCommercieauxSuccess = createAction(GET_LIST_COMMERCIEAUX_SUCCESS, props<{ payload: Commerciaux[] }>());
export const GetListCommercieauxFail = createAction(GET_LIST_COMMERCIEAUX_FAIL, props<{ payload: string }>());

/*Update Calendar Status*/
export const GET_LIST_STATUS_SUCCESS = '[CALENDAR] Get List status success';
export const GET_LIST_STATUS_FAIL = '[CALENDAR] Get List status fail';
export const GetListStatusSuccess = createAction(GET_LIST_STATUS_SUCCESS, props<{ payload: any }>());
export const GetListStatusFail = createAction(GET_LIST_STATUS_FAIL, props<{ payload: string }>());

/*Update details*/

export const UPDATE_DETAILS = '[DETAILS] Update details';

export const UpdateDetails = createAction(UPDATE_DETAILS, props<{ payload: Details }>());
