import { FormbuilderValidationService } from './../services/formbuilder-validation.service';
import { RangeDateComponent } from './../components/rangeDate/range-date/range-date.component';
import { TaskDetailComponent } from './../components/task-detail/task-detail.component';
import { SharedInformationBankComponent } from './../components/shared-information-bank/shared-information-bank.component';
import { SharedTagsComponent } from './../components/shared-tags/shared-tags.component';
import { SharedListDocumentsComponent } from '../components/list-documents/shared-list-documents.component';
import { GroupGarantieSharedComponent } from '../components/group-garantie-shared/group-garantie-shared.component';
import { SharedDocumentsComponent } from '../components/shared-documents/shared-documents.component';
import { SharedFormViewComponent } from './../components/FormBuilder/form-builder/form-view/form-view.component';
import { SharedFormParamsComponent } from './../components/FormBuilder/form-builder/form-params/form-params.component';
import { SharedFormBuilderComponent } from './../components/FormBuilder/form-builder/form-builder.component';
import { SharedGlobalFormComponent } from './../components/FormBuilder/global/global-form.component';
import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../components/footer/footer.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { LoadingBarComponent } from '../components/loading-bar/loading-bar.component';
import { StatusBarComponent } from '../components/status-bar/status-bar.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TicketsAndSupportComponent } from '../pages/tickets-and-support/list-tickets/tickets-and-support.component';
import { StatusComponent } from '../pages/affaires/affaire-details/status/status.component';
import { TicketsComponent } from '../pages/affaires/affaire-details/tickets/tickets.component';
import { AddTicketsAffaireComponent } from '../pages/affaires/affaire-details/tickets/add-tickets-affaire/add-tickets-affaire.component';
import { HistoriqueCommunicationComponent } from '../pages/opportunities/details-opportunite/Elements/historique-communication/historique-communication.component';
import { AgmCoreModule } from '@agm/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { NullTypeComponent } from '../components/dynamicFormFields/null-type/null-type.component';
import { ArrayTypeComponent } from '../components/dynamicFormFields/array-type/array-type.component';
import { ObjectTypeComponent } from '../components/dynamicFormFields/object-type/object-type.component';
import { MultiSchemaTypeComponent } from '../components/dynamicFormFields/multi-schema-type/multi-schema-type.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormlyFieldStepperComponent } from '../components/dynamicFormFields/formly-field-stepper/formly-field-stepper.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { RepeatTypeComponent } from '../components/dynamicFormFields/repeat-type/repeat-type.component';
import { EmailEditorModule } from 'angular-email-editor';
import { CommentairesComponent } from '../pages/opportunities/details-opportunite/Elements/commentaires/commentaires.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ToastrModule } from 'ngx-toastr';
import { DndDirective } from './SharedDirectives/dndFiles.directive';
import { NumberDirective } from './SharedDirectives/numbers-only.directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutoCompleteAdressesComponent } from '../components/auto-complete-adresses/auto-complete-adresses.component';
import { IconPickerComponent } from '../components/icon-picker/icon-picker.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CustomRangePanelComponent } from '../components/rangeDate/range-date/custom-range-panel/custom-range-panel.component';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UploadFilesComponent } from '../components/upload-files/upload-files.component';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { GoJsDiagramBpmComponent } from '../components/go-js-diagram-bpm/go-js-diagram-bpm.component';
import { InspectorComponent } from '../components/go-js-diagram-bpm/inspector/inspector.component';
import { GoJsDiagramBpmAffaireComponent } from '../components/go-js-diagram-bpm-affaire/go-js-diagram-bpm-affaire.component';
import { InspectorAffaireComponent } from '../components/go-js-diagram-bpm-affaire/inspector/inspector.component';
import { GojsAngularModule } from 'gojs-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FilterTicketModule } from '../pages/tickets-and-support/list-tickets/filtres-ticket/filter-ticket.module';
import { FiltresTicketComponent } from '../pages/tickets-and-support/list-tickets/filtres-ticket/filter-ticket/filtres-ticket.component';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { CheckBoxTypeComponent } from '../components/dynamicFormFields/check-box-type/check-box-type.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { AddMailevaComponent } from '../components/add-maileva/add-maileva.component';
import { AddTicketsComponent } from '../components/add-tickets/add-tickets.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatMenuModule } from '@angular/material/menu';
import { NgbdmodalContent } from '../../app/components/add-affaire-modal/add-affaire-modal.component';
import { AngularEditorModule } from '../components/angular-editor/angular-editor.module';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import { PasswordTypeComponent } from '../components/dynamicFormFields/password-type/password-type.component';
import { EmailTypeComponent } from '../components/dynamicFormFields/email-type/email-type.component';
import{StringTypeComponent} from '../components/dynamicFormFields/string-type/string-type.component';
import { JsonTypeComponent } from '../components/dynamicFormFields/json-type/json-type.component';
import { TimeTypeComponent } from '../components/dynamicFormFields/time-type/time-type.component';
import { RadioTypeComponent } from '../components/dynamicFormFields/radio-type/radio-type.component';
import {MonetiqueTypeComponent} from '../components/dynamicFormFields/monetique-type/monetique-type.component';
import { FullProspectDetailsComponent } from '../components/full-prospect-details/full-prospect-details.component';
import { ProspectFormModule } from '../pages/opportunities/add-opportunite/Elements/add_prospect/prospect-form.module';
import { ProspectFormComponent } from '../pages/opportunities/add-opportunite/Elements/add_prospect/prospect-form/prospect-form.component';
import { ProspectProFormComponent } from '../pages/opportunities/add-opportunite/Elements/add_prospect/prospect-pro-form/prospect-pro-form.component';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ChangeStatusButtonComponent } from '../components/shared_buttons/change-status-button/change-status-button.component';
import { SharedButtonEnrgComponent } from '../components/shared_buttons/shared-button-enrg/shared-button-enrg.component';
import { SharedButtonActualiComponent } from '../components/shared_buttons/shared-button-actuali/shared-button-actuali.component';
import { AddCommunicationComponent } from '../components/add-communication/add-communication.component';
import { AddCommentaireComponent } from '../components/add-commentaire/add-commentaire.component';
import { AddTasksComponent } from '../components/add-tasks/add-tasks.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionGroupeeTicketsComponent } from '../components/action-groupee-tickets/action-groupee-tickets.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FicheWorkflowComponent } from '../components/workflow/fiche-workflow/fiche-workflow.component';
import { ActionsGroupeeComponent } from '../components/actions-groupee/actions-groupee.component';
import { GroupGarantieProduitSharedComponent } from '../components/group-garantie-produit-shared/group-garantie-produit-shared.component';
import { FormConventionComponent } from '../components/form-convention/form-convention.component';
import { StatusConventionComponent } from '../components/status-convention/status-convention.component';
import { VersionConventionComponent } from '../components/version-convention/version-convention.component';
import { FilterEcheanceModule } from '../pages/echeances/filter/filter-echeances/filter-echeances.module';
import { FormObjetAssurerComponent } from '../components/form-objet-assurer/form-objet-assurer.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SaveFiltreComponent } from '../components/save-filtre/save-filtre.component';
import { ListFilterSavedComponent } from '../components/list-filter-saved/list-filter-saved.component';
import { GroupeGarantieComponent } from '../components/groupe-garantie/groupe-garantie.component';
import {NumberTypeComponent} from '../components/dynamicFormFields/number-type/number-type.component';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

export function patternValidatorFactory(pattern: string | RegExp, valueInput, fieldName , fieldLabel) {
  let isValid: boolean = true;

  if (valueInput) {
    isValid = new RegExp(pattern).test(valueInput);
  } else {
    isValid = true;
  }

  // Emit validation status using the shared service
  const validationService = SharedModule.injector.get(FormbuilderValidationService);

  validationService.updateValidationStatus(fieldName, isValid,fieldLabel);

  return isValid ? null : { pattern: true };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function minItemsValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `ne devrait PAS avoir moins de ${field.templateOptions.minItems} éléments`;
}

export function maxItemsValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `ne devrait PAS avoir plus dee than ${field.templateOptions.maxItems} éléments`;
}

export function minlengthValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `ne doit PAS être plus court que ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `ne devrait PAS être plus long que ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `devrait être >= ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `devrait être <= ${field.templateOptions.max}`;
}

export function multipleOfValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `doit être multiple de ${field.templateOptions.step}`;
}

export function exclusiveMinimumValidationMessage(err, field: FormlyFieldConfig) {
  let min;
  console.log(err);
  if (field.templateOptions.step === null) min = field.templateOptions.exclusiveMinimum;
  else min = field.templateOptions.step;
  return `devrait être > ${min}`;
}

export function exclusiveMaximumValidationMessage(err, field: FormlyFieldConfig) {
  let max;
  console.log(err);
  if (field.templateOptions.step === null) max = field.templateOptions.exclusiveMaximum;
  else max = field.templateOptions.step;
  return `devrait être < ${max}`;
}

export function constValidationMessage(err, field: FormlyFieldConfig) {
  console.log(err);
  return `doit être égal à constant ${field.templateOptions.const}`;
}
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  interactionPlugin,
  timeGridPlugin,
  dayGridPlugin,
  iCalendarPlugin,
]);

@NgModule({
  declarations: [
    TaskDetailComponent,
    NgbdmodalContent,
    FooterComponent,
    LoadingBarComponent,
    StatusBarComponent,
    TicketsAndSupportComponent,
    StatusComponent,
    TicketsComponent,
    HistoriqueCommunicationComponent,
    RepeatTypeComponent,
    ObjectTypeComponent,
    NullTypeComponent,
    MultiSchemaTypeComponent,
    ArrayTypeComponent,
    CommentairesComponent,
    DndDirective,
    NumberDirective,
    AutoCompleteAdressesComponent,
    IconPickerComponent,
    UploadFilesComponent,
    GoJsDiagramBpmComponent,
    GoJsDiagramBpmAffaireComponent,
    InspectorAffaireComponent,
    InspectorComponent,
    FiltresTicketComponent,
    PasswordTypeComponent,
    EmailTypeComponent,
    NumberTypeComponent,
    StringTypeComponent,
    JsonTypeComponent,
    TimeTypeComponent,
    RadioTypeComponent,
    MonetiqueTypeComponent,
    CheckBoxTypeComponent,
    AddMailevaComponent,
    AddTicketsComponent,
    AddCommunicationComponent,
    AddCommentaireComponent,
    FullProspectDetailsComponent,
    ProspectProFormComponent,
    ProspectFormComponent,
    AddTicketsAffaireComponent,
    SharedGlobalFormComponent, // declaration Shared FormBuilder
    SharedFormBuilderComponent, // declaration Shared FormBuilder
    SharedFormParamsComponent, // declaration Shared FormBuilder
    SharedFormViewComponent, // declaration Shared FormBuilder
    SharedListDocumentsComponent, // declaration Shared Documents List,
    GroupGarantieSharedComponent,
    GroupeGarantieComponent,
    GroupGarantieProduitSharedComponent,
    SharedDocumentsComponent,
    ChangeStatusButtonComponent,
    SharedTagsComponent, // declaration shared tags
    SharedButtonEnrgComponent,
    SharedButtonActualiComponent,
    AddTasksComponent,
    SharedInformationBankComponent,
    FicheWorkflowComponent, // declaration of shared information bank
    ActionsGroupeeComponent,
    FormConventionComponent,
    StatusConventionComponent,
    VersionConventionComponent,
    RangeDateComponent,
    FormObjetAssurerComponent,
    ActionGroupeeTicketsComponent,
    CustomRangePanelComponent,
    SaveFiltreComponent,
    ListFilterSavedComponent,
  ],
  imports: [
    FilterEcheanceModule,
    MatProgressBarModule,
    CommonModule,
    AngularMaterialModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2TelInputModule.forRoot(),
    CKEditorModule,
    EmailEditorModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot(),
    TranslateModule,
    //   TranslateModule.forRoot({
    //     loader: {
    //         provide: TranslateLoader,
    //         useFactory: HttpLoaderFactory,
    //         deps: [HttpClient]
    //     }
    // }),
    AgmCoreModule.forRoot({
      apiKey: '',
    }),
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true, resetFieldOnHide: true, checkExpressionOn: 'modelChange' },
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'null', message: 'should be null' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'multipleOf', message: multipleOfValidationMessage },
        { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
        { name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
        { name: 'minItems', message: minItemsValidationMessage },
        { name: 'maxItems', message: maxItemsValidationMessage },
        { name: 'uniqueItems', message: "ne devrait PAS avoir d'éléments en double" },
        { name: 'const', message: constValidationMessage },
        { name: 'pattern', message: 'Le texte ne respecte pas le motif spécifié.' },
      ],
      types: [
        // {
        //   name: 'number',
        //   extends: 'input',
        //   defaultOptions: {
        //     templateOptions: {
        //       type: 'number',
        //       pattern: '^-?[0-9]+$',
        //       description: '',
        //     },

        //     validators: {
        //       pattern: {
        //         expression: (c: AbstractControl, input) => {
        //           const pattern = input.templateOptions.pattern;
        //           const inputValue = c.value;
        //           patternValidatorFactory(pattern, inputValue, input.key,input.templateOptions.label);
        //           if (!pattern || !inputValue) {
        //             return true; // No pattern to match or input is empty, considered valid
        //           }

        //           const regex = new RegExp(pattern);
        //           return regex.test(inputValue);
        //         },
        //         message: () => {
        //           return 'Le nombre ne respecte pas le motif spécifié.';
        //         },
        //       },
        //     },
        //   },
        // },

       
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
              pattern: '^-?[0-9]+$',
              description: '',
            },

            validators: {
              pattern: {
                expression: (c: AbstractControl, input) => {
                  const pattern = input.templateOptions.pattern;
                  const inputValue = c.value;
                  patternValidatorFactory(pattern, inputValue, input.key,input.templateOptions.label);
                  if (!pattern || !inputValue) {
                    return true;
                  }

                  const regex = new RegExp(pattern);
                  return regex.test(inputValue);
                },
                message: () => {
                  return 'Le nombre ne respecte pas le motif spécifié.';
                },
              },
            },
          },
        },
        { name: 'multischema', component: MultiSchemaTypeComponent },
        { name: 'json', component: JsonTypeComponent },
        { name: 'enum', extends: 'select' },
        { name: 'null', component: NullTypeComponent, wrappers: ['form-field'] },
        { name: 'array', component: ArrayTypeComponent },
        { name: 'object', component: ObjectTypeComponent },
        { name: 'password', component: PasswordTypeComponent },
        { name: 'email', component: EmailTypeComponent },
        { name: 'time', component: TimeTypeComponent },
        { name: 'number', component: NumberTypeComponent },
        { name: 'string', component: StringTypeComponent },
        { name: 'monetique', component: MonetiqueTypeComponent },
        
        // {
        //   name: 'string',
        //   extends: 'input',
        //   defaultOptions: {
        //     templateOptions: {
        //       type: 'text',
        //       pattern: '', 
        //       description: '',
        //     },

        //     validators: {
        //       pattern: {
        //         expression: (c: AbstractControl, input) => {
        //           const pattern = input.templateOptions.pattern;
        //           const inputValue = c.value;
        //           patternValidatorFactory(pattern, inputValue, input.key,input.templateOptions.label);
        //           if (!pattern || !inputValue) {
        //             return true; 
        //           }

        //           const regex = new RegExp(pattern);
        //           return regex.test(inputValue);
        //         },
        //         message: () => {
        //           return 'Le texte ne respecte pas le format spécifié.';
        //         },
        //       },
        //     },
        //   },
        // },

        { name: 'boolean', component: CheckBoxTypeComponent },
        { name: 'stepper', component: FormlyFieldStepperComponent, wrappers: ['form-field'] },
      ],
    }),
    FormlyBootstrapModule,
    NgJsonEditorModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMatDatetimePickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    SweetAlert2Module,
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true, checkExpressionOn: 'modelChange' } }),
    FormlyMaterialModule,
    GooglePlaceModule,
    FilterTicketModule,
    GojsAngularModule,
    NgxMatColorPickerModule,
    MatSelectCountryModule.forRoot('fr'),
    FullCalendarModule, // register FullCalendar with you app
    MatMenuModule,
    FormsModule,
    FroalaEditorModule,
    FroalaViewModule,
    MatDialogModule,
    MatStepperModule,
    AngularEditorModule,
    FilterEcheanceModule,
  ],

  exports: [
    SweetAlert2Module,
    FilterEcheanceModule,
    TaskDetailComponent,
    RangeDateComponent,
    CustomRangePanelComponent,
    MatProgressBarModule,
    AddTasksComponent,
    NgbdmodalContent,
    MatStepperModule,
    ActionsGroupeeComponent,
    ActionGroupeeTicketsComponent,
    AngularMaterialModule,
    NgMultiSelectDropDownModule,
    FooterComponent,
    LoadingBarComponent,
    CKEditorModule,
    StatusBarComponent,
    TicketsAndSupportComponent,
    StatusComponent,
    TicketsComponent,
    HistoriqueCommunicationComponent,
    AgmCoreModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyBootstrapModule,
    RepeatTypeComponent,
    ObjectTypeComponent,
    NullTypeComponent,
    TimeTypeComponent,
    MultiSchemaTypeComponent,
    ArrayTypeComponent,
    PasswordTypeComponent,
    RadioTypeComponent,
    EmailTypeComponent,
    CommentairesComponent,
    EmailEditorModule,
    NgJsonEditorModule,
    DndDirective,
    NumberDirective,
    AutocompleteLibModule,
    AutoCompleteAdressesComponent,
    AutoCompleteAdressesComponent,
    IconPickerComponent,
    NgMultiSelectDropDownModule,
    NgMultiSelectDropDownModule,
    NgxMatDatetimePickerModule,
    UploadFilesComponent,
    GoJsDiagramBpmAffaireComponent,
    InspectorAffaireComponent,
    GoJsDiagramBpmComponent,
    InspectorComponent,
    // TranslateModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    GooglePlaceModule,
    FilterTicketModule,
    GojsAngularModule,
    NgxMatColorPickerModule,
    CheckBoxTypeComponent,
    Ng2TelInputModule,
    MatSelectCountryModule,
    AddMailevaComponent,
    AddTicketsComponent,
    AddCommunicationComponent,
    AddCommentaireComponent,
    FullProspectDetailsComponent,
    ProspectProFormComponent,
    ProspectFormComponent,
    ImageCropperModule,
    SharedGlobalFormComponent, // export Shared FormBuilder
    SharedFormBuilderComponent, // export Shared FormBuilder
    SharedFormParamsComponent, // export Shared FormBuilder
    SharedFormViewComponent, // export Shared FormBuilder
    SharedListDocumentsComponent, // export Shared Documents List
    GroupGarantieSharedComponent,
    GroupeGarantieComponent,
    GroupGarantieProduitSharedComponent,
    FroalaEditorModule,
    FroalaViewModule,
    SharedDocumentsComponent,
    ChangeStatusButtonComponent,
    SharedTagsComponent, // export shared tags
    SharedButtonEnrgComponent,
    SharedButtonActualiComponent,
    SharedInformationBankComponent, // export of shared information bank
    FullCalendarModule, // register FullCalendar with you app
    MatDialogModule,
    MatStepperModule,
    FicheWorkflowComponent,
    MatChipsModule,
    FormObjetAssurerComponent,
    TranslateModule,
    VersionConventionComponent,
    FormObjetAssurerComponent,
    FormConventionComponent,

    SaveFiltreComponent,
    ListFilterSavedComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    FormbuilderValidationService,
    {
      provide: NgxMatDatetimePickerModule,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },

    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
  ],
})
export class SharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    SharedModule.injector = injector;
  }
}
