import { Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith, tap, catchError } from 'rxjs/operators';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { GroupUser } from 'src/app/entity/User/GroupUser';
import { Acces } from 'src/app/entity/User/UserAdmin';
import { UserAdminList } from 'src/app/entity/User/UserAdminList';
import { Zone } from 'src/app/entity/Zone';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { ApiStatusService } from 'src/app/services/ApiStatus/api-status.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ApiLocationServices } from 'src/app/services/ApiLocation/api-location.service';
 
import { Utilisateur } from 'src/app/entity/Affaires/FilterAffaires';
import { compareNumbers } from '@fullcalendar/core';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-filtre-user',
  templateUrl: './filtre-user.component.html',
  styleUrls: ['./filtre-user.component.css'],
})
export class FiltreUserComponent implements OnInit, OnChanges, AfterViewInit {
   
  OrganismeSelectionees = [];
  CreateurSelectionees = [];
  ZonesSelectionees = [];
  ServiceSelectionnees = [];
  groupesSelectionees = [];

  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filterUtilisateur: Utilisateur = new Utilisateur();

  ListOrganisme: string[] = [];
  allOrganisme: Observable<OrganismeListe[]>;
  listOrganisme: OrganismeListe[] = [];

  // Mat Chips Createur
  CreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCreateur: Observable<UserAdminList[]>;
  ListCreateur: string[] = [];
  allCreateur: UserAdminList[] = [];

  @ViewChild('CreateurInput') CreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCreateur') matAutocompleteCreateur: MatAutocomplete;

  // Mat Chips Groupe
  groupeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupe: Observable<GroupUser[]>;
  listGroupe: string[] = [];
  // allGroupe: GroupUser[] = [];

  @ViewChild('groupeInput') groupeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupe') matAutocompleteGroupe: MatAutocomplete;

  // Mat Chips Zones
  zoneFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredZones: Observable<Zone[]>;
  ListZones: string[] = [];
  // allZones: Zone[] = [];

  @ViewChild('ZonesInput') ZonesInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoZones') matAutocompleteZones: MatAutocomplete;

  // Mat Chips service
  serviceFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredService: Observable<Acces[]>;
  ListService: string[] = [];
  // allService: any[] = [];
  getFilteredService: any[];

  @ViewChild('serviceInput') serviceInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoService') matAutocompleteService: MatAutocomplete;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterUtilisateur: EventEmitter<Utilisateur> = new EventEmitter();

  organismeFormControl: FormControl = new FormControl();
  filtre: any;
  constructor(
    private ApiCampagniesService: ApiCampagniesService,
    private apiUsersService: ApiUsersService,
    private apiStatusService: ApiStatusService,
    private ApiLocationService: ApiLocationServices,
    private chRef: ChangeDetectorRef
  ) {}

  // Createur -----------------------------------------------------------------------------------------------
  selectedCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.CreateurSelectionees.includes(event.option.value)) {
      this.ListCreateur.push(event.option.viewValue);
      this.CreateurSelectionees.push(event.option.value);
    }
    this.CreateurInput.nativeElement.value = '';
    this.CreateurFromCtrl.setValue(null);
    this.EmitFilterUtilisateur();
  }

  removeCreateur(Createur: string): void {
    const index = this.ListCreateur.indexOf(Createur);
    if (index >= 0) {
      this.ListCreateur.splice(index, 1);
      this.CreateurSelectionees.splice(index, 1);
    }
  }

  private _filterCreateur(): UserAdminList[] {
    let filterValue = this.CreateurFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCreateur.filter((Createur) =>
      Createur.nom != null && Createur.nom.toLowerCase().includes(filterValue) ||
      (Createur.prenom != null && Createur.prenom.toLowerCase().includes(filterValue))
    );
  }

  // Groupe  -----------------------------------------------------------------------------------------------
  selectedGroupe(event: MatAutocompleteSelectedEvent): void {
    this.listGroupe.push(event.option.viewValue);
    this.groupesSelectionees.push(event.option.value);
    this.groupeInput.nativeElement.value = '';
    this.groupeFromCtrl.setValue(null);
  }

  removeGroupe(groupe: string): void {
    const index = this.listGroupe.indexOf(groupe);
    if (index >= 0) {
      this.listGroupe.splice(index, 1);
      this.groupesSelectionees.splice(index, 1);
    }
  }

  // private _filterGroupe(): GroupUser[] {
  //   let filterValue = this.groupeFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroupe.filter((groupe) => groupe.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  // Zones -----------------------------------------------------------------------------------------------

  selectedZones(event: MatAutocompleteSelectedEvent): void {
    this.ListZones.push(event.option.viewValue);
    this.ZonesSelectionees.push(event.option.value);
    this.ZonesInput.nativeElement.value = '';
    this.zoneFromCtrl.setValue(null);
    this.EmitFilterUtilisateur();
  }

  removeZones(Zones: string): void {
    const index = this.ListZones.indexOf(Zones);
    if (index >= 0) {
      this.ListZones.splice(index, 1);
      this.ZonesSelectionees.splice(index, 1);
    }
  }

  // private _filterZones(): Zone[] {
  //   let filterValue = this.zoneFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allZones.filter((Zones) => Zones.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  // Service -----------------------------------------------------------------------------------------------

  selectedService(event: MatAutocompleteSelectedEvent): void {
    if (!this.ServiceSelectionnees.includes(event.option.value)) {
      this.ListService.push(event.option.viewValue);
      this.ServiceSelectionnees.push(event.option.value);
    }
    this.serviceInput.nativeElement.value = '';
    this.serviceFromCtrl.setValue(null);
    this.EmitFilterUtilisateur();
  }

  removeService(serv: string): void {
    const index = this.ListService.indexOf(serv);
    if (index >= 0) {
      this.ListService.splice(index, 1);
      this.ServiceSelectionnees.splice(index, 1);
    }
  }

  // private _filterService(): any[] {
  //   let filterValue = this.serviceFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   this.getFilteredService = this.allService.filter((element) => element.libele != null);
  //   return this.getFilteredService.filter((serv) => serv.libele.toLowerCase().indexOf(filterValue) === 0);
  // }

  ngAfterViewInit() {
    this.ApiCampagniesService.getOrganismes().subscribe((Response: OrganismeListe[]) => {
      this.listOrganisme = Response;
      if (this.filtre) {
        if (this.filterUtilisateur.entreprise_call) {
          const selectedOrganisme = this.listOrganisme.find(
            (element) => element.id === this.filterUtilisateur.entreprise_call
          );
          if (selectedOrganisme) {
            this.organismeFormControl.setValue(selectedOrganisme);
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.allOrganisme = this.organismeFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value)),
      map((value) => {
        return this._organismeAfilter(value);
      })
    );

    this.apiUsersService.getAllUsers().subscribe((Response: UserAdminList[]) => {
      this.allCreateur = Response;
      if (this.filtre) {
        if (this.filterUtilisateur.recheche_create_user.length > 0) {
          this.ListCreateur = this.allCreateur
            .filter((createur) => this.filterUtilisateur.recheche_create_user.includes(createur.id))
            .map((createurAff) => {
              this.CreateurSelectionees.push(createurAff.id);
              return createurAff.nom + ' ' + createurAff.prenom;
            });
        }
      }
    });
    // this.apiStatusService
    //   .getLisServicesGet()
    //   .pipe(
    //     tap(({ CategoryStatusOpp }) => {
    //       this.allService = CategoryStatusOpp;
    //       if (this.filtre) {
    //         if (this.filterUtilisateur.service.length > 0) {
    //           this.ListService = this.allService
    //             .filter(service => this.filterUtilisateur.service.includes(service.id))
    //             .map(serviceAff => {
    //               this.ServiceSelectionnees.push(serviceAff.id);
    //               return serviceAff.libele
    //             });
    //         }
    //   }
    //     })
    //   )
    //   .subscribe();
    // this.apiUsersService.getGroupUsers().subscribe((Response: GroupUser[]) => {
    //   this.allGroupe = Response;
    // });
    //   this.ApiLocationService.getListeZone().subscribe((Response: Zone[]) => {
    //     this.allZones = Response;
    //     if (this.filtre) {
    //       if (this.filterUtilisateur.recheche_zone.length > 0) {
    //         this.ListZones = this.allZones
    //           .filter(zone => this.filterUtilisateur.recheche_zone.includes(zone.id))
    //           .map(zoneAff => {
    //             this.ZonesSelectionees.push(zoneAff.id);

    //             return zoneAff.nom
    //           });
    //       }
    // }
    //   });
    // this.filteredZones = this.zoneFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((Zones: string | null) => (Zones ? this._filterZones() : this.allZones.slice()))
    // );
    // this.filteredService = this.serviceFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((serv: string | null) => (serv ? this._filterService() : this.allService.slice()))
    // );

    // this.filteredGroupe = this.groupeFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupe: string | null) => (groupe ? this._filterGroupe() : this.allGroupe.slice()))
    // );
    this.filteredCreateur = this.CreateurFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterCreateur() : this.allCreateur.slice()))
    );
    this.filtre = localStorage.getItem('filtreAffaire');
    this.filtre = JSON.parse(this.filtre);
    if (this.filtre) {
      this.filterUtilisateur = this.filtre.aff?.utilisateur;
      const allApi: Observable<any>[] = [this.apiUsersService.getAllUsers(), this.ApiCampagniesService.getOrganismes()];
      forkJoin(allApi)
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            return [];
          })
        )
        .subscribe((responses) => {
          this.EmitFilterUtilisateur();
        });
    }
  }

  private _organismeAfilter(value: string): OrganismeListe[] {
    if (value?.length > 0) {
      const filterValue = value?.toLowerCase();
      return this.listOrganisme.filter((option) => option.nom?.toLowerCase().includes(filterValue));
    } else return [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterUtilisateurForm(changes.Reset.currentValue);
  }

  public displayOrganismeFn(organisme): string | undefined | null {
    if (organisme?.id) return organisme ? organisme.nom : null;
  }

  resetfilterUtilisateurForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListCreateur = [];
      this.filterUtilisateur.recheche_create_user = this.CreateurSelectionees = [];
      this.listGroupe = [];
      this.filterUtilisateur.recherche_groupe = this.groupesSelectionees = [];
      this.ListZones = [];
      this.filterUtilisateur.recheche_zone = this.ZonesSelectionees = [];
      this.ListService = [];
      this.filterUtilisateur.service = this.ServiceSelectionnees = [];
      this.filterUtilisateur.entreprise_call = '';
      this.filterUtilisateur.operateur_recherche = '';
      Reset = false;
      this.organismeFormControl.reset();
      this.returnToparent.emit(Reset);
      this.onPushFilterUtilisateur.emit(this.filterUtilisateur);
    }
  }

  getOrganisme(value): void {
    if (value) {
      this.filterUtilisateur.entreprise_call = value.id;
    } else {
      this.filterUtilisateur.entreprise_call = value;
    }

    this.EmitFilterUtilisateur();
  }

  EmitFilterUtilisateur() {
    this.filterUtilisateur.recheche_create_user = this.CreateurSelectionees;
    this.filterUtilisateur.recheche_zone = this.ZonesSelectionees;
    this.filterUtilisateur.recherche_groupe = this.groupesSelectionees;
    this.filterUtilisateur.service = this.ServiceSelectionnees;
    this.onPushFilterUtilisateur.emit(this.filterUtilisateur);
  }
}
