<ng-container *ngIf="contrat && !loading">
    <!--    <div class="row">
        <ul>
            <li [ngClass]="{'assuree': statutContrat}" (change)="pushInfo()"><a>N/R</a></li>
            <li><a>Non Assuré</a>
            </li>
            <li><a [ngClass]="{'assuree': !statutContrat}" (change)="pushInfo()">Assuré</a></li>
        </ul>
    </div> -->

    <div class="row card-body" [ngClass]="{'cover': !allowed_to_update}">
        <div class="col-12">

            <form [formGroup]="formContrat" class="example-form">
                <ng-container *ngIf="allCompagnie">
                    <mat-form-field class="example-full-width" style="width: 100%;">
                        <mat-label>
                            <mat-spinner *ngIf="loadingCie | async" [diameter]="15"></mat-spinner>
                             {{"languages.affaire.label_cie" | translate}}
                        </mat-label>
                        <input formControlName="denomination" (change)="pushInfo()" required [(ngModel)]="contrat.denomination" name="denomination" type="text" matInput [matAutocomplete]="auto">
                        <input formControlName="siren" [(ngModel)]="contrat.siren" type="text" matInput style="display:none">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option (onSelectionChange)="selectedCie(item);" *ngFor="let item of cieAutoComplete$| async; let index = index" [value]="item.text">
                                {{ item.text | titlecase}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
            </form>
        </div>
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <input matInput placeholder="{{'languages.affaire.productName' | translate}}" (change)="pushInfo()" type="text" [(ngModel)]="contrat.nom_produit" name="nomproduit" #nomproduit="ngModel">
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <input matInput placeholder="{{'languages.affaire.PAN_TTC' | translate}}"  type="number" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" min="1" step="1" (change)="pushInfo()" [(ngModel)]="contrat.mca" required #panttc="ngModel" name="panttc">


            </mat-form-field>
            <div *ngIf="panttc.invalid && (panttc.dirty || panttc.touched)" class="message-erreur">
                <div *ngIf="panttc.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                     {{"languages.opportunite.details.panTtcOblig" | translate}}
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <input matInput placeholder="{{'languages.affaire.PM_TTC' | translate}}"  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" min="1" step="1" type="number" [(ngModel)]="contrat.mcm" (change)="pushInfo()" required #pmttc="ngModel" name="pmttc">
            </mat-form-field>
            <div *ngIf="pmttc.invalid && (pmttc.dirty || pmttc.touched)" class="message-erreur">
                <div *ngIf="pmttc.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                    {{"languages.opportunite.details.PMttcoblig" | translate}}
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <input matInput placeholder="{{'languages.opportunite.details.nbrAssure' | translate}}"  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" min="1" step="1" type="number" [(ngModel)]="contrat.assure" (change)="pushInfo()" required #assuree="ngModel" name="assuree">
            </mat-form-field>
            <div *ngIf="assuree.invalid && (assuree.dirty || assuree.touched)" class="message-erreur">
                <div *ngIf="assuree.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                     {{"languages.opportunite.details.nombreAssureOblig" | translate}}
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -14px;">
                <input matInput [matDatepicker]="echeance" (dateChange)="updateDate()" [(ngModel)]="contrat.echeance_resiliation" placeholder="{{'languages.opportunite.label_mainduedate' | translate}}"  required #date="ngModel" name="date">
                <mat-datepicker-toggle matSuffix [for]="echeance"></mat-datepicker-toggle>
                <mat-datepicker #echeance></mat-datepicker>
            </mat-form-field>
            <div *ngIf="date.invalid && (date.dirty || date.touched)" class="message-erreur">
                <div *ngIf="date.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                     {{"languages.opportunite.details.dateEcheanceOblig" | translate}}
                </div>
            </div>
        </div>
    </div>

</ng-container>
<!-- <ng-container *ngIf="contrat === null && !loading">
    <p>pas d'ancien contrat</p>
</ng-container> -->
<ng-container *ngIf="loading">
    <mat-spinner [diameter]="40"></mat-spinner>
</ng-container>
