import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as go from 'gojs';
import { DataSyncService, DiagramComponent, PaletteComponent } from 'gojs-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAdminBpmService } from '../../services/ApiAdminBpm/api-admin-bpm.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-go-js-diagram-bpm',
  templateUrl: './go-js-diagram-bpm.component.html',
  styleUrls: ['./go-js-diagram-bpm.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GoJsDiagramBpmComponent implements OnInit {
  title: string = '';
   
  @ViewChild('myDiagram', { static: true }) public myDiagramComponent: DiagramComponent;
  @ViewChild('myPalette', { static: true }) public myPaletteComponent: PaletteComponent;

  public diagramDivClassName: string = 'myDiagramDiv';
  public skipsDiagramUpdate = false;
  private id_cycle_vie: string = null;
  navbarTxt:string="";
  public diagramNodeData: Array<go.ObjectData> = [];
  public diagramLinkData: Array<go.ObjectData> = [];
  routeNav: string;

  constructor(
    private route: ActivatedRoute,
    private apiAdminBpmService: ApiAdminBpmService,
    private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.showloader();
    this.translate.get('languages').subscribe((object: any) => {

      this.navbarTxt=object.navbar.CycleDeVie +' ➜ '+ object.navbar.Aperçu
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.CycleDeVie +' ➜ '+ object.navbar.Aperçu
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
    });
    this.route.paramMap.subscribe((paramMap) => {
      this.id_cycle_vie = paramMap.get('id_cycle_vie');
      if (this.id_cycle_vie !== null && this.id_cycle_vie !== '') {
        this.apiAdminBpmService.GetGoJsPreviewData(this.id_cycle_vie).subscribe((data) => {
          this.diagramLinkData = data?.diagramLinkData;
          this.diagramNodeData = data?.diagramNodeData;
          this.hideloader();
        });
      }
      this.title = paramMap.get('title');
    });
    this.routeNav= this.route.snapshot.queryParamMap.get('route');

  }

  // initialize diagram / templates
  public initDiagram(): go.Diagram {
    const $ = go.GraphObject.make;
    const dia = $(go.Diagram, {
      initialContentAlignment: go.Spot.Center,
      layout: $(go.GridLayout, { alignment: go.GridLayout.Location }),
      'undoManager.isEnabled': true,
    });
    dia.model = $(go.GraphLinksModel, {
      linkKeyProperty: 'key', // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    });
    dia.commandHandler.archetypeGroupData = { key: 'Group', isGroup: true };
    // define the Node template
    dia.nodeTemplateMap.add(
      'etat',
      $(
        go.Node,
        'Auto',
        { resizable: true, desiredSize: new go.Size(100, 50) },
        $(go.Shape, 'RoundedRectangle', new go.Binding('fill', 'color')),
        $(go.TextBlock, { margin: 8 }, new go.Binding('text'))
      )
    );
    dia.nodeTemplateMap.add(
      'test1',
      $(
        go.Node,
        'Auto',
        { resizable: true, desiredSize: new go.Size(100, 50) },
        $(go.Shape, 'RoundedRectangle', { fill: 'green' }),
        $(go.TextBlock, { margin: 8 }, new go.Binding('text'))
      )
    );
    dia.nodeTemplateMap.add(
      'test2',
      $(
        go.Node,
        'Auto',
        { resizable: true, desiredSize: new go.Size(100, 50) },
        $(go.Shape, 'RoundedRectangle', { fill: 'red' }),
        $(go.TextBlock, { margin: 8 }, new go.Binding('text'))
      )
    );
    dia.nodeTemplateMap.add(
      'test3',
      $(
        go.Node,
        'Auto',
        { resizable: true, desiredSize: new go.Size(100, 50) },
        $(go.Shape, 'RoundedRectangle', { fill: 'white' }),
        $(go.TextBlock, { margin: 8 }, new go.Binding('text'))
      )
    );

    dia.groupTemplateMap.add(
      'multiactions',
      $(
        go.Group,
        'Auto',
        {
          layout: $(go.LayeredDigraphLayout, { direction: 0, columnSpacing: 10 }),
        },
        $(
          go.Shape,
          'RoundedRectangle',
          new go.Binding('fill', 'color'), // surrounds everything
          { parameter1: 10 }
        ),
        $(
          go.Panel,
          'Vertical', // position header above the subgraph
          { defaultAlignment: go.Spot.Left },
          $(
            go.Panel,
            'Horizontal', // the header
            { defaultAlignment: go.Spot.Top },
            $('SubGraphExpanderButton'), // this Panel acts as a Button
            $(
              go.TextBlock, // group title near top, next to button
              { font: 'Bold 12pt Sans-Serif' },
              new go.Binding('text', 'text')
            )
          ),
          $(
            go.Placeholder, // represents area for all member parts
            { padding: new go.Margin(0, 10), background: 'white' }
          )
        )
      )
    );
    dia.groupTemplateMap.add(
      'Service',
      $(
        go.Group,
        'Vertical',
        {
          resizable: true,
          layout: $(go.LayeredDigraphLayout, { direction: 90, columnSpacing: 10 }),
        },
        $(go.TextBlock, new go.Binding('text')),
        $(go.Panel, 'Auto', $(go.Shape, 'RoundedRectangle', { fill: 'beige' }), $(go.Placeholder, { padding: 10 }))
      )
    );

    dia.linkTemplate = $(
      go.Link,
      {
        reshapable: true,
        resegmentable: true,
        routing: go.Link.AvoidsNodes,
        corner: 10,
        curve: go.Link.JumpOver,
        fromSpot: go.Spot.AllSides,
        toSpot: go.Spot.AllSides,
      },
      new go.Binding('points').makeTwoWay(),
      $(go.Shape),
      $(
        go.Shape, // the "from" end arrowhead
        { fromArrow: 'Circle' }
      ),
      $(
        go.Shape, // the "to" end arrowhead
        { toArrow: 'Standard', fill: 'red' }
      )
    );

    return dia;
  }

  // When the diagram model changes, update app data to reflect those changes
  public diagramModelChange = function (changes: go.IncrementalData) {
    // when setting state here, be sure to set skipsDiagramUpdate: true since GoJS already has this update
    // (since this is a GoJS model changed listener event function)
    // this way, we don't log an unneeded transaction in the Diagram's undoManager history
    this.skipsDiagramUpdate = true;
    this.diagramNodeData = DataSyncService.syncNodeData(changes, this.diagramNodeData);
    this.diagramLinkData = DataSyncService.syncLinkData(changes, this.diagramLinkData);
    this.diagramModelData = DataSyncService.syncModelData(changes, this.diagramModelData);
  };

  retourToList() {
  //  this.routeNav = localStorage.getItem("retourList")
    this.routes.navigate([this.routeNav]);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
