import { Garantie } from './../../../entity/Garantie';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
 
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IconPickerComponent } from 'src/app/components/icon-picker/icon-picker.component';

export function valeurMinInferieureValeurMaxValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valeurMin = parseInt(control.get('valeur_min').value);
    const valeurMax = parseInt(control.get('valeur_max').value);

    if (valeurMin !== null && valeurMax !== null && valeurMin > valeurMax) {
      return { valeurMinInferieureValeurMax: true };
    } else {
      return null;
    }
  };
}
@Component({
  selector: 'app-add-garantie',
  templateUrl: './add-garantie.component.html',
  styleUrls: ['./add-garantie.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Assurez-vous que c'est nécessaire
})
export class AddGarantieComponent implements OnInit {
   
  grantieForm: FormGroup;
  ModeAjout: boolean;
  garantie_id: string;
  listeOfUnsubscribeWebService: Subscription[] = [];
  garantie: Garantie = new Garantie();
  show_loading_add: boolean = false;
  alerts: any;
  buttons: any;
  navbarTxt:string="";
  @ViewChild(IconPickerComponent) iconPickerComponent!: IconPickerComponent;
  icon: string = '';
  currency: string = environment.currentCurrency;
  constructor(
    private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private route: ActivatedRoute,
    private apiGarantieService: ApiGarantieService,
    private translate : TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
   if (!currentRoute.includes('/add-garantie')) {
     localStorage.removeItem('search');
     localStorage.removeItem('page');
   }
 }
 

  ngOnInit(): void {


    this.translate.onLangChange.subscribe(() => {

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.navbarTxt=object.navbar.gestionGarantie +' ➜ '+ object.navbar.Ajouter
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
  });

  this.translate.get('languages').subscribe((object: any) => {
    this.alerts = object.alerts;
    this.buttons = object.buttons;
    this.navbarTxt=object.navbar.gestionGarantie +' ➜ '+ object.navbar.Ajouter
    this.sharedMenuObserverService.updateMenu(this.navbarTxt);
  });


    this.grantieForm = new FormGroup({
      description: new FormControl(''),
      code: new FormControl('', [Validators.required]),
      unite: new FormControl('', [Validators.required]),
      nom: new FormControl('', [Validators.required]),
      cotisation: new FormControl(false),
      icon: new FormControl(''),
      valeur_min: new FormControl(''),
      valeur_max: new FormControl('', [valeurMinInferieureValeurMaxValidator]),
      capitaux_assure: new FormControl(false),
    });

    this.route.paramMap.subscribe((params) => {
      if (params.get('id') !== 'nouveau') {

        this.translate.get('languages').subscribe((object: any) => {

          this.navbarTxt=object.navbar.gestionGarantie +' ➜ '+ object.navbar.Détails
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
        this.translate.onLangChange.subscribe(() => {

          this.translate.get('languages').subscribe((object: any) => {

            this.navbarTxt=object.navbar.gestionGarantie +' ➜ '+ object.navbar.Détails
            this.sharedMenuObserverService.updateMenu(this.navbarTxt);
          });
        });
        this.ModeAjout = false;
        const unsubscribeGetGarantieById = this.apiGarantieService
          .getGarantieById(params.get('id'))
          .subscribe((data: any) => {
            this.garantie = data;

            this.garantie_id = params.get('id');
            this.grantieForm.get('code').setValue(this.garantie[0].code);
            this.grantieForm.get('description').setValue(this.garantie[0].description);
            this.grantieForm.get('cotisation').setValue(this.garantie[0].cotisation);
            this.grantieForm.get('unite').setValue(this.garantie[0].unite);
            this.grantieForm.get('nom').setValue(this.garantie[0].nom);
            this.grantieForm.get('capitaux_assure').setValue(this.garantie[0].capitaux_assure);
            this.grantieForm.get('valeur_min').setValue(this.garantie[0].valeur_min);
            this.grantieForm.get('valeur_max').setValue(this.garantie[0].valeur_max);
            if(this.garantie[0].icon !== '' && this.garantie[0].icon!== null){
            this.grantieForm.get('icon').setValue(this.garantie[0].icon);
            this.icon =this.garantie[0].icon;
          }else{
            this.icon = '';
          }
          });
        this.listeOfUnsubscribeWebService.push(unsubscribeGetGarantieById);
      } else {
        this.ModeAjout = true;
      }
    });
  }

  checkedIcon = true;
  onCheckedNameIcon(event) {
    this.checkedIcon = event;
  }

  add() {
    this.show_loading_add = true;
    this.grantieForm.get('code').markAsTouched();
    this.grantieForm.get('unite').markAsTouched();
    this.grantieForm.get('nom').markAsTouched();
    if (this.grantieForm.valid && this.checkedIcon) {
      if (this.ModeAjout) {
        const unsubscribeInsertGarantie = this.apiGarantieService.addGarantie(this.grantieForm.value).subscribe(
          (Response) => {
            
            this.alertSuccess(Response.message, Response.code, true);
            this.show_loading_add = false;
            this.cdr.detectChanges();
          },
          (err) => {
            this.alertError(this.alerts.errorAddGarantie, err?.error?.message);
            this.show_loading_add = false;
            this.cdr.detectChanges();
          }
        );
        this.listeOfUnsubscribeWebService.push(unsubscribeInsertGarantie);
      } else {
        const unsubscribeUpdateGarantie = this.apiGarantieService
          .UpdateGarantie(this.grantieForm.value, this.garantie_id)
          .subscribe(
            (Response) => {
              this.alertSuccess(Response.message, Response.id, false);
              this.show_loading_add = false;
              this.cdr.detectChanges();
            },
            (err) => {
              this.alertError(this.alerts.errorUpdatingGarantie, err.error.message);
              this.show_loading_add = false;
              this.cdr.detectChanges();
            }
          );
        this.listeOfUnsubscribeWebService.push(unsubscribeUpdateGarantie);
      }
    } else if (this.grantieForm.invalid) {
      this.alertError(this.alerts.formulaireinvalide, this.alerts.mercideRemplir);
      this.show_loading_add = false;
      this.cdr.detectChanges();
    } else if (this.grantieForm.valid && !this.checkedIcon) {
      this.show_loading_add = false;
      this.alertError(this.alerts.invalidIcon, this.alerts.fillIcon);
      this.cdr.detectChanges();
    }
  }

  retourToList() {
    this.routes.navigate(['/garantie']);
  }

  clearForm() {
    if (this.ModeAjout) {
      this.grantieForm.reset();
      this.icon =''
    }else{
      this.grantieForm.get('code').setValue(this.garantie[0].code);
      this.grantieForm.get('description').setValue(this.garantie[0].description);
      this.grantieForm.get('cotisation').setValue(this.garantie[0].cotisation);
      this.grantieForm.get('unite').setValue(this.garantie[0].unite);
      this.grantieForm.get('nom').setValue(this.garantie[0].nom);
      this.grantieForm.get('capitaux_assure').setValue(this.garantie[0].capitaux_assure);
      this.grantieForm.get('valeur_min').setValue(this.garantie[0].valeur_min);
      this.grantieForm.get('valeur_max').setValue(this.garantie[0].valeur_max);
      if(this.garantie[0].icon !== '' && this.garantie[0].icon!== null){
      this.grantieForm.get('icon').setValue(this.garantie[0].icon);
      this.icon =this.garantie[0].icon;
    }else{
      this.icon = '';
    }
    }
    this.iconPickerComponent.simulateNgOnChanges(this.icon);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data, idGarantie, modeedit) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: this.buttons.newGarantie,
        denyButtonColor: '833626',
        cancelButtonText: this.buttons.modifyGarantie,
        confirmButtonText:this.buttons.listGarantie,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/garantie']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['garantie/add-garantie/' + idGarantie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['garantie/add-garantie/nouveau']).then(() => {
            window.location.reload();
          });
        }
      });
  }

  alertError(data, err?) {
    swal.fire({
      title: data,
      // text: "Quelque chose s'est mal passé!",
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  onSelectIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon = event;
  }

  onSelectNameIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon = event;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isValeurMaxInvalid(): boolean {
    const isInvalid = this.grantieForm.get('valeur_min').value >= this.grantieForm.get('valeur_max').value;
    return isInvalid;
  }
}
