import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-add-tickets',
  templateUrl: './add-new-tickets.component.html',
  styleUrls: ['./add-new-tickets.component.css'],
})
export class AddNewTicketsComponent implements OnInit {
  constructor(private sharedMenuObserverService: SharedMenuObserverService,
    private translate : TranslateService
    ) {}

  type: string = 't';
  fromDetails: Boolean = false;
  navbarTxt:string="";
  ngOnInit(): void {


    this.translate.get('languages').subscribe((object: any) => {

      this.navbarTxt=object.navbar.ticketSupport +' ➜ '+ object.navbar.Ajouter
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.ticketSupport +' ➜ '+ object.navbar.Ajouter
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });});
  }
}
