
  <div class="row" #myDiv >
    <div class="col-lg-12">
      <div class="navbar-center row">
  
  
        <div class="col">
          <div class="row position-absolute flex-nowrap" style="right: 0px">
            <div class="col text-nowrap text-right mt-2">
              <p >{{'languages.listing.label_goto' | translate}}</p>
            </div>
            <div class="col mt-1 ml-2">
              <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
                size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4 border-0 border" >
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-3">
                
                <button  type="button" class="btn-dt-exporter" (click)="exportDataToCsv()" [disabled]="logs?.length==0"   [ngStyle]="{'opacity': logs?.length==0 ? '0.5' : '1'}">
                    <i class="fa-solid fa-file-excel fa-lg"></i>
                           {{'languages.buttons.exporterCSV' | translate}}
                </button>
            </div>
          <div class="col-6 blocDate mt-2">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-2">    
                  <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
          </div>
              <div class="col-8">
              
                    <mat-form-field appearance="standard" class="w-100">
                      <mat-date-range-input [rangePicker]="picker"  >
                        <input matStartDate [(ngModel)]="date_debut" readonly placeholder="Date début "  (click)="openDatePicker()" />
                        <input matEndDate [(ngModel)]="date_fin" readonly placeholder="Date fin" (click)="openDatePicker()"/>
                        
                      </mat-date-range-input>
                      
                      <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                        <img src="assets/icons/calendar.svg" > 
                      </mat-icon></mat-datepicker-toggle>
                      <img  class="remouveDate" matSuffix (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
                      <mat-date-range-picker  [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
                    </mat-form-field>
                 
                </div>
                <div class="col-2 w-100 pl-0 ">
                    <button type="button" class="filtrer" (click)=" filtrer()">
                      <img src="assets/icons/filtre.svg" class="mr-3" alt=""> 
                      <p class="m-0" style="font-size: 14px;">{{'languages.buttons.filtrer' | translate}}</p>
      </button>
                </div>
            </div>
        </div>
          <div class="col-3 p-2  text-right">
            <input class="search" name="search" [(ngModel)]="search"  (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
            <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>
          </div>
          
        </div>
        
        <div class="table-responsive p-3">
          <table class="table align-items-center">
            <thead class="head-table">
              <tr>
                <th>
                  {{'languages.listing.event' | translate}}
                </th>
                <th>
                    {{'languages.listing.dateExecution' | translate}}
                  </th>
                  <th>
                    {{'languages.listing.idRule' | translate}}
                  </th>

                <th >
                  {{'languages.convention.status' | translate}}
                </th>
                <th >{{'languages.opportunite.source' | translate}}
                
                </th>
                
                  <th>
                    {{'languages.listing.msg' | translate}}
                  </th>
                  <th>

                    {{'languages.listing.actions' | translate}}
                  </th>
                
              </tr>
            </thead>
            <tbody class="body-table">
              <tr *ngFor="let log of logs; let i = index">
                <td>
                    <p [title]="log?.source_event" >{{expandFileName(log?.source_event)}}</p>
                  </td>
                  <td>
                    <p>{{log?.last_execution}}</p>
                  </td>
                <td>
                  <p [title]="log?.name_rule">{{log?.id_rule}}</p>
                </td>
                <td class="d-flex align-items-center justify-content-center">
                  <div [ngClass]="{'ok-class': log.status === 'OK', 'ko-class': log.status === 'KO'}">
                    <p>{{log.status}}</p>
                  </div>
                 
                </td>
                <td>
                  <i *ngIf="log?.id_entity" (click)="getPageSource(log?.entity,log?.id_entity)" class="fa-solid fa-link fa-icon-style linkPageSource"></i>
                  
                </td>
                <td>
                  <p [title]="log?.msg">{{expandFileName(log?.msg)}}</p>
                </td>
                <td>
  
                 <div class="row d-flex justify-content-end align-items-center pr-2">
                  <button class="reexecute" *ngIf="log?.status === 'KO' && !log.isLoading" (click)="Reexecute(log)">
                    <i [title]="'languages.listing.reexecuteevent' | translate" class="fa-solid fa-arrows-rotate-reverse fa-lg"></i>
                  </button>
                  
                  <ng-container *ngIf="log.isLoading">
                    <div class="">
                        <div class="text-center m-auto ">
                            <mat-spinner [diameter]="20"></mat-spinner>
        
                        </div>
                    </div>
                </ng-container>
                    
                    <button class="downloadJson "  (click)="downloadJson(log)">
                        <i [title]="'languages.listing.downloadJson' | translate" class="fa-solid fa-download fa-lg"></i>
                    </button>
                 </div>
            </td>
              </tr>
            </tbody>
          </table>
          <div class="row d-flex justify-center align-items-center text-center ">
            <p *ngIf="logs?.length===0"  class="col-12 noTicket">  {{'languages.affaire.noDataAvailable' | translate}} </p>
           </div>
        </div>
      </div>
      <div class="navbar-center mb-4 row">
        <div class="col pt-1">
          <div class="row w-50 flex-nowrap" >
            <div  class="col mt-1">
              <p>{{'languages.miniListing.Afficher' | translate}}</p>
            </div>
            <div class="col mx-1">
              <select [(ngModel)]="limit" (change)="updateListTickets()"
                class="bg-white border pt-1 rounded font-weight-bold">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col mt-1">
              <p class="text-nowrap">{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-8">
          <nav aria-label="Page navigation example" *ngIf="totalPages>0">
            <ul class="pagination pg-blue">
              <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                <a class="page-link-symbol">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Previous">
                  <i class="fa fa-chevron-left "></i>
                </a>
              </li>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+pages-3>0" class="page-item">
                  <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
                </li>
              </ng-container>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+pages<totalPages" class="page-item">
                  <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded"
                    [ngClass]="{'active-page':pages==(i+pages)}">
                    {{i + pages}}
                  </a>
                </li>
              </ng-container>
              <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
              <li class="page-item">
                <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                  [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
              </li>
              <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Next">
                  <i class="fa fa-chevron-right"></i>
                </a>
              </li>
              <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                <a class="page-link-symbol">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col">
          <div class="row position-absolute flex-nowrap" style="right: 0px">
            <div class="col text-nowrap text-right mt-2">
              <p >{{'languages.listing.label_goto' | translate}}</p>
            </div>
            <div class="col mt-1 ml-2">
              <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
                size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  