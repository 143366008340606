import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';
@Injectable({
  providedIn: 'root'
})
export class ApiGroupGarantieService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) { 

      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }



    public getListGroupGarantie(

      observe?: 'body',
      reportProgress?: boolean
    ): Observable<any> {
      // let sort: string;
   
  
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
  
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
      headers = headers.set('Content-Type', 'application/json');
  
     
      return this.httpClient.request<any>('get', `${this.basePath}/garantie/list_groupe_garantie`, {
        withCredentials: this.configuration.withCredentials,
        observe: observe,
        headers: headers,
        reportProgress: reportProgress,
      });
    }


    public getListGroupGarantieV1(
      limit? : any,
      page? : any,
      search?: any,
      observe?: 'body',
      reportProgress?: boolean
    ): Observable<any> {
      // let sort: string;
   
      let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      queryParameters = queryParameters.append('limit', <any>limit);
      queryParameters = queryParameters.append('page', <any>page);
      queryParameters = queryParameters.append('search', <any>search);
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
  
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
      headers = headers.set('Content-Type', 'application/json');
  
     
      return this.httpClient.request<any>('get', `${this.basePath}/garantie/v1/list_groupe_garantie`, {
        withCredentials: this.configuration.withCredentials,
        observe: observe,
        headers: headers,
        reportProgress: reportProgress,
        params: queryParameters

      });
    }



    public addGroupGarantie(groupGarantie: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
  
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      return this.httpClient.request<any>('post', `${this.basePath}/garantie/add_Groupe_garantie`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: groupGarantie,
      });
    }

    public UpdateGroupGarantie(groupGarantie: any,  observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [];
  
      return this.httpClient.request<any>('put', `${this.basePath}/garantie/update_groupe_garantie`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: groupGarantie
        }
      );
    }


    public getGroupGarantieById(group_id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [];
  
      return this.httpClient.request<any>('get', `${this.basePath}/garantie/get_groupe_garantie/${group_id}`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
}
