import { Component, OnInit } from '@angular/core';
import {FieldType} from "@ngx-formly/core";

@Component({
  selector: 'app-multi-schema-type',
  templateUrl: './multi-schema-type.component.html',
  styleUrls: ['./multi-schema-type.component.css']
})
export class MultiSchemaTypeComponent extends FieldType implements OnInit {
  searchValue: any;

  ngOnInit(): void {
    this.searchValue = this.field.templateOptions?.autofill;

    // Disable or enable based on autofill option
    if (this.field.templateOptions?.autofill) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }

    
    // Set initial value if provided
   
}

updateValue(event: Event): void {

  this.formControl.setValue('Some update'); // Update with the logic you need
}
}