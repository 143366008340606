<div class="row  mt-2" >

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input (change)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.nom_prenom_expediteur" matInput placeholder="{{'languages.communication.nomPrenomExp' | translate}}"
                    type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input (change)="EmitFilterExpiditeur()" 
                    [(ngModel)]="filterExpediteur.adresse_expediteur" matInput placeholder="{{'languages.communication.NomNumRue' | translate}}  "
                    type="text">
            </mat-form-field>

        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input  (change)="EmitFilterExpiditeur()"
                    pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel"
                    [(ngModel)]="filterExpediteur.cp_expediteur" matInput placeholder="{{'languages.prospect.CodePostal' | translate}}" type="text">
            </mat-form-field>
            <!-- <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                <div *ngIf="nombre.errors.pattern">
                    <i class="fa fa-exclamation-triangle"></i>&nbsp;     {{'languages.opportunite.check_onlyNumber' | translate}}
                </div>
            </div> -->
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input  (change)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.ville_expediteur" matInput placeholder="{{'languages.prospect.Ville' | translate}}" type="text">
            </mat-form-field>

        </div>
    </div>


    <div class="col-12">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input  (change)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.complement_adresse_expediteur" matInput
                    placeholder= "{{'languages.prospect.fullAdress' | translate}}" type="text">
            </mat-form-field>

        </div>
    </div>
</div>
