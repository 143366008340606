import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commentaires',
  templateUrl: './commentaires.component.html',
  styleUrls: ['./commentaires.component.css'],
})
export class CommentairesComponent implements OnInit {
  id_opportunite: string;
  allowed_to_update: any;
  navbarTxt:string="";
  constructor(
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiOpportuniteServices: ApiOpportunitService,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportuniteServices.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {
    this.id_opportunite = this.opportunitiesDetailsComponent.opportunity_id;
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsCommentaire
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);

    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.OppDetailsCommentaire
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
     });
  }
}
