import { Component, Input, OnInit,Injectable ,ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { TransactionFinanciere } from './../../../../entity/Affaires/transactionFinanciere';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { tap, filter } from 'rxjs/operators';
import { ApiPaymentService } from 'src/app/services/ApiPayment/api-payment.service';
import { UtilsService } from './../../../../services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import{HistoryServiceTicket} from 'src/app/pages/tickets-and-support/history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';



@Component({
  selector: 'app-transaction-financiere',
  templateUrl: './transaction-financiere.component.html',
  styleUrls: ['./transaction-financiere.component.css']
})
export class TransactionFinanciereComponent implements OnInit {
  show_loading: boolean = true;
  @Input() ticket_id = '';
  listtransactions: TransactionFinanciere[]=[];
  transactionAdd : TransactionFinanciere = new TransactionFinanciere()
  listeUnsubscribeTransaction: Subscription[] = [];
  limit: number = 5;
  totalPages: number ;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  public editorOptions: JsonEditorOptions;
page=1
  transaction: any;
  allPaiement: any;

  typeTransaction:any=[
    {type:'Credit',libelle: 'Credit'},
    {type:'Debit',libelle: 'Debit'}
  ]

  @ViewChild('Motif') motifControl: NgForm;
  @ViewChild('Montant') montantControl: NgForm;

  @ViewChild('Modepayemnt') paiementControl: NgForm;
  @ViewChild('date_creation') dateControl: NgForm;
  @ViewChild('type') typeControl: NgForm;
  
  @ViewChild('closeModal') private closeModal: ElementRef;
  alerts: any;
  buttons: any;
  listMotifTransaction: any;
  showLoaderDoc: boolean;
  show_loading_adding: boolean;


  constructor(
    private routes: ActivatedRoute,
    private datePipe: DatePipe,
    private translate : TranslateService,
    private apiTicketsServices: ApiTicketsService,
    private apiPaiementService: ApiPaymentService,
    private notificationsService: NotificationsService,
    private HistoryServiceTicket: HistoryServiceTicket

  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['view', 'code', 'text']; // set all allowed modes
    this.editorOptions.mode = 'view'; // set only one mode
   }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.translateFn(object)
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.translateFn(object)
      });

      this.apiTicketsServices.reloadTransaction$.subscribe((reload) => {
        if (reload) {
          this.GetTransactionsTransaction();  // Reload transactions
        }
      });

    this.getModePaiement()
    this.GetMotifTransactions()
    this.routes.paramMap.subscribe((params) => {
      this.GetTransactionsTransaction();
    });
  }


  translateFn(object){
    this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.typeTransaction=[
          {type:'Credit',libelle: object.affaire.credit},
          {type:'Debit',libelle: object.affaire.debit}
        ]
  }
  
  getModePaiement() {
    this.apiPaiementService
      .GetModePaiement()
      .pipe(
        tap(({ data }) => {
          this.allPaiement = data.filter((element) => element.libelle !== "");
        })
      )
      .subscribe();
    
  }
  
  expandReason(name: string): string {
    name = name?.trim();
    if (name?.length < 15) {
      return name;
    } else {
      return name?.substring(0, 14) + '...';
    }
  }

  GetMotifTransactions(){
    this.apiPaiementService
      .GetMotifTransactions()
      .subscribe(
        (response)=>{
          this.listMotifTransaction=response.motifs.filter((element) => element.libelle !== "");
        },
        (error)=>{
          console.log(error)
        }
      );
  }

  InsertTransaction(){
    
    this.makeTouched();
    if (!this.checkObligData()){
      this.alertError(this.alerts.fillInputs)
    }else if (parseFloat(this.transactionAdd.Montant) === 0){
      this.alertError(this.alerts.montantnotzero)
    }
    
    else{
      const formData: FormData = new FormData();
      formData.append('type_mouvement', this.transactionAdd.motif);
      formData.append('mode', this.transactionAdd.mode_Payment);
      formData.append('date_creation', this.datePipe.transform(this.transactionAdd.date, 'yyyy-MM-ddTHH:mm:ss') );
      formData.append('file', this.transactionAdd.file);
      if (this.transactionAdd.type==="Credit"){
        formData.append('Credit', this.transactionAdd.Montant);
        formData.append('Debit', '');
      }else  {
        formData.append('Credit', '');
        formData.append('Debit', this.transactionAdd.Montant);
      }
     
    

      this.show_loading_adding=true
      this.apiTicketsServices.InsertTransaction(this.ticket_id,formData).subscribe(
        (response)=>{
          this.alertSuccess(response.message)
          this.GetTransactionsTransaction();
          this.show_loading_adding=false
          this.HistoryServiceTicket.sendFetchDataSignal(true)
          this.closeModal.nativeElement.click();

      },
    (error)=>{
      this.show_loading_adding=false
      this.alertError(error.error.message)
    });
    }
   
  }

  makeTouched(){
    this.motifControl.control.markAsTouched();
    this.motifControl.control.updateValueAndValidity();
    this.montantControl.control.markAsTouched();
    this.montantControl.control.updateValueAndValidity();
    this.paiementControl?.control.markAsTouched();
    this.paiementControl?.control.updateValueAndValidity();
    this.dateControl.control.markAsTouched();
    this.dateControl.control.updateValueAndValidity();
    this.typeControl.control.markAsTouched();
    this.typeControl.control.updateValueAndValidity();
  }

  checkObligData(){
    if(!this.transactionAdd.Montant || !this.transactionAdd.date || (this.transactionAdd.type==="Credit" && !this.transactionAdd.mode_Payment) || !this.transactionAdd.type || !this.transactionAdd.motif){

      return false
    }else {
      return true 
    }
  }
 


  onFileChange(event: any) {
    this.showLoaderDoc = true 
    const file = event.target.files[0];
    if (file) {
      this.showLoaderDoc = false 
      this.transactionAdd.file = file;
      this.transactionAdd.fileName=file.name

     
      // or readAsText(file) or readAsArrayBuffer(file) depending on your needs
    }else{
      this.showLoaderDoc = false 
    }
  }



  convertCurreny(event) {
    this.transactionAdd.Montant = UtilsService.formatValue(event.target.value.toString(), this.currency);
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 40) {
      return name;
    } else {
      return name?.substring(0, 40) + '...';
    }
  }

  removefile(){
    this.transactionAdd.file=""
  }


  getTransaction(transaction: any): void {
    this.transaction = transaction;
  }


  ngOnDestroy(): void {
    this.listeUnsubscribeTransaction.forEach((element) => {
      element?.unsubscribe();
    });
  }

  GetTransactionsTransaction(){
    this.show_loading = true;
    const unsubscribeGetEchancierAffaire = this.apiTicketsServices.GetTransactionsTransaction(this.ticket_id , this.limit ,this.page).subscribe(
      (response) => {
        this.listtransactions = response.mouvement_financiere;
        
        this.calculate_nb_pages(response.total)
        this.show_loading = false;
      
      },
      (error) => {
        console.log(error);
        this.show_loading = false;
      }
    );
    this.listeUnsubscribeTransaction.push(unsubscribeGetEchancierAffaire);
  }

  extractDate(inputDate) {
    if (inputDate){const dateObject = new Date(inputDate);
      return this.datePipe.transform(dateObject, 'dd-MM-yyyy');}
    
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return value;

    return value.charAt(0).toUpperCase() + value.slice(1);
  }
 


  // numberOnly(event): boolean {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }


  emptyTransaction() {
    this.transactionAdd= new TransactionFinanciere()
    if (this.motifControl) {
      this.motifControl.control.markAsPristine();
      this.motifControl.control.markAsUntouched();
      this.motifControl.control.setErrors(null);
    }
    if (this.montantControl) {
      this.montantControl.control.markAsPristine();
      this.montantControl.control.markAsUntouched();
      this.montantControl.control.setErrors(null);
    }
    if (this.paiementControl) {
      this.paiementControl.control.markAsPristine();
      this.paiementControl.control.markAsUntouched();
      this.paiementControl.control.setErrors(null);
    }
    if (this.dateControl) {
      this.dateControl.control.markAsPristine();
      this.dateControl.control.markAsUntouched();
      this.dateControl.control.setErrors(null);
    }
    if (this.typeControl) {
      this.typeControl.control.markAsPristine();
      this.typeControl.control.markAsUntouched();
      this.typeControl.control.setErrors(null);
    }
  }


  alertSuccess(data) {
    swal.fire({
      title: this.alerts.Successoperation,
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#68a45b',
    });
  }

  alertError(err) {
    swal.fire({
      title: this.alerts.error,
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
      
    });
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  setPage(val: number) {
    this.page = val;

    this.GetTransactionsTransaction()
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    this.GetTransactionsTransaction()
  }
  
  // generateQuitanceTransaction(id_transaction: string) {
  //   this.generateQuitanceLoader[id_transaction] = true;

  //   this.apiAffairesService.generateQuitanceTransaction(id_transaction).subscribe(
  //     (data: any) => {
  //       this.generateQuitanceLoader[id_transaction] = false;
  //       const fileLink = data.url_file;
  //       if (fileLink) {
  //         window.open(fileLink, '_blank');
  //       }
  //     },
  //     (error) => {
  //       this.generateQuitanceLoader[id_transaction] = false;
  //       const notificationMessage = new NotificationMessage();
  //       notificationMessage.type = NotificationType.error;
  //       notificationMessage.title = this.alerts.failedOperation;
  //       notificationMessage.message = error.error.message;
  //       this.notificationsService.sendMessage(notificationMessage);
  //     }
  //   );
  // }

}
