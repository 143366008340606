<div class="row">
  <div class="col-9">
    <div class="row">
      <div class="col-8">
        <agm-map [latitude]="lat" [longitude]="lng" style="height: 500px;">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
      </div>
      <div class="col-4">
        <div class="card-info">
          <div class="row" st>
            <div class="col">
              <p class="title p-title">Collaborateur</p>
              <h3>Nom</h3>
            </div>
            <div class="col">
              <img src="../../../../../../assets/img/ID53506340_618147591965833_474.png">
            </div>
          </div>
          <div class="row" st>
            <div class="col">
              <p class="title p-title">RDVs</p>
              <h3>2</h3>
            </div>

          </div>
          <div class="row" st>
            <div class="col">
              <p class="title p-title">Durée Totale Estimée</p>
              <h3>301 minutes</h3>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="title p-title">Distance Totale</p>
              <h3>55 km</h3>
            </div>
          </div>
          <div class="row" style="justify-content: center;">
            <img class="img-map" src="../../../../../../assets/img/map.jpg">
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-6" style="margin-top: 20px;">
        <div class="row card-body">
          <h2>Rappel</h2>
          <div class="table-responsive p-3">
            <div class="range-bar">
              <input type="range" min="1" max="100" value="50" class="bar">
            </div>
            <table class="table align-items-center ">
              <thead class="thead-light text-center">
                <tr>
                  <th>Nom
                  </th>
                  <th>Etat
                  </th>
                  <th>Action
                  </th>
                </tr>
              </thead>
              <tbody class="main-table-body">

                <tr>
                  <td class="td-fix">Atef</td>
                  <td class="td-fix"> Rappel</td>
                  <td style="padding-top: 0px;">
                    <table class="table align-items-center">
                      <tbody class="main-table-body">

                        <tr>
                          <td style="border-top: none;">
                            <button class="btn"><img src="../../../assets/img/opportunites/defaults/c.png" alt="">
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                              
                                <i class="fa-solid fa-location-dot icon-style"  style="margin-left: 5px; color: #cbd664;"></i>
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                           
                                <i class="fa-solid fa-venus-mars icon-style " style="margin-left: 5px; color: #387714;"></i>
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                             
                                <i class="fa-solid fa-pen-to-square icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="col-6" style="margin-top: 20px;">
        <div class="row card-body">
          <h2>Inexploité</h2>
          <div class="table-responsive p-3">
            <div class="range-bar">
              <input type="range" min="1" max="100" value="50" class="bar">
            </div>
            <table class="table align-items-center ">
              <thead class="thead-light text-center">
                <tr>
                  <th>Nom
                  </th>
                  <th>Etat
                  </th>
                  <th>Action
                  </th>
                </tr>
              </thead>
              <tbody class="main-table-body">

                <tr>
                  <td class="td-fix">Atef</td>
                  <td class="td-fix"> Rappel</td>
                  <td style="padding-top: 0px;">
                    <table class="table align-items-center">
                      <tbody class="main-table-body">

                        <tr>
                          <td style="border-top: none;">
                            <button class="btn"><img src="../../../assets/img/opportunites/defaults/c.png" alt="">
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                              <i class="fa-solid fa-location-dot icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>

                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                             
                                <i class="fa-solid fa-venus-mars icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                              <i class="fa-solid fa-pen-to-square icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-6" style="margin-top: 20px;">
        <div class="row card-body">
          <h2>Honoré</h2>

          <div class="table-responsive p-3">
            <div class="range-bar">
              <input type="range" min="1" max="100" value="50" class="bar">
            </div>
            <table class="table align-items-center ">
              <thead class="thead-light">
                <tr>
                  <th>Nom
                  </th>
                  <th>Etat
                  </th>
                  <th>Action
                  </th>
                </tr>
              </thead>
              <tbody class="main-table-body">

                <tr>
                  <td class="td-fix">Atef</td>
                  <td class="td-fix"> Rappel</td>
                  <td style="padding-top: 0px;">
                    <table class="table align-items-center">
                      <tbody class="main-table-body">

                        <tr>
                          <td style="border-top: none;">
                            <button class="btn"><img src="../../../assets/img/opportunites/defaults/c.png" alt="">
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                              
                                <i class="fa-solid fa-location-dot icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                              <i class="fa-solid fa-venus-mars icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                          <td style="border-top: none;">
                            <button class="btn">
                                <i class="fa-solid fa-pen-to-square icon-style" style="margin-left: 5px; color: #9b9b9b;"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>


  </div>

  <div class="col-3 card-info">
    <div class="col-12" style="text-align: -webkit-center;">
      <mat-calendar #calendar>
      </mat-calendar>
      <!-- <ejs-calendar id=calendar [value]='value' [min]='minDate' [max]='maxDate'> </ejs-calendar> -->
    </div>
    <div class="row">
      <div class="col-8">
        <button class="btn-applique">
        
          <i class="fa-solid fa-circle-check fa-lg mr-1" style="color: white;margin-left: 10px; background-color: #2a96ff;"></i>
          Appliquer
        </button>
      </div>
      <div class="col-4">
        <button class="btn-proposition" data-toggle="modal" data-target="#Proposition">
          Proposition
        </button>
      </div>
    </div>
    <hr>
    <div class="col-12 card-time">
      <div class="row">
        <div class="col">
          <i class="fa-solid fa-user" style="color: #2a96ff;margin-right: 15px;"></i>
          <p class="p-style">Nom d'utilisateur</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn-time">
            <i class="fa-solid fa-clock" style="color: red;margin-right: 15px;"></i>
            18:00 
            <i class="fa-solid fa-clock"  style="color: #2a96ff;margin-left: 10px;"></i>

          </button>
        </div>
        <div class="col" style="text-align: end;">
          <button type="button" class="bbtn-dt-list" (click)="navigate()">
            <img src="assets/icons/edit-icon.svg">
          </button>
        </div>

      </div>
      <div class="row" style="margin-top: 20px;">
        <div class="col">
          <i class="fa-solid fa-globe" style="color: #2a96ff;margin-right: 15px;"></i>
          <p style="display: inline-block; margin-left: 20px;">348.1 km</p>
        </div>
        <div class="col">
          <i class="fa-solid fa-globe" style="color: #2a96ff;margin-right: 15px;"></i>
          <p style="display: inline-block; margin-left: 20px;">53 minutes</p>
        </div>
      </div>

    </div>
  </div>
</div>

<!------------ Proposition Model------------->
<div class="modal fade" id="Proposition" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog model-lg" role="document">
    <div class="modal-content" style="width: 60rem; margin-left: -135px;">
      <div class="modal-header">
        <h2>Propositions</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <div class="col">
              <div class="row">
                <div style="max-width: 50px" class="col">
                  <p>{{'languages.miniListing.Afficher' | translate}}</p>
                </div>
                <div style="max-width: 50px" class="col">
                  <select class="select-style" style="margin-top: -6px; padding-top: 8px;">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div class="col">
                  <p>Entrées</p>
                </div>
              </div>

            </div>
          </div>
          <div class="col-4"></div>
          <div class="col-4">
            <div id="search-grp" class="column">
              <i class="fa-solid fa-magnifying-glass icon-style fa-lg" style="color:  grey;"></i>
              <input type="text" class="search" name="" style="border: 2px solid grey;" placeholder="Search...">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive p-3">
            <table class="table align-items-center ">
              <thead class="thead-light">
                <tr>
                  <th>Aller à
                    <div class="sort-icon" style="display: inline-block;">
                     
                      <i class="fa-solid fa-arrow-down-short-wide icon-style fa-x" style="margin-left: 5px;"></i>
                    </div>
                  </th>
                  <th>Date
                    <div class="sort-icon" style="display: inline-block;">
                      <i class="fa-solid fa-arrow-down-short-wide icon-style fa-x" style="margin-left: 5px;"></i>

                    </div>
                  </th>
                  <th>De .. à
                    <div class="sort-icon" style="display: inline-block;">
                      <i class="fa-solid fa-arrow-down-short-wide icon-style fa-x" style="margin-left: 5px;"></i>

                    </div>
                  </th>
                  <th>Impact
                    <div class="sort-icon" style="display: inline-block;">
                      <i class="fa-solid fa-arrow-down-short-wide icon-style fa-x" style="margin-left: 5px;"></i>

                    </div>
                  </th>
                  <th>Nbre. RDVs
                    <div class="sort-icon" style="display: inline-block;">
                      <i class="fa-solid fa-arrow-down-short-wide icon-style fa-x" style="margin-left: 5px;"></i>

                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="main-table-body">

                <tr class="tr-style">
                  <td>
                    <button type="button" class="btn-dt-list">
                      <img src="assets/icons/edit-icon.svg">
                    </button>
                  </td>
                  <td> lundi 16/11/20</td>
                  <td>09->17</td>
                  <td>0</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>