<div class="card-body-bloc bg-white border-0 p-3 customMargin">
    <div class="card-doc-title ml-3">
        <i class="fa-solid fa-list-check mr-2 fa-lg"></i>
         {{'languages.buttons.details' | translate}}
    </div>
    <form>
        <div class="row p-3">
            <div class="col-3">
                <div class="form-group">
                    
                    <mat-form-field class="w-100">
                        <mat-label>{{'languages.alerts.subject' | translate}}</mat-label>
                        <input [(ngModel)]="informationTicket.sujet" matInput placeholder="Sujet ticket " required #sujetT="ngModel" name="sujetT" (change)="doSomething($event)">
                        <mat-error *ngIf="sujetT.invalid && (sujetT.dirty || sujetT.touched)" >
                            <div *ngIf="sujetT.errors.required">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                {{'languages.ticket.obligSujetTicket' | translate}}
                            </div>
                        </mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                 
                    <mat-form-field class="w-100">
                        <mat-label>{{'languages.alerts.affectedTo' | translate}}</mat-label>
                        <input type="text"  [(ngModel)]="this.informationTicket.affecte_a"  matInput [formControl]="myControlaff" [matAutocomplete]="auto" (change)="onInput($event.target.value)">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event.option.value)">
                          <mat-option *ngFor="let option of allAffectefilter | async" [value]="option">
                            {{option.nom}} {{option.prenom}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="myControlaff.invalid && (myControlaff.dirty || myControlaff.touched)" >
                            <div *ngIf="myControlaff.hasError('required')">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                {{'languages.ticket.obligUser' | translate}}
                            </div>
                        </mat-error>
                        <mat-error *ngIf="myControlaff.errors?.invalidUser">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                            {{'languages.ticket.verifyUser' | translate}}
                        </mat-error>
                      </mat-form-field>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <mat-form-field class="w-100">
                        <mat-label>{{'languages.ticket.prospectClient' | translate}}</mat-label>
                        <input [(ngModel)]="informationTicket.prospect" name="prospect" placeholder="Prospect " matInput disabled (change)="doSomething($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'languages.opportunite.label_creationdate' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="informationTicket.date_creation" name="date_creation" placeholder="Date de création" disabled (change)="doSomething($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                  
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{'languages.ticket.dateLimit' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker3" [(ngModel)]="informationTicket.date_limit" name="date_limit" placeholder="Date Limite" [min]="minDate" (ngModelChange)="doSomething($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
     <!--        <div class="col-3" *ngIf="!isDisabled">
                <label class="text-secondary font-weight-bold" *ngIf="informationTicket.delai_traitement !== null">{{'languages.ticket.delaiConventionnel' | translate}}</label>
                <ng-container *ngIf="loader_delai">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="d-flex justify-content-center my-3">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loader_delai">
                    <div class="card shadow bg-white rounded" *ngIf="informationTicket.delai_traitement !== null">
                        <div class="card-body text-center">
                            {{delai_convent}}
                        </div>
                    </div>
                    <div class="card shadow bg-white rounded mt-4" *ngIf="informationTicket.delai_traitement == null">
                        <div class="card-body text-center">
                            {{traitementDate}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-3" *ngIf="isDisabled ">
                <label class="text-secondary font-weight-bold"> {{'languages.ticket.closeDate' | translate}}</label>
                <ng-container *ngIf="loader_delai">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="d-flex justify-content-center my-3">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loader_delai">
                    <div class="card shadow bg-white rounded">
                        <div class="card-body text-center">
                            {{DateFermeture}}
                            <br> {{delai_ferm}}
                        </div>
                    </div>
                </ng-container>
            </div> -->
            <div class="col-12 mt-3">
                <app-add-tasks [id_opportunite]="tickets_id"  [fromType]="'T'"></app-add-tasks>

            </div>
          
            <div class="col-12 mt-4">
                <div class="form-group">
                    <label class="text-secondary font-weight-bold">   {{'languages.ticket.description' | translate}}</label>
                    <!-- <div [froalaEditor] [(froalaModel)]="informationTicket.commentaire" name="commentaire" style="z-index: 1;"></div> -->
                    <angular-editor class="w-100 mt-4"  name="commentaire"  (ngModelChange)="doSomething($event)"  [(ngModel)]="informationTicket.commentaire" style="z-index: 1;" [config]="config2" ></angular-editor>
                </div>
            </div>
        </div>
    </form>

</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-l" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel" *ngIf="modeAjoutTache">  {{'languages.ticket.addTask' | translate}}</h2>
                <h2 class="modal-title" id="exampleModalLabel" *ngIf="!modeAjoutTache">  {{'languages.ticket.updateTask' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <form #tacheForm="ngForm">
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="form-group w-100"> -->
                        <div class="col-md-12">
                            <mat-form-field *ngIf="!modeAjoutTache" class="w-100">
                                <h4 class="w-100">{{'languages.ticket.title' | translate}}</h4>
                                <input [(ngModel)]="tickets_info.titre" matInput name="titre-tache" >
                            </mat-form-field>
                        </div>
                        <!-- <label class="title text-secondary font-weight-bold">Affecté à</label> -->
                        <div class="col-md-12">
                            <h4 class="w-100">  {{'languages.alerts.affectedTo' | translate}}</h4>
                        </div>
                        <div class="col-md-12 d-flex">
                            <div class="col-md-6 " *ngFor="let type of types">
                                <input name="type" type="radio" id="{{type.viewValue}}" [checked]="typeselectedAffect==type.viewValue" (change)="getList(type.viewValue,$event)" >
                                <label class="ml-3" for="{{type.viewValue}}">{{type.viewValue}}</label>
                            </div>
                        </div>
                        <div class="col-md-12 " [ngSwitch]="typeselectedAffect">

                            <div class="col-md-12 mx-0 px-0" *ngSwitchCase="'Utilisateur'">
                                <!-- <mat-spinner *ngIf="!allAffecte.length" [diameter]="15"></mat-spinner>
                              <ng-autocomplete  #autoServicepopup [data]="allAffecte" [notFoundText]=" 'Utilisateur n\'existe pas'"  [(ngModel)]="selectedAffecte" (change)="onChangeSearchPopup($event.target.value)" [searchKeyword]="'nom_prenom'" [itemTemplate]="itemnomPrenomUser"
                              [notFoundTemplate]="notFoundnomPrenomUser" (selected)="getValue($event)" name="IdAffecte" placeholder="{{typeselectedAffect}}">
                              </ng-autocomplete>
                              <ng-template #itemnomPrenomUser let-item>
                                  <a [innerHTML]="item.nom+' '+item.prenom"></a>
                              </ng-template>
                              <ng-template #notFoundnomPrenomUser let-notFound>
                                  <div [innerHTML]="notFound">
                                  </div>
                              </ng-template> -->
                                <mat-form-field class="w-100">
                                    <mat-label>{{typeselectedAffect}}</mat-label>
                                    <input type="text" matInput [(ngModel)]="Utilisateur" [formControl]="myControlaff" [matAutocomplete]="auto" >
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnn">
                                        <mat-option *ngFor="let option of allAffectefilter | async" [value]="option">
                                            {{option.nom}} {{option.prenom}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 mx-0 px-0" *ngSwitchDefault>
                                <mat-form-field class="w-100">
                                    <mat-label>{{typeselectedAffect}}</mat-label>
                                    <input type="text" matInput [(ngModel)]="prospect" [formControl]="myControl" [matAutocomplete]="auto" >
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option.full_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- </div> -->
                    </div>
                    <!-- Edit 22/06/2022 -->

                    <div class="col-md-12" *ngIf="modeAjoutTache">
                        <h4 class="mb-0 w-100">{{'languages.ticket.keywords' | translate}}</h4>
                        <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of listselectedtags" >
                            <span class="badge badge-primary p-2 m-1" *ngIf="tagsAll.id==tags">{{tagsAll.libelle}}</span>
                        </span>
                        </span>
                    </div>
                    <div class="col-md-12" *ngIf="!modeAjoutTache">
                        <h4 class="mb-0 w-100">{{'languages.ticket.keywords' | translate}}  </h4>
                        <span *ngFor="let tags of listselectedtags">
                            <span class="badge badge-primary p-2 m-1" >{{tags.libelle}}</span>
                        </span>
                    </div>
                    <div class="col-md-12 ">
                        <mat-form-field class="w-100">
                            <mat-label>{{'languages.ticket.keywords' | translate}}</mat-label>
                            <mat-select placeholder="tags" [(ngModel)]="motcles" (selectionChange)="changeTags($event.value)" [formControl]="tags" multiple>
                                <div class="input-icons w-100">
                                    <i class="fa fa-search p-1 position-absolute"></i>
                                    <input class="recherche p-1 w-100" onblur="this.focus()" (keyup)="onKey($event.target.value)" >
                                </div>
                                <mat-option *ngFor="let tags of tagsList" [value]="tags.id">{{tags.libelle}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col-md-12 d-flex">

                        <div class="col-md-6 mx-0 px-0">
                            <h4 class="mb-0">  {{'languages.ticket.Priorite' | translate}}</h4>
                            <mat-form-field>
                                <mat-label>{{'languages.ticket.Priorite' | translate}}</mat-label>
                                <mat-select [(ngModel)]="priori" [formControl]="priorite">
                                    <mat-option *ngFor="let priorite of priorites" [value]="priorite.id">
                                        {{priorite.libelle}}
                                        <i class=" {{priorite.icon}} " ></i>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div class="col-md-6  mx-0 px-0">
                            <h4 class="mb-0">{{'languages.ticket.dateEcheance' | translate}}</h4>
                            <mat-form-field>
                                <mat-label>{{'languages.ticket.dateEcheance' | translate}}</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="echeance" [formControl]="echeanceC" >
                                <mat-hint>DD/MM/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>

                    <!-- Fin Edit -->
                    <div class="col-md-12">
                        <h4 class="titleN">{{'languages.ticket.messageDescription' | translate}}  </h4>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <mat-label> {{'languages.ticket.messageDescription' | translate}}</mat-label>
                            <textarea matInput [(ngModel)]="tickets_info.description" name="message" placeholder="{{'languages.ticket.description' | translate}}"  ></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn-dt-cancel-popup" (click)="cancelAddOrUpdate()" data-dismiss="modal">
                        <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
                    {{'languages.buttons.annuler' | translate}}
                  </button>

                    <button type="button" class="btn-dt-save-popup" (click)="addTodo($event)" data-dismiss="modal">
                        <i class="fa-solid fa-circle-check fa-lg mr-1 mt-1 fa-icon-style"></i>
                    {{'languages.buttons.enregistrer' | translate}}
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>
