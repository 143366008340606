import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
})
export class AssistantComponent implements OnInit {
   
  constructor(private route: Router, private sharedMenuObserverService: SharedMenuObserverService) {}

  ngOnInit(): void {
    
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Prospect');
  }

  public minDate: Date = new Date('20/10/2020');
  public maxDate: Date = new Date('29/12/2020');
  public value: Date = new Date('26/10/2020');

  lat = 51.678418;
  lng = 7.809007;

  navigate() {
    this.route.navigate(['dashboard/opportunities/details']);
  }
}
