import { DatePipe } from '@angular/common';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { startWith, map } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GroupUser } from 'src/app/entity/User/GroupUser';
import { Observable } from 'rxjs';
import { Validators ,FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
 
import { Action_user } from 'src/app/entity/Affaires/FilterAffaires';
@Component({
  selector: 'app-filter-action-user-opp',
  templateUrl: './filter-action-user-opp.component.html',
  styleUrls: ['./filter-action-user-opp.component.css']
})
export class FilterActionUserOppComponent implements OnInit {
   
  groupesSelectionees = [];
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userSelected: any[] = [];
  date_debut_creation: string =null;
  date_fin_action: string = null;

  // Mat Chips Groupe
  groupeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupe: Observable<GroupUser[]>;
  listGroupe: string[] = [];
  // allGroupe: GroupUser[] = [];

  @ViewChild('groupeInput') groupeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupe') matAutocompleteGroupe: MatAutocomplete;

  // Mat Chips user
  userFormCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredUser: Observable<any[]>;
  ListUser: string[] = [];
  allUser: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoUser') matAutocompleteUser: MatAutocomplete;


  filterActionUtilisateurOpp: Action_user = new Action_user();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterActionUtilisateurOpp: EventEmitter<Action_user> = new EventEmitter();
  constructor(
    private apiUsersService: ApiUsersService,
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe) { }

  // Groupe  -----------------------------------------------------------------------------------------------
  selectedGroupe(event: MatAutocompleteSelectedEvent): void {
    this.listGroupe.push(event.option.viewValue);
    this.groupesSelectionees.push(event.option.value);
    this.groupeInput.nativeElement.value = '';
    this.groupeFromCtrl.setValue(null);
  }

  removeGroupe(groupe: string): void {
    const index = this.listGroupe.indexOf(groupe);
    if (index >= 0) {
      this.listGroupe.splice(index, 1);
      this.groupesSelectionees.splice(index, 1);
    }

  }

  // private _filterGroupe(): GroupUser[] {
  //   let filterValue = this.groupeFromCtrl.value.toString().toLowerCase();
  //   if (filterValue == null) filterValue = '';
  //   return this.allGroupe.filter(groupe => groupe.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  // User ---------------------------------------------------------------------------------------------

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    this.ListUser.push(event.option.viewValue);
    this.userSelected.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userFormCtrl.setValue(null);

  }

  removeUser(util: string): void {
    const index = this.ListUser.indexOf(util);
    if (index >= 0) {
      this.ListUser.splice(index, 1);
      this.userSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.userFormCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allUser.filter(option => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.getUser();
    this.filteredUser = this.userFormCtrl.valueChanges.pipe(
      startWith(null),
      map((utils: string | null) => utils ? this._filter() : this.allUser.slice()));
    // this.apiUsersService.getGroupUsers().subscribe(
    //   (Response: GroupUser[]) => {
    //     this.allGroupe = Response;
    //   }
    // );

    // this.filteredGroupe = this.groupeFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupe: string | null) => groupe ? this._filterGroupe() : this.allGroupe.slice()));
  }

  getUser() {
    this.apiUsersService.getAllUsers()
      .subscribe((data: any[]) => {
        this.allUser = data;
      });
  }

  checking(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateurOpp.dernier_action = 'on'
    }
    else {
      this.filterActionUtilisateurOpp.dernier_action = 'off'
    }
  }

  action(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateurOpp.enreg_avec_action = 'on'
    }
    else {
      this.filterActionUtilisateurOpp.enreg_avec_action = 'off'
    }
  }

  simple(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateurOpp.enreg_simple = 'on'
    }
    else {
      this.filterActionUtilisateurOpp.enreg_simple = 'off'
    }
  }




  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterActionUtilisateurOppForm(changes.Reset.currentValue);
  }

  resetfilterActionUtilisateurOppForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.listGroupe = [];
      this.filterActionUtilisateurOpp.groupes = this.groupesSelectionees = [];
      this.ListUser = [];
      this.filterActionUtilisateurOpp.list_user = this.userSelected = [];
      this.filterActionUtilisateurOpp.dernier_action = "";
      this.filterActionUtilisateurOpp.date_creation_action = null;
      this.filterActionUtilisateurOpp.date_fin_action = null;
      this.filterActionUtilisateurOpp.enreg_simple = "";
      this.filterActionUtilisateurOpp.operateur = "";
      this.filterActionUtilisateurOpp.enreg_avec_action = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterActionUtilisateurOpp.emit(this.filterActionUtilisateurOpp);
    }
  }

  EmitFilterActionUtilisateurOpp() {
    this.filterActionUtilisateurOpp.date_creation_action = this.datePipe.transform(this.date_debut_creation, 'yyyy-MM-dd');
    this.filterActionUtilisateurOpp.date_fin_action = this.datePipe.transform(this.date_fin_action, 'yyyy-MM-dd');
    this.filterActionUtilisateurOpp.groupes = this.groupesSelectionees
    this.filterActionUtilisateurOpp.list_user = this.userSelected
    this.onPushFilterActionUtilisateurOpp.emit(this.filterActionUtilisateurOpp);
  }
}
