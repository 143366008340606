import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { DatePipe } from '@angular/common';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { AfterViewInit, Component, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
 
import swal from 'sweetalert2';
import { ApiVilleService } from 'src/app/services/ApiVille/api-ville.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepperIntl } from '@angular/material/stepper';
import { TarificateurVeloService } from 'src/app/services/tarificateur-Berilish/tarificateur-velo.service';
export enum OccupationEnum {
  'Employee' = 'Employée',
  'Liberal or independant worker' = 'Travailleur libéral ou indépendant',
  'Loueur Independant' = 'Loueur Indépendant',
  'Office de tourisme, Mairie' = 'Loueur Office de tourisme, Mairie',
  'Camping, Hotels' = 'Camping, Hôtels',
  'Entreprise Privé' = 'Entreprise Privée',
  'Administration ou entreprise publique' = 'Administration ou entreprise publique',
  'Autre professionnel' = 'Autre professionnel',
  'Other' = 'Autre',
}

export enum BikeTypeEnum {
  'Standard' = 'Standard',
  'Electric' = 'Électrique',
}

export enum BikeUsageEnum {
  'Private Use and Commuting' = 'Usage privé et déplacements',
  'Professional Use' = 'Utilisation professionnelle',
}

export enum BikeClassificationEnum {
  'Road Bike' = 'Vélo de route',
  'Off-road Bike' = 'Vélo tout-terrain',
  'Racing Bike' = 'Vélo de course',
}

export enum BikeTrackerGPSEnum {
  Yes = 'Oui',
  No = 'Non',
}
export function EmailValidator(confirmEmailInput: string) {
  let confirmEmailControl: FormControl;
  let emailControl: FormControl;
  
  return (control: FormControl) => {
    if (!control.parent) {
      return null;
    }
  
    if (!confirmEmailControl) {
      confirmEmailControl = control;
      emailControl = control.parent.get(confirmEmailInput) as FormControl;
      emailControl.valueChanges.subscribe(() => {
        confirmEmailControl.updateValueAndValidity();
      });
    }

    if (emailControl.value.toLocaleLowerCase() !==
        confirmEmailControl.value.toLocaleLowerCase()
    ) {
      return { notMatch: true };
    }

    return null;
  }
}
@Component({
  selector: 'add-opportunite-dialog',
  styleUrls: ['../tarificateur-velo.component.css'],
  templateUrl: 'add-opportunite-tarificateur-dialog-component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },

  ],
})
export class AddOpportuniteTarificateurDialogComponent implements OnInit {
   
  dateNow: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  titleDialog: string = '';
  opportuniteForm: FormGroup;
  prospectForm: FormGroup;
  occupation = OccupationEnum;
  biketypes = BikeTypeEnum;
  bikeusageEnum = BikeUsageEnum;
  bikeGpsEnum = BikeTrackerGPSEnum;
  bikeClassificationEnum = BikeClassificationEnum;
  listvilles: string[] = [];
  birthDate: any;
  purchaseDate: any;
  informationPro: boolean = false;
  dataTarif: any;
  OccupationList: any[];
  selectedTab = new FormControl(0);

  codePostalLoader: boolean = false;
  listVilles: string[] = [];
  step: string;
  mobile: any = '';
  tel: any = '';
  couleur: any;
  boxes: any;
  verif: string;
  verif_Color: string;
  api_key:string;
  GravageExiste: boolean = false;
  constructor(
    private apiTarificateurVelo: TarificateurVeloService,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private apiVilleService: ApiVilleService,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<AddOpportuniteTarificateurDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   
    this.api_key=data.api_key;
    this.dataTarif = data.item;
    this.couleur = data.couleur;
    this.listvilles = data.villes;
    this.listVilles = data.villes;
    if (data.send) this.titleDialog = 'Envoyer un devis';
    else this.titleDialog = 'Ajouter une opportunité';

  }

  ngOnInit(): void {
    const youngerThanValidator =
      (maxAge: number): ValidatorFn =>
      (control) =>
        new Date().getFullYear() - new Date(control.value).getFullYear() < maxAge ? { younger: { maxAge } } : null;
    this.opportuniteForm = this._formBuilder.group({
      informationProForm: this._formBuilder.group({
        siren: new FormControl('', [Validators.maxLength(9), Validators.minLength(9)]),
        RS: new FormControl(''),
        streetNumber: new FormControl('', Validators.min(0)),
        streetName: new FormControl(''),
        postalCode: new FormControl(''),
        ville: new FormControl(''),
        adress: new FormControl(''),
        isPro: new FormControl(''),
      }),
      prospectForm: this._formBuilder.group({
        civilite: new FormControl(''),
        name: new FormControl('', Validators.required),
        surname: new FormControl('', Validators.required),
        adresse_mail: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        confirmationEmail : new FormControl('',[
          Validators.required,
          EmailValidator('adresse_mail'),
        ]),
        tel: new FormControl(''),
        DN: new FormControl('', [ Validators.required ,youngerThanValidator(18)]),
        mobile: new FormControl('', Validators.required),
        occupation: new FormControl('', Validators.required),
        CP: new FormControl(''),
        ville: new FormControl(''),
        streetName: new FormControl(''),
        streetNumber: new FormControl('', Validators.min(0)),
        complement_adr: new FormControl(''),
        // step: new FormControl('', [Validators.required]),
      }),
      devis: this._formBuilder.group({
        step: new FormControl('', [Validators.required]),
      }),

      informationVeloForm: this._formBuilder.group({
        type: new FormControl('Electric'),
        purchasePrice: new FormControl(''),
        purchaseDate: new FormControl(''),
        usage: new FormControl('Private Use and Commuting'),
        TrackerGPS: new FormControl(''),
        maximumSpeed: new FormControl('25', Validators.max(25)),
        serialNumber: new FormControl(''),
        etchingNumber: new FormControl('', Validators.required),
        classification: new FormControl('Road Bike'),
        brand: new FormControl(''),
        model: new FormControl(''),
      }),
    });
    this.opportuniteForm.get('prospectForm.CP').setValue(this.data.zipCode);
    this.opportuniteForm.get('prospectForm.CP').disable();
    this.opportuniteForm.get('prospectForm.ville').setValue(this.data.selectedVille);
    this.opportuniteForm.get('informationProForm.ville').setValue(this.data.selectedVille);
    this.opportuniteForm.get('informationProForm.postalCode').setValue(this.data.zipCode);
    this.opportuniteForm.get('informationVeloForm.TrackerGPS').setValue(this.dataTarif.TrackerGPS);
    this.opportuniteForm.get('informationVeloForm.TrackerGPS').disable();
    this.opportuniteForm.get('informationVeloForm.purchasePrice').setValue(this.dataTarif.purchasePrice);
    this.opportuniteForm.get('informationVeloForm.purchasePrice').disable();
    this.purchaseDate = this.dataTarif.purchaseDate;
    this.opportuniteForm.get('informationVeloForm.purchaseDate').disable();
    this.changeUsage(this.opportuniteForm.get('informationVeloForm.usage').value);


    this.boxes = document.getElementsByClassName(
      'mat-step-icon-selected',
    ) as HTMLCollectionOf<HTMLDivElement>;

  if (this.boxes.length > 0) {
   this.boxes[0].style.backgroundColor= this.couleur;
  }
  }

  onSubmitClick(): void {
    this.opportuniteForm.get('prospectForm.DN').setValue(this.datePipe.transform(this.birthDate, 'yyyy-MM-dd'));
    this.opportuniteForm
      .get('informationVeloForm.purchaseDate')
      .setValue(this.datePipe.transform(this.purchaseDate, 'yyyy-MM-dd'));

    const prospectControlGroup: any = this.opportuniteForm.get('prospectForm');
    const prospectInvalidControls: any[] = [];
    for (const key in prospectControlGroup.controls) {
      const element = prospectControlGroup.controls[key];
      if (element.status === 'INVALID') prospectInvalidControls.push(key);
    }
    if (prospectInvalidControls.length === 0) this.dialogRef.close(this.opportuniteForm.value);
    else this.selectedTab.setValue(1);
  }

  getIndexTab($event): void {
    this.selectedTab.setValue($event);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeUsage(event) {
    if (event === 'Private Use and Commuting') {
      this.OccupationList = [
        { key: 'Employee', value: 'Employé(e)' },
        { key: 'Liberal or independant worker', value: 'Travailleur libéral ou indépendant' },
        { key: 'Other', value: 'Autre' },
      ];
    } else {
      this.OccupationList = [
        { key: 'Loueur Independant', value: 'Loueur Indépendant' },
        { key: 'Office de tourisme, Mairie', value: 'Loueur Office de tourisme, Mairie' },
        { key: 'Camping, Hotels', value: 'Camping, Hôtels' },
        { key: 'Entreprise Privé', value: 'Entreprise Privée' },
        { key: 'Administration ou entreprise publique', value: 'Administration ou entreprise publique' },
        { key: 'Autre professionnel', value: 'Autre professionnel' },
      ];
    }
  }

  telInputObject(object): void {
    object.setCountry(object.j);
  }

  getNumber($event: any, type: string) {
    switch (type) {
      case 'mobile':
        this.mobile = $event;
        // this.prospectForm.controls.mobile.setValue($event);
        this.opportuniteForm.get('prospectForm.mobile').setValue($event);
        break;
      case 'tel':
        // this.prospectForm.controls.tel.setValue($event);
        this.opportuniteForm.get('prospectForm.tel').setValue($event);
        this.tel = $event;
        break;
      case 'tel2':
        //    this.prospectForm.controls.tel2.setValue($event);
        break;
      default:
        break;
    }
  }

  get name(): any {
    return this.opportuniteForm.get('prospectForm.name');
  }

  get surname(): any {
    return this.opportuniteForm.get('prospectForm.surname');
  }

  get adresseMail(): any {
    return this.opportuniteForm.get('prospectForm.adresse_mail');
  }
  get confirmationEmail(): any {
    return this.opportuniteForm.get('prospectForm.confirmationEmail');
  }


  get maximumSpeed(): any {
    return this.opportuniteForm.get('informationVeloForm.maximumSpeed');
  }
  valuechange(newValue) {
    if(newValue!==null && newValue!==''){
      this.apiTarificateurVelo.check_etching_number(this.opportuniteForm.get('informationVeloForm.etchingNumber').value, this.api_key).subscribe(
        (data) => {
         this.opportuniteForm.get('informationVeloForm.etchingNumber').setErrors({'incorrect': null});
         this.opportuniteForm.get('informationVeloForm.etchingNumber').updateValueAndValidity();
        },
        (err: any) => {
          console.log(err);
          this.opportuniteForm.get('informationVeloForm.etchingNumber').setErrors({'incorrect': true})
          this.GravageExiste=true;
        }
      );
   }else{
     this.opportuniteForm.get('informationVeloForm.etchingNumber').setErrors({'incorrect': true})

   }
  }
  get etchingNumber(): any {
  return this.opportuniteForm.get('informationVeloForm.etchingNumber');
  }
  get phone(): any {
    return this.opportuniteForm.get('prospectForm.tel');
  }

  get birthDateControl(): any {
    return this.opportuniteForm.get('prospectForm.DN');
  }

  
  get purchaseDateControl(): any {
    return this.opportuniteForm.get('prospectForm.purchaseDate');
  }

  alertWarning(prospectInvalidControls: any[]): void {
    let listInvalid = '';
    prospectInvalidControls.forEach((element) => {
      if (typeof element === 'string')
        listInvalid = listInvalid + '<li style="font-size: 17px">' + this.getAtributName(element) + '</li>';
    });

    swal.fire({
      title: 'Formulaire invalide',
      html: '<div class="text-text" style=" margin: 0% 0% 0% 9%"><ul class="text-left">' + listInvalid + '</ul></div>',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#cb874d',
      confirmButtonText: 'Fermer',
    });
  }

  getAtributName(name): string {
    let nameReturned: string;
    switch (name) {
      case 'name':
        nameReturned = 'Nom';
        break;
      case 'surname':
        nameReturned = 'Prénom';
        break;
      case 'date_naiss_user':
        nameReturned = 'Date de naissance';
        break;
      case 'email':
        nameReturned = 'Email';
        break;
      case 'mobile':
        nameReturned = 'Mobile';
        break;
      case 'tel':
        nameReturned = 'Téléphone';
        break;
      case 'occupation':
        nameReturned = 'Occupation';
        break;
      case 'zip_code':
        nameReturned = 'Code postal';
        break;
      case 'ville':
        nameReturned = 'Ville';
        break;
      case 'streetNumber':
        nameReturned = 'N°de Voie';
        break;
      case 'streetName':
        nameReturned = 'Voie';
        break;
      case 'complement_adresse':
        nameReturned = "Complément d'adresse";
        break;

      default:
        nameReturned = '';
    }
    return nameReturned;
  }

  checkInformationPro(): void {
    this.informationPro = !this.informationPro;
    if (!this.informationPro) {
      this.opportuniteForm.get('informationProForm').reset();
    }
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.opportuniteForm.get('informationProForm.adresse').setValue(adress.adresseComplete);
  }

  getVilleByCodepostal(): void {
    this.codePostalLoader = true;
    this.apiVilleService.GetVillesByZipcode(this.opportuniteForm.get('informationProForm.postalCode').value).subscribe(
      (response) => {
        this.codePostalLoader = false;
        if (response.cities.length === 0) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.warning;
          notificationMessage.title = 'Code postal erroné';
          notificationMessage.message = 'Aucune ville pour le code postal.';
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.listVilles = response.cities;
          if (this.listVilles.length === 1) {
            this.opportuniteForm.get('informationProForm.ville').setValue(this.listVilles[0]);
          }
        }
      },
      () => {
        this.codePostalLoader = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Code postal';
        notificationMessage.message = 'Aucune ville pour le code postal.';
      }
    );
  }

  demandeDevis(value) {
    if (value === 'oui') {
      this.opportuniteForm.get('devis.step').setValue('tarificateur');
      this.verif= 'oui'
      this.verif_Color = 'oui'
    } else {
      this.opportuniteForm.get('devis.step').setValue('no');
      this.verif_Color = 'no'
      this.verif= 'no'
    }
  }





}
