import { FormField } from 'src/app/entity/Formbuilder/FormField';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.css'],
})
export class SharedFormViewComponent implements OnInit {
  @Input() item: FormField;
  @Output() onChangeParams = new EventEmitter<any>();
  dict = {
    Select: 'selectionner',
    Time: 'temps',
    Text: 'Texte',
    'Text Area': 'Zone de texte',
    Radio: 'Radio',
    Password: 'Mot de passe',
    Number: 'Nombre',
    Json:'Json',
    monetique:'Monetique',
    'Null Element': 'Element nul',
    Email: 'E-mail',
    Date: 'Date',
    'Check box': 'Case a cocher',
    Array: 'Tableau',
    'Multi Select': 'Selection multiple',
  };

  formbuilderTransaltion: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.formbuilderTransaltion = object.formbuilderTransaltion;
        this.dict = {
          Select: this.formbuilderTransaltion.selectionner,
          Time: this.formbuilderTransaltion.temps,
          Text:  this.formbuilderTransaltion.Texte,
          'Text Area':  this.formbuilderTransaltion.texteArea,
          Radio: this.formbuilderTransaltion.Radio,
          Password: this.formbuilderTransaltion.password,
          Number: this.formbuilderTransaltion.Nombre,
          'Null Element': this.formbuilderTransaltion.NullElement,
          Email: this.formbuilderTransaltion.Email, 
          Date: this.formbuilderTransaltion.Date,
          'Check box': this.formbuilderTransaltion.checkBox,
          Array: this.formbuilderTransaltion.Tableau,
          'Multi Select': this.formbuilderTransaltion.MultiSelect,
          Json: this.formbuilderTransaltion.Json,
          monetique:this.formbuilderTransaltion.monetique,
        };
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.formbuilderTransaltion = object.formbuilderTransaltion;
        this.dict = {
          Select: this.formbuilderTransaltion.selectionner,
          Time: this.formbuilderTransaltion.temps,
          Text:  this.formbuilderTransaltion.Texte,
          'Text Area':  this.formbuilderTransaltion.texteArea,
          Radio: this.formbuilderTransaltion.Radio,
          Password: this.formbuilderTransaltion.password,
          Number: this.formbuilderTransaltion.Nombre,
          'Null Element': this.formbuilderTransaltion.NullElement,
          Email: this.formbuilderTransaltion.Email, 
          Date: this.formbuilderTransaltion.Date,
          'Check box': this.formbuilderTransaltion.checkBox,
          Array: this.formbuilderTransaltion.Tableau,
          'Multi Select': this.formbuilderTransaltion.MultiSelect,
          Json: this.formbuilderTransaltion.Json,
          monetique:this.formbuilderTransaltion.monetique
        };
      });
  }
}
