<mat-form-field class="full-width">
    <mat-label>{{ to.label }}</mat-label>
    <mat-select
      [formControl]="formControl"
      [formlyAttributes]="field"
      [multiple]="true"
      name="select-form"
      [disabled]="formControl.disabled"
    >
      <mat-option *ngFor="let opt of to.options" [value]="opt.value">
        {{ opt.label }}
      </mat-option>
    </mat-select>
  
    <!-- Reload Button -->
    <button mat-icon-button *ngIf="searchValue" matSuffix (click)="updateValue()">
      <mat-icon>refresh</mat-icon>
    </button>
  
    <div class="description-wrapper" *ngIf="field.templateOptions.description">
      <span class="description-text">{{ field.templateOptions.description }}</span>
    </div>
  
    <mat-error>
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </mat-form-field>
  