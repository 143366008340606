import { HistoryServiceTicket } from 'src/app/pages/tickets-and-support/history.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiTicketsService } from '../../../../services/ApiTickets/api-tickets.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sous-ticket',
  templateUrl: './sous-ticket.component.html',
  styleUrls: ['./sous-ticket.component.css']
})
export class SousTicketComponent implements OnInit {
  @Input() ticket_id = '';
  @Input() prospect_id
  @Input() type
  @Input() affaire_id
  @Input() opportunite_id
  fromDetails: Boolean = true;
  private reloadSousTickets: Subscription;
  show_loading_list: boolean = false;
  listSousTicket : any 
  choix: string;
  id_entity: any;
  constructor(    private apiTicketsServices: ApiTicketsService,private datePipe: DatePipe,
    private router: Router,
    private HistoryServiceTicket: HistoryServiceTicket,
  ) {}

  ngOnInit(): void {
     if (this.opportunite_id){
      this.choix="o"
      this.id_entity=this.opportunite_id
     }else if (this.affaire_id){
      this.choix="a"
      this.id_entity=this.affaire_id
     }else{
      this.choix="t"
      this.id_entity=""
     }
    this.reloadSousTickets = this.HistoryServiceTicket.reloadSousTicketsData$.subscribe((signal) => {

      this.getListTickets();
    });
  }

  getListTickets() {
    this.show_loading_list = true;
    this.apiTicketsServices.listSousTicket(this.ticket_id).subscribe(
      (data) => {
      this.listSousTicket=data
      this.listSousTicket.forEach(element => {
        element.date_creation =this.formatDateAndTime(element.date_creation)
      });
      
      this.show_loading_list = false;
    },()=>{
      this.show_loading_list = false;
    });
  }

  NaviagateToTicketDetails(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/ticketsandsupport/details/' + id]));
    window.open(url, '_blank');

  }

  formatDateAndTime(dateCreate) {
    const date = new Date(dateCreate);
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  expandFileName(name: string): string {
    if (name?.length < 15) {
      return name;
    } else {
      return name?.substring(0, 15) + '...';
    }
  }


}
