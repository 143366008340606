export class RendezVousCalendar {
  classe: string = '';
  icon: string =''
  borderColor='#ff7c10';
  color: string = '';
  title: string = '';
  description : string = ""
  start: any = new Date().toISOString().replace(/T.*$/, '') + 'T10:00:00';
  end: any = new Date().toISOString().replace(/T.*$/, '') + 'T12:00:00';
  etat: string = '';
  id: string = '-1';
  type_opp: string = '';
  backgroundColor = '#ff7c10';
  textColor = '#000';
  type: string ="";
  url: string = '';
  id_opp_md5: string = '';
  editable=false;
  startEditable=false;
  durationEditable=false;
  allDay=false
}
