<div class="form-group">
    <mat-form-field class="full-width">
      <mat-label>{{field.templateOptions?.label}}</mat-label>
      <input
        matInput
        type="email"
        name="email"
        [formControl]="emailFormControl"
        [errorStateMatcher]="matcher"
        [formlyAttributes]="field"
        placeholder="mon.email@email.com"
      />
      <button mat-icon-button *ngIf="searchValue" matSuffix (click)="updateValue()">
        <mat-icon>refresh</mat-icon>
      </button>
      <div class="description-wrapper" *ngIf="field.templateOptions?.description">
        <span class="description-text">{{ field.templateOptions.description }}</span>
      </div>
  
      <mat-error *ngIf="emailFormControl.hasError('required')">
        {{'languages.formbuilderTransaltion.emailOblig' | translate}}
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">
        {{'languages.formbuilderTransaltion.enterValidadressMail' | translate}}
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('pattern') && !emailFormControl.hasError('email')">
        {{'languages.formbuilderTransaltion.EmailregexError' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  