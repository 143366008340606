
import { FormField } from './FormField';

export class MonetiqueFormly extends FormField {
accDebit="";
accCredit="";
pattern="";
  autofill="";
  format="";
  description=""
  urlValidation=""
  urlIdentifiant=""
   default=""
constructor() {
    super();
    super.icon = "fa fa-credit-card-alt"; 
    super.name = 'monetique_' + super.generateRandomId();
    this.type = 'monetique'; 
    this.title = 'Monétique'; 
    super.typename = 'monetique'; 
    this.widget.formlyConfig.templateOptions.col = "6";
  }

}