import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { TelephonieOpp } from 'src/app/entity/Affaires/FilterAffaires';
 
@Component({
  selector: 'app-filter-telephonie-opp',
  templateUrl: './filter-telephonie-opp.component.html',
  styleUrls: ['./filter-telephonie-opp.component.css']
})
export class FilterTelephonieOppComponent implements OnInit, OnChanges {
   
  filterTelephonie: TelephonieOpp = new TelephonieOpp();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterTelephonie: EventEmitter<TelephonieOpp> = new EventEmitter();
  noValue: boolean;
  noDuree: boolean;

  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  checking(event) {
    if (event.target.checked == true) {
      this.filterTelephonie.argumentes_tout = '1'


    }
    else {
      this.filterTelephonie.argumentes_tout = null
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetTelephonieForm(changes.Reset.currentValue);
  }
  resetTelephonieForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {
      this.filterTelephonie.argumentes_tout = "";
      this.filterTelephonie.operateur_duree = "";
      this.filterTelephonie.operateur_telephonie = "";
      this.filterTelephonie.value_duree = "";
      this.filterTelephonie.value_telephonie = "";
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterTelephonie.emit(this.filterTelephonie)
    }
  }

  EmitFilterTelephonie() {
    this.onPushFilterTelephonie.emit(this.filterTelephonie);
  }

  getNombre(event: any) {
    if (event) {
      this.noValue = true;
    }
  }
  getDuree(event: any) {
    if (event) {
      this.noDuree = true;
    }
  }
}
