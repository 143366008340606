import { ApiLogsService } from './../../../services/ApiLogs/api-logs.service';
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import swal from 'sweetalert2';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  alerts: any;
  buttons: any;
  currentLanguage: string;
  filterSsearch: boolean = false;
  inputSubject = new Subject<string>();
  search: string;
  limit = 50;
  pages = 1;
  totalPages = 0;
  filtre: string;
  date_fin: any;
  date_debut: any;
  myDate = new Date();
  date_finInit: Date;
  date_debutInit: Date;
  intervale = 1;
  totalLength: number = 0;
  readonly rangeDateCreation = RangeDateComponent;
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  logs: any[];
  filtres_logs: any;
  
  constructor(    private translate: TranslateService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private apiLogs:ApiLogsService,
  ) {window.addEventListener('beforeunload', this.clearLocalStorage.bind(this));
    this.currentLanguage = this.translate.currentLang;
    this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.clearDateCreation()
      this.getlistLogs();
    }); }

  ngOnInit(): void {


    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.logs);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
        this.sharedMenuObserverService.updateMenu(object.navbar.logs);
      });
    });
    this.date_debut = new Date(new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()));
    this.date_fin = this.myDate;
    this.date_debutInit = new Date(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate())
    );
    this.date_finInit = this.myDate;
   
this.getlistLogs()
    
   
    
   
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
  }

  clearDateCreation() {
    this.date_debut = null;
    this.date_fin = null;
  }

  clearLocalStorage() {
    localStorage.removeItem('filtreTicket');
    localStorage.removeItem('pages');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('search');
    localStorage.removeItem('sort_type');
  }
  
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.clearLocalStorage();
  }

  

  getlistLogs() {
   

    this.showloader();
    const dateDebut= this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
    const dateFin=this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
     this.apiLogs.listLogs( this.pages, this.limit, this.search , dateDebut,dateFin)
      .subscribe(
        (data) => {
          
          
          this.date_debut = parseDate(data?.min_date_creation);
          this.date_fin = parseDate(data?.max_date_creation);
         
            this.logs=data?.Data
            this.totalLength = data?.Total;
            this.calculate_nb_pages(data?.Total);
            this.hideloader();

          
     
        },
        () => {
          this.hideloader();
          this.logs = [];

       

        }
      );
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }


  exportDataToCsv() {
    const formattedDateDebut = this.formatDate(this.date_debut);
    const formattedDateFin = this.formatDate(this.date_fin);
    this.apiLogs.exportToCsv(this.logs, `Logs_${formattedDateDebut}_${formattedDateFin}.csv`);
  }

  Reexecute(log: any) {
    log.isLoading = true;
    // Call your API here
    this.apiLogs.reexecute(log.id).subscribe(
      (response) => {
        log.isLoading = false;
        this.alertSuccess(response.message);
     
      },
      (error) => {
       this.alertError(error.error.message);
        log.isLoading = false;
      }
    );
  }

  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        this.getlistLogs()
      });
  }

  alertError(data) {
    swal.fire({
      title: this.alerts.error,
      text: data || this.alerts.badHappened,
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      allowOutsideClick: false,
      confirmButtonColor: '#d53a3a',
    })
    .then((result) => {
      this.getlistLogs()
    });
  }

  

downloadJson(log): void {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(log?.input));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    if (log?.entity === 'A') {
      downloadAnchorNode.setAttribute("download", `log_Affaire_${log?.id_entity}.json`);
    }else if (log?.entity === 'O'){
      downloadAnchorNode.setAttribute("download", `log_Opportunite_${log?.id_entity}.json`);
    }else if (log?.entity === 'T'){
      downloadAnchorNode.setAttribute("download", `log_Ticket_${log?.id_entity}.json`);
    }
      else{
        downloadAnchorNode.setAttribute("download", `log_${log?.id_entity}.json`);
    }

    
    document.body.appendChild(downloadAnchorNode); 
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  filtrer() {
    this.getlistLogs()
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getlistLogs();
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListTickets() {
    this.pages = 1;
    this.getlistLogs()
  }

  changepage(number: number) {
    localStorage.setItem('pages', number.toString());
    this.pages = number;
    this.getlistLogs()
  }




  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getlistLogs()
      }
    }
  }



 

  getPageSource(entity , id){
    let url: string;

  if (entity === 'A') {
    url = '/affaires/details/' + id;
  } else if (entity === 'O') {
    url = '/opportunities/details/' + id + '/prospect';
  } else {
    url = '/ticketsandsupport/details/' + id;
  }

  window.open(url, '_blank');
  }

  
  
  convertUTCDateToLocalDate(date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }



  expandFileName(name: string): string {
    if (name) {
      if (name.length < 50) {
        return name;
      } else {
        return name.substring(0, 50) + '...';
      }
    }
  }


}
