import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { DetailsOpportuniteStore } from 'src/app/pages/opportunities/details-opportunite/store/details-opportunite.reducers';
import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GetOpportuniteDetails } from '../../entity/Opportunity/GetOpportuniteDetails';
import { ListProspect, Prospect } from '../../entity/Prospect';
import { ListVille } from '../../entity/ListVille';
import { Entreprise } from '../../entity/Opportunity/Entreprise';
import swal from 'sweetalert2';
 
import * as addOppActions from '../../pages/opportunities/details-opportunite/store/details-opportunite.actions';
import { I } from '@angular/cdk/keycodes';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-full-prospect-details',
  templateUrl: './full-prospect-details.component.html',
  styleUrls: ['./full-prospect-details.component.css'],
})
export class FullProspectDetailsComponent implements OnInit, OnChanges {
  opportunite_details: GetOpportuniteDetails = new GetOpportuniteDetails();
  listVille: ListVille[] = [];
  id_prospect: string = '';
  id_commercieux: string;
  id_campagne: string;
  MyAdresse: string = '';
   
  show_loading_add: boolean = false;
  @Input() typeEntity;
  @Input() entreprise: Entreprise = null;
  @Input() typeProspect: string = ''; // permet de retourner 'Prospect' ou 'Client'
  entrepriseClone: Entreprise = new Entreprise();
  @Output() onUpdateEntreprise: EventEmitter<Entreprise> = new EventEmitter();
  step: number = 0;
  @Input() type: string;
  @Input() allowed_to_update: boolean;
  @Input() person: boolean = false;
  keyFormProspect: string = environment.keyFormProspect;
  prospectSchema: any = null;
  keyFormEntreprise: string = environment.keyFormEntreprise;
  EntrepriseSchema: any = null;
  constructor(
    private apiProspectService: ApiProspectsService,
    private apiConfigurationService: ApiConfigurationService
  ) {}

  ngOnInit(): void {
    // this.entrepriseClone = JSON.parse(JSON.stringify(this.entreprise));
    this.apiProspectService.setAccess(this.allowed_to_update);
    this.getConfigurationForm();
    this.getConfigurationFormEntreprise();

    this.apiProspectService.actionUpdateProspect$.subscribe((value: boolean) => {
      if (value) {
        this.onUpdateEntreprise.emit(this.entrepriseClone);
        this.apiProspectService.actionUpdateProspect$.complete();
        this.apiProspectService.setActionUpdateProspect(false);
      }
    });
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  onChangeAdresseSaisieAuto(valeur: string) {
    this.MyAdresse = valeur;
  }

  getConfigurationForm(): void {
    this.apiConfigurationService.getParamConfig(this.keyFormProspect).subscribe((response) => {
      this.prospectSchema = response.value;
    });
  }

  getConfigurationFormEntreprise(): void {
    this.apiConfigurationService.getParamConfig(this.keyFormEntreprise).subscribe((response) => {
      this.EntrepriseSchema = response.value;
    });
  }

  updateProspect($event: Prospect | any, index: number) {
    if ($event)
      if (index < 0) {
        this.entrepriseClone.mainProspect = $event;
      } else {
        this.entrepriseClone.listProspectsLier[index - 1] = $event;
      }
     this.onUpdateEntreprise.emit(this.entrepriseClone);
  }

  updateEntreprise($event: Entreprise) {
    $event.mainProspect = this.entrepriseClone.mainProspect;
    $event.listProspectsLier = this.entrepriseClone.listProspectsLier;
    this.entrepriseClone = $event;
    this.onUpdateEntreprise.emit(this.entrepriseClone);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.entrepriseClone = JSON.parse(JSON.stringify(this.entreprise));
  }

  /**
   * Enlever une relation prospect
   * @param index
   */
  deleteProspect(index: number) {
    if (this.entrepriseClone.listProspectsLier[index - 1].id) {
      this.entrepriseClone.listProspectsLier[index - 1].deleted = true;
      this.updateProspect(this.entrepriseClone.listProspectsLier[index - 1], index);
    } else {
      this.entrepriseClone.listProspectsLier.splice(index - 1, 1);
    }
  }

  addProspect() {
    if (this.entrepriseClone.listProspectsLier) {
      this.entrepriseClone.listProspectsLier.push(new Prospect());
      // this.store$.dispatch(addOppActions.AddProspectAction());
    }
  }
}
