export class Gamme {
  conditions_souscription: string = '';
  active: string = '1';
  cie: string = '';
  description: string = '';
  formulaire_adhesion: any;
  formulaire_adhesion_name: string = '';
  tableaux_de_garanties_name: string = '';
  notice_information_name: string = '';
  id: string = '';
  id_campagne: string;
  id_contrat: string;
  logo: string = '';
  nom: string = '';
  notice_information: any;
  pro: string = '0';
  tableaux_garanties: any;
  url_tarification: string = '';
  ipid: any;
  ipid_name: string = '';
  avantages_tiers_payant: any;
  avantages_tiers_payant_name;
  Renewable: string = '1';
  tableaux_de_garanties: string = '';
  support_commercial: any;
  support_commercial_name: string = '';
  denomination: string;
  type_contrat: string;
  real_id: string = '';
  id_externe: string = '';
  garanties: [];
  garanties_herite: [];
  age_min: any;
  age_max: any;
  obligatoire: boolean = false;
  form: any;
  form_complementaire: any;
  RenewMode: string = '';
  RenewNoticeDelay: string = '';
  Notice_email_template: string = '';
  Notice_mail_template: string = '';
  constructor() {}
}
