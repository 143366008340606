<div class=" card-historiques bg-white p-4" [ngClass]="{'cover': !allowed_to_update_affaire}">
    <div class="card-doc-title ml-3 row">
<i class="fa-solid fa-clock-rotate-left fa-lg mr-2 mt-2"></i>
        {{'languages.opportunite.details.label_history' | translate}}
    </div>
    <mat-form-field class="w-100">
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'languages.miniListing.search' | translate}}" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>   {{'languages.opportunite.details.dateAction' | translate}}</th>
                <td mat-cell *matCellDef="let row"> {{row.date_action}} </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{'languages.opportunite.label_user' | translate}}</th>
                <td mat-cell *matCellDef="let row"> {{row.utilisateur}} </td>
            </ng-container>
            <ng-container matColumnDef="commentaire">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'languages.opportunite.details.commentaireStandard' | translate}}</th>
                <td mat-cell *matCellDef="let row"> {{row.commentaire_stantard}} </td>
            </ng-container>
            <ng-container matColumnDef="export">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'languages.opportunite.details.timeMachine' | translate}}</th>
                <td mat-cell *matCellDef="let row">

                    <button type="button" class="btn-dt-ouvrir" *ngIf="row.json_object" (click)="getJson(row.json_object)" data-toggle="modal" data-target="#JsonModal">
<i class="fa-solid  fa-up-right-from-square icon-btn-ouvrir"></i> {{'languages.opportunite.details.label_history' | translate}}
          </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">{{'languages.affaire.noDataAvailable' | translate}} </td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>

<!-- Consulter Modal json--------------------------------------------------------------------------------- -->


<div class="modal fade" id="JsonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <form #myForm="ngForm">
                <div class="modal-header border-0">
                    <h3 class="mt-2" id="exampleModalLabel">  {{'languages.opportunite.details.data' | translate}}</h3>
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                    <div class="container-modal-elements">
                        <div class="border-1">

                            <json-editor [options]="editorOptions" [data]="json_object"></json-editor>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button class="btn-dt-cancel-popup" type="button" data-dismiss="modal" aria-label="Close" #ClosePopup>
<i class="fa-solid  fa-up-right-from-square icon-btn-ouvrir"></i>
                              {{'languages.buttons.fermer' | translate}}
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>
