import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModelObject } from '../model/modelObject';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { FilterTicket } from '../../entity/TicketsAndSupport/FilterTicket';
import { DocumentsTicket } from '../../entity/TicketsAndSupport/DocumentsTicket';
import { Taches } from 'src/app/pages/parametres/gestion-categorie-ticket/_models/Categorie-ticket';
import { Frais } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { Task } from 'src/app/entity/task';
@Injectable()
export class ApiTicketsService {
  private _listnersTicket = new Subject<any>();
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private reloadTransactionSubject = new Subject<boolean>();

  // Observable to subscribe to
  reloadTransaction$ = this.reloadTransactionSubject.asObservable();

  // Method to trigger the reload
  triggerReloadTransaction() {
    this.reloadTransactionSubject.next(true);
  }

  pushTicket(filterBy: string) {
    this._listnersTicket.next(filterBy);
  }

  listenTicket(): Observable<any> {
    return this._listnersTicket.asObservable();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Ajouter ou modifier une categorie ticket suivant son identifiant codé en md5
   *
   * @param cle cle de l&#x27;operation update ou create
   * @param libelle libelle de categorie ticket
   * @param parentCat Identifiant de categorie parent codé en md5
   * @param parent identifiant de menu parent codé en md5 (a demander par mail a it@sodedif.fr)
   * @param group menu de groupe (1) ou non (0)
   * @param type type de menu 0 ou 1
   * @param id Identifiant de categorie tickets a modifier (Champs obligatoire pour la modification)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createOrUpdateCategoriesTicketsGet(
    cle: string,
    libelle: ModelObject,
    parentCat: ModelObject,
    parent: ModelObject,
    group: string,
    type: string,
    id?: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public createOrUpdateCategoriesTicketsGet(
    cle: string,
    libelle: ModelObject,
    parentCat: ModelObject,
    parent: ModelObject,
    group: string,
    type: string,
    id?: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public createOrUpdateCategoriesTicketsGet(
    cle: string,
    libelle: ModelObject,
    parentCat: ModelObject,
    parent: ModelObject,
    group: string,
    type: string,
    id?: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public createOrUpdateCategoriesTicketsGet(
    cle: string,
    libelle: ModelObject,
    parentCat: ModelObject,
    parent: ModelObject,
    group: string,
    type: string,
    id?: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCategoriesTicketsGet.');
    }

    if (libelle === null || libelle === undefined) {
      throw new Error(
        'Required parameter libelle was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    if (parentCat === null || parentCat === undefined) {
      throw new Error(
        'Required parameter parentCat was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    if (parent === null || parent === undefined) {
      throw new Error(
        'Required parameter parent was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    if (group === null || group === undefined) {
      throw new Error(
        'Required parameter group was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling createOrUpdateCategoriesTicketsGet.');
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (libelle !== undefined && libelle !== null) {
      queryParameters = queryParameters.set('libelle', <any>libelle);
    }
    if (parentCat !== undefined && parentCat !== null) {
      queryParameters = queryParameters.set('parent_cat', <any>parentCat);
    }
    if (parent !== undefined && parent !== null) {
      queryParameters = queryParameters.set('parent', <any>parent);
    }
    if (group !== undefined && group !== null) {
      queryParameters = queryParameters.set('group', <any>group);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/create_or_update_categories_tickets`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher les identifiants et les noms des documents suivant l&#x27;identifiant de l&#x27;opportunité code en md5
   *
   * @param idTicket identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listDocumentTicket(idTicket: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter idTicket was null or undefined when calling listDocumentTicketGet.');
    }

    // let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // if (idTicket !== undefined && idTicket !== null) {
    //   queryParameters = queryParameters.set('id_tickets', idTicket);
    // }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_document_ticket/${idTicket}`, {
      // params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param idTicket Identifiant de l&#x27;opportubite codé en md5
   * @param nomDocc Identifiant de l&#x27;opportubite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addDocumentTicketGet(
    addDocument: DocumentsTicket,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (addDocument === null || addDocument === undefined) {
      throw new Error('Required parameter docTicket was null or undefined when calling addDocumentTicketGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_document_ticket?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: addDocument,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param formData Identifiant de l&#x27;opportubite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadDocumentTicket(
    uploadFile: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (uploadFile === null || uploadFile === undefined) {
      throw new Error('Required parameter idTickets was null or undefined when calling addDocumentTicketsGet.');
    }

    if (name === null || name === undefined) {
      throw new Error('Required parameter name was null or undefined when calling addDocumentTicketsGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/upload_document_ticket?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: uploadFile,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param idTicket Identifiant de l&#x27;id opportunite codé en md5
   * @param idDoc Identifiant de l&#x27;documumet opportunite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadDocumentTicket(
    idTicket: string,
    idDoc: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addDocumentTicket.');
    }

    if (idDoc === null || idDoc === undefined) {
      throw new Error('Required parameter idDoc was null or undefined when calling addDocumentTicket.');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/load_document_ticket/${idTicket}/${idDoc}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * List des tickets suivant des filtres
   *
   * @param filtresEndCreation filtres[endCreation] &#x3D;&#x3D;&gt; date depot de l&#x27;oppotunite
   */

  public GetListTickets(
    filtres_tickte: FilterTicket,
    page: number,
    limit: number,
    sSearch: string,
    sSortDir_0?: string,
    iSortCol_0?: string,
    observe: any = 'body',
    @Optional() reportProgress: boolean = false
  ): Observable<any> {
    let sort: string;
    const sort_dict = {
      default: 0,
      '': 0,
      ticket_date_creation: 0,
      ticket_user_creat: 1,
      ticket_titre: 2,
      ticket_user_affect: 3,
      ticket_prospect: 4,
      ticket_opportunite: 5,
      ticket_affaire: 6,
      ticket_categorie: 7,
      ticket_statut: 8,
    };

    if (filtres_tickte === null || filtres_tickte === undefined) {
      throw new Error('Required parameter filtres_tickte is null or undefined when calling listTicketGet.');
    }

    if (page === null || page === undefined) {
      throw new Error('Required parameter page is null or undefined when calling listTicketGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit is null or undefined when calling listTicketGet.');
    }

    if (iSortCol_0 === null || iSortCol_0 === undefined || sort_dict === undefined || sort_dict === undefined) {
      throw new Error(
        'Required parameter iSortCol_0 was null , undefined or invalid when calling listOpportunitesGet.'
      );
    }

    if (sSortDir_0 === null || sSortDir_0 === undefined) {
      throw new Error(
        'Required parameter parent was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    if (sSearch === null || sSearch === undefined) {
      throw new Error(
        'Required parameter parent was null or undefined when calling createOrUpdateCategoriesTicketsGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('items_per_page', <any>limit);
    }
    if (iSortCol_0 !== undefined && iSortCol_0 !== null) {
      queryParameters = queryParameters.set('iSortCol_0', sort_dict[iSortCol_0]);
    }
    if (sSortDir_0 !== undefined && sSortDir_0 !== null) {
      if (sSortDir_0 === 'desc') {
        sort = 'desc';
      } else {
        sort = 'asc';
      }
      queryParameters = queryParameters.set('sSortDir_0', <any>sort);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const request_body = {
      filtre_ticket: filtres_tickte,
      page: page,
      items_per_page: limit,
      sSortDir_0: sSortDir_0,
      sSearch: sSearch,
      iSortCol_0: sort_dict[iSortCol_0],
    };

    return this.httpClient.request<any>('post', `${this.basePath}/list_tickets`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: request_body,
    });
  }

  /**
   * Ajouter ou modifier une categorie ticket suivant son identifiant codé en md5
   *
   * @param idTicket Identifiant de  tickets  (Champs obligatoire)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInformationTicketGet(
    idTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCategoriesTicketsGet.');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_ticket/${idTicket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public InsertTransaction(
    id_ticket: string,
    transaction: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_ticket === null || id_ticket === undefined) {
      throw new Error('Required parameter id_ticket was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/ticket/add_mouvement_financiere/${id_ticket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: transaction,
    });
  }

  public GetTransactionsTransaction(
    idTicket: string,
    limit: any,
    page: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter idTicket was null or undefined when calling GetTransactionsAffaire.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    if (idTicket !== undefined && idTicket !== null) {
      queryParameters = queryParameters.set('id_ticket', <any>idTicket);
    }
    queryParameters = queryParameters.set('limit', <any>limit);
    queryParameters = queryParameters.set('page', <any>page);
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/ticket/get_mouvement_financiere`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public addGarantieTicket(garanties: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_ticket_garanties`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: garanties,
    });
  }

  public getAffaireGarantiTicket(
    id_affaire: string,
    subscribed: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // queryParameters = queryParameters.set('limit', <any>limit);
    // queryParameters = queryParameters.set('page', <any>page);
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    if (subscribed !== undefined && subscribed !== null) {
      queryParameters = queryParameters.set('subscribed', <any>subscribed);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/garantie/v2/get_affaire_garantie/${id_affaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getGarantiTicket(
    id_Ticket: string,
    affaire_id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_Ticket === null || id_Ticket === undefined) {
      throw new Error('Required parameter id_Ticket was null or undefined when calling getLiaisonContrat.');
    }
    if (affaire_id === null || affaire_id === undefined) {
      throw new Error('Required parameter affaire_id was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // queryParameters = queryParameters.set('limit', <any>limit);
    // queryParameters = queryParameters.set('page', <any>page);
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_garanties_ticket/${affaire_id}/${id_Ticket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Ajouter ou modifier une categorie ticket suivant son identifiant codé en md5
   *
   * @param addTicket  cle de l&#x27;operation update ou create
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public AjouterTicket(
    addTicket: {},
    idAffaire: string,
    idOpp: string,
    idPros: string,
    choix: string,
    breadcrumb_cat_ticket: string,
    parentTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (addTicket === null || addTicket === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCategoriesTicketsGet.');
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>idOpp);
    }

    if (idPros !== undefined && idPros !== null) {
      queryParameters = queryParameters.set('id_prospect', <any>idPros);
    }

    if (choix !== undefined && choix !== null) {
      queryParameters = queryParameters.set('choix', <any>choix);
    }

    if (breadcrumb_cat_ticket !== undefined && breadcrumb_cat_ticket !== null) {
      queryParameters = queryParameters.set('breadcrumb_cat_ticket', <any>breadcrumb_cat_ticket);
    }
    if (parentTicket !== undefined && parentTicket !== null) {
      queryParameters = queryParameters.set('ticket_parent', <any>parentTicket);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_ticket`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: addTicket,
    });
  }

  public listSousTicket(idTicket: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter idTicket was null or undefined when calling listSousTicket.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_ticket_fils_details/${idTicket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *get  status des tickets
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetStatusTicket(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_all_status_tickets?`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * update tickets
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTickets(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_ticket?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Data,
    });
  }

  public actionGroupeTickets(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/ticket/multiple_action`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Data,
    });
  }
  /**
   * update tickets
   *
   * @param idTickets
   * @param idStatus
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public updateStatusTickets(
    idTickets: string,
    idStatus: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_statut_ticket/${idTickets}/${idStatus}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher tous les categories des tickets avec leur id , id_parent et leur libelle
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listCatTicketGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_cat_ticket`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher tous les categories des tickets avec leur id , id_parent et leur libelle
   *
   * @param idUser set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetHistoriquesTickets(
    idTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCategoriesTicketsGet.');
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idTicket !== undefined && idTicket !== null) {
      queryParameters = queryParameters.set('id_ticket', <any>idTicket);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_historique_ticket?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher tous les categories des tickets avec leur id , id_parent et leur libelle
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetCountTicketsByCategorie(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_count_ticket_by_categorie?`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la date de creation ,l’identifiant en md5,la libelle , le nom , le titre de ticket suivant l’identifiant de l’affaire et l’identifiants des categories codé en md5.
   *
   * @param idAffaireTicket Identifiant de l’affaire codé en md5
   * @param idCategorie Identifiant de categorie codé en md5 a demander par mail a it@sodedif.fr
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public testExistTicketGet(
    idAffaireTicket: ModelObject,
    idCategorie: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public testExistTicketGet(
    idAffaireTicket: ModelObject,
    idCategorie: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public testExistTicketGet(
    idAffaireTicket: ModelObject,
    idCategorie: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public testExistTicketGet(
    idAffaireTicket: ModelObject,
    idCategorie: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaireTicket === null || idAffaireTicket === undefined) {
      throw new Error('Required parameter idAffaireTicket was null or undefined when calling testExistTicketGet.');
    }

    if (idCategorie === null || idCategorie === undefined) {
      throw new Error('Required parameter idCategorie was null or undefined when calling testExistTicketGet.');
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idAffaireTicket !== undefined && idAffaireTicket !== null) {
      queryParameters = queryParameters.set('id_affaire_ticket', <any>idAffaireTicket);
    }
    if (idCategorie !== undefined && idCategorie !== null) {
      queryParameters = queryParameters.set('id_categorie', <any>idCategorie);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/test_exist_ticket`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public saveNotification(tickets_info: Task, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (tickets_info === null || tickets_info === undefined) {
      throw new Error('Required parameters tickets_info was null or undefine');
    }
    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/create_tache`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: tickets_info,
    });
  }

  public list_prospects(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_prospects`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public UpdateTache(tache: Taches, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (tache === null || tache === undefined) {
      throw new Error('Required parameters tickets_info was null or undefine');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_tache`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: tache,
    });
  }

  public updateTicketsinformationSpecifiques(
    form: any,
    idTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (form === null || form === undefined) {
      throw new Error('Required parameters form was null or undefine');
    }

    const queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_info_specifique_ticket/${idTicket}?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: form,
    });
  }

  public GetListTachesTicket(
    idTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_taches_ticket/${idTicket}?`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public GetListTaches(
    idTicket: string,
    entity: string,
    search: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idTicket !== undefined) {
      queryParameters = queryParameters.set('id_entity', <any>idTicket);
      queryParameters = queryParameters.set('entity', <any>entity);
      queryParameters = queryParameters.set('Search', <any>search);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_taches_by_entity?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public GetListTachesV1(
    idTicket: string,
    entity: string,
    search: string,
    page,
    limit,
    archive,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (idTicket !== undefined) {
      queryParameters = queryParameters.set('id_entity', <any>idTicket);
      queryParameters = queryParameters.set('entity', <any>entity);
      queryParameters = queryParameters.set('Search', <any>search);
      queryParameters = queryParameters.set('limit', <any>limit);
      queryParameters = queryParameters.set('page', <any>page);
      queryParameters = queryParameters.set('key', <any>archive);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_taches_by_entity?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public updateTicketNotification(
    idticket: string,
    idNotification: string,
    status: Number,
    entity: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('entity', entity);

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/update_status_tache/${idNotification}/${status}/${idticket}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        params: queryParameters,
      }
    );
  }

  public updateTicketNotificationV2(
    idticket: string,
    idNotification: string,
    status: Number,
    entity: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('entity', entity).set('id_entity', idticket);

    return this.httpClient.request<any>('put', `${this.basePath}/update_status_tache/${idNotification}/${status}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      params: queryParameters,
    });
  }

  /**
   * Permet de retourner les informations spécifiques d'une ticket
   *
   * @param idTicket Identifiant d'une ticket'
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetInformationSpecifique(
    idTicket: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_specifique_ticket/${idTicket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public list_priorite(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_priorite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  private change$ = new BehaviorSubject<any>({});
  selectedchanges$ = this.change$.asObservable();

  setchange(changeForm: any) {
    this.change$.next(changeForm);
  }

  public getfraisPayementTicket(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_frais_paiement_ticket/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public InsertFrais(
    idTicket: string,
    frais: Frais,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idTicket === null || idTicket === undefined) {
      throw new Error('Required parameter idTicket was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/insert_frais_ticket/${idTicket}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: frais,
    });
  }

  public getListRules(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/rule/v0/listRules`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
