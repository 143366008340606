import { Component, OnInit } from '@angular/core';
import {FormField} from '../../../entity/Formbuilder/global.model';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-check-box-type',
  templateUrl: './check-box-type.component.html',
  styleUrls: ['./check-box-type.component.scss']
})
export class CheckBoxTypeComponent extends FieldType implements OnInit {

  colors: any = {
    accent: 'accent',
    warn: 'warn',
    primary: 'primary'
  };
  
  searchValue: any;
  
  ngOnInit(): void {
    this.searchValue = this.field.templateOptions?.autofill;

    // Disable or enable checkbox based on autofill option
    if (this.field.templateOptions?.autofill) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  updateValue(event: Event): void {

    this.formControl.setValue('Some update'); // Update with the logic you need
  }

  setAll(completed: boolean) {
  }
}
