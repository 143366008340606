




  export class Prospect {
    recheche_nom_prenom: string = '';
  }


  export class Ticket {
    recheche_num_ticket: string = '';
    recheche_sujet_ticket: string = ''; 
    date_debut_creation : string = '';
    date_fin_creation: string = '';
    date_debut_traitement: string = '';
    date_fin_traitement: string = '';
    date_debut_limit: string = '';
    date_fin_limit: string = '';
    recheche_status: any[]=[];
    categorie: any[]=[];
  }


  // export class Reclamation {

  //   reglement:string = '';
  //   origine: any[]=[];
  //   date_debut_rec_clt: string = '';
  //   date_fin_rec_clt: string = '';
  //   date_debut_reception: string = '';
  //   date_fin_reception: string = '';
  //   date_debut_acc_recp_clt: string = '';
  //   date_fin_acc_recp_clt: string = '';
  //   nature_rec:any[]=[];
  //   date_debut_envoi_assure: string = '';
  //   date_fin_envoi_assure: string = '';
  //   rec_mot_just: string = '';
  //   date_debut_doss_complet: string = '';
  //   date_fin_doss_complet: string = '';
  //   date_debut_rep_rec: string = '';
  //   date_fin_rep_rec: string = '';
  //   date_debut_cloture_rec: string = '';
  //   date_fin_cloture_rec: string = '';
  //   action_juridique: any[]=[];
  //   stade_traitement: any[]=[];

  // }
  export class Utilisateur {

    recheche_create_user:any[]=[];
    recheche_collab:any[]=[];
      }
  export class FilterTicket {
    utilisateur: Utilisateur = new Utilisateur();
    prospect: Prospect = new Prospect();
    ticket:Ticket =new Ticket(); 
    // reclamation:Reclamation =new Reclamation();
 
}


