import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryServiceTicket {
  private fetchDataSubject = new BehaviorSubject<boolean>(false);
  private reloadSousTickets = new BehaviorSubject<boolean>(false);
  reloadSousTicketsData$: Observable<boolean> = this.reloadSousTickets.asObservable();
  fetchData$: Observable<boolean> = this.fetchDataSubject.asObservable();
  constructor() { }
  sendFetchDataSignal(signal: boolean) {
    this.fetchDataSubject.next(signal);
  }

  reloadSousTicket(signal: boolean) {
    this.reloadSousTickets.next(signal);
  }

}
