<div class="card-head">
    <div class="row">
        <div class="col-9" style="margin-top: 8px">
            <p>
              {{'languages.organisme.numOrganism' | translate}} :
                <label class="label-card-info">
                    {{idOrg}}
                </label>
            </p>
            <p>
              {{'languages.user.organismName' | translate}} :
                <label class="label-card-info">
                    {{nomOrg}}
                </label>
            </p>
        </div>

    </div>
</div>
<div class="card-head text-center">
    <div class="row details-header">
       
        <button type="button" (click)="goToOrganisme()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
            </div>
        </button>
    </div>
</div>
<div class="card-head">
    <div class="row col-md-12 mt-3 blockColor justify-content-center">
        <div class="col-12 " id="expand">
            <div id="ex1">
                <div class="containerTache p-3">
                    <div class="row  m-2">
                        <h2 class="title-details-blue ml-1 col-md-4">
                            <i class="fa-solid fa-handshake-angle fa-lg"></i>
                            {{'languages.convention.conventionsASC' | translate}}
                        </h2>
                    </div>
                    <div class="row  ">
                        <div class="example-list w-100 mw-100 blockk d-block mr-3 " id="ex2">
                            <div class="card borderCard mb-3">
                                <div class="card-body">
                                    <div class="row w-100">
                                        <div class="col-4">
                                          {{'languages.convention.conventionName' | translate}}
                                        </div>
                                        <div class="col-2">
                                          {{'languages.convention.fournisseur' | translate}}
                                        </div>
                                        <div class="col-2"></div>
                                        <div class="col-2">
                                          {{'languages.convention.status' | translate}}
                                        </div>

                                        <div class="col-2">
                                          {{'languages.convention.version' | translate}}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <mat-spinner [diameter]="30" *ngIf="loaderAsc" class="loaderstyle"></mat-spinner>
                            <mat-accordion class="descendantes-headers-align w-100"
                                *ngFor=" let item of listConventionAsc; let index = index">
                                <mat-expansion-panel class="mt-2" [expanded]="IndexAsc === index" (opened)="fermerPanneauAsc()"
                                    style="border-radius: 16px;">
                                    <mat-expansion-panel-header id="colorTEST"  (click)="ouvrirPanneauAsc(index)">
                                        <div class="row w-100">
                                            <div class="col-4 mt-1">
                                                {{expandFileName(item.nom)}}
                                            </div>
                                            <div class="col-2 mt-1">
                                                {{expandFileName(item.nom_organisme_fourn)}}
                                            </div>
                                            <div class="col-2"></div>
                                            <div class="col-2">
                                                <span class="btn-status d-flex justify-content-center"
                                                    [ngStyle]="{ color: item.color, 'background-color': item.background }">{{item.libelle}}</span>
                                            </div>
                                            <div class="col-2 textVersion mt-1">
                                                Version {{item.version}}
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>

                                    <app-form-convention [organisme_id]="id_fourn" [conventionData]="item"
                                        [allOrganismeList]="allOrganisme" [allGammesList]="allGammes" [mode]="'ajout'"
                                        [type]="'asc'" [disabledName]="false" [color]="'white'"></app-form-convention>

                                    <div class="row justify-content-center mb-3">

                                        <mat-accordion class="version-headers-align w-100 mt-2 custemPanel"
                                            *ngFor=" let version of item.details; let index = index" style="display: flex;
                                            justify-content: center;">
                                            <mat-expansion-panel  [expanded]="IndexAscVersion === index"
                                                style="border-radius: 16px;">
                                                <mat-expansion-panel-header id="colorCild" (click)="ouvrirPanneauAscV(index)">
                                                    <div class="row w-100">
                                                        <div class="col-3 textVersion">
                                                          {{'languages.convention.version' | translate}} {{version.version}}
                                                        </div>
                                                        <div class="col-5">
                                                        </div>
                                                        <div class="col-1"> </div>
                                                        <div class="col-3">
                                                            <span class="btn-status d-flex justify-content-center"
                                                                [ngStyle]="{ color: version.color, 'background-color': version.background }">
                                                                {{version.libelle}}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </mat-expansion-panel-header>
                                                <div class="row justify-content-center ">

                                                    <app-form-convention [organisme_id]="id_fourn"
                                                        [allOrganismeList]="allOrganisme" [allGammesList]="allGammes"
                                                        [mode]="'ajout'" [disabledName]="false"
                                                        [conventionData]="version" [type]="'asc'"
                                                        [color]="'#FFEDEE'"></app-form-convention>

                                                </div>

                                            </mat-expansion-panel>

                                        </mat-accordion>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>



                        </div>
                    </div>
                </div>
            </div>


            <nav aria-label="Page navigation example" *ngIf="totalPages_asc>0">
                <ul class="pagination pg-blue">
                    <li (click)="first_asc()" class="page-item" [ngClass]="{'disabled':isFirst_asc()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="!isFirst_asc()" (click)="prev_asc()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Previous">

                            <i class="fa-solid fa-chevron-left"></i>
                        </a>
                    </li>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page_asc-3>0" class="page-item">
                            <a (click)="changepage_asc(i+page_asc-3)" class="page-link mt-2 border rounded">{{i +
                                page_asc - 3}}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page_asc <totalPages_asc" class="page-item">
                            <a (click)="changepage_asc(i+page_asc)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page_asc==(i+page_asc)}">
                                {{i + page_asc}}
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="totalPages_asc>1 && totalPages_asc > 3 " class="m-2"> ... </li>
                    <li class="page-item">
                        <a (click)="changepage_asc(totalPages_asc)" class="page-link mt-2 border rounded"
                            [ngClass]="{'active-page':page_asc==totalPages_asc}">{{totalPages_asc}}</a>
                    </li>
                    <li *ngIf="!isLast_asc()" (click)="next_asc()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Next">
                            <i class="fa-solid fa-chevron-right"></i>
                        </a>
                    </li>
                    <li (click)="last_asc()" class="page-item" [ngClass]="{'disabled':isLast_asc()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>

    <div class="row col-md-12  blockColor ">
        <div class="containerTache">
            <div class="row  m-2">
                <h2 class="title-details-blue ml-1 col-md-4">
<i class="fa-solid fa-handshake-angle fa-lg"></i>
                    {{'languages.convention.conventionsDESC' | translate}}
                </h2>
                <button type="button" (click)="pushListconvention()"
                    [ngStyle]="{'opacity': allGammes.length==0 || disabledButton==true ?'0.5':'1'}"
                    [disabled]="allGammes.length==0 || disabledButton==true"
                    class="add-btn-en btn-details-add ajout fa-lg">
                    <i class="fa-solid fa-circle-plus fa-lg"></i>
                </button>


            </div>
        </div>
        <div class="col-12 " id="expandDes">
            <div class=" add_panel m-1" *ngIf="isExpandDes">

                <div class="containerTachee p-3">
                    <div class="row w-100 justify-content-center text-height">
                      {{'languages.convention.AddConvention' | translate}}
                    </div>
                    <div class="row w-100 mt-3 justify-content-center">
                        <app-form-convention (onPushConvention)="getDataConvention($event)"
                            [conventionData]="pushListconventions[indexDes]" [index]="indexDes"
                            [allOrganismeList]="allOrganisme" [allGammesList]="allGammes" [mode]="'ajout'"
                            [type]="'des'" [organisme_id]="id_fourn" [disabledName]="true">
                        </app-form-convention>

                    </div>
                    <div class="row justify-content-center">
                        <button type="submit" class="btn-dt-save-popup mr-2" (click)=" createConvention()">
<i class="fa-solid fa-circle-check fa-lg mr-1 mt-1"></i>
                            {{'languages.buttons.enregistrer' | translate}}
                        </button>

                        <button type="button" class="btn-dt-cancel-popup" (click)="resetForm()">
                            <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
                            {{'languages.buttons.annuler' | translate}}
                        </button>
                        <mat-spinner *ngIf="showloader" [diameter]="20" class="ml-2 mt-2"></mat-spinner>
                    </div>
                </div>
            </div>

            <div class="containerTache p-3">
                <div class="hide" id="ex1">
                    <div class="row  ">
                        <div class="example-list w-100 mw-100  blockk d-block mr-3 " id="ex2">
                            <div class="card borderCard mb-3">
                                <div class="card-body">

                                    <div class="row w-100">
                                        <div class="col-4">
                                          {{'languages.convention.conventionName' | translate}}
                                        </div>
                                        <div class="col-2">
                                          {{'languages.convention.Distributeur' | translate}}
                                        </div>

                                        <div class="col-2">
                                          {{'languages.convention.status' | translate}}
                                        </div>
                                        <div class="col-2">
                                          {{'languages.convention.version' | translate}}
                                        </div>
                                        <div class="col-1"></div>
                                        <div class="col-1 float-left">
                                          {{'languages.convention.Actions' | translate}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <mat-spinner class="loaderstyle" [diameter]="30" *ngIf="loaderDesc"></mat-spinner>

                            <mat-accordion class="descendantes-headers-align w-100  mt-4"
                                *ngFor=" let item of pushListconventions ; let indexC = index;" multi >
                                <mat-expansion-panel class="mt-2" [expanded]="panneauOuvertIndex === indexC"  style="border-radius: 16px;"
                                (opened)="fermerPanneau()">
                                    <mat-expansion-panel-header (click)="ouvrirPanneau(indexC)">
                                        <div class="row w-100">
                                            <div class="col-4 mt-1">
                                                {{expandFileName(item.nom)}}
                                            </div>
                                            <div class="col-2 mt-1">
                                                {{expandFileName(item.nom_organisme_distib)}}
                                            </div>

                                            <div class="col-2">
                                                <span class="btn-status d-flex justify-content-center"
                                                    [ngStyle]="{ color: item.color, 'background-color': item.background }">{{item.libelle}}</span>

                                            </div>

                                            <div class="col-2 textVersion mt-1">
                                              {{'languages.convention.version' | translate}} {{item.version}}
                                            </div>

                                            <div class="col-2" (click)="$event.stopPropagation();">

                                               
                                                <i class="fa-solid fa-clone iconDuiplicate mr-2 mt-2" (click)="duplicateConvention(item , indexC)"></i>
                                                <svg width="48" height="22" viewBox="0 0 52 26" fill="none"
                                                (click)="newVersion(item , indexC)"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect width="52" height="26" rx="8" fill="#6197CB" />
                                                <path
                                                    d="M21.5058 12.0526H17.4005V7.94737C17.4005 7.42947 16.971 7 16.4531 7C15.9352 7 15.5058 7.42947 15.5058 7.94737V12.0526H11.4005C10.8826 12.0526 10.4531 12.4821 10.4531 13C10.4531 13.5179 10.8826 13.9474 11.4005 13.9474H15.5058V18.0526C15.5058 18.5705 15.9352 19 16.4531 19C16.971 19 17.4005 18.5705 17.4005 18.0526V13.9474H21.5058C22.0237 13.9474 22.4531 13.5179 22.4531 13C22.4531 12.4821 22.0237 12.0526 21.5058 12.0526Z"
                                                    fill="white" />
                                                <path
                                                    d="M43.556 6L37.3206 20H35.2355L29 6H31.6411L36.2879 16.8227L40.9546 6H43.556Z"
                                                    fill="white" />
                                            </svg>

                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="row w-100 mt-1 justify-content-center">
                                        <app-form-convention (onPushConvention)="getDataConvention($event)"
                                            [conventionData]="item" [index]="indexC" [type]="'des'"
                                            [allOrganismeList]="allOrganisme" [allGammesList]="allGammes"
                                            [organisme_id]="id_fourn" [disabledName]="true">
                                        </app-form-convention>
                                    </div>
                                    <div class="row justify-content-center mt-4"
                                        *ngIf="item.status !== ConventionStatusFerme && item.status !== ConventionStatusExpire">
                                        <button type="submit" class="btn-dt-save-popup mr-2"
                                            (click)=" enregistrer(indexC ,'convention' ,indexC) ">
<i class="fa-solid fa-circle-check fa-lg mr-1 "></i>
                                            {{'languages.buttons.enregistrer' | translate}}
                                        </button>

                                        <button type="button" class="btn-dt-cancel-popup" (click)="initForm()">
                                            <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
                                            {{'languages.buttons.annuler' | translate}}
                                        </button>
                                        <mat-spinner *ngIf="showloaderModif" [diameter]="20"
                                            class="ml-2 mt-1"></mat-spinner>

                                    </div>

                                    <div class="row justify-content-center mt-3 ">

                                        <mat-accordion class="version-headers-align w-100 mt-1 custemPanel" style="display: flex;
                                        justify-content: center;" [ngClass]="{'mt-4': isFirstItem === true}"
                                            *ngFor=" let version of item.details; let index = index ;let isFirstItem = first;">
                                            <mat-expansion-panel [expanded]="IndexVersion === index"
                                            style="border-radius: 16px;" class="mt-1">
                                                <mat-expansion-panel-header (click)="ouvrirPanneauVersion(index)">
                                                    <div class="row w-100">
                                                        <div class="col-3 textVersion">
                                                          {{'languages.convention.version' | translate}} {{version.version}}
                                                        </div>
                                                        <div class="col-4">
                                                        </div>
                                                        <div class="col-1"> </div>
                                                        <div class="col-3">
                                                            <span class="btn-status d-flex justify-content-center"
                                                                [ngStyle]="{ color: version.color, 'background-color': version.background }">{{version.libelle}}</span>
                                                        </div>

                                                        <div class="col-1 d-flex justify-content-center align-items-center" (click)="$event.stopPropagation();">
                                                            <i class="fa-solid fa-clone iconDuiplicate fa-lg  mr-3 " (click)="DuplicateVersion(version , indexC)"></i>

                                                          

                                                        </div>
                                                    </div>
                                                </mat-expansion-panel-header>


                                                <div class="row justify-content-center custom-panel-body">

                                                    <app-form-convention [organisme_id]="id_fourn"
                                                        [conventionData]="version" [type]="'des'"
                                                        [allOrganismeList]="allOrganisme" [allGammesList]="allGammes"
                                                        (onPushConvention)="getDataConvention($event)"
                                                        [disabledName]="false" [color]="'#FFEDEE'">
                                                    </app-form-convention>
                                                </div>
                                                <div class="row justify-content-center mb-3"
                                                    *ngIf="version.status !== ConventionStatusFerme && version.status !== ConventionStatusExpire">

                                                    <button type="submit" class="btn-dt-save-popup mr-2"
                                                        (click)=" enregistrer(indexC , 'version', index)">
<i class="fa-solid fa-circle-check fa-lg mr-1 mt-1"></i>
                                                            {{'languages.buttons.enregistrer' | translate}}
                                                    </button>
                                                    <button type="button" class="btn-dt-cancel-popup"
                                                        (click)="initForm()">
                                                        <i class="fa-solid fa-circle-xmark fa-icon-style mr-1 mt-1  fa-lg"></i>
                                                            {{'languages.buttons.annuler' | translate}}

                                                    </button>
                                                    <mat-spinner *ngIf="showloaderModif || showloader " [diameter]="20"
                                                        class="ml-2 mt-1"></mat-spinner>
                                                </div>


                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                    </div>
                </div>

            </div>
            <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                <ul class="pagination pg-blue">
                    <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Previous">
                            <i class="fa-solid fa-chevron-left"></i>
                        </a>
                    </li>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page-3>0" class="page-item">
                            <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                3}}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page<totalPages" class="page-item">
                            <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==(i+page)}">
                                {{i + page}}
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                    <li class="page-item">
                        <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                            [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                    </li>
                    <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Next">
                            <i class="fa-solid fa-chevron-right"></i>
                        </a>
                    </li>
                    <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</div>
