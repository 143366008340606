import { Configuration } from '../services/configuration';
import { UserLogin } from '../login/login.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
export class ApiConfigurationFactory {
  static createConfiguration(route: ActivatedRoute, router: Router) {
    const userLogin: UserLogin = new UserLogin();
    const configuration = new Configuration({
      apiKeys: {
        idSession: '',
        apiKey: '',
        'PRIVATE-TOKEN': '',
      },
      id_user: '',
    });

    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const queryParams = route.snapshot.queryParams;

      if (queryParams && Object.keys(queryParams).length > 0) {
        route.queryParams.subscribe((params) => {
          const idSession = params?.idSession;

          if (idSession) {
            localStorage.setItem('Acces_Token', idSession);
            userLogin.Acces_Token = idSession;
            configuration.apiKeys.idSession = idSession;
            if (userLogin?.Acces_Token?.length > 1) {
              configuration.setLogin(userLogin);
            }
          } else {
            userLogin.load();
            if (userLogin?.Acces_Token?.length > 1) {
              configuration.setLogin(userLogin);
            }
          }
        });
      } else {
        userLogin.load();
        if (userLogin?.Acces_Token?.length > 1) {
          configuration.setLogin(userLogin);
        }
      }
    });

    return configuration;
  }
}
