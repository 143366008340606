import { NgModule } from '@angular/core';
import { ProspectAddComponent } from './prospect-add.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../../../angular-material/angular-material.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ProspectFiltredListModalComponent } from './prospect-filtred-list-modal/prospect-filtred-list-modal/prospect-filtred-list-modal.component';
import { ProspectProFiltredListComponent } from './prospect-pro-filtred-list/prospect-pro-filtred-list/prospect-pro-filtred-list.component';

@NgModule({
  declarations: [ProspectAddComponent, ProspectFiltredListModalComponent, ProspectProFiltredListComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AngularMaterialModule, SharedModule],
  exports: [ProspectAddComponent],
})
export class ProspectFormModule {}
