<div class="row card" *ngIf="prospect_id !== null">

    <label class="card-doc-title row">

      <div class="col-8">
    <i class="fa-solid fa-bars-staggered"></i>
      <ng-container *ngIf="prospect_id !== null && affaire_id === null">
         {{"languages.prospect.Informationsprospect" | translate }}
      </ng-container>
      <ng-container *ngIf="prospect_id !== null && affaire_id !== null">
         {{"languages.prospect.Informationsclient" | translate }}
      </ng-container>
    </div>
    <div class="col-4 text-right">
      <button type="button" class="btn-dt-link" (click)="toFiche()" >
        <i class="fa-solid fa-link fa-icon-style"></i>
  {{"languages.opportunite.details.ficheAdherent" | translate }}
  </button>
  </div>
  </label>


    <ng-container  *ngIf="prospect.id">
       
        <app-prospect-form style="pointer-events: none; opacity: 0.9;"  [allowed_to_update]="false" [ticket]="'ticket'" [prospect]="prospect" [type]="3" [schemaValue]="prospect.form" [isReadOnly]="true" (onUpdateProspect)="updateProspect($event,-1)">
        </app-prospect-form>
    </ng-container>

</div>
