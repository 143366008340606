import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiTicketsService } from '../../../services/ApiTickets/api-tickets.service';
import { TicketsAndSupport } from '../../../entity/TicketsAndSupport/TicketsAndSupport';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CountTicketsByCategorie } from '../../../entity/TicketsAndSupport/CountTicketsByCategorie';
import { FilterTicket } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { DatePipe } from '@angular/common';
import { formatDateToLocal, UniformiserDateToLocal, parseDate } from 'src/app/shared/Utils/sharedFunctions';
import { Subject, Subscription } from 'rxjs';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { TranslateService } from "@ngx-translate/core";
import { AccessList } from 'src/app/entity/accessList';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActionGroupeeTicketsComponent } from 'src/app/components/action-groupee-tickets/action-groupee-tickets.component';
import swal from 'sweetalert2';
import { isEqual, differenceWith } from 'lodash';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-tickets-and-support',
  templateUrl: './tickets-and-support.component.html',
  styleUrls: ['./tickets-and-support.component.css'],
})
export class TicketsAndSupportComponent implements OnInit, OnDestroy , AfterViewInit{
  actionButton: boolean = false;
  limit = 15;
  pages = 1;
  totalPages = 0;
  categorieTickets: CountTicketsByCategorie = new CountTicketsByCategorie();
  ticketsSupport: TicketsAndSupport[] = [];
  intervale = 1;
  sort_field: string = 'default';
  sort_value: boolean = false;
  sort_type: string = 'desc';
  search: string = '';
  listStatutWebService: Subscription[] = [];
  filtres_tickets: FilterTicket = new FilterTicket();
  filtres_ticketsInit: FilterTicket = new FilterTicket();
  date_fin: any;
  date_debut: any;
  myDate = new Date();
  date_finInit: Date;
  date_debutInit: Date;
  isEdit: boolean = false;
  filtre: string = null;
  isListe: boolean = false;
  alerts: any;
  buttons: any;
  totalLength: number = 0;
  currentLanguage: string;
  filterSsearch: boolean = false;
  inputSubject = new Subject<string>();
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  readonly rangeDateCreation = RangeDateComponent;
  constructor(
    private translate: TranslateService,
    private apiTickets: ApiTicketsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private router: Router,
    private datePipe: DatePipe,
    private apiAuthentificationService: ApiAuthentificationService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private renderer: Renderer2

  ) {
    window.addEventListener('beforeunload', this.clearLocalStorage.bind(this));
    this.currentLanguage = this.translate.currentLang;
    this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.getDataValue();
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  collapseFromParent : boolean ;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;

      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event){
    this.collapseFromParent = event;
  }
  
  ngOnInit(): void {


    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.ticketSupport);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
        this.sharedMenuObserverService.updateMenu(object.navbar.ticketSupport);
      });
    });
    this.date_debut = new Date(new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()));
    this.date_fin = this.myDate;
    this.date_debutInit = new Date(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate())
    );
    this.date_finInit = this.myDate;
    this.filtre = localStorage.getItem('filtreTicket');
    const page = localStorage.getItem('pages');
    const sort_fields = localStorage.getItem('sort_field');
    const searchs = localStorage.getItem('search');

    const sort_types = localStorage.getItem('sort_type');
    if (sort_fields != null) {
      this.sort_field = sort_fields;
    }
    if (sort_types != null) {
      this.sort_type = sort_types;
    }

    if (page !== null) {
      this.pages = parseInt(page);
    }
    if (searchs) {
      this.search = searchs;
      this.getListTicketsSupport('search');
    } else if (this.filtre !== null) {
      this.filtres_tickets = JSON.parse(this.filtre);
      this.getListTicketsSupport('');
    } else {
      this.getListTicketsSupport('init');
    }
    this.getCountTicketsByCategorie();
  }

  ngOnDestroy() {
    if (this.isEdit === false) {
      localStorage.removeItem('filtreTicket');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('search');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('savedFilter');
      localStorage.removeItem('savedCollection');
    } else {
      localStorage.setItem('search', this.search);
      localStorage.setItem('pages', this.pages.toString());
      localStorage.setItem('filtreTicket', JSON.stringify(this.filtres_tickets));
    }
    this.listStatutWebService.forEach((element) => {
      element?.unsubscribe();
    });
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
  }

  clearDateCreation() {
    this.date_debut = null;
    this.date_fin = null;
  }

  clearLocalStorage() {
    localStorage.removeItem('filtreTicket');
    localStorage.removeItem('pages');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('search');
    localStorage.removeItem('sort_type');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.clearLocalStorage();
  }

  isObjectEqual() {
    const differences = differenceWith([this.filtres_tickets, this.filtres_ticketsInit]);
    return isEqual(this.filtres_tickets, this.filtres_ticketsInit);
  }

  filtrer() {
    this.getListTicketsSupport('filtrer');
  }

  getListTicketsSupport(event) {
    if (this.sort_value === false) {
      this.sort_value = true;
    } else {
      this.sort_value = false;
    }

    this.showloader();
    if (event === 'init') {
      this.pages = 1;
      this.filtres_tickets.ticket.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.filtres_tickets.ticket.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else if (event === 'submit') {
      this.search = '';

      this.pages = 1;
      if (this.isObjectEqual()) {
        this.filtres_tickets.ticket.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.filtres_tickets.ticket.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else if (
        this.filtres_tickets.ticket.date_debut_creation &&
        this.filtres_tickets.ticket.date_fin_creation &&
        (this.filtres_tickets.ticket.date_debut_creation !==
          this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd') ||
          this.filtres_tickets.ticket.date_fin_creation !== this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd'))
      ) {
        this.date_debut = this.datePipe.transform(this.filtres_tickets.ticket.date_debut_creation, 'yyyy-MM-dd');
        this.date_fin = this.datePipe.transform(this.filtres_tickets.ticket.date_fin_creation, 'yyyy-MM-dd');
      } else {
        this.filtres_tickets.ticket.date_debut_creation = '';
        this.filtres_tickets.ticket.date_fin_creation = '';
      }
    } else if (event === 'ticket') {
      this.search = '';
      this.pages = 1;
      if (this.isObjectEqual()) {
        this.filtres_tickets.ticket.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.filtres_tickets.ticket.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else {
        this.date_debut = this.datePipe.transform(this.filtres_tickets.ticket.date_debut_creation, 'yyyy-MM-dd');
        this.date_fin = this.datePipe.transform(this.filtres_tickets.ticket.date_fin_creation, 'yyyy-MM-dd');
      }
    } else if (event === 'reset') {
      this.search = '';
      this.filtres_tickets.ticket.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.filtres_tickets.ticket.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else if (event === 'filtrer') {
      this.pages = 1;
      // this.filtres_tickets = new FilterTicket();
      this.filtres_tickets.ticket.date_debut_creation = this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
      this.filtres_tickets.ticket.date_fin_creation = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    } else if (event === 'search') {
      this.router.navigate([], {
        queryParams: {
          filter: null,
          collection: null,
        },
        queryParamsHandling: 'merge',
      });
      this.filtres_tickets.ticket.date_debut_creation = '';
      this.filtres_tickets.ticket.date_fin_creation = '';
    } else if (event === 'filterStatus') {
      this.search = '';
      this.pages = 1;
      this.filtres_tickets.ticket.date_debut_creation = '';
      this.filtres_tickets.ticket.date_fin_creation = '';
    }
    const unsubscribeGetListTickets = this.apiTickets
      .GetListTickets(this.filtres_tickets, this.pages, this.limit, this.search, this.sort_type, this.sort_field)
      .subscribe(
        (data) => {
          this.filterSsearch = false;

          this.date_debut = parseDate(data.min_date_creation);
          this.date_fin = parseDate(data.max_date_creation);
          if (data.length !== 0) {
            this.ticketsSupport = data?.aaData.filter((element) => element.statut_libelle[0] !== null);
            this.totalLength = data?.iTotalDisplayRecords;
            this.calculate_nb_pages(data?.iTotalDisplayRecords);
            this.hideloader();
            this.ticketsSupport.forEach((element) => {
              const dateticket = UniformiserDateToLocal(element.ticket_date_creation);

              element.ticket_date_creation = dateticket.toString();
            });
          } else {
            this.ticketsSupport = [];

            this.calculate_nb_pages(data?.iTotalDisplayRecords);

            this.hideloader();
          }
          this.listStatutWebService.push(unsubscribeGetListTickets);
        },
        () => {
          this.hideloader();
        }
      );
  }

  getDataValue() {
    localStorage.setItem('search', this.search);
    this.pages = 1;
    this.filtres_tickets = new FilterTicket();
    if (this.search.length > 0) {
      this.filterSsearch = true;

      this.getListTicketsSupport('search');
    } else if (this.search.length === 0) {
      this.getListTicketsSupport('init');
    }
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getListTicketsSupport('');
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListTickets() {
    this.pages = 1;
    this.getListTicketsSupport('');
  }

  changepage(number: number) {
    localStorage.setItem('pages', number.toString());
    this.pages = number;
    this.getListTicketsSupport('');
  }

  setSortField(sort_field: string) {
    if (this.sort_field === sort_field) {
      if (this.sort_type === 'desc') {
        this.sort_type = 'asc';
      } else {
        this.sort_type = 'desc';
      }
    } else {
      this.sort_type = 'asc';
    }
    this.sort_field = sort_field;
    localStorage.setItem('sort_field', this.sort_field);
    localStorage.setItem('sort_type', this.sort_type);
    this.getListTicketsSupport('');
  }

  getCountTicketsByCategorie() {
    const unsubscribeGetCountTicketsByCategorie = this.apiTickets
      .GetCountTicketsByCategorie()
      .subscribe((data: CountTicketsByCategorie) => {
        this.categorieTickets = data;
      });
    this.listStatutWebService.push(unsubscribeGetCountTicketsByCategorie);
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getListTicketsSupport('');
      }
    }
  }

  setStatusFilter(status_filter: string) {
    localStorage.removeItem('pages');
    localStorage.setItem('status_filter', this.sort_field);
    this.filtres_tickets = new FilterTicket();
    this.pages = 1;
    this.filterSsearch = true;
    if (this.filtres_tickets.ticket.recheche_status.indexOf(status_filter) !== -1) {
      this.hideloader();
      return;
    } else {
      if (status_filter !== '') {
        this.filtres_tickets.ticket.recheche_status = [];
        this.filtres_tickets.ticket.recheche_status.push(status_filter);
      } else {
        this.filtres_tickets.ticket.recheche_status = [];
      }
    }
    this.getListTicketsSupport('filterStatus');
  }

  NavigateToAddTicket() {
    this.router.navigate(['/ticketsandsupport/add']);
  }

  NavigateToTicketDetails(id: string) {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('filter') !== null && queryParams.get('collection') !== null) {
        localStorage.setItem('savedFilter', queryParams.get('filter'));
        localStorage.setItem('savedCollection', queryParams.get('collection'));
      }
    });
    this.isEdit = true;
    this.router.navigate(['/ticketsandsupport/details/' + id]);
  }

  NaviagateToOpportuniteDetials(id: string) {
    this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  NaviagateToAffaireDetials(id: string) {
    this.router.navigate(['/affaires/details/' + id]);
  }

  EmitFilterTickets($event: FilterTicket) {
    this.filtres_tickets = $event;
  }

  convertUTCDateToLocalDate(date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  actionGroupeTicket() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'my-class ',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };
    const modeldoc = this.modalService.open(ActionGroupeeTicketsComponent, options);
    modeldoc.componentInstance.listeTickets = this.checkedList;
    modeldoc.result.then(
      (res) => {},
      (dismiss) => {
        if (dismiss !== '' && dismiss !== undefined) {
          if (dismiss !== 'error') {
            this.alertSuccesss(dismiss);
          } else {
            this.alertError(this.buttons.permissiondenied);
          }
        }
      }
    );
  }

  expandFileName(name: string): string {
    if (name) {
      if (name.length < 12) {
        return name;
      } else {
        return name.substring(0, 20) + '...';
      }
    }
  }

  checklist: any[] = [];
  checkedList: any;
  masterSelected: boolean = false;
  checkUncheckAll() {
    for (let i = 0; i < this.ticketsSupport.length; i++) {
      this.ticketsSupport[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.ticketsSupport.every(function (item: any) {
      return item.isSelected === true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.ticketsSupport?.length; i++) {
      if (this.ticketsSupport[i].isSelected) this.checkedList.push(this.ticketsSupport[i].id_ticket);
    }
    if (this.checkedList.length > 0) {
      this.actionButton = true;
    } else {
      this.actionButton = false;
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  alertSuccesss(data) {
    const total = data.nb_modified_status + data.nb_modified_affect;

    swal
      .fire({
        icon: 'info',
        html:
          ` <div class='row justify-content-center'>  <div class=' col-8 card-info-1 mb-3'><p class='p-info-2' style=' padding-top: 13px;font-size: 14px; text-align: center; '>
          ${data.nb_total}  ${this.alerts.selectedElements} <br> 
          ${total}
          ${this.alerts.Modifications_effectuees}: </p></div></div> <div class='row justify-content-center'><div class='col-6'>  <div class='card-info-3 d-flex align-items-center justify-content-center' style='  width: 100%; height: 90px;border-radius: 10px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_13T09_16_51_979Z.png' width='20' height='20'> <br>
          ${data.nb_modified_status}
          ${this.alerts.StatusModified}</p><p class='p-info-1' style='font-size: 15px;text-align: center;  font-weight: bold;'>
          </p></div></div><div class='col-6'><div class='card-info-3 d-flex align-items-center justify-content-center'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '><img src='assets/icons/image_2023_01_12T14_48_35_211Z.png' width='20' height='20'> <br>
          ${data.nb_modified_affect}
          ${this.alerts.peopleAffectedTo}</p></div></div> </div>`,
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.buttons.fermer,
      })
      .then((result) => {
        this.hideloader();
        this.masterSelected = false;
        this.getListTicketsSupport('');
        this.getCountTicketsByCategorie();
        for (let i = 0; i < this.ticketsSupport.length; i++) {
          this.ticketsSupport[i].isSelected = false;
          this.actionButton = false;
        }
      });
  }

  alertError(data) {
    swal.fire({
      title:this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }
}
