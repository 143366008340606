<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (change)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_nom_prenom" matInput
                    placeholder="{{'languages.opportunite.label_nomandprenom' | translate}}" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <!-- pattern="[0-9]{9}" -->
                <input (change)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_tel" matInput
                    placeholder="{{'languages.prospect.phone' | translate}}" type="number" #phone="ngModel"  name="phone">
            </mat-form-field>
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="message-erreur">
                <div *ngIf="phone.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>{{'languages.opportunite.check_phone9' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (change)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_mail" matInput
                    placeholder="{{'languages.prospect.email' | translate}}" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="mail"
                    #mail="ngModel">
            </mat-form-field>
            <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">
                <div *ngIf="mail.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>{{'languages.opportunite.check_invalidformat' | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (change)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_ville" matInput
                    placeholder="{{'languages.opportunite.label_villeCp' | translate}}" type="text">
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (change)=" EmitFilterProspect()" [(ngModel)]="filterProspect.RS" matInput
                    placeholder="{{ 'languages.prospect.RaisonSociale' | translate}}" type="text">
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>Zone</mat-label>
                    <ng-container class="p-3" *ngIf="allZones?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Zone>
                        <mat-chip *ngFor="let zone of ListZones" [selectable]="selectable" [removable]="removable"
                            (removed)="removeZones(zone)" (removed)=" EmitFilterProspect()">
                            {{zone}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="{{'languages.opportunite.label_zone_select' | translate}}"  #ZonesInput
                            [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
                        <mat-option *ngFor="let zone of filteredZones | async" [value]="zone.id">
                            {{zone.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'languages.opportunite.Mot_cle' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #tagsContient>
                        <mat-chip *ngFor="let tags of ListTagsContient" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsContient(tags)"
                            (removed)=" EmitFilterProspect()">
                            {{tags}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>

                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="{{'languages.opportunite.selectTagsContient' | translate}}"
                            #TagsContientInput [formControl]="tagsContientFromCtrl" [matAutocomplete]="autoTagsContient"
                            [matChipInputFor]="tagsContient" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsContient="matAutocomplete"
                        (optionSelected)="selectedTagsContient($event)">
                        <mat-option *ngFor="let tags of filteredTagsContient | async" [value]="tags.id">
                            {{tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<!-- <div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'opportunities.label.tagsnotcontain' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #tagsContientPas>
                        <mat-chip *ngFor="let tags of ListTagsContientPas" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsContientPas(tags)"
                            (removed)=" EmitFilterProspect()">
                            {{tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="{{'languages.opportunite.selectTagsContientPas' | translate}}"
                            #TagsContientPasInput [formControl]="tagsContientPasFromCtrl"
                            [matAutocomplete]="autoTagsContientPas" [matChipInputFor]="tagsContientPas"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsContientPas="matAutocomplete"
                        (optionSelected)="selectedTagsContientPas($event)">
                        <mat-option *ngFor="let tags of filteredTagsContientPas | async" [value]="tags.id">
                            {{tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>

</div> -->
