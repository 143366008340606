import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  static formatValue(inputValue: string, currency: string) {
    if (!inputValue) return inputValue;
    // Parse the input value to a number
    const numericValue = parseFloat(inputValue);

    // Check if the numeric value is valid
    if (isNaN(numericValue)) return inputValue;

    // Get currency display information for the given currency
    const currencyDisplayInfo = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).resolvedOptions();

    // Get the minimum fraction digits from the currency display information
    const minimumFractionDigits = currencyDisplayInfo?.minimumFractionDigits;
    const maximumFractionDigits = currencyDisplayInfo?.maximumFractionDigits;
    // Format the numeric value based on the currency and minimumFractionDigits
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }).format(numericValue);

    // Remove commas from the formatted value
    return formattedValue.replace(/,/g, '');
  }
}
