<legend *ngIf="to.label">{{ to.label }}</legend>
<p *ngIf="to.description">{{ to.description }}</p>
<div class="alert alert-danger" role="alert" *ngIf="showError && formControl?.errors">
  <formly-validation-message [field]="field"></formly-validation-message>
</div>
<div class="row">
  <ng-container *ngFor="let f of field.fieldGroup">
    <ng-container *ngIf="f.type!='array'&& f.type!='null'">
      <div class="col-{{f.templateOptions.col}}"   >
        <formly-field  [field]="f"></formly-field>
      </div>
    </ng-container>
    <ng-container *ngIf="f.type=='array'&& f.type!='null'">
      <div class="col-12 sub-object-group">
        <formly-field  [field]="f"></formly-field>
      </div>
    </ng-container>
  </ng-container>

</div>
