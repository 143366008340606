import {FormField} from './FormField';


export class TimeFormly extends FormField {
  pattern=""
  autofill="";
  urlValidation=""
  urlIdentifiant=""
   default=""
  constructor() {
    super();
    super.icon="fa fa-clock";
    super.name = 'temps_' + super.generateRandomId();
    super.type = 'time';
    super.title="temps"
    super.typename = 'Time';
  }
}
