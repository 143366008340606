import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  build:string =environment.build
  year=new Date().getFullYear()
  version:string =environment.version
  constructor() { }
  ngOnInit(): void {
  }
  
  formatVersion(version: string): string {
    const lastCharacter = version.charAt(version.length - 1);
    if (lastCharacter === '0') {
      return version;
    } else {
      const versionParts = version.split(".");
      if (versionParts.length <= 2) {
        return version;
      } else {
        const majorMinor = version.substring(0, version.lastIndexOf('.'));
        const patch = version.substring(version.lastIndexOf('.') + 1);
        return `${majorMinor}-Fix${patch}`;
      }
    }
  }

}
