<div class="row">
    <div class="col card bg-white border-0 p-3 customMargin">
        <div class="col">
            <div class="card-doc-title ml-3 row">
                <i class="fa-solid fa-circle-info mr-2  mt-2 fa-lg"></i>
                  {{"languages.opportunite.details.infoSpecific" | translate}}
            </div>
            <div class="row p-3">
                <div class="col-12">
                    <ng-container *ngIf="fields.length>0  && !loading">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-12">
                                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (modelChange)="fromChange()" ></formly-form>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="fields.length<1  && loading">
                        <p>  {{"languages.opportunite.details.Noinformations" | translate}}</p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
