import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';

import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-tache',
  templateUrl: './tache.component.html',
  styleUrls: ['./tache.component.css'],
})
export class TacheComponent implements OnInit, AfterViewInit {
  opportunity_id: string = '';
  allowed_to_update: any;
  navbarTxt:string="";
  constructor(
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private apiOpportunitService: ApiOpportunitService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate : TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.Tasks
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.navbar.Tasks
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
     });
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
  }
}
