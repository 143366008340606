import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
 
import { HistoriqueTransactions } from '../../entity/Generic/HistoriqueTransactions';
import { ApiLedgerService } from 'src/app/services/ApiLedger/api-ledger.service';
import * as $ from 'jquery'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-historique-transaction',
  templateUrl: './historique-transaction.component.html',
  styleUrls: ['./historique-transaction.component.css']
})
export class HistoriqueTransactionComponent implements OnInit {

  dataTableCompany: any;
   
  historique_utilisateur: HistoriqueTransactions[] = [];
  historique_company: HistoriqueTransactions[] = [];
  dataTableUser: any;
  miniListing: any;

  constructor(private sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private ApiLedgerService: ApiLedgerService,
    private  translate : TranslateService
    ) { }

  ngOnInit(): void {
    this.showloader()
    this.sharedMenuObserverService.updateMenu("Historiques des transactions")

    this.translate.get('languages').subscribe((object: any) => {
      this.miniListing = object.miniListing;
      this.getHistoriqueTransactionUtilisateur()
      this.getHistoriqueTransactionCompany()
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.miniListing = object.miniListing;
        this.getHistoriqueTransactionUtilisateur()
        this.getHistoriqueTransactionCompany()
      });
  });

  }

  getHistoriqueTransactionUtilisateur() {
    this.ApiLedgerService.getHistoriqueTransactionUtilisateur()
      .subscribe((data: HistoriqueTransactions[]) => {
        this.historique_utilisateur = data
        this.hideloader()
        this.chRef.detectChanges();
        this.dataTableUser?.destroy()
        const table: any = $('#TableUtilisateur');
        this.dataTableUser = table.DataTable({
          stateSave: true,
          language: {
            sProcessing: this.miniListing.traitement,
            searchPlaceholder: this.miniListing.search,
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
            sInfo:
              this.miniListing.displayingElement +
              '_START_' +
              this.miniListing.label_a +
              '_END_' +
              this.miniListing.label_sur +
              '_TOTAL_' +
              this.miniListing.element,
            sInfoEmpty: this.miniListing.sInfoEmpty,
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: this.miniListing.chargement,
            sZeroRecords: this.miniListing.noRecords,
            sEmptyTable: this.miniListing.emptyTable,
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: this.miniListing.sSortAscending,
              sSortDescending: this.miniListing.sSortDescending,
            },
            select: {
              rows: {
                _: '%d' + this.miniListing.selectedLines,
                0: this.miniListing.zeroselected,
                1: this.miniListing.oneselected,
              },
            },
          },
        });
      })
  }

  getHistoriqueTransactionCompany() {
    this.ApiLedgerService.getHistoriqueTransactionCompany()
      .subscribe((data: HistoriqueTransactions[]) => {
        this.historique_company = data
        this.chRef.detectChanges();
        this.dataTableCompany?.destroy()
        const table: any = $('#TableEntreprise');
        this.hideloader()
        this.dataTableCompany = table.DataTable({
          stateSave: true,
          language: {
            sProcessing: this.miniListing.traitement,
            searchPlaceholder: this.miniListing.search,
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
            sInfo:
              this.miniListing.displayingElement +
              '_START_' +
              this.miniListing.label_a +
              '_END_' +
              this.miniListing.label_sur +
              '_TOTAL_' +
              this.miniListing.element,
            sInfoEmpty: this.miniListing.sInfoEmpty,
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: this.miniListing.chargement,
            sZeroRecords: this.miniListing.noRecords,
            sEmptyTable: this.miniListing.emptyTable,
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: this.miniListing.sSortAscending,
              sSortDescending: this.miniListing.sSortDescending,
            },
            select: {
              rows: {
                _: '%d' + this.miniListing.selectedLines,
                0: this.miniListing.zeroselected,
                1: this.miniListing.oneselected,
              },
            },
          }
        });
      })
  }

  hideloader() {
    document.getElementById('loadingBar')
      .style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar')
      .style.display = 'block';
  }
}
