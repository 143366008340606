export class Status {
  color: string = '';
  icon: string = '';
  id: string = '';
  libelle: string = '';
  background_color: string = '';

}
export class InformationTicket {
  affect: string = '';
  affecte_a: string = '';
  categorie: string = '';
  createur: string = '';
  date_creation: any;
  date_fermeture: string = '';
  delai_traitement: number;
  date_limit: string = '';
  date_traitement: string = '';
  prospect: string = '';
  statut_details: Status;
  sujet: string = '';
  id_prospect: string = '';
  id_ticket: string = '';
  id_ticket_md5: string = '';
  id_affaire: string = '';
  real_id_opp: any ;
  id_opp : any;
  real_id_affaire: string = '';
  comm_ticket: string = '';
  commentaire = '';
  form;
  claim : any =0;
  id_ticket_parent: string = '';
 
  constructor(init?: Partial<InformationTicket>) {
    Object.assign(this, init);
  }
}
export class form {
  data: any;
}

export class priorite {
  id: string = '';
  libelle: string = '';
  icon: string = '';
  color: string = '';
}

export class TicketNotification {
  titre: string = '';
  url: string = '';
  msg: string = '';
  date_notification: string = '';
  heure_notification: string = '';
  type: string = '';
  date_create_api: string = '';
  id: string = '';
  nb_view: string = '';
  semaine_notification: string = '';
  status: Number = null;
  user: string = '';
  user_full_name: string = '';
  _Notifications__user_name: string = '';
}


export class Statut {
  libelle: string = '';
  type: string = '';
  validation: string = '';
}
export class Frais {
  montant: any = '';
  note: string = '';
  id_mode_paiement: string = '';
  id_frais: string = '';

  statut:string =""
  type: string = '';
  taxe: any = '';
}
