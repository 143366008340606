<div class="card-headd text-center  ">
    <div class="row details-header">
        <div class="col-1 d-flex align-items-center">
        <button type="button" class="btn-dt-retourr mt-1" (click)="returnToList()">
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>

            </div>
        </button>
    </div>
    <div class="col-2 customMargin" *ngIf="informationsContrat?.solde?.date "> 
         <div class="montant p-2" >
        <img src="assets/icons/montantIcon.svg" >
        <div class="d-flex flex-column" *ngIf="!loaderMontant">
            <div class="d-flex montantNum">
                <p class="m-0">{{informationsContrat?.solde?.montant |currency:currency:'symbol':formatCurrency:'fr-FR'}}</p>
                <!-- <p class="fractional m-0 mt-1 ">{{  fractionalPart}}</p> -->
                <!-- <p class="fractional m-0"> {{currentCurrency}}</p> -->
            </div>
          
            <p class="montantDate m-0">
                {{extractDate(informationsContrat?.solde?.date)}}
            </p>

        </div>
        <ng-container *ngIf="loaderMontant">
            <div class="">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="15"></mat-spinner>

                </div>
            </div>
        </ng-container>
                </div>
               
            </div>
      <p></p>
        <div class="col-9 d-flex justify-content-end align-items-center pr-0">
            
            <div class=" row d-flex  align-items-center">
                <button type="button" class="btn-load mt-0 mr-2" id="clear" (click)="Actualise()">
                    <i class="fa-solid fa-rotate-right"></i>
                </button>
                <button type="button" [ngClass]="{'cover': !allowed_to_update_affaire}" class="Avenant-btn"
                (click)="openModalOrPopup()" [disabled]="!acces_avenant || !informationsContrat?.autorisation_avenant" data-toggle="modal" data-target="#myModal"
                [ngStyle]="{'opacity':!acces_avenant || !informationsContrat?.autorisation_avenant ? 0.4 : 1}"
                [matTooltip]="informationsContrat?.contrat?.num_contrat ? ('languages.affaire.AllowcreateAvenant' | translate) : ('languages.affaire.fillContractNum' | translate)" 
        matTooltipPosition="above"
        [matTooltipDisabled]="!(acces_avenant && !informationsContrat?.autorisation_avenant)">
        
<i class="fa-solid fa-square-plus fa-xl"></i>&nbsp;
                <span>{{'languages.affaire.createAvenant' | translate}}</span>
            </button>

            <app-shared-button-enrg [type]="'A'" data-toggle="modal" [allowed_to_update]="allowed_to_update_affaire"
                data-target="#Actions" (click)="getStatusAutorise()" class="ml-2"
                [label]="Enregistrer"></app-shared-button-enrg>
            
                  
           
            </div>


           
        </div>
    </div>

</div>


<div class="row custem-margin">
    <div class="col-4 w-100">
        <div class="card-head  w-100 float-left">
            <div class="row">
               
                <div class="col-12 p-0">
                    <div class="row ">
                        <div class="col-12 text-nowrap ">
                            {{'languages.affaire.nContrat' | translate}}: 
                            <label
                                class="label-card-info " [title]="numContrat"> {{expandNumContrat(numContrat)}}  
                            </label>
                        </div>
                        <div class="col-12 text-nowrap ">
                           
                            {{'languages.opportunite.creationDate' | translate}}: 
                            <label class="label-card-info">
                               {{informationsContrat?.contrat?.date_creation ? transformDateCreation:
                                "---"}} 
                             
                            </label>
                        </div>
                        <div class="col-12 text-nowrap ">
                            
                            {{'languages.affaire.label_dateeffet' | translate}}: 
                            <label class="label-card-info">
                               {{informationsContrat?.contrat?.date_deff ? transformDateDeff:
                                "---"}} 
                             
                            </label>
                        </div>
                        <div class="col-12 text-nowrap ">
                            {{'languages.opportunite.opportuniteNum' | translate}}:
                            <label class="label-card-info">
                                <a class="a-style row cursor-pointer" target="_blank"
                                    (click)="NaviagateToOppoDetials(informationsContrat?.contrat.id_opp_md5)">
                                    {{informationsContrat?.contrat.id_opp}}
                                </a>
                            </label>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>


    <div class="col-4 w-100">
        <div class="card-head w-100">
            <div class="row justify-content-center">
                <!--       <div class="col-12" *ngIf="societe?.type_opportunite !== 'entrepise'">
                    <label class="label-card-info"></label>
                </div> -->
                <div class="text-nowrap col-12" *ngIf="informationsContrat?.contrat?.nom_organisme">
                    {{'languages.campagne.Organisme' | translate}} :
                    <label class="label-card-info">
                        {{informationsContrat?.contrat?.nom_organisme}}
                    </label>
                </div>
                <div class="text-nowrap col-12">
                    {{'languages.opportunite.createdBy' | translate}} :
                    <label class="label-card-info">
                        {{informationsContrat?.contrat?.nom_commerciale}}
                    </label>
                </div>

                <div class="text-nowrap col-12">
                    {{'languages.alerts.affectedTo' | translate}} :
                    <label class="label-card-info">
                        {{informationsContrat?.contrat?.nom_affect}}
                     
                        <i class="fa-solid fa-user-pen cursor-pointer  mt-1 colorBlue" *ngIf="access_Affectation && allowed_to_update_affaire" data-toggle="modal" data-target="#ModalAffectA" (click)="openModalOrPopupAffect()" ></i>
                    </label>
                </div>

                <div class="text-nowrap col-12" *ngIf="societe?.type_opportunite !== 'entrepise'">
                    {{'languages.opportunite.Client' | translate}}:
                    <label class="label-card-info">
                        {{informationsContrat?.contrat?.nom_pros}}
                    </label>
                </div>

                <div class="text-nowrap col-12" *ngIf="societe?.type_opportunite=== 'entrepise'">
                    {{'languages.opportunite.Societe' | translate}}:
                    <label class="label-card-info">
                        {{societe?.RS}}
                    </label>
                </div>

            </div>
        </div>
    </div>
    <div class="col-4 w-100 ">
        <div class="card-head mr-2 w-100">
            <div class="row">
                <div class="text-nowrap col-12">

                    <div class="col-2 mrgin  ">
                        {{'languages.affaire.label_gamme' | translate}}:
                        <label class="label-card-info" [title]="informationsContrat?.contrat.gamme?.nom">
                            {{expandNumContrat(informationsContrat?.contrat.gamme?.nom)}}

                        </label>

                    </div>
                 



                </div>
                <div class="text-nowrap col-12">
                    <label> {{'languages.opportunite.statusActuel' | translate}}:</label>
                    <label 
                     data-toggle="modal" data-target="#Actions"
                        class="VAD ml-2 mr-2 text-center border-0 cursor-pointer "
                        (click)="showModalUpdateStatut()"
                        [class.disabled]="allowed_to_update_affaire== false"
                        [style.background]="informationsContrat?.contrat?.etat_dossier_background"
                        [style.color]="informationsContrat?.contrat?.etat_dossier_color"
                         >
                        {{expandFileName(informationsContrat?.contrat?.status)}}
                            <i class="fa-solid fa-pen cursor-pointer float-right mt-1 " *ngIf="allowed_to_update_affaire"
                            data-toggle="modal" data-target="#Actions" (click)="showModalUpdateStatut()"></i>
                    </label>
                </div>
                <div class="text-nowrap col-12">
                    <label> {{'languages.affaire.Motif' | translate}}:</label>
                    <label class="label-card-info" *ngIf="!informationsContrat?.contrat?.motif?.libelle">  {{'languages.affaire.noMotif' | translate}}</label>
                    <label 
                    class="VAD ml-2 text-center border-0 cursor-pointer" 
                    data-toggle="modal" data-target="#MotifChange" 
                     (click)="getMotifByStatutActuel()"
                     [class.disabled]="allowed_to_update_affaire== false"
                        *ngIf="informationsContrat?.contrat?.motif?.libelle"
                        [style.background]="informationsContrat?.contrat?.motif?.background"
                        [style.color]="informationsContrat?.contrat?.motif?.color"
                         >
                        {{expandFileName(informationsContrat?.contrat?.motif?.libelle)}}
                        <i class="fa-solid fa-pen cursor-pointer float-right mt-1" data-toggle="modal"
                            data-target="#MotifChange" (click)="getMotifByStatutActuel()"
                            *ngIf="allowed_to_update_affaire "></i>
                    </label>

                </div>
            </div>
        </div>

    </div>
</div>
<div class="row ml-2" *ngIf="informationsContrat.contrat.id ">
    <div class="col" style="max-width: 248px;">
        <div class="sidebar-affaire " id="scrollbar-affaire">
            <div class="card card-affaire bg-white text-center border-0">
                <div class="mt-1">

                    <div class="col py-1">
                        <button (click)="scroll('cord')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-user-tie fa-lg"></i>
                            </label>
                            <span> {{'languages.prospect.adherentSouscipteur' | translate}}</span>
                        </button>
                    </div>

                    <div class="col py-1">
                        <button (click)="scroll('infcont')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-file-contract fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.infoContrat' | translate}}</span>
                        </button>
                    </div>

                    <div class="col py-1">
                        <button (click)="scroll('garantie')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-umbrella fa-lg "></i>
                            </label>
                            <span>{{'languages.affaire.garanties' | translate}}</span>
                        </button>
                    </div>
                    <div *ngIf="bien" class="col py-1">
                        <button (click)="scroll('objetAssurer')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-object-ungroup fa-lg"></i>
                            </label>
                            <span> {{'languages.produit.objetAssure' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('information-bancaire')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-landmark fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.infoBancaire' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1" *ngIf="lecture_transaction_financiere_client">
                        <button (click)="scroll('echeancier')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-file-invoice-dollar fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.echeanciers' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('paimentfrais')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-credit-card fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.fraisAndPaiment' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1 "  *ngIf="lecture_transaction_financiere_client">
                        <button (click)="scroll('transactionFinanciere')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                
                                <i class="fa-solid fa-right-left fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.transactionsFinanciere' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('ListDocuments')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-file-import fa-lg"></i>
                            </label>
                            <span>{{'languages.navbar.OppDetailsDocument' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('LiaisonsContrats')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-retweet fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.relationAffaire' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('Tickets')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-ticket-simple fa-lg"></i>
                            </label>
                            <span>{{'languages.navbar.OppDetailsTickets' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('tache')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-list-check fa-lg"></i>
                            </label>
                            <span> {{'languages.affaire.todoList' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('InfAvancee')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-circle-question fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.infoAvancee' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('comment')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-comment-dots fa-lg"></i>
                            </label>
                            <span>{{'languages.opportunite.details.commentaire_label' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('Communication')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-comments fa-lg"></i>
                            </label>
                            <span>{{'languages.opportunite.label_communication' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1" *ngIf="histAccess">
                        <button (click)="scroll('Historique')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-clock-rotate-left fa-lg"></i>
                            </label>
                            <span> {{'languages.opportunite.details.label_history' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1" *ngIf="histCommAccess">
                        <button (click)="scroll('HistoriqueCommunication')"
                            class=" focus-style full-width d-block border-0 text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-clock-rotate-left fa-lg"></i>
                            </label>
                            <span>{{'languages.opportunite.details.HistoriqueCommunications' | translate}}</span>
                        </button>
                    </div>
                    <div class="col py-1"  *ngIf="access_qualite">
                        <button (click)="scroll('qualite')"
                            class=" focus-style full-width d-block border-0 text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-medal fa-lg"></i>
                            </label>
                            <span>{{'languages.settings.infoBlocQualite' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-left">
                <shared-tags [isReadOnly]="allowed_to_update_affaire" [affaire_id]="affaire_id"></shared-tags>
            </div>
        </div>
    </div>


    <div class="col p-1 overflow-auto" *ngIf="informationsContrat.contrat.id ">
        <div class="row">
            <div class="col-12 p-0">
                <div class="ml-4">
                    <!--      <div class="p-2 mb-2" id="infoAffaire">
                        <app-info-affaire [informationsContrat]="informationsContrat"
                            [societe]="societe"></app-info-affaire>
                    </div> -->
                    <div class="p-2 " id="stat">
                        <app-status [listOfProcesses]="listOfProcess"></app-status>
                    </div>
                    <div class="p-2 " id="cord" *ngIf="informationsContrat.contrat.id_prospect.toString()">
                        <app-cordonnees-client [person]="person" *ngIf="prospect?.mainProspect?.id" [prospectDetails]="prospect"
                            [allowed_to_update_affaire]="informationsContrat.contrat.allowed_to_update"
                            (onUpdateSociete)="onUpdateSociete($event)" (ErroronUpdateSociete)="getError($event)"
                            [affaireId]="affaire_id">
                        </app-cordonnees-client>
                    </div>

                    <div class="p-2 " id="infcont" *ngIf="informationsContrat.contrat.id ">
                        <app-informations-contract [informationsContrat]="informationsContrat"
                            (onUpdateinformationsContrat)="onUpdateContrat($event)"
                            [allowed_to_update_affaire]="allowed_to_update_affaire">
                        </app-informations-contract>
                    </div>
                    <div class="p-2 " id="garantie" [ngClass]="{'cover': !allowed_to_update_affaire}">
                        
                            <div class="scrollbar">
                                <app-garantie [affaire_id]="affaire_id" [reloadGaranties]="reloadGaranties" [allowed_to_update]="allowed_to_update_affaire"
                                    (garantiexport)="onUpdateGaranties($event)"></app-garantie>
                            </div>
                       
                    </div>
                    <div class="p-2 " *ngIf="bien" id="objetAssurer" [ngClass]="{'cover': !allowed_to_update_affaire}">
                        <div class="card bg-white border-0 p-3">



                            <div class="scrollbar ">
                                <app-objet-assurer-aff [affaire_id]="affaire_id" [opportunity_id]="id_opportunite"
                                    [bien]="bien"
                                    (updateObjetAsuure)="updateobjetAssure($event)"></app-objet-assurer-aff>
                            </div>


                        </div>
                    </div>
                    <div class="p-2  ml-1" id="information-bancaire" *ngIf="informationsContrat">
                        <div class="scrollbar mb-1" *ngIf="informationsContrat.contrat.id">
                            <shared-information-bank [customStyle]="'true'" [typeEntity]="'affaire'"
                                [allowed_to_update_affaire]="allowed_to_update_affaire"
                                [bankInformation]="informationsContrat.info_bancaire"
                                (onChangesdetect)=" changedetectInfo($event)"
                                (onChangeBankInformation)="getBankInformation($event)"
                                [idAffaire]="affaire_id"></shared-information-bank>
                        </div>
                    </div>
                </div>

                <div class="p-2  ml-4" id=echeancier *ngIf="lecture_transaction_financiere_client && informationsContrat.contrat.id ">
                    <app-echeancier [transaction_financiere_client]="ecriture_transaction_financiere_client"
                        [allowed_to_update_affaire]="allowed_to_update_affaire"
                        (echeanciersexport)="getlistecheancier($event)" (refreshContrat)="reloadContrat($event)" [balance]="informationsContrat?.solde?.montant"></app-echeancier>
                </div>
                <div class="p-2  ml-4" id=paimentfrais>
                    <app-paiment-frais [allowed_to_update_affaire]="allowed_to_update_affaire" *ngIf="informationsContrat.contrat.id"
                        (paimentfraisexport)="getlistpaimentfrais($event)" [idAffaire]="affaire_id" (soldeAffaire)="getInformationsContrat()" ></app-paiment-frais>
                </div>
                <div class="p-2  ml-4" id=transactionFinanciere  *ngIf="lecture_transaction_financiere_client"> 
                    <app-transaction-financiere #transactionFinance [solde]="informationsContrat?.solde?.montant" [allowed_to_update_affaire]="allowed_to_update_affaire" (soldeAffaire)="getInformationsContrat()"
                       ></app-transaction-financiere>
                </div>
                <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != '' && prospect_id.trim() != ''"
                    class="p-2 ml-4" id="ListDocuments">
                    <app-list-documants [allowed_to_update_affaire]="allowed_to_update_affaire"
                        (listDocumentsOpporExport)="getListDocOpp($event)" (listdocumentexport)="getListDoc($event)"
                        [affaire_id]="affaire_id" [id_opportunite]="id_opportunite" [prospect_id]="prospect_id"
                        [contrat_id]="contrat_id"></app-list-documants>
                </div>
                <div class="p-2  ml-4" id="LiaisonsContrats" *ngIf="informationsContrat != null ">
                    <app-liaisons-contracts [allowed_to_update_affaire]="allowed_to_update_affaire"
                        [informationsContrat]="informationsContrat"
                        (listContratEnLiasonExport)="getlistContratenliason($event)"></app-liaisons-contracts>
                </div>
                <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != ''" class="p-2  ml-4" id="Tickets">
                    <app-tickets [allowed_to_update_affaire]="allowed_to_update_affaire"
                        (listticketsexport)="getlistticket($event)" [affaire_id]="affaire_id"
                        [id_opportunite]="id_opportunite" [prospect_id]="prospect_id">
                    </app-tickets>
                </div>
                <div class="p-2  ml-4" id="tache">
                    <app-tache [allowed_to_update_affaire]="allowed_to_update_affaire"  ></app-tache>
                </div>
                <div class="p-2  ml-4" id="InfAvancee" *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != ''">
                    <app-information-avancees [allowed_to_update_affaire]="allowed_to_update_affaire"
                        (infoComplementaireexport)="getlistComp($event)" (infoSpecifiqueexport)="getlistSpecif($event)"
                        (onUpdateinformationsComp)="submitInfoCompl($event)"
                        (onUpdateinformationsSpecifique)="submitInfoSpecifique($event)" [affaire_id]="affaire_id"
                        [id_opportunite]="id_opportunite">
                    </app-information-avancees>
                </div>
                <div class="p-2 ml-4" id="comment">

                    <app-add-commentaire (commentaireExport)="getlistcommentaireexport($event)"
                        [allowed_to_update]="allowed_to_update_affaire" [fromType]="'A'"></app-add-commentaire>
                </div>
                <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != '' " class="p-2  ml-4"
                    id="Communication">

                    <app-add-communication [allowed_to_update_affaire]="allowed_to_update_affaire"
                        [affaire_id]="affaire_id" [id_opportunite]="id_opportunite" [listParent]="listParent"
                        [fromtype]="'A'" [listTypes]="listTypes" [type_envoie]='type_envoie'
                        [statut_envoi]="statut_envoi" [statut]="statut" [prospect]="prospect" [telNumber]="telNumber">
                    </app-add-communication>

                </div>



                <div class="p-2  ml-4" id="Historique" *ngIf=" histAccess">
                    <app-historique [allowed_to_update_affaire]="allowed_to_update_affaire"></app-historique>
                </div>
                <div class="p-2  ml-4" id="HistoriqueCommunication" *ngIf=" histCommAccess">
                    <app-historiques-communication 
                        [allowed_to_update_affaire]="allowed_to_update_affaire"></app-historiques-communication>
                </div>

                <div class="p-2 mb-4 ml-4" id="qualite"  *ngIf="access_qualite">
                    <app-bloc-qualite [affaire_id]="affaire_id"
                     (dataformQuality)="getQualityData($event)" 
                     (detectchangeQualite)="detectChangeQuality($event)"
                     [allowed_to_update_affaire]="allowed_to_update_affaire"
                       ></app-bloc-qualite>
                </div>
            </div>
        </div>
    </div>

</div>




<div *ngIf="appearModel">
    <div class="modal fade" id="Actions" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  actions-dialog" role="document">
            <div class="modal-content modal-first w-100">
                <div class="modal-header text-center">
                    <div class="row w-100">
                        <div class="col-3">

                        </div>
                        <div class="col-6">
                            <p class="text-center justify-content-center m-0 align-items-center title">
                                {{'languages.affaire.updateAffaire' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="resetStatus()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body ">
                    <div class="row text-center justify-content-center" *ngIf="appear">
                        <div class="row">
                            <p class="custemPara">
                                {{'languages.affaire.infirmSave' | translate}} </p>
                        </div>
                        <div class="row d-flex justify-content-center mt-2 mb-3 ">
                            <div class="col-6">
                                <button type="button" class="btn-dt-save-popup sizeC mr-3"
                                    [allowed_to_update]="allowed_to_update_affaire" (click)="updateAffaire()"
                                    data-dismiss="modal" aria-label="Close">
<i class="fa-solid fa-circle-check mr-2 fa-lg fa-icon-style"></i>&nbsp;
                                    {{'languages.affaire.saveQuit' | translate}}
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="changeStatus " (click)="statutappear()">
                                    <span class="mb-1">{{'languages.affaire.changeStatut' | translate}}</span>
                                    <i class="fa-solid fa-arrow-right fa-icon-style   ml-3 fa-lg"></i>
                                </button>
                            </div>
                         


                        </div>
                    </div>
                    <div class="row text-center justify-content-center paddingBlock" *ngIf="!appear && !step">
                        <ng-container *ngIf="show_loading_status">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <div
                                        style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row text-center justify-content-center w-100" *ngIf="!show_loading_status">
                            <ng-container *ngFor="let item of status_autorise.listCategory">
                                <div class="col w-100 paddingBlock scrollAp customHeight">
                                    <div class="row w-100">
                                        <div class="col-12 style-div title text-center">
                                            {{'languages.listing.Service' | translate}} : <b class="title-custem">{{item.label}}</b>
                                        </div>
                                        <div class="col-12 w-100">
                                            <div class="row w-100">
                                                <ng-container *ngFor="let status of item.listactions">
                                                    <div class="col-4 text-center style-div">
                                                        <button
                                                            [disabled]="(status.dependance!==status_id && status.dependance!==null)|| status.access_sup == false "
                                                            [ngStyle]="{'opacity':(status.dependance!==status_id && status.dependance!==null)|| status.access_sup == false ? 0.4 : none}"
                                                            (click)="updateSubActionsList(status?.id); appearS()"
                                                            class="btn-affect border-0"
                                                            [style]="{'background-color': status?.color, 'color': status?.couleur_police}">

                                                            <p>{{status?.etat}}  <span
                                                                    *ngIf="status.etat == prospect.etat"
                                                                    style="color: darkred">*</span>
                                                            </p>
                                                            
                                                        </button>
                                                    </div>
                                                </ng-container>

                                            </div>
                                            <div class="row w-100 mt-3 justify-content-center" *ngIf="!appear">
                                                <button (click)="resetStatus()" type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                                    aria-label="Close">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                                                        {{'languages.buttons.annuler' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="step||!appear">
                        <div class="   justify-content-center align-items-center">
                            <ng-container *ngIf="show_loading">
                                <div class="row mb-3 mt-3">
                                    <div class="text-center m-auto ">
                                        <mat-spinner [diameter]="50"></mat-spinner>

                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!show_loading">
                                <div class="row scrollAp" *ngIf="subStatusList.length>0 && step">
                                    <div class=" col-4 text-center style-div" *ngFor="let status of subStatusList">
                                        <button class="btn-affect border-0 w-100"
                                        [disabled]="(status.dependance!==status_id && status.dependance!==null)|| status.access_sup == false "
                                        [ngStyle]="{'opacity':(status.dependance!==status_id && status.dependance!==null)|| status.access_sup== false ? 0.4 : none}"
                                        (click)="updateSubActionsList(status?.id)" class="btn-affect"
                                            [style]="{'background-color': status.color, 'color': status.couleur_police}">

                                            <p>{{status?.etat}}  <span *ngIf="status.etat == prospect.etat"
                                                    style="color: darkred">*</span></p>
                                        </button>
                                    </div>
                                    <div class="col-12 mt-4 d-flex justify-content-center">
                                        <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                            aria-label="Close" (click)="resetStatus()">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                                            {{'languages.buttons.annuler' | translate}}
                                        </button>
                                    </div>
                                </div>
                                <div class="row scrollAp customHeight" *ngIf="liste_motif.length >0 && !show_massage">
                                    <div class="col-12">
                                        <h2 class="text-center m-auto custemParaa">
                                             {{'languages.affaire.changeMotif' | translate}}
                                        </h2>
                                    </div>
                                    <div class=" col-4 text-center style-div" *ngFor="let motif of liste_motif">
                                        <button class="btn-affect border-0 w-100" (click)="getMotif(motif?.id)"
                                            class="btn-affect"
                                            [disabled]="!motif.active"
                                            [class.disabled]="!motif.active"
                                            [style]="{'background-color': motif.background, 'color': motif.color}">
                                            <p>{{motif?.libelle}} </p>
                                        </button>
                                    </div>
                                    <div class="col-12 mt-4 d-flex justify-content-center">
                                        <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                            aria-label="Close" (click)="resetStatus()">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                                            {{'languages.buttons.annuler' | translate}}  
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-4 " *ngIf="subStatusList.length == 0 && step && show_massage ">
                                    <div class="col-12 text-center">
                                        <h2 class="text-center m-auto custemPara">
                                            {{'languages.affaire.confirmUpdateStatut' | translate}}
                                        </h2>
                                    </div>
                                    <div class="col-12 w-100 justify-content-center d-flex">
                                        <div class="row mt-2  justify-content-center d-flex">
                                            <div class="col-6 col-6 d-flex justify-content-end">
                                                <button (click)="toggleupdate()" class="btn-dt-save-popup mr-3">
                                                    <i class="fa-solid fa-circle-check mr-1 fa-lg"></i>
                                                    {{'languages.affaire.Oui' | translate}}
                                                </button>
                                            </div>
                                            <div class="col-6 d-flex justify-content-start">
                                                <button type="reset" class="btn-dt-cancel-popup " data-dismiss="modal"
                                                    (click)="resetStatus()">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                                                        {{'languages.affaire.Non' | translate}}
                                                </button>
                                            </div>



                                        </div>
                                    </div>
                                </div>




                            </ng-container>

                        </div>


                        <!--   <div class="row mt-3" *ngIf="subStatusList.length>0  && !show_massage">
                            <div class="col-12">
                                <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal" aria-label="Close"
                                    (click)="resetStatus()">
                                    <fa-icon class="fa-icon-style" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                                    Annuler
                                </button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="modal fade" id="subActionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog model-lg modal-dialog-centered" role="document">
        <div class="modal-content modal-sec w-100">
            <div class="modal-header text-center">
                <div class="row w-100">
                    <div class="col-3"></div>
                    <div class="col">
                        <h2 class="text-center justify-content-center w-100" id="exampleModalLongTitle">
                            {{'languages.affaire.updateAffaire' | translate}}  
                        </h2>
                    </div>
                </div>
                <div class="row w-100">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="modal-bodyy paddingBlock ">
                <ng-container *ngIf="show_loading">
                    <div class="row mb-3 mt-3">
                        <div class="text-center m-auto ">
                            <mat-spinner [diameter]="50"></mat-spinner>

                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!show_loading" class="">
                    <div class="row " *ngIf="subStatusList.length>0">
                        <ng-container *ngFor="let status of subStatusList">
                            <div class="col-6 text-center style-div ">
                                <button class="btn-affect border-0 "
                                    [ngStyle]="{'opacity':(status.dependance!==status_id && status.dependance!==null) || status.access_sup==false ? 0.4 : none}"
                                    [disabled]="status.etat == prospect.etat || (status.dependance!==status_id && status.dependance!==null)|| status.access_sup==false"
                                    (click)="updateSubActionsList(status?.id)" class="btn-affect"
                                    [style]="{'background-color': status.color, 'color': status.couleur_police}">

                                    <p>{{status?.etat}} <span *ngIf="status.etat == prospect.etat"
                                            style="color: darkred">*</span></p>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" *ngIf="subStatusList.length==0 && liste_motif.length == 0 ">
                        <h2 class="text-center m-auto custemPara">
                              {{'languages.affaire.confirmUpdateStatut' | translate}}
                        </h2>
                    </div>
                    <div *ngIf="subStatusList.length == 0 && liste_motif.length == 0"
                        class="d-flex justify-content-center text-center">
                        <button (click)="changeAction()" class="btn-dt-save-popup " data-dismiss="modal"
                            aria-label="Close">
<i class="fa-solid fa-circle-check mr-1 fa-lg "></i>
                            {{'languages.affaire.Oui' | translate}}
                        </button>
                        <button type="reset" class="btn-dt-cancel-popup " data-dismiss="modal">
                            <i class="fa-solid fa-circle-xmark mr-1 fa-lg fa-icon-style"></i>
                            {{'languages.affaire.Non' | translate}}
                        </button>
                    </div>
                    <div class="row" *ngIf="liste_motif.length > 0 && subStatusList.length==0">
                        <div class="col-12">
                            <h2 class="text-center m-auto custemParaa">
                                {{'languages.affaire.changeMotif' | translate}}
                            </h2>
                        </div>
                        <div class=" col-4 text-center style-div" *ngFor="let motif of liste_motif">
                            <button class="btn-affect border-0 w-100" 
                            (click)="getMotif(motif?.id)" class="btn-affect"
                            [disabled]="!motif.active"
                              [class.disabled]="!motif.active"
                                [style]="{'background-color': motif.background, 'color': motif.color}">
                                <p>{{motif?.libelle}} </p>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>

    </div>
</div>







<div *ngIf="showModal">
    <div id="myModal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg modal-dialog-centered" role="document">
            <div class="modal-content modal-avenant">
                <div class="modal-header d-flex justify-content-center align-items-center">
                    <h2 class="text-center justify-content-center title m-0 w-100" id="exampleModalLabel">{{'languages.affaire.createAvenant' | translate}} </h2>
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <!-- <div class="row">
                        <div class="col">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h3 class="title-avenant">Créer un avenant
                            </h3>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 w-100">
                            <mat-form-field class="example-full-width w-100">
                                <mat-label>{{'languages.affaire.Motif' | translate}}:</mat-label>
                                <ng-container *ngIf="listMotifLoader">
                                    <div class="row mb-3 mt-3">
                                        <div class="text-center m-auto ">
                                            <mat-spinner [diameter]="15"></mat-spinner>
                
                                        </div>
                                    </div>
                                </ng-container>
                                <mat-select *ngIf="!listMotifLoader" [formControl]="MotifControl" required>
                                    <mat-option *ngFor="let motif of listMotif" [value]="motif.id">
                                        {{motif.libelle}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="MotifControl.hasError('required')">{{'languages.alerts.motifOblig' | translate}}</mat-error>
                                <mat-hint>{{'languages.alerts.motifOblig' | translate}} </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-12 w-100 mt-3">
                            <div class="card-commentaire p-2">
                                <mat-form-field class="w-100">
                                    <textarea [(ngModel)]="commentaire" name="description" class="textarea-style"
                                        matInput rows="8" placeholder="{{'languages.opportunite.details.commentaire' | translate}}">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="addAvenantLoader" >
                      
                        <div class="row d-flex mt-2 justify-content-center">
                            <div class="text-center m-auto ">
                                <mat-spinner [diameter]="25"></mat-spinner>
            
                            </div>
                        </div>
                    </ng-container>
                    <div class="row d-flex justify-content-center mt-2">
                      
                            <button type="button" class="btn-dt-save-popup custemSize mr-2" [disabled]="addAvenantLoader" (click)="creerAvenant()"
                                >
<i class="fa-solid fa-circle-check mr-1 fa-icon-style"></i>
                                {{'languages.buttons.ajouter' | translate}}
                            </button>

                            <button #closeModal type="button"  [disabled]="addAvenantLoader" class="btn-dt-cancel-popup ml-2 custemSize" data-dismiss="modal"
                                aria-label="Close">
<i class="fa-solid fa-circle-xmark mr-1 fa-icon-style"></i>
                                {{'languages.buttons.annuler' | translate}}  
                            </button>
                       
                           
                        
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>











<div *ngIf="showModalMotif">
    <div class="modal fade" id="MotifChange" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
        <div class="modal-dialog  actions-dialog" role="document">
            <div class="modal-content modal-sec w-100">
                <div class="modal-header text-center">
                    <div class="row w-100">
                        <div class="col-10"></div>
                        <div class="col-2">
                            <h2 class=" ml-3 text-center text-nowrap justify-content-center w-100 d-flex"
                                id="exampleModalLongTitle">
                                {{'languages.affaire.ModifierMotif' | translate}} 
                            </h2>
                        </div>

                    </div>
                    <div class="row w-100">
                        <button (click)="resetMotif()" type="button" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-bodyy paddingBlockkk scrollAp ">
                    <ng-container *ngIf="loadermotifActuel">
                        <div class="row mb-3 mt-3">
                            <div class="text-center m-auto ">
                                <mat-spinner [diameter]="50"></mat-spinner>

                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="row mt-3" *ngIf="list_motif_statut_actuel.length > 0">
                            <div class=" col-3 text-center style-div" *ngFor="let motif of list_motif_statut_actuel">
                                <button class="btn-affect border-2 w-100" 
                                (click)="updateMotif(motif?.id)"
                                [disabled]="!motif.active"
                                            [class.disabled]="!motif.active"
                                    [style]="{'background-color': motif.background, 'color': motif.color}">
                                    <p>{{motif?.libelle}} </p>
                                </button>
                            </div>
                            <div class="col-12 mt-3 mb-4 justify-content-center d-flex">
                                <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal" aria-label="Close"
                                    (click)="resetMotif()">
<i class="fa-solid fa-circle-xmark mr-1 fa-icon-style"></i>
                                    {{'languages.buttons.annuler' | translate}}  
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center text-center"
                            *ngIf="list_motif_statut_actuel.length == 0 && !loadermotifActuel ">
                            <label class="text-center justify-content-center mt-5 text-wrn"> {{'languages.affaire.noMotif' | translate}}  </label>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="appearModelAffectUser">
    <div class="modal fade" id="ModalAffectA" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
        aria-labelledby="ModalAffectA" aria-hidden="true">
        <div class="modal-dialog  actions-dialog" role="ModalAffectA">
            <div class="modal-content modal-first w-100">
                <div class="modal-header text-center">
                    <div class="row w-100">
                        <div class="col-3">

                        </div>
                        <div class="col-6">
                            <p class="text-center justify-content-center m-0 align-items-center title">
                                {{'languages.affaire.updateAffaire' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body ">
                    <div class="row d-flex justify-content-center align-items-center" >
                        <div class="col-12">
                            <h2 class="text-center m-auto custemParaa">
                                {{'languages.alerts.affectedTo' | translate}}
                            </h2>
                        </div>
                       
                        <mat-form-field class="w-50" appearance="standard">
                            <mat-label>{{'languages.opportunite.details.affecte' | translate}} </mat-label>
                            <input type="text" matInput  [formControl]="userAffect" [matAutocomplete]="autoStatus" placeholder="{{'languages.settings.search' | translate}}" name="status">
                            <mat-autocomplete (optionSelected)="AffectUser($event.option.value)" [displayWith]="displayFnUser" autoActiveFirstOption #autoStatus="matAutocomplete">
                                <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                                    {{option.nom +" "+option.prenom}}
                                </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                          
                          <ng-container *ngIf="showAffectingLoader">
                            <div class="col-12 mt-4 d-flex justify-content-center">
                                <div class="text-center m-auto ">
                                    <mat-spinner [diameter]="20"></mat-spinner>
                
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12 mt-4 d-flex justify-content-center">
                            <button type="button" class="btn-dt-save-popup mr-2" (click)="reAffectUser()">
                                <i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                                    {{'languages.buttons.enregistrer' | translate}}
                                </button>

                            <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                aria-label="Close" (click)="resetAffect()">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                                {{'languages.buttons.annuler' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
