export class FilterEcheances {
 date_debut_periode: any =null;
 date_fin_periode:any =null;
 user: any ="";
 montant_total: any ="";
 date_prelevement:any[] = [];
 motif: string []= [];
 operateur_motif :string = "="
 status_echeances:any[]= [];
 operateur_status:string = "=";
 operateur_status_echeances: string = "=";
 status_echeanciers: string []=[];
 operateur_status_echeanciers :string = "=";
 name:string ="";
 surname:string="";
 sSearch:string = ""; 
ligne_de_produit:any[]= [];
operateur_ligne_de_produit:string = "=";
gamme:any[]= [];
operateur_gamme:string = "=";
organisme:any[]= [];
operateur_organisme:string = "=";
sortCol: 0;
sort: 'desc';
} 