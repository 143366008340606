import { Component, HostBinding } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';

const customPresets = [
  { key: 'today', label: "Aujourd'hui" },
  { key: 'last7Days', label: '7 derniers jours' },
  { key: 'thisWeek', label: 'Cette semaine' },
  { key: 'thisMonth', label: 'Ce mois' },
  { key: 'thisYear', label: 'Cette année' },
  { key: 'lastWeek', label: 'Semaine dernière' },
  { key: 'lastMonth', label: 'Mois dernier' },
  { key: 'lastYear', label: "L'année dernière" },
] as const;

// equivalent to "today" | "last 7 days" | ... | "last year"
type CustomPreset = typeof customPresets[number]['key'];
@Component({
  selector: 'app-custom-range-panel',
  templateUrl: './custom-range-panel.component.html',
  styleUrls: ['./custom-range-panel.component.css'],
})
export class CustomRangePanelComponent<D> {
  customPresets = customPresets;
  @HostBinding('class.touch-ui')
  readonly isTouchUi = this.picker.touchUi;

  constructor(
    private dateAdapter: DateAdapter<D>,
    private picker: MatDateRangePicker<D>,
    private translateService: TranslateService
  ) {
    this.translateService.get('languages').subscribe((object: any) => {
      this.traduction(object);
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('languages').subscribe((object: any) => {
        this.traduction(object);
      });
    });
  }

  traduction(object) {
    this.customPresets = [
      { key: 'today', label: object.dates.today },
      { key: 'last7Days', label: object.dates.last7Days },
      { key: 'thisWeek', label: object.dates.thisWeek },
      { key: 'thisMonth', label: object.dates.thisMonth },
      { key: 'thisYear', label: object.dates.thisYear },
      { key: 'lastWeek', label: object.dates.lastWeek },
      { key: 'lastMonth', label: object.dates.lastMonth },
      { key: 'lastYear', label: object.dates.lastYear },
    ];
  }

  // called when user selects a range preset:
  selectRange(rangeName: CustomPreset): void {
    const [start, end] = this.calculateDateRange(rangeName);
    this.picker.select(start);
    this.picker.select(end);
    this.picker.close();
  }

  private calculateDateRange(rangeName: CustomPreset): [start: D, end: D] {
    const today = this.today;
    const year = this.dateAdapter.getYear(today);
    switch (rangeName) {
      case 'today':
        return [today, today];
      case 'last7Days': {
        const start = this.dateAdapter.addCalendarDays(today, -6);
        return [start, today];
      }
      case 'thisWeek': {
        return this.calculateWeek(today);
      }
      case 'thisMonth': {
        return this.calculateMonth(today);
      }
      case 'thisYear': {
        const start = this.dateAdapter.createDate(year, 0, 1);
        const end = this.dateAdapter.createDate(year, 11, 31);
        return [start, end];
      }
      case 'lastWeek': {
        const thisDayLastWeek = this.dateAdapter.addCalendarDays(today, -7);
        return this.calculateWeek(thisDayLastWeek);
      }
      case 'lastMonth': {
        const thisDayLastMonth = this.dateAdapter.addCalendarMonths(today, -1);
        return this.calculateMonth(thisDayLastMonth);
      }
      case 'lastYear': {
        const start = this.dateAdapter.createDate(year - 1, 0, 1);
        const end = this.dateAdapter.createDate(year - 1, 11, 31);
        return [start, end];
      }
      default:
        return rangeName;
    }
  }

  private calculateMonth(forDay: D): [start: D, end: D] {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    const start = this.dateAdapter.createDate(year, month, 1);
    const end = this.dateAdapter.addCalendarDays(start, this.dateAdapter.getNumDaysInMonth(forDay) - 1);
    return [start, end];
  }

  private calculateWeek(forDay: D): [start: D, end: D] {
    const deltaStart = this.dateAdapter.getFirstDayOfWeek() - this.dateAdapter.getDayOfWeek(forDay);
    const start = this.dateAdapter.addCalendarDays(forDay, deltaStart);
    const end = this.dateAdapter.addCalendarDays(start, 6);
    return [start, end];
  }

  private get today(): D {
    const today = this.dateAdapter.getValidDateOrNull(new Date());
    if (today === null) {
      throw new Error('date creation failed');
    }
    return today;
  }
}
