<div class="card-4" [ngClass]="{'cover': !allowed_to_update}">
    <div class="row">
        <div class="col-12">
            <label class="card-doc-title">
<i class="fa-solid fa-calendar-check mx-2 fa-lg"></i>
       {{"languages.opportunite.details.meeting" | translate }}
      </label>
        </div>
    </div>
    <div *ngIf="loading" style="margin-top: 40px;">
        <div class="row">
            <div class="col-12 text-center">
                <mat-spinner [diameter]="30" style="margin: auto"></mat-spinner>
            </div>
        </div>
    </div>
    <div *ngIf="!loading" class="row">
        <div class="col-8">
            <div class="row">
                <div class="col-10">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-select [(ngModel)]="type_rdv" placeholder="{{'languages.opportunite.details.oppType' | translate }}"(selectionChange)="onChangeTypeOpp()">
                            <mat-option *ngFor="let type of listTypeRendezVous " [value]="type.id">
                                {{type.libelle}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-10" *ngIf="type_rdv!=4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>{{'languages.opportunite.details.affecte' | translate }}</mat-label>
                        <input type="text" matInput [formControl]="affecterAFormControl" [matAutocomplete]="auto">
                        <mat-autocomplete (optionSelected)="selectCommercieaux($event.option.value)" autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of listCommerciauxFilter | async" [value]="option">
                                {{option.nom + ' ' + option.prenom}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-4" style="background-color: white;
        border-radius: 15px;">
            <mat-calendar [selected]="selectedDateMatCalendar" (selectedChange)="onSelect($event)">
            </mat-calendar>
        </div>
    </div>
    <div *ngIf="type_rdv!=4; else NoRdv" class="calendar-container">
        <div *ngIf="!updateListRdv" class="loader-span">
            <div class="row" style="margin-top: 150px">
                <div class="col-12 text-center">
                    <mat-spinner [diameter]="30" style="margin: auto"></mat-spinner>
                </div>
            </div>
        </div>
        <div [ngStyle]="{'opacity': updateListRdv? '1':'1'}" style="margin-top: 40px;" class="row">
            <div class="col-12">
                <h2> {{'languages.navbar.Agenda' | translate }}</h2>
            </div>
            <div class="col-12">
                <full-calendar #planification [options]="calendarOptions">
                </full-calendar>
            </div>
        </div>
    </div>
    <ng-template #NoRdv>
        <div class="row" style="margin-top: 50px">
            <div class="col-12 text-center"> <span>{{'languages.opportunite.details.withoutMeeting' | translate }}</span> </div>
        </div>
    </ng-template>

    <ng-template #OppUpdateRdvModal>
        <div class="modal-header">
            <p class="modal-title text-center m-0 justify-content-center w-100 " id="modal-basic-title">{{'languages.opportunite.details.meetingSetting' | translate }}</p>
            <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>
        </div>
        <div class="modal-body mx-5">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <input matInput [ngxMatDatetimePicker]="picker1" placeholder="{{'languages.opportunite.details.startsAt' |translate}}" [formControl]="dateDebutControl" (dateChange)="changerdatedebut($event)" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <input matInput [ngxMatDatetimePicker]="picker2" placeholder="{{'languages.opportunite.details.finishAt' |translate}}" [formControl]="dateFinControl" (dateChange)="changerdatefin($event)" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="row  d-flex justify-content-center align-items-center mb-3">
            <button (click)="updateRdvDetails()" type="button" class="btn-dt-save-popup mr-2">
<i class="fa-solid fa-circle-check fa-lg mr-1 "></i>
                {{'languages.buttons.enregistrer' | translate }}
              </button> 
    <button type="button" class="btn-dt-fermer ml-2" (click)="modalService.dismissAll()">
        <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
        {{'languages.buttons.annuler' | translate}}
</button>
         
   
    </div>
    </ng-template>

    <ng-template #OppDetailsRdvModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title2">{{'languages.buttons.detailsMeeting' | translate }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>
        </div>
        <div class="modal-body"></div>
    </ng-template>
</div>
