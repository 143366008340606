import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotificationsNavbarService {
  private notifyUpdate = new Subject<void>();

  notifyUpdate$ = this.notifyUpdate.asObservable();
  constructor() { }

  triggerNotificationUpdate() {
    this.notifyUpdate.next();
  }
  
}
