import { Prospect } from 'src/app/entity/Prospect';
import { createReducer, on } from '@ngrx/store';
import { Entreprise } from '../../../../entity/Opportunity/Entreprise';
import * as actions from './details-opportunite.actions';
import { GetOpportuniteDetails } from '../../../../entity/Opportunity/GetOpportuniteDetails';

export enum storeStatusEnum {
  INIT_STATE = 'INIT STATE',
  LOADING_STATE = 'LOADING STATE',
  LOADED_STATE = 'LOADED STATE',
  ERROR_STATE = 'ERROR STATE',
  UPDATE_SUCCESS = 'UPDATED SUCCESS',
  UPDATE_FAIL = 'UPDATED FAIL',
  INSERT_STATE_SUCCESS = 'INSERT STATE SUCCESS',
  UNSAVED_UPDATE = 'UNSAVED UPDATE',
  RDV_DATE_UPDATED = 'DATE RDV UPDATED',
  RDV_DATE_UPDATE_ERROR = 'DATE RDV UPDATE ERROR',
  OBJET_ASSURE_UPDATE_ERROR = 'OBJET ASSURE UPDATE ERROR',

}

export enum addOpportuniteTypeProspectEnum {
  ENTREPRISE = 'entrepise',
  PARTICULIER = 'particulier'
}

export class ErrorMessage{
  error:string;
  message:string;
}


export interface DetailsOpportuniteStore {
  detailsOpportunite: DetailsOpportuniteStatus
}

export interface DetailsOpportuniteStatus {
  entreprise: {
    entreprise: Entreprise,
    status: storeStatusEnum,
    prospectUpdate: Entreprise,
    prospectUpdated: boolean,
    errorMsg: string,
  },
  constractActuel: any,
  informationComplementaires: any,
  informationSpecifiques: any,
  planification: any,
  listObjetAssurer:any[],
  constractActuelUpdated: boolean,
  informationComplementairesUpdate: boolean,
  informationSpecifiquesUpdated: boolean,
  state: storeStatusEnum,
  details: GetOpportuniteDetails,
  opportunite_id: string
  affaire_id: string
  detailsUpdated: boolean,
  prospectPro: any,
  prospectParticulier: any,
  typeProspect: any;
  errorMessage?: any;
}

export const initState: DetailsOpportuniteStatus = {
  entreprise: {
    entreprise: null,
    status: storeStatusEnum.INIT_STATE,
    prospectUpdate: null,
    prospectUpdated: false,
    errorMsg: null,
  },
  constractActuel: null,
  informationComplementaires: null,
  informationSpecifiques: null,
  planification: null,
  constractActuelUpdated: false,
  informationComplementairesUpdate: false,
  informationSpecifiquesUpdated: false,
  state: storeStatusEnum.INIT_STATE,
  details: null,
  opportunite_id: null,
  affaire_id: null,
  detailsUpdated: false,
  typeProspect:'',
  prospectParticulier: null,
  prospectPro: null,
  listObjetAssurer:[]
};

export const _detailsOpportuniteReducer = createReducer<DetailsOpportuniteStatus>(
  initState,
  on(actions.AddProspectAction, (state) => {
    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      const prospectPro = {...state.prospectPro};
      prospectPro.listProspects = [...prospectPro.listProspects, new Prospect()];
      return {
        ...state,
        prospectPro: prospectPro
      };
    } else {
      let prospectParticulier: any[] = [];
      if(state.entreprise.entreprise?.listProspectsLier){
        prospectParticulier = [...state.entreprise.entreprise?.listProspectsLier, new Prospect()];
      }

      return {
        ...state,
        prospectParticulier: prospectParticulier
      };

    }
  }),
  on(actions.UpdateDateRdv, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        opportunite_details: {
          ...state.details.opportunite_details,
          startRdv: payload.start,
          endRdv: payload.end
        }
      },
      state: storeStatusEnum.UNSAVED_UPDATE,
      detailsUpdated: true
    };
  }),
  /////////////////////////////
  on(actions.UpdateOBJETAssurer, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        opportunite_details: {
          ...state.details.opportunite_details,
          id_opp: payload.id_opp,
          listObjetAssurer: payload.listObjetAssurer
        }
      },
      state: storeStatusEnum.UNSAVED_UPDATE,
      detailsUpdated: true
    };
  }),
  on(actions.UpdateOBJETAssurerSuccess, (state) => {
    return {
      ...state,
      state: storeStatusEnum.UPDATE_SUCCESS,
      detailsUpdated: false,
    };
  }),
  on(actions.UpdateOBJETAssurerFail, (state) => {
    return {
      ...state,
      state: storeStatusEnum.OBJET_ASSURE_UPDATE_ERROR,
    };
  }),
  ////////////////////////////////
  on(actions.UpdateDateRdvSuccess, (state) => {
    return {
      ...state,
      state: storeStatusEnum.UPDATE_SUCCESS,
      detailsUpdated: false,
    };
  }),
  on(actions.UpdateDateRdvFail, (state) => {
    return {
      ...state,
      state: storeStatusEnum.RDV_DATE_UPDATE_ERROR,
    };
  }),
  on(actions.UpdateProspectAction, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        prospectUpdate: payload,
        prospectUpdated: true,
      },
      state: storeStatusEnum.UNSAVED_UPDATE
    };
  }),

  on(actions.UpdateInfoSpecifqueAction, (state, { payload }) => {
    return {
      ...state,
      informationSpecifiques: payload,
      informationSpecifiquesUpdated: true,
      state: storeStatusEnum.UNSAVED_UPDATE
    };
  }),
  on(actions.UpdateInfoSpecifqueSuccess, (state) => {
    return {
      ...state,
      informationSpecifiquesUpdated: false,
      state: storeStatusEnum.UPDATE_SUCCESS
    };
  }),
  on(actions.UpdateInfoSpecifqueFail, (state, { payload }) => {
    return {
      ...state.informationSpecifiques,
      informationSpecifiquesUpdated: true,
      state: storeStatusEnum.UPDATE_FAIL
    };
  }),
  on(actions.UpdateInfoComplementaireAction, (state, { payload }) => {
    return {
      ...state,
      informationComplementaires: payload,
      informationComplementairesUpdate: true,
      state: storeStatusEnum.UNSAVED_UPDATE
    };
  }),
  on(actions.UpdateConstractActuelAction, (state, { payload }) => {
    return {
      ...state,
      constractActuel: payload,
      constractActuelUpdated: true,
      state: storeStatusEnum.UNSAVED_UPDATE
    };
  }),
  on(actions.UpdateInfoComplementaireSuccess, (state) => {
    return {
      ...state,
      informationComplementairesUpdate: false,
      state: storeStatusEnum.UPDATE_SUCCESS
    };
  }),
  on(actions.UpdateConstractActuelSuccess, (state) => {
    return {
      ...state,
      constractActuelUpdated: false,
      state: storeStatusEnum.UPDATE_SUCCESS
    };
  }),
  on(actions.UpdateInfoComplementaireFail, (state, { payload }) => {
    return {
      ...state.informationComplementaires,
      informationComplementairesUpdate: true,
      state: storeStatusEnum.UPDATE_FAIL
    };
  }),
  on(actions.UpdateConstractActuelFail, (state, { payload }) => {
    return {
      ...state.constractActuel,
      constractActuelUpdated: true,
      state: storeStatusEnum.UPDATE_FAIL
    };
  }),
  on(actions.UpdateAffectedTo, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        opportunite_details: {
          ...state.details.opportunite_details,
          commerciaux_id: payload.commerciaux_id
        }
      },
      state: storeStatusEnum.UNSAVED_UPDATE,
      detailsUpdated: true
    };
  }),
  on(actions.UpdateTypeRdv, (state, { payload }) => {
    return {
      ...state,
      details: {
        ...state.details,
        opportunite_details: {
          ...state.details.opportunite_details,
          type_opportunite: payload.type_rdv_id
        }
      },
      state: storeStatusEnum.UNSAVED_UPDATE,
      detailsUpdated: true
    };
  }),
  on(actions.SaveDetails, (state) => {
    let stateValue = storeStatusEnum.INIT_STATE;
    if (state.entreprise.prospectUpdated
      || state.detailsUpdated
      || state.constractActuelUpdated
      || state.informationSpecifiquesUpdated
      || state.informationComplementairesUpdate) {
      stateValue = storeStatusEnum.LOADING_STATE;
    }
    return {
      ...state,
      state: stateValue
    };
  }),
  on(actions.UpdateDetails, (state) => {
    let stateValue = storeStatusEnum.INIT_STATE;
    if (state.detailsUpdated) {
      stateValue = storeStatusEnum.LOADING_STATE;
    }
    return {
      ...state,
      state: stateValue
    };
  }),
  on(actions.UpdateProspectServiceSuccess, (state, { payload }) => {
    if(payload.error){
      return {
        ...state,
        state: storeStatusEnum.UPDATE_FAIL
      };
    }else{
      return {
        ...state,
        entreprise: {
          ...state.entreprise,
          status: storeStatusEnum.UPDATE_SUCCESS,
          prospectUpdated: false,
        },
        state: storeStatusEnum.UPDATE_SUCCESS
      };
    }

  }),
  on(actions.GetDetailsSuccess, (state, { payload }) => {
    return {
      ...state,
      details: payload,
      state: storeStatusEnum.INIT_STATE
    };
  }),
  on(actions.GetDetailsFail, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.ERROR_STATE
    };
  }),
  on(actions.GetDetails, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.LOADING_STATE,
      opportunite_id: payload.opportuniteId
    };
  }),
  on(actions.GetInfoSpecifqueSuccess, (state, { payload }) => {
    return {
      ...state,
      informationSpecifiques: payload,
      state: storeStatusEnum.INIT_STATE
    };
  }),
  on(actions.GetInfoSpecifqueFail, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.ERROR_STATE
    };
  }),
  on(actions.GetInfoSpecifique, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.LOADING_STATE,
      opportunite_id: payload.opportuniteId
    };
  }),
  on(actions.GetInfoComplementaireSuccess, (state, { payload }) => {
    return {
      ...state,
      informationComplementaires: payload,
      state: storeStatusEnum.INIT_STATE
    };
  }),
  on(actions.GetConstractActuelSuccess, (state, { payload }) => {
    return {
      ...state,
      constractActuel: payload,
      state: storeStatusEnum.INIT_STATE
    };
  }),
  on(actions.GetInfoComplementaireFail, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.ERROR_STATE
    };
  }),
  on(actions.GetConstractActuelFail, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.ERROR_STATE
    };
  }),
  on(actions.GetInfoComplementaire, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.LOADING_STATE,
      opportunite_id: payload.opportuniteId
    };
  }),
  on(actions.GetConstractActuel, (state, { payload }) => {
    return {
      ...state,
      state: storeStatusEnum.LOADING_STATE,
      opportunite_id: payload.opportuniteId
    };
  }),
  on(actions.UpdateProspectServiceFail, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        status: storeStatusEnum.UPDATE_FAIL,
        prospectUpdated: true,
      },
      state: storeStatusEnum.UPDATE_FAIL,
      errorMessage: payload.errorMsg
    };
  }),
  on(actions.ResetProspect, (state) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        prospectUpdate: state.entreprise.entreprise,
        prospectUpdated: false,
      },
    };
  }),
  on(actions.GetProspect, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        status: storeStatusEnum.LOADING_STATE
      }
    };
  }),
  on(actions.GetProspectSuccess, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        entreprise: payload.entreprise,
        prospectUpdate: payload.entreprise,
        prospectUpdated: false,
        status: storeStatusEnum.LOADED_STATE
      },
      state: storeStatusEnum.INIT_STATE
    };
  }),
  on(actions.GetProspectFail, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
        errorMsg: payload.errorMsg,
        status: storeStatusEnum.ERROR_STATE
      }
    };
  }),
  on(actions.NoopAction, (state, { payload }) => {
    return {
      ...state,
      entreprise: {
        ...state.entreprise,
      }
    };
  }),
);


export function detailsOpportuniteReducer(state, action) {
  return _detailsOpportuniteReducer(state, action);
}


