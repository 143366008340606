import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigurationService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();


  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }













  public getParamConfig(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getParamConfig.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/param_config/get_param_config_by_key/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }


  
  public getInfoRule(
   id,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
   
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/rule/v0/getRule/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }


  
  public rulesList(
    limit?: any,
    page?: any,
    search?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
   
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
 
    queryParameters = queryParameters.append('limit', <any>limit);
    queryParameters = queryParameters.append('page', <any>page);
    queryParameters = queryParameters.append('Search', <any>search);

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/rule/v0/listRules`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }


  public rulesTypes(
   
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
   
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/rule/v0/types`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }



  public addParamConfig(bodyConfig: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/param_config/add_param_config`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyConfig,
    });
  }



  public updateParamConfig( id : string ,bodyConfig: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/param_config/update_param_config/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyConfig,
    });
  }


  public updateRule( bodyConfig: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/rule/v0/update_rule`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyConfig,
    });
  }
  

  public createRule( bodyConfig: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/rule/v0/add`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyConfig,
    });
  }

}
