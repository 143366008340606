import {Opportunity} from './Opportunity';

export class FiltresQuery {
  query_search:string="";
  having:string="";
  date_cond_cdr:string="";
  cond_cdr:string="";
  autre_params:string="";
}

export class OpportunityList{
  aaData: Opportunity[];
  min_date_depot : any;
  max_date_depot : any ;
  iTotalDisplayRecords:number =0;
  iTotalRecords:number =0;
  filtres_query:FiltresQuery
}
