import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormbuilderValidationService } from './../../../services/formbuilder-validation.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-email-type',
  templateUrl: './email-type.component.html',
  styleUrls: ['./email-type.component.scss'],
})
export class EmailTypeComponent extends FieldType implements OnInit {
  emailFormControl = new FormControl('', [Validators.email]);

  matcher = new MyErrorStateMatcher();
  fieldName: any;
  searchValue: any;
  constructor(private validationService: FormbuilderValidationService) {
    super();
  }

  ngOnInit(): void {
    // Ensure formControl gets the pattern from field templateOptions
    this.fieldName = this.field.key || `emailField_${Math.random().toString(36).substr(2, 9)}`;
    const pattern:any  = this.field.templateOptions?.pattern || '';
   
     
      console.log(pattern);

    this.emailFormControl.setValidators([Validators.email, this.emailPatternValidator(pattern)]);
    this.searchValue=this.field.templateOptions?.autofill
    if (this.field.templateOptions?.autofill) {
      this.emailFormControl.disable();
    } else {
      this.emailFormControl.enable();
    }
    // Initialize form control value if needed
    if (this.formControl.value) {
      this.emailFormControl.setValue(this.formControl.value);
    }

    // Update Formly form control value on changes
    this.emailFormControl.valueChanges.subscribe((value) => {
      this.formControl.setValue(value);
      this.emitValidationStatus();
    });
    this.emitValidationStatus();
  }

  private emitValidationStatus(): void {
    const isValid = this.emailFormControl.disabled || this.emailFormControl.valid;

    this.validationService.updateValidationStatus(this.fieldName, isValid,this.field.templateOptions?.label);
  }

  private emailPatternValidator(pattern: string | RegExp): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = new RegExp(pattern);
      if (control.value && !regex.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }

  updateValue(event: Event): void {

    this.emailFormControl.setValue('Some update'); // Update with the logic you need
  }

}
