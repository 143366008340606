import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Optional} from '@angular/core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Configuration} from '../configuration';
import {CustomHttpUrlEncodingCodec} from '../encoder';
import {BASE_PATH} from '../variables';

@Injectable({
  providedIn: 'root',
})
export class ApiRdvService {
  protected basePath = environment.api_url;
  protected PathAdresses = environment.PathAdresses;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * sert a affecter une  affaire a l’utilisateur connecté si la relation n’existe pas ou de supprimer l’affectation si elle  existe. Sur l&#x27;IHM il suffit de cliquer sur (*) a coté de l&#x27;affaire dans l&#x27;onglet tous les affaires pour invoker ce web services
   *
   * @param collaborateur Identifiant de collaborateur codé en md5
   * @param strat Date debut de recherche
   * @param end Date fin de recherche
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListeRdv(collaborateur: string, start: string, end: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter collaborateur was null or undefined when calling getListeRdv.');
    }

    if (start === null || start === undefined) {
      throw new Error('Required parameter strat was null or undefined when calling getListeRdv.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter end was null or undefined when calling getListeRdv.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', collaborateur);
    }
    if (start !== undefined && start !== null) {
      queryParameters = queryParameters.set('start', start);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_rdv`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getListEvenments(collaborateur: string, strat: string, end: string,type:string,imported_calendars:any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling addFavoriteAffaireGet.');
    }

    if (strat === null || strat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', collaborateur);
    }
    if (strat !== undefined && strat !== null) {
      queryParameters = queryParameters.set('start', strat);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end);
    }
    if (type) {
      queryParameters = queryParameters.set('types', type);
    }
    
    if (imported_calendars) {
      queryParameters = queryParameters.set('imported_calendars', imported_calendars);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_all_events`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getExport(collaborateur: string, strat: string, end: string,type:string ,typeExport : string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling addFavoriteAffaireGet.');
    }

    if (strat === null || strat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', collaborateur);
    }
    if (strat !== undefined && strat !== null) {
      queryParameters = queryParameters.set('start', strat);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end);
    }
    if (type) {
      queryParameters = queryParameters.set('types', type);
    }
    if (typeExport) {
      queryParameters = queryParameters.set('download_type', typeExport);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/events/export_to_ics`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public importCalendarUrl(collaborateur: string, strat: string, end: string,type:string ,typeExport : string,link : string,libelle :any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling addFavoriteAffaireGet.');
    }

    if (strat === null || strat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', collaborateur);
    }
    if (strat !== undefined && strat !== null) {
      queryParameters = queryParameters.set('start', strat);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end);
    }
    if (type) {
      queryParameters = queryParameters.set('types', type);
    }
    if (typeExport) {
      queryParameters = queryParameters.set('data_type', typeExport);
    }
    if (link) {
      queryParameters = queryParameters.set('url', link);
    }
    if (libelle) {
      queryParameters = queryParameters.set('libelle', libelle);
    }
    

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/events/upload_from_ics`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public importCalendarFile(collaborateur: string, strat: string, end: string,type:string ,typeExport : string,file : any,libelle :any , observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling addFavoriteAffaireGet.');
    }

    if (strat === null || strat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', collaborateur);
    }
    if (strat !== undefined && strat !== null) {
      queryParameters = queryParameters.set('start', strat);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end);
    }
    if (type) {
      queryParameters = queryParameters.set('types', type);
    }
    if (typeExport) {
      queryParameters = queryParameters.set('data_type', typeExport);
    }
    if (libelle) {
      queryParameters = queryParameters.set('libelle', libelle);
    }
    

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/events/upload_from_ics`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: file
      }
    );
  }
  

  public getImportedCalendars(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/filtre/event?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }



  public createEvenments(event:any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // if (title === null || title === undefined) {
    //   throw new Error('Required parameter title was null or undefined when calling CreateEvenments.');
    // }

    // if (strat === null || strat === undefined) {
    //   throw new Error('Required parameter strat was null or undefined when calling CreateEvenments.');
    // }
    // if (end === null || end === undefined) {
    //   throw new Error('Required parameter end was null or undefined when calling CreateEvenments.');
    // }

   

    // let body = {};
    // if (title !== undefined && title !== null) {
    //   body['title'] = title;
    // }
    // if (strat !== undefined && strat !== null) {
    //   body['start'] = strat;
    // }
    // if (end !== undefined && end !== null) {
    //   body['end'] = end;
    // }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
   const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/create_event_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: event
      }
    );
  }

  public updateEvenments(id ,event, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // if (title === null || title === undefined) {
    //   throw new Error('Required parameter title was null or undefined when calling updateEvenments.');
    // }
    // if (id === null || id === undefined) {
    //   throw new Error('Required parameter id was null or undefined when calling updateEvenments.');
    // }

    // if (strat === null || strat === undefined) {
    //   throw new Error('Required parameter strat was null or undefined when calling updateEvenments.');
    // }
    // if (end === null || end === undefined) {
    //   throw new Error('Required parameter end was null or undefined when calling updateEvenments.');
    // }

    

    // let body = {};
    // if (title !== undefined && title !== null) {
    //   body['title'] = title;
    // }
    // if (strat !== undefined && strat !== null) {
    //   body['start'] = strat;
    // }
    // if (end !== undefined && end !== null) {
    //   body['end'] = end;
    // }
    if (id !== undefined && id !== null) {
      event['id_event'] = id;
    }

    let headers = this.defaultHeaders;
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/update_event_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: event
      }
    );
  }

  public deleteEvenments(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteEvenments.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/delete_event_user/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
