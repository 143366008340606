import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserAdminList } from 'src/app/entity/User/UserAdminList';
 
import { GroupUser } from 'src/app/entity/User/GroupUser';
import { Createur } from 'src/app/entity/Maileva/FilterMaileva';
@Component({
  selector: 'app-filters-createurs',
  templateUrl: './filters-createurs.component.html',
  styleUrls: ['./filters-createurs.component.css'],
})
export class FiltersCreateursComponent implements OnInit, OnChanges, OnDestroy {
  CreateurSelectionees: string[] = [];
   
  listeOfUnsubscribeWebService: Subscription[] = [];
  GroupeSelectionees: string[] = [];

  filterCreateur: Createur = new Createur();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterCreateur: EventEmitter<Createur> = new EventEmitter();

  // Mat Chips Createur
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  CreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCreateur: Observable<UserAdminList[]>;
  ListCreateur: string[] = [];
  allCreateur: UserAdminList[] = [];

  @ViewChild('CreateurInput') CreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCreateur') matAutocompleteCreateur: MatAutocompleteModule;

  // Mat Chips Groupe
  groupeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupe: Observable<GroupUser[]>;
  ListGroupe: string[] = [];
  // allGroupe: GroupUser[] = [];

  @ViewChild('groupeInput') groupeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupe') matAutocompleteGroupe: MatAutocomplete;
  filtre: any;

  constructor(private apiUsersService: ApiUsersService, private chRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });
  }

  // Createur -----------------------------------------------------------------------------------------------
  selectedCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.CreateurSelectionees.includes(event.option.value)){
      this.ListCreateur.push(event.option.viewValue);
      this.CreateurSelectionees.push(event.option.value);
    }

    this.CreateurInput.nativeElement.value = '';
    this.CreateurFromCtrl.setValue(null);
    this.EmitFilterCreateur()
  }

  removeCreateur(Createur: string): void {
    const index = this.ListCreateur.indexOf(Createur);
    if (index >= 0) {
      this.ListCreateur.splice(index, 1);
      this.CreateurSelectionees.splice(index, 1);
    }
  }

  private _filterCreateur(): UserAdminList[] {
    let filterValue = this.CreateurFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allCreateur.filter((Createur) => (Createur.nom != null && Createur.nom.toLowerCase().includes(filterValue)) ||
    (Createur.prenom != null && Createur.prenom.toLowerCase().includes(filterValue)));
  }

  // Groupe  -----------------------------------------------------------------------------------------------
  // selectedGroupe(event: MatAutocompleteSelectedEvent): void {
  //   this.ListGroupe.push(event.option.viewValue);
  //   this.GroupeSelectionees.push(event.option.value);
  //   this.groupeInput.nativeElement.value = '';
  //   this.groupeFromCtrl.setValue(null);
  // }

  // removeGroupe(groupe: string): void {
  //   const index = this.ListGroupe.indexOf(groupe);
  //   if (index >= 0) {
  //     this.ListGroupe.splice(index, 1);
  //     this.GroupeSelectionees.splice(index, 1);
  //   }
  // }

  // private _filterGroupe(): GroupUser[] {
  //   let filterValue = this.groupeFromCtrl.value.toString().toLowerCase();
  //   if (filterValue == null) filterValue = '';
  //   return this.allGroupe.filter((groupe) => groupe.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  ngOnInit(): void {
    this.filtre = localStorage.getItem('filtreMaileva');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
    this.filterCreateur = this.filtre?.createur
    
  }
    const unsubscribeGetAllUsers = this.apiUsersService.getAllUsers().subscribe((Response: UserAdminList[]) => {
      this.allCreateur = Response;
      if (this.filtre) {
        if (this.filterCreateur.recheche_create_user.length > 0) {
          this.ListCreateur = this.allCreateur
            .filter(user => this.filterCreateur.recheche_create_user.includes(user.id))
            .map(userAff => {
              this.CreateurSelectionees.push(userAff.id);
              this.EmitFilterCreateur()
              return userAff.nom + userAff.prenom;
              
            });
        }}
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetAllUsers);

    // const unsubscribeGetGroupUsers = this.apiUsersService.getGroupUsers().subscribe((Response: GroupUser[]) => {
    //   this.allGroupe = Response;
    // });
    // this.listeOfUnsubscribeWebService.push(unsubscribeGetGroupUsers);
    this.filteredCreateur = this.CreateurFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterCreateur() : this.allCreateur.slice()))
    );

    // this.filteredGroupe = this.groupeFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupe: string | null) => (groupe ? this._filterGroupe() : this.allGroupe.slice()))
    // );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetCreateurForm(changes.Reset.currentValue);
  }

  resetCreateurForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListCreateur = [];
      this.filterCreateur.recheche_create_user = this.CreateurSelectionees = [];
      // this.ListGroupe = [];
      // this.filterCreateur.recheche_group = this.GroupeSelectionees = [];
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterCreateur.emit(this.filterCreateur);
    }
  }

  EmitFilterCreateur() {
    this.filterCreateur.recheche_create_user = this.CreateurSelectionees;
    // this.filterCreateur.recheche_group = this.GroupeSelectionees;
    this.onPushfilterCreateur.emit(this.filterCreateur);
  }
}
