import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Email, EmailClientLibService} from 'email-client-lib';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.css']
})
export class DriveComponent implements OnInit {

  constructor(
    private sharedMenuObserverService:SharedMenuObserverService,
    private translate : TranslateService
    ) { }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.Drive);

    });
    this.translate.onLangChange.subscribe(() => {


    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.Drive);

    });

  });
  }

}
