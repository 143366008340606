import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilterHistoryService {
  public defaultHeaders = new HttpHeaders();
  private configuration: Configuration;
  protected basePath = environment.api_url;
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    this.configuration = configuration;
    if (basePath) {
      this.basePath = basePath;
    }
  }

  /**
   * Permet d&#x27;ajouter une collection des filtre
   *
   * @param entity Identifiant de l&#x27; O si opportubite, A si Affaire ..
   * @param libelle libelle de collection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addFilterCollection(
    entity: String,
    libelle: String,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling addFilterCollection.');
    }

    if (libelle === null || libelle === undefined) {
      throw new Error('Required parameter libelle was null or undefined when calling addFilterCollection.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/filtre/collection/add?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: { entity: entity, libelle: libelle },
    });
  }

  /**
   * Permet d&#x27;ajouter un filtre
   *
   * @param entity Identifiant de l&#x27; O si opportubite, A si Affaire ..
   * @param libelle libelle de filtre
   * @param collection id de collection
   * @param input json de filtre
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addFilterHistory(
    entity: String,
    libelle: String,
    collection: number,
    input: String,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling addFilterCollection.');
    }

    if (libelle === null || libelle === undefined) {
      throw new Error('Required parameter libelle was null or undefined when calling addFilterCollection.');
    }

    if (collection === null || collection === undefined) {
      throw new Error('Required parameter collection was null or undefined when calling addFilterCollection.');
    }

    if (input === null || input === undefined) {
      throw new Error('Required parameter input was null or undefined when calling addFilterCollection.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/filtre/add?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: { entity: entity, libelle: libelle, collection: collection, input: input },
    });
  }

  /**
   * Permet recuperer la liste des collections
   *
   * @param entity Identifiant O si opportunite , A si Affaire ...
   * @param Ssearch chaine de recherche...
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListCollection(
    entity: string,
    Ssearch: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling addDocumentOpportuniteGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (Ssearch !== undefined && Ssearch !== null) {
      queryParameters = queryParameters.set('search', <any>Ssearch);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/filtre/collection/get_by_entity/${entity}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Permet recuperer les détails d'une collection
   *
   * @param entity Identifiant O si opportunite , A si Affaire ...
   * @param collection Identifiant O si opportunite , A si Affaire ...
   *  @param Ssearch chaine de recherche...
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFiltreCollection(
    entity: string,
    collection: number,
    Ssearch: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling addDocumentOpportuniteGet.');
    }
    if (collection === null || collection === undefined) {
      throw new Error('Required parameter collection was null or undefined when calling addDocumentOpportuniteGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (Ssearch !== undefined && Ssearch !== null) {
      queryParameters = queryParameters.set('search', <any>Ssearch);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/filtre/get_favori_by_entity/${entity}/${collection}?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  public deleteCollection(
    idCollection: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCollection === null || idCollection === undefined) {
      throw new Error('Required parameter libelle was null or undefined when calling deleteEvenments.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCollection !== undefined && idCollection !== null) {
      queryParameters = queryParameters.set('id', <any>idCollection);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/filtre/collection/delete`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public deleteFilter(
    libellefilter: string,
    idCollection: number,
    entity: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (libellefilter === null || libellefilter === undefined) {
      throw new Error('Required parameter libellefilter was null or undefined when calling deleteEvenments.');
    }
    if (idCollection === null || idCollection === undefined) {
      throw new Error('Required parameter idCollection was null or undefined when calling deleteEvenments.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (libellefilter !== undefined && libellefilter !== null) {
      queryParameters = queryParameters.set('libelle', <any>libellefilter);
    }
    if (idCollection !== undefined && idCollection !== null) {
      queryParameters = queryParameters.set('collection', <any>idCollection);
    }
    if (entity !== undefined && entity !== null) {
      queryParameters = queryParameters.set('entity', <any>entity);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/filtre/delete`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public updateCollection(
    collection_id: number,
    libelle: string,
    entity: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (collection_id === null || collection_id === undefined) {
      throw new Error('Required parameter libelle was null or undefined when calling getCommentaireOpportuniteGet.');
    }
    if (libelle === null || libelle === undefined) {
      throw new Error('Required parameter libelle was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    // const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (libelle !== undefined && libelle !== null) {
      queryParameters = queryParameters.set('libelle', <any>libelle);
    }

    if (entity !== undefined && entity !== null) {
      queryParameters = queryParameters.set('entity', <any>entity);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/filtre/collection/update/${collection_id}?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getFiltrebyID(
    id: any,
    IDCollection: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (IDCollection !== undefined && IDCollection !== null) {
      queryParameters = queryParameters.set('id_collection', <any>IDCollection);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/filtre/get_favori_by_id?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
}
