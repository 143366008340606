// JsonFormly.ts
import { FormField } from './FormField';

export class JsonFormly extends FormField {
  maxLength = 255;
  minLength = 0;
  pattern = "";
  format = "";
  description = "";
urlValidation=""
autofill="";
urlIdentifiant=""
 default=""
  constructor() {
    super();
    super.icon = "fa fa-code"; 
    super.name = 'json_' + super.generateRandomId();
    this.type = 'json'; 
    this.title = 'Json Object'; 
    super.typename = 'Json'; 
  }
}