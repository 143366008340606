import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { runInThisContext } from 'vm';
export class Reassurance {
  Config_Premium_Reas_Part: number;
  Config_Premium_Reas_Limit: number;
  Config_Claim_Reas_Limit: string = '';
  Config_Claim_Settlement: string = '';
  Config_Premium_Part_Amount: string='';
  Calculated_Premium_Reas_Part: number;
  Calculated_Claim_Reas_Part: number;
  Config_Premium_Deductible_Amount: string='';
  Config_Claim_Reas_Limit_out_of_deductible:  string='';
  Config_Claim_Reas_Absolut_Limit: string='';
  Calculated_Premium_Reas_Excess: number;
  Config_Trigger_Claims_Amount_Sum: string='';
  Config_Claims_Amount_Sum_Limit: string='';
  Config_Claims_Periodicity: string = '';
  code_garantie: string = '';
  id_gamme?: string;
  type_reassurance: string = '';
  Reassureur: string = '';
  type: string = '';
}
export class TypeAssureur {
  id: number;
  type: string;
}
@Component({
  selector: 'app-modal-reassurance',
  templateUrl: './modal-reassurance.component.html',
  styleUrls: ['./modal-reassurance.component.css'],
})
export class ModalReassuranceComponent implements OnInit {
  show_loading_adding: boolean;
  list_type: TypeAssureur[];
  Organismes: OrganismeListe[] = [];
  reassurance: Reassurance = new Reassurance();
  alerts: any;
  buttons: any;
  show_loading_add: boolean;
  mode: string;
  reassureurInput = '';
  reassureurFormControl = new FormControl(); // FormControl for the input
  filteredOrganismes!: Observable<any[]>;
  currency: string = environment.currentCurrency;
  constructor(
    public dialogRef: MatDialogRef<ModalReassuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiGarantieService: ApiGarantieService,
    private apiCampagniesService: ApiCampagniesService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.listeReassuranceTypes();
    this.getListOrganisme();
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });

    this.reassurance.code_garantie = this.data.idGarantie;
    // this.reassurance.id_gamme=this.data.idGamme;
    this.mode = this.data.mode;

    if (this.mode == 'edit') {
      this.reassurance = this.data.reassurance;
      this.reassurance.type = this.data.reassurance.type_reassurance.toString();
     
        this.reassurance.Config_Claim_Reas_Limit = UtilsService.formatValue(this.reassurance.Config_Claim_Reas_Limit?.toString(), this.currency);
     
        this.reassurance.Config_Premium_Part_Amount = UtilsService.formatValue(this.reassurance.Config_Premium_Part_Amount?.toString(), this.currency);
 
        this.reassurance.Config_Premium_Deductible_Amount= UtilsService.formatValue(this.reassurance.Config_Premium_Deductible_Amount?.toString(), this.currency);
 
        this.reassurance.Config_Claim_Reas_Limit_out_of_deductible= UtilsService.formatValue(this.reassurance.Config_Claim_Reas_Limit_out_of_deductible?.toString(), this.currency);
 
        this.reassurance.Config_Claim_Reas_Absolut_Limit= UtilsService.formatValue(this.reassurance.Config_Claim_Reas_Absolut_Limit?.toString(), this.currency);
     
        this.reassurance.Config_Trigger_Claims_Amount_Sum= UtilsService.formatValue(this.reassurance.Config_Trigger_Claims_Amount_Sum?.toString(), this.currency);
    
        this.reassurance.Config_Claims_Amount_Sum_Limit= UtilsService.formatValue(this.reassurance.Config_Claims_Amount_Sum_Limit?.toString(), this.currency);

    }
  }
  onSelect(event: any): void {
    this.reassurance = new Reassurance();
    this.reassurance.type = event.value.toString();
    this.reassurance.type_reassurance = event.value.toString();

    this.reassurance.code_garantie = this.data.idGarantie;
  }

  onNoClick(): void {
    this.dialogRef.close(this.reassurance);
  }

  AddReassureur() {
    if (this.data.idGamme) {
      this.reassurance.id_gamme = this.data.idGamme;
    }

    this.dialogRef.close(this.reassurance);
  }

  listeReassuranceTypes() {
    this.apiGarantieService.getListReassuranceTypes().subscribe((data) => {
      this.list_type = data;
      if (this.mode == 'add') {
        this.reassurance.type = this.list_type[0]?.id.toString();
        this.reassurance.type_reassurance = this.list_type[0]?.id.toString();
      }
    });
  }

  getListOrganisme() {
    const unsubscribeGetOrganismes = this.apiCampagniesService
      .getOrganismes()
      .subscribe((Response: OrganismeListe[]) => {
        this.Organismes = Response;

        this.filteredOrganismes = this.reassureurFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => {
            return this._filterOrganismes(value || '');
          })
        );
      });
  }

  private _filterOrganismes(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.Organismes.filter((organisme) => organisme.nom.toLowerCase().includes(filterValue));
  }

  // Display function for the autocomplete
  displayOrganismeFn(organisme?: any): string {
    return organisme ? organisme.nom : '';
  }

  convertCurreny(event,type) {
    if(type=='plafondSinistre'){
      this.reassurance.Config_Claim_Reas_Limit = UtilsService.formatValue(event.target.value.toString(), this.currency);
    }
    else if(type=='plein'){
      this.reassurance.Config_Premium_Part_Amount = UtilsService.formatValue(event.target.value.toString(), this.currency);
    }else if(type=='montantFranchise'){
      this.reassurance.Config_Premium_Deductible_Amount= UtilsService.formatValue(event.target.value.toString(), this.currency);
    }else if(type=='plafondIntervention'){
      this.reassurance.Config_Claim_Reas_Limit_out_of_deductible= UtilsService.formatValue(event.target.value.toString(), this.currency);
    }else if(type='MontantMaxSinistreCouvert'){
      this.reassurance.Config_Claim_Reas_Absolut_Limit= UtilsService.formatValue(event.target.value.toString(), this.currency);
    }else if(type=='seuilActivation'){
      this.reassurance.Config_Trigger_Claims_Amount_Sum= UtilsService.formatValue(event.target.value.toString(), this.currency);
    }else if(type=='MontantMaxPrisEnCharge'){
      this.reassurance.Config_Claims_Amount_Sum_Limit= UtilsService.formatValue(event.target.value.toString(), this.currency);
    }
    
  }
}
