import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IconFontAwesomeService {

  constructor(protected httpClient: HttpClient,) { }
  
  private url = 'https://font-awesome.neopolis-dev.com/font-awesome-pro@main/fontawesome-pro-6.5.1-web/metadata/icons.json';
  private manifestUrl = 'https://raw.githubusercontent.com/FortAwesome/Font-Awesome/master/metadata/icons.json';

  getFontAwesomeIcons(): Observable<any> {
    return this.httpClient.get(this.url);
  }

  searchFontAwesomeIcons(query: string): Observable<string[]> {
    return this.getFontAwesomeIcons().pipe(
      map((icons: any) => Object.keys(icons).filter(icon => icon.toLowerCase().includes(query.toLowerCase())))
    );
  }


}
