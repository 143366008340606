import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NotificationsService } from '../NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from '../../entity/NotificationMessage';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  webmailEx: boolean = true;
  forgetPassword: boolean = true;
  service_unavailable: boolean = false;
  errorRaised: boolean = false;
  alerts: any;
  buttons: any;
  navbar: any;
  constructor(private notificationsService: NotificationsService, private injector: Injector, private router: Router,private translate : TranslateService,) {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts=object.alerts
      this.buttons=object.buttons
      this.navbar=object.navbar
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts=object.alerts
      this.buttons=object.buttons
      this.navbar=object.navbar
      });
     });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        const notificationMessage = new NotificationMessage();
        if (error.status === 401) {
          if (error.error.message.includes('0792')) {
            if (!this.errorRaised) {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.ImpossibleValiderVotreIdentite;
              notificationMessage.message = this.alerts.sessionExpirer;
              notificationMessage.timeOut = 3000;
              this.notificationsService.sendMessage(notificationMessage);
            }
            this.router.navigate(['login']);
            localStorage.clear();
            localStorage.setItem('currentRoute', this.router.url);
            document.getElementById('loadingBar').style.display = 'none';
            this.errorRaised = true;
            return throwError(() => error);
          }
          if (error.error.message === 'Password invalide pour cette adresse mail') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.ImpossibleValiderVotreIdentite;
            notificationMessage.message = this.alerts.MerciVerifierVotreMotDePasse;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }

          if (error.error.message === "Il n'y a aucun compte associé à cette adresse mail ou Api-key " || error.error.message ==="Aucun compte n'est lié à cette adresse e-mail,API-key, ou il y a des autorisations manquantes. ") {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.ImpossibleValiderVotreIdentite;
            notificationMessage.message = this.alerts.aucuncompteassocie;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }
          if (error.error.message?.includes('error 1592')) {
            this.router.navigate(['IpNonAutorise']);
            return;
          }
        }

        if (error.status === 503 || error.status === 504 || error.status === 505) {
          if (error.error.message === "Echec d'authentification de l'email") {
            if (this.webmailEx === true) {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.navbar.webmail;
              notificationMessage.message = this.alerts.verifyWebmail;
              notificationMessage.timeOut = 3000;
              this.notificationsService.sendMessage(notificationMessage);
              this.webmailEx = false;
              return;
            } else {
              return;
            }
          }

          if (error.error.message === 'Failed VoIP login') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.VoIP;
            notificationMessage.message = this.alerts.verifyVoIP;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }

          // other gateway error
          if (!this.service_unavailable) {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Serviceindisponible;
            notificationMessage.message =
              this.alerts.RessayercontactSupport + error.error.message;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            this.service_unavailable = true;
          }
          return [error.error.message];
        }

        /* if (error.status === 409) {
          return [error.error.message];
        } */
        if (error.status === 406) {
          if (error.error.message === 'Aucun users correspond a id') {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = this.alerts.aucuncompteassocie;
            notificationMessage.timeOut = 3000;
            this.notificationsService.sendMessage(notificationMessage);
            return;
          }
        }

        // any other unmanaged error

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error}`;
        }
        // back end server offline or unavailable
        if (
          errorMessage?.includes('Http failure response') &&
          errorMessage?.includes('Error Code: 0') &&
          !this.service_unavailable
        ) {
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Serviceindisponible;
          notificationMessage.message =this.alerts.cnxBackImpossible;
          notificationMessage.timeOut = 3000;
          this.notificationsService.sendMessage(notificationMessage);
          this.service_unavailable = true;
        }
        return throwError(error);
      })
    );
  }
}
