import {FormField} from './FormField';

export class CheckboxFormly extends FormField {
  pattern=""
  urlValidation=""
   default=""
  autofill="";
  urlIdentifiant=""
  constructor() {
    super();
    super.icon="fa fa-check-square";
    this.type = 'boolean';
    super.name = 'case_a_cocher_' + super.generateRandomId();
    super.title = "C'est une case a cocher";
    super.typename = 'Check box';
  }
}
