import { CustomItems, CustomWidget } from './SharedElementsFormly';

/**
 * Json schema form input field
 */
export class FormField {
  toggle: boolean = false;
  disabled: boolean = false;
  name: string;
  typename: string;
  title: string = 'Texte';
  type: string = '';
  default: string | number;
  format: string;
  description: string;
  urlValidation : string;
  pattern : string;
  icon: string;
  widget: CustomWidget = new CustomWidget();
  items = new CustomItems();
autofill: string;
 urlIdentifiant: string;
  constructor() {
    this.widget.formlyConfig.templateOptions.col = '6';
  }

  generateRandomId() {
    const possible = '1234567890';
    const lengthOfCode = 4;
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
