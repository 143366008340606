export class Task {
  id: string = '';
  titre: string = '';
  description: string = '';
  statut: number = 0;
  affecte_a: string = '';
  date_limit: string = '';
  priorite: string = '';
  tags: any[] = [];
  prospect_id: string = '';
  id_entity: string = '';
  entity: string = '';
  url:any=''
}
