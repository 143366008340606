import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { BASE_PATH } from '../variables';
import { CustomHttpUrlEncodingCodec } from '../encoder';

@Injectable({
  providedIn: 'root'
})
export class ApiLogsService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders(); 
  public configuration = new Configuration();
  private _listners$ = new Subject<any>();
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }


  public listLogs(
    page: number,
    limit: number,
    sSearch: string,
    date_debut,
    date_fin,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    // let sort: string;
   

    if (page === null || page === undefined) {
      throw new Error('Required parameter filtresEndCreation was null or undefined when calling listLogs.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit was null or undefined when calling listLogs.');
    }

    

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    headers = headers.set('Content-Type', 'application/json');

    const request_body = {
      page: page,
      limit: limit,
      search: sSearch,
      date_debut,
      date_fin
    };
    return this.httpClient.request<any>('post', `${this.basePath}/rule/v0/get_post_processing_logs`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      observe: observe,
      headers: headers,
      reportProgress: reportProgress,
      body: request_body,
    });
  }

  exportToCsv(data: any[], filename: string): void {
    const csvData = this.convertToCsv(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  private convertToCsv(data: any[]): string {
    const csvRows = [];
    const headers = [
      'idEvent',
      'Event',
      'Execution date',
      'Rule ID',
      'entity',
      'entityId',
      'Status',
      'Parameter',
      'Message',
      'Input'
      
    ];
    csvRows.push(headers.join(','));
  
    for (const row of data) {
      const values = [
        row.id,
        row.source_event,
        row.last_execution,
        row.id_rule,
        row.entity,
        row.id_entity,
        row.status,
        row.params,
        row.msg,
        JSON.stringify(row.input).replace(/"/g, '""')
      ];
      csvRows.push(values.join(','))
    }
  
    return csvRows.join('\n');
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    };
  
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    
    // Replace the default separator with "_"
    return formattedDate.replace(/[,]/g, '_');
  }

  

  public reexecute(
    id_log: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/rule/v0/execute_rule/${id_log}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
  
}
