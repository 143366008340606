import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProspectCLientService } from 'src/app/services/prospect-client.service';
 

export class FilterBody {
  name: string = '';
  surname: string = '';
  mobile: string = '';
  info_com: {} = {};
}
@Component({
  selector: 'app-prospect-pro-filtred-list',
  templateUrl: './prospect-pro-filtred-list.component.html',
  styleUrls: ['./prospect-pro-filtred-list.component.css'],
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class ProspectProFiltredListComponent implements OnInit {
  list_prospect_pro: any[] = []
  limit = 5;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  filterBody: FilterBody = new FilterBody();
  loader: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private prospectCLientService: ProspectCLientService,
    private dialogRef: MatDialogRef<ProspectProFiltredListComponent>
  ) {
    this.filterBody = data.filterBody;
  }

  ngOnInit(): void {
    this.get_list_societe();
  }

  get_list_societe() {
    this.prospectCLientService.getListSociete(this.filterBody, this.page, this.limit).subscribe(
      (data) => {
        this.list_prospect_pro = data.list_societe;
        this.list_prospect_pro.forEach((element) => {});
        this.totalLength = data.iTotalDisplayRecords;
        this.calculate_nb_pages(this.totalLength);
        this.loader = false;
      },
      (error) => {
        console.log(error);
        this.loader = false;
        this.list_prospect_pro = [];
      }
    );
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page', this.page.toString());
    this.get_list_societe();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', this.page.toString());
    this.get_list_societe();
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.get_list_societe();
      }
    }
  }

  updateListProspectpro() {
    this.page = 1;
    this.get_list_societe();
  }

  onClose() {
    this.dialogRef.close();
  }

  sendDataIndismiss(item) {
    this.dialogRef.close(item);
  }

  selectedItems: Set<number> = new Set<number>();
  toggleSelection(index: number): void {
    if (this.selectedItems.has(index)) {
      this.selectedItems.delete(index);
    } else {
      this.selectedItems.add(index);
    }
  }

  isSelected(index: number): boolean {
    return this.selectedItems.has(index);
  }
}
