import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRdvService } from '../../services/ApiRdv/api-rdv.service';
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { take } from 'rxjs/operators';
import { RendezVousCalendar } from '../../entity/RendezVousCalendar';
import { SharedMenuObserverService } from '../../services/SharedMenuObserver/shared-menu-observer.service';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '../../shared/NotificationsService/notifications.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';


class eventCalendar {
  title: string = '';
  start: string = '';
  end: string = '';
  url: string = '';
  description: string = '';
}
interface filters {
  name: string;
  value: string;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [],
})
export class CalendarComponent implements OnInit {
  RendezVous: any[] = [];
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    datesSet: this.visibleRangeHandler.bind(this),
    selectable: true,
    initialView: 'dayGridMonth',
    eventClick: this.handleEventClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    select: this.handleDateSelect.bind(this),
    eventDrop: this.handleEventUpdate.bind(this),
    eventResize: this.handleEventUpdate.bind(this),
    timeZone: 'local',
    locale: 'fr',
    locales: [frLocale],
    events: this.RendezVous,
    allDaySlot: true, 
    eventContent: this.renderEventContent,
    headerToolbar: {
      center: 'dayGridMonth timeGridWeek timeGridDay',
      end: 'today prevYear,prev,next,nextYear', // will normally be on the right. if RTL, will be on the left
    },
    nowIndicator: true,
    editable: true,
  };

  selectedItemsString: string = '';
  importFile: any;
  importfileName: any;
  exportFromFile: boolean;
  exportFromUrl: boolean;
  getExportDocLoader: boolean;
  getExportUrlLoader: boolean;
  fileContentBase64: any;
  filenameExport: any;
  exportUrl: any;
  tooltipVisible: boolean=false;
  getImportUrlLoader: boolean;


  renderEventContent(eventInfo) {
    return {
      html: `
        <div class="fc-event-main containersEvent"  title="${eventInfo.event.title}" style="background-color: ${eventInfo.event.backgroundColor}; border-color: ${eventInfo.event.borderColor}; color: ${eventInfo.event.textColor}; padding: 5px; border-radius: 5px ; overflow: hidden; width: 100%;">
          ${eventInfo.event.title}
        </div>
      `,
    };
  }

  importedCalendars : any[] = []
  filters: filters[] = [
    { name: 'Tickets', value: 'ticket' },
    { name: 'Tâches', value: 'tache' },
    { name: 'évènements', value: 'reunion' },
    { name: 'RDVs', value: 'opportunite' },
  ];

  selected_rdv: RendezVousCalendar = null;
  @ViewChild('OppUpdateRdvModal') OppUpdateRdvModal: ElementRef;
  @ViewChild('OppCreateRdvModal') OppCreateRdvModal: ElementRef;
  @ViewChild('ImportCalendar') ImportCalendar: ElementRef;
  @ViewChild('ExportCalendar') ExportCalendar: ElementRef;
  private dateDebut: any = null;
  private dateEnd: any = null;
  private affecte: string = localStorage.getItem('id_user');
  eventsFullInfo: any[] = [];
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  updateListRdv: boolean = false;
  navbarTxt: string;
  alerts: any;
  buttons: any;
  currentLanguage: string;
  list_upcoming_rdvs: RendezVousCalendar[] = [];
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateDebutControl = new FormControl();
  public eventTitleControl = new FormControl('');
  public eventLinkControl = new FormControl('');
  public eventDescriptionControl = new FormControl('');
  public dateFinControl = new FormControl();
  minDate: Date;
  importUrl = new FormControl('');
  AgendaName = new FormControl('', Validators.required);
  constructor(
    private modalService: NgbModal,
    public NotificationsService: NotificationsService,
    private RdvService: ApiRdvService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService,
   
  ) {
    this.importUrl.valueChanges.subscribe(() => {
      this.validateUrl();
    });
  }


  validateUrl() {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;
    const isValid = this.importUrl.value && urlPattern.test(this.importUrl.value);
    this.importUrl.setErrors(isValid ? null : { invalidUrl: true });
  }

  parseDate(dateStr: string, format: string = 'YYYY-MM-DDThh:mm:ssZ') {
    if (dateStr === (undefined || null)) {
      return null;
    } else {
      return moment(dateStr, format).toDate();
    }
  }


  parse(value: string): any {
    const parts = value.split(',');
    const dateparts = parts[0].split('/');
    const timeparts = parts[1].split(':');
    const date = new Date(+dateparts[2], +dateparts[1] - 1, +dateparts[0], +timeparts[0], +timeparts[1], +timeparts[2]);

    return date;
  }

  changerdatedebut(event) {
    const date = this.parse(event.targetElement.value);
    this.dateDebutControl.setValue(date);
  }

  changerdatefin(event) {
    const date = this.parse(event.targetElement.value);
    this.dateFinControl.setValue(date);
  }

  translateFn(object: any) {
    this.navbarTxt = object.navbar.Agenda;
    this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    this.alerts = object.alerts;
    this.buttons = object.butttons;
    this.currentLanguage = this.translate.currentLang;
    this.filters = [
      { name: object.navbar.OppDetailsTickets, value: 'ticket' },
      { name: object.navbar.Tasks, value: 'tache' },
      { name: object.Agenda.events, value: 'reunion' },
      { name: object.Agenda.RDVs, value: 'opportunite' },
    ];
    this.changeCalendar();
  }

  ngOnInit(): void {
    this.selectedItems = [...this.filters];
    this.minDate = new Date();
    this.getImportedCalendars();
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.translateFn(object);
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.translateFn(object);
    });
  }

  showLoaderImportedCalenders ;boolean = false

  getImportedCalendars() {
    this.showLoaderImportedCalenders = true
    this.RdvService.getImportedCalendars().subscribe((data: any) => {
      this.importedCalendars = data;
      this.showLoaderImportedCalenders = false
    },
  ()=>{
    this.showLoaderImportedCalenders = false
  });
    
  }

  changeCalendar() {
    if (this.currentLanguage === 'en') {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [esLocale];
    } else {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [frLocale];
    }
  }

  handleEventClick(info) {
    info.jsEvent.preventDefault();
    
      if (info !== undefined && info !== null && info.event.id !== (undefined || null)) {
        this.selected_rdv = this.eventsFullInfo.filter((elem) => elem.id === info.event.id).pop();
        if (this.selected_rdv === (undefined || null)) {
          return;
        }
        if (this.selected_rdv.type !== 'reunion') {
          window.open(info.event.url, '_blank');
        } else {
        this.dateDebutControl.setValue(info.event.start);
        this.dateFinControl.setValue(info.event.end);
        this.eventTitleControl.setValue(info.event.title);
        this.eventLinkControl.setValue(info.event.url);
        this.eventDescriptionControl.setValue(this.selected_rdv.description);
        this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
        // window.open(info.event.url, '_blank');
      }
    }
  }

  clickTodayActivity(event) {
    
    
      if (event && event.id) {
        this.selected_rdv = this.eventsFullInfo.filter((elem) => elem.id === event.id).pop();
        if (this.selected_rdv === (undefined || null)) {
          return;
        }
        if (this.selected_rdv.type !== 'reunion') {
          window.open(event.event.url, '_blank');
        } else {
        this.dateDebutControl.setValue(event.start);
        this.dateFinControl.setValue(event.end);
        this.eventTitleControl.setValue(event.title);
        this.eventLinkControl.setValue(event.url);
        this.eventDescriptionControl.setValue(this.selected_rdv.description);
        this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
      }
    }
  }

  

  handleDateClick(event) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    if (event.date < today) {
      return;
    }
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      this.calendarComponent.getApi().changeView('timeGridDay', event.date.toISOString());
      return;
    }

    this.eventTitleControl.setValue('');
    this.eventLinkControl.setValue('');
    this.eventDescriptionControl.setValue('');
    this.dateDebutControl.setValue(event.date);
    this.modalService.open(this.OppCreateRdvModal, { ariaLabelledBy: 'modal-basic-title2' });
   
  }

  unattachedEvents(): void {
    this.dateDebutControl.setValue('');
    this.dateFinControl.setValue('');
    this.eventTitleControl.setValue('');
    this.eventLinkControl.setValue('');
    this.eventDescriptionControl.setValue('');
    this.modalService.open(this.OppCreateRdvModal, { ariaLabelledBy: 'modal-basic-title2' });
  
  }

  importCalendar() {
    this.modalService.open(this.ImportCalendar, { ariaLabelledBy: 'ImportCalendar' });
  }

  importCalendarInfo(){
    if (this.AgendaName.invalid) {
      // Mark the control as touched to display the error message
      this.AgendaName.markAsTouched();
      return;
    }else{ 
      if (this.importFromUrl){
        this.validateUrl();
        if (this.importUrl.invalid) {
          // Mark the control as touched to display the error message
          this.importUrl.markAsTouched();
          return;
        }else{
          this.getImportUrlLoader=true
        
        this.RdvService.importCalendarUrl(this.affecte, this.dateDebut, this.dateEnd, this.selectedItemsString,"url",this.importUrl.value , this.AgendaName.value).subscribe(
          (data) => {
            this.RendezVous = [];
          this.list_upcoming_rdvs=[]
            this.getImportUrlLoader=false

            this.eventsFullInfo = data;
              this.buildCalendarEvents(data);
              this.modalService.dismissAll()
              this.importUrl.reset()
              this.AgendaName.reset()
              this.getImportedCalendars()
          },
          (error) => {
            this.errorAlert(this.alerts.failedOperation, error.error.message);
            this.getImportUrlLoader=false
          }
        );
        }
      }else {
        if(this.importfileName){
          this.getImportUrlLoader=true
          const formData: FormData = new FormData();
          formData.append('file', this.importFile);
          this.RdvService.importCalendarFile(this.affecte, this.dateDebut, this.dateEnd, this.selectedItemsString,"file",formData,this.AgendaName.value).subscribe(
          (data) => {
            this.RendezVous = [];
          this.list_upcoming_rdvs=[]
            this.getImportUrlLoader=false

            this.eventsFullInfo = data;
              this.buildCalendarEvents(data);
              this.modalService.dismissAll()
              this.importfileName=""
              this.importFile=""
              this.AgendaName.reset()
              this.getImportedCalendars()
          },
          (error) => {
            this.errorAlert(this.alerts.failedOperation, error.error.message);
            this.getImportUrlLoader=false
          }
          );
        
        }
      }
}
  }

  exportCalendar() {
    this.modalService.open(this.ExportCalendar, { ariaLabelledBy: 'ExportCalendar' });
  }

  handleDateSelect(event) {
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      return;
    }
    this.dateDebutControl.setValue(event.start);
    this.dateFinControl.setValue(event.end);
    this.eventTitleControl.setValue('');
    this.eventLinkControl.setValue('');
    this.eventDescriptionControl.setValue('');
    this.modalService.open(this.OppCreateRdvModal, { ariaLabelledBy: 'modal-basic-title2' });
    // }
    // }
  }

  visibleRangeHandler(data) {
    const startDateList = data.startStr.split('T');
    const endDateList = data.endStr.split('T');
    if (startDateList[0] !== this.dateDebut && endDateList[0] !== this.dateEnd) {
      this.dateDebut = startDateList[0];
      this.dateEnd = endDateList[0];
      this.RechargerListeRdv();
    }
  }

  selectedItems: filters[] = [];
  onCheckboxChange(event: any, item: filters) {
    if (event.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem.value !== item.value);
    }
    this.selectedItemsString = this.selectedItems.map((item) => item.value).join(',');
    this.RechargerListeRdv();
  }


  updateSelectedItemsString() {
    this.selectedItemsString = this.selectedItems.map((item) => item.value).join(',');
  }

  ImportedCalendarsSelected: any[] = [];
  onCheckboxChangeImportedCalendars(event: any, item: any) {
    if (event.checked) {
      this.ImportedCalendarsSelected.push(item.name);
    } else {
      this.ImportedCalendarsSelected = this.ImportedCalendarsSelected.filter((selectedItem) => selectedItem !== item.name);
    }
    
    this.RechargerListeRdv();
  }
  

  // RechargerListeRdv() {
  //   this.updateListRdv = false;
  //   if (this.dateDebut !== null && this.dateEnd !== null) {
  //     this.RdvService.getListeRdv(this.affecte, this.dateDebut, this.dateEnd)
  //       .pipe(take(1))
  //       .subscribe((Response: RendezVousCalendar[]) => {
  //          this.buildCalendarRdv(Response);
  //         this.RendezVous = [];
  //         this.RdvService.getListEvenments(this.affecte, this.dateDebut, this.dateEnd,this.selectedItemsString)
  //           .pipe(take(1))
  //           .subscribe((Response: RendezVousCalendar[]) => {
  //             this.eventsFullInfo = Response;
  //             this.buildCalendarEvents(Response);
  //           });
  //       });
  //   }
  // }

  RechargerListeRdv() {
    this.updateListRdv = false;
    if (this.dateDebut !== null && this.dateEnd !== null) {
      this.RendezVous = [];
      this.list_upcoming_rdvs=[]
      this.RdvService.getListEvenments(this.affecte, this.dateDebut, this.dateEnd, this.selectedItemsString ,this.ImportedCalendarsSelected)
        .pipe(take(1))
        .subscribe((Response: RendezVousCalendar[]) => {
          this.eventsFullInfo = Response;
          this.buildCalendarEvents(Response);
        },(error) => {
          this.updateListRdv = true;
          this.errorAlert(this.alerts.failedOperation, error.error.message);
        });
    }
  }


  getExportUrl() {
    this.getExportUrlLoader=true
    this.RdvService.getExport(this.affecte, this.dateDebut, this.dateEnd, this.selectedItemsString,"url").subscribe(
      (data) => {
        this.getExportUrlLoader=false
        this.exportUrl=data.url_file
      },
      (error) => {
        this.errorAlert(this.alerts.failedOperation, error.error.message);
        this.getExportUrlLoader=false
      }
    );
  }

  getExportDoc() {
    this.getExportDocLoader=true;
    this.RdvService.getExport(this.affecte, this.dateDebut, this.dateEnd, this.selectedItemsString,"file").subscribe(
      (data) => {
      this.getExportDocLoader=false
      this.fileContentBase64=data.file_content_base64
      this.filenameExport=data.filename
      },
      (error) => {
        this.errorAlert(this.alerts.failedOperation, error.error.message);
        this.getExportDocLoader=false
      }
    );
  }

  
  downloadFile() {
    const linkSource = `data:text/calendar;base64,${this.fileContentBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = this.filenameExport;
  
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  copyToClipboard(url: string) {
    navigator.clipboard.writeText(url).then(() => {
      this.tooltipVisible = true;
      setTimeout(() => this.tooltipVisible = false, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }


  // private buildCalendarRdv(rendezVousCalendar: RendezVousCalendar[]) {
  //   this.RendezVous = [];
  //   this.list_upcoming_rdvs = [];
  //   rendezVousCalendar.forEach((element) => {
  //     const rdv = new RendezVousCalendar();
  //     rdv.title = element.title;
  //     rdv.id = element.id_opp_md5;
  //     rdv.backgroundColor = '#C0EEE4';
  //     rdv.url = './opportunities/details/' + element.id_opp_md5 + '/planification';
  //     rdv.start = element.start;
  //     rdv.end = element.end;
  //     rdv.borderColor= 'transparent';
  //     rdv.textColor = '#000000';
  //     rdv.icon="faCalendarCheck"
  //     this.RendezVous.push(rdv);
  //     if (new Date() > this.parseDate(element.start)) {
  //       this.list_upcoming_rdvs.push(rdv);
  //     }

  //   });
  // }

  private buildCalendarEvents(rendezVousCalendar: RendezVousCalendar[]) {
    if (rendezVousCalendar === null) {
      this.updateListRdv = true;
      this.calendarComponent.getApi()?.setOption('events', this.RendezVous);
      this.calendarComponent.getApi()?.setOption('visibleRange', {
        start: this.dateDebut,
        end: this.dateEnd,
      });
      return;
    }
    rendezVousCalendar.forEach((element) => {
      const rdv = new RendezVousCalendar();
      rdv.title = element.title;
      rdv.start = element.start;
      rdv.end = element.end;
      rdv.id = element.id;
      rdv.description = element.description;
      rdv.editable = false;
      rdv.startEditable = false;
      rdv.durationEditable = false;
      rdv.type=element.type;
      rdv.allDay = this.isAllDayEvent(element.start, element.end);
      if (element.type === 'ticket') {
        rdv.backgroundColor = '#99CDFF';
        rdv.url = './ticketsandsupport/details/' + element.id;
        rdv.allDay = true;
        rdv.icon = 'fa-tickets';
      } else if (element.type === 'tache') {
        rdv.backgroundColor = '#FDBB69';
        rdv.url = './tasks';
        rdv.icon = 'fa-list-check';
        rdv.allDay = true;
      } else if (element.type === 'reunion') {
        rdv.backgroundColor = '#83D199';
        rdv.editable = true;
      rdv.startEditable = true;
      rdv.durationEditable = true;
        rdv.url = element.url;

        rdv.icon = 'fa-users-rectangle'; 
      }else if (element.type === 'agenda'){
        rdv.backgroundColor = '#D49BFF';
        rdv.icon = 'fa-calendar-arrow-down';
        rdv.allDay = true;
      } else {
        rdv.url = './opportunities/details/' + element.id + '/planification';
        rdv.backgroundColor = '#FF808B';
        rdv.icon = 'fa-calendar-check';
      }
      rdv.borderColor = 'transparent';
      this.RendezVous.push(rdv);
      
      if (this.isSameDay(new Date(), this.parseDate(element.start))|| this.isSameDay(new Date(), this.parseDate(element.end))|| 
      this.isBetweenDates(new Date(), this.parseDate(element.start), this.parseDate(element.end))) {
        this.list_upcoming_rdvs.push(rdv);
      }
    });
    this.updateListRdv = true;
    this.exportUrl =""
    this.exportFromUrl=false
    this.exportFromFile=false
    this.calendarComponent?.getApi().setOption('events', this.RendezVous);
    this.calendarComponent?.getApi().setOption('visibleRange', {
      start: this.dateDebut,
      end: this.dateEnd,
    });
  }
  
  private isBetweenDates(date: Date, startDate: Date, endDate: Date): boolean {
    return date >= startDate && date <= endDate;
}
  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
}

  private isAllDayEvent(start: Date, end: Date): boolean {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startMidnight = startDate.getHours() === 0 && startDate.getMinutes() === 0;
    const endMidnight = endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getTime() === startDate.getTime() + (24 * 60 * 60 * 1000);
    return startMidnight && endMidnight;
  }


  /* mat-calendar */
  selectedDate: any;

  onSelect(event) {
    this.calendarComponent.getApi().gotoDate(event.toISOString());
  }

  handleEventUpdate(event) {
    this.dateDebutControl.setValue(event.event.start);
    this.dateFinControl.setValue(event.event.end);
    const start = new Date(this.dateDebutControl.value);
    const end = new Date(this.dateFinControl.value);
    this.selected_rdv = this.eventsFullInfo.filter((elem) => elem.id === event.event.id).pop();
    this.updateListRdv = false;

    this.addevent.title = event.event.title
    this.addevent.start = start.toISOString();
    this.addevent.end = end.toISOString();
    this.addevent.url = event.event.url
    this.addevent.description = this.selected_rdv.description
    const filteredEvent = {};
    for (const key in this.addevent) {
        if (this.addevent[key] !== null ) {
            filteredEvent[key] = this.addevent[key];
        }
    }
    this.RdvService.updateEvenments(event.event.id, filteredEvent).subscribe(
      (data) => {
        this.successAlert(this.alerts.updateEvent);
        this.RechargerListeRdv();
      },
      (error) => {
        this.errorAlert(this.alerts.failedOperation, error.error.message);
        this.updateListRdv = true;
      }
    );
  }

  updateRdvDetails() {
    if (!this.dateDebutControl.value) {
      this.errorAlert(this.alerts.wrongDate, '');
      return;
    }
    if (this.dateFinControl.value < this.dateDebutControl.value) {
      this.errorAlert(this.alerts.obligDateFin, '');
      return;
    }
    const start = new Date(this.dateDebutControl.value);
    const end = new Date(this.dateFinControl.value);

    this.modalService.dismissAll();
    this.updateListRdv = false;

    this.addevent.title = this.eventTitleControl.value;
    this.addevent.start = start.toISOString();
    this.addevent.end = end.toISOString();
    this.addevent.url = this.eventLinkControl.value;
    this.addevent.description = this.eventDescriptionControl.value;
    const filteredEvent = {};
    for (const key in this.addevent) {
        if (this.addevent[key] !== null ) {
            filteredEvent[key] = this.addevent[key];
        }
    }

    this.RdvService.updateEvenments(this.selected_rdv.id, filteredEvent).subscribe(
      (data) => {
        this.successAlert(this.alerts.updateEvent);
        this.RechargerListeRdv();
      },
      (error) => {
        
        this.errorAlert(this.alerts.failedOperation, error.error.message);
        this.updateListRdv = true;
      }
    );
  }

  successAlert(data) {
    swal.fire({
      title: data,
      text: this.alerts.Successoperation,
      icon: 'success',
      showConfirmButton: true,
    });
  }

  errorAlert(data, err) {
    swal.fire({
      title: data,
      text: err,
      icon: 'error',
      showConfirmButton: true,
    });
  }

  errorInfo() {
    swal.fire({
      title: this.alerts.noModif,
      confirmButtonText: this.buttons.annuler,
      icon: 'info',
    });
  }

  deleteRdv() {
    this.modalService.dismissAll();
    this.updateListRdv = false;

    this.RdvService.deleteEvenments(this.selected_rdv.id).subscribe(
      (data) => {
        this.successAlert(this.alerts.eventDeleted);
        this.RechargerListeRdv();
      },
      (error) => {
        this.errorAlert(this.alerts.eventDeleted, error);
        this.updateListRdv = true;
      }
    );
  }

  
  addevent: eventCalendar = new eventCalendar();

  
  createRdvDetails() {
    if (!this.dateDebutControl.value) {
      this.errorAlert(this.alerts.wrongDate, '');
      return;
    }
    if (this.dateFinControl.value < this.dateDebutControl.value) {
      this.errorAlert(this.alerts.dateSupp, '');
      return;
    }
    if (!this.eventTitleControl.value) {
      this.errorAlert(this.alerts.titreOblig, '');
      return;
    }
    const start = new Date(this.dateDebutControl.value);
    const end = new Date(this.dateFinControl.value);
    this.modalService.dismissAll();
    this.updateListRdv = false;
    this.addevent.title = this.eventTitleControl.value;
    this.addevent.start = start.toISOString();
    this.addevent.end = end.toISOString();
    this.addevent.url = this.eventLinkControl.value;
    this.addevent.description = this.eventDescriptionControl.value;
    const filteredEvent = {};
    for (const key in this.addevent) {
      if (this.addevent[key] !== null ) {
          filteredEvent[key] = this.addevent[key];
      }
  }
    this.RdvService.createEvenments(filteredEvent).subscribe(
      (data) => {
        this.successAlert(this.alerts.eventAdded);
        this.RechargerListeRdv();
      },
      (error) => {
        this.updateListRdv = true;
        this.errorAlert(this.alerts.failedOperation, error.error.message);
      }
    );
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return value;

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  expandFileName(name: string): string {
    if (name.length < 30) {
      return name;
    } else {
      return name.substring(0, 30) + '..';
    }
  }

  dateClass = (date: Date): string => {
    if (this.selectedDate && date.toDateString() === this.selectedDate.toDateString()) {
      return 'selected-date';
    }
    return '';
  }

  importFromUrl: boolean = false;
  importFromFile: boolean = false;

  onImportOptionChange(option: string) {
      if (option === 'url') {
          this.importFromUrl = true;
          this.importFromFile = false;
      } else if (option === 'file') {
          this.importFromUrl = false;
          this.importFromFile = true;
      }
  }

  onExportOptionChange(option: string) {
    if (option === 'url') {
        this.exportFromUrl = true;
        this.exportFromFile = false;
        this.getExportUrl()
    } else if (option === 'file') {
        this.exportFromUrl = false;
        this.exportFromFile = true;
        this.getExportDoc()
    }
}





  onFileChange(event: any) {
   
    const file = event.target.files[0];
    if (file) {
      if (this.isValidFileType(file)) {
       
      this.importFile = file;
      this.importfileName=file.name
    }


     
    }


}

removefile(){
  this.importFile = "";
  this.importfileName=""
}

expandName(name: string): string {
  name = name?.trim();
  if (name?.length < 40) {
    return name;
  } else {
    return name?.substring(0, 40) + '...';
  }
}


private isValidFileType(files): boolean {
  const allowedFileTypes = ['ics'];


    const fileExtension = this.getFileExtension(files.name).toLowerCase();
    if (!allowedFileTypes.includes(fileExtension)) {
      return false;
    }
 

  return true;
}

private getFileExtension(filename: string): string {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
}


}
