<div class="card-head">
  <div class="row">
    <div class="col-6">
      <label class="card-title">
        <i class="fa-solid fa-bars-staggered fa-lg"></i>
        Historique Transaction Utilisateur
      </label>
    </div>
  </div>
  <div class="card-gestion">
    <div class="table-responsive p-3">
      <table id="TableUtilisateur" class="table align-items-center ">
        <thead class="text-center style-card-thead">
          <tr>
            <th class="style-th-start"> # </th>
            <th class="style-th-center"> Date de création </th>
            <th class="style-th-center"> Description </th>
            <th class="style-th-end"> Montant</th>
          </tr>
        </thead>
        <tbody class="main-table-body text-center">
          <tr *ngFor="let user of historique_utilisateur;let index = index">
            <td class="style-td">{{index+1}}</td>
            <td class="style-td">{{user.date_de_creation}}</td>
            <td class="style-td">{{user.description}}</td>
            <td class="style-td">{{user.montant/100}} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card-head">
  <div class="row">
    <div class="col-6">
      <label class="card-title">
        <i class="fa-solid fa-bars-staggered fa-lg"></i>
        Historique Transaction Entreprise
      </label>
    </div>
  </div>
  <div class="card-gestion">
    <div class="table-responsive p-3">
      <table id="TableEntreprise" class="table align-items-center">
        <thead class="text-center style-card-thead">
          <tr>
            <th class="style-th-start"> # </th>
            <th class="style-th-center"> Date de création </th>
            <th class="style-th-center"> Description </th>
            <th class="style-th-center"> Transaction fait par</th>
            <th class="style-th-end"> Montant</th>
          </tr>
        </thead>
        <tbody class="main-table-body text-center">
          <tr *ngFor="let transaction of historique_company;let index = index">
            <td class="style-td">{{index+1}}</td>
            <td class="style-td">{{transaction.date_de_creation}}</td>
            <td class="style-td">{{transaction.description}}</td>
            <td class="style-td">{{transaction.fait_par}} </td>
            <td class="style-td">{{transaction.montant/100}} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
