<div class="row" style="margin-top: 15px">
  <div class="col-12">
    <div class="form-group">

      <mat-form-field class="example-full-width">
        <mat-label>{{ "languages.opportunite.label_nomandprenom"  | translate }} </mat-label>
        <input type="text" (change)="EmitFilterProspec()" [(ngModel)]="prospet.recheche_nom_prenom" matInput>
      </mat-form-field>

    </div>
  </div>
</div>
