
export class CustomItems {
  type: string;
  enum: string[] = [];
}

export class CustomOptions {
  value: string | number;
  label: string;

  constructor(label: string, value: string | number) {
    this.value = value;
    this.label = label;
  }
}

export class CustomTemplateOptions {
  options: CustomOptions[] = [];
  placeholder: string;
  rows: number;
  type: string;
  col: string;
  pattern;
  autofill ; 
  urlValidation;
  urlIdentifiant;
}

export class CustomFormlyConfig {
  type: string;
  templateOptions: CustomTemplateOptions = new CustomTemplateOptions();
}

export class CustomWidget {
  formlyConfig: CustomFormlyConfig = new CustomFormlyConfig();
}
