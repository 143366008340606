<div class="card bg-white border-0 p-3 customMargin" >
    <div class="card-doc-title ml-3 row">
<i class="fa-solid fa-credit-card mr-2 fa-lg mt-2"></i>
          {{'languages.affaire.fraisAndPaiment' | translate}}
    </div>
    <button type="button" class="btn-document-add btn-position-add"   [attr.data-title]="'languages.affaire.addunFrais' | translate" data-toggle="modal" data-target="#AjoutFraisModal">
        <i class="fa-solid fa-plus fa-lg"></i>
    </button>
    <ng-container *ngIf="!show_loading">
        <div class="row mb-3 mt-3">
            <div class="text-center m-auto ">
                <mat-spinner [diameter]="30"></mat-spinner>
            </div>
        </div>
    </ng-container>
    <ng-containner class="py-3" *ngIf="show_loading">
        <div [ngClass]="{'table-scroll': frais.length >6 }" id="listFrais">
            <table class="table align-items-center " id="dataTable1">
                <thead class="head-table">
                    <tr>
                        <th> {{'languages.listing.Type' | translate}}</th>
                        <th> {{'languages.affaire.montanHT' | translate}} ({{currency}})  </th>
                        <th>{{'languages.affaire.montantTTC' | translate}} ({{currency}})</th>
                        <th> {{'languages.listing.Statut' | translate}}</th>
                        <th> {{'languages.buttons.details' | translate}}</th>
                       
                    </tr>
                </thead>
                <tbody class="body-table">
                    <tr *ngFor="let fra of frais">
                        <td>{{fra.type}}</td>

                        <td>{{fra?.montant|currency:currency:'symbol':formatCurrency:'fr-FR'}}</td>
                        <td>{{fra?.montantTTC|currency:currency:'symbol':formatCurrency:'fr-FR'}}</td>
                        
                        <td>{{fra?.statut?.libelle}}</td>
                        <td>
                                                      
                            <button (click)="openTaxDetailsPopup(fra)" type="button"
                        class="btn-dt-list " data-toggle="modal" data-target="#consultFraisModal" > 
                        <img src="/assets/icons/share-icon.svg">
                    </button>
                        </td>

                    </tr>
                </tbody>
            </table> 
            <div *ngIf="frais.length < 1" class="row">
                <div class="col-12  d-flex justify-content-center">
                    <img src="assets/icons/nodata.svg">

                  </div>
                  <div class="col-12  d-flex justify-content-center">
                    <p class="noData"> {{'languages.affaire.noDataAvailable' | translate}}</p>
                  </div>
            </div>
        </div>
    </ng-containner>
</div>


<!-- Ajouter Frais Modal --------------------------------------------------------------------------------- -->


<div class="modal fade" id="AjoutFraisModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog model-xl modall" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <form (ngSubmit)="addFrais(myForm.form.valid,myForm._directives)" #myForm="ngForm">
                <div class="modal-header">
                    <h2 class="text-center title justify-content-center m-0 w-100" id="exampleModalLabel">{{'languages.affaire.addFrais' | translate}}</h2>
                    <button #closeModal (click)="emptyFrais()"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="conatainer-modal-elements">
                        <div class="row">
                            <div class="col-6 p-2">
                               
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>{{ 'languages.listing.Type' | translate }}</mat-label>
                                  
                                    <!-- Input field that triggers the autocomplete -->
                                    <input matInput
                                           [formControl]="typeControl"
                                           name="typefrais"
                                           type="text" required
                                           [matAutocomplete]="auto"
                                           [(ngModel)]="typefrais"
                                           >
                                  
                                    <!-- Autocomplete dropdown -->
                                    <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayContactFn">
                                      <mat-option *ngFor="let type of filteredTypeFrais$ | async"
                                                  [value]="type" 
                                                  (onSelectionChange)="onTypeSelected(type)">
                                        {{ type.type_frais }}
                                      </mat-option>
                                    </mat-autocomplete>
                                  
                                    <!-- Error messages -->
                                    <mat-error *ngIf="typeControl.hasError('required') && (typeControl.dirty || typeControl.touched)">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                        {{ 'languages.affaire.typeOblig' | translate }}
                                      </mat-error>
                                  </mat-form-field>
                                  
                                  
                                  
                            </div>
                            <div class="col-6 p-2 ">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>{{'languages.affaire.Montant' | translate}}</mat-label>
                                    <input matInput 
                                    [(ngModel)]="fraisInsert.montant"
                                    (change)="convertCurreny('montant',$event)" 
                                    type="number" 
                                    name="montant"
                                    #montant="ngModel"
                                    required
                                     placeholder="{{'languages.affaire.Montant' | translate}}" 
                                     min="0"
                                      appNotNegative >
                                      <mat-error *ngIf="montant.invalid && (montant.dirty || montant.touched)">
                                        <div *ngIf="montant.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.montantOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            
                            <div class="col-6  p-2  ">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>{{'languages.affaire.Note' | translate}}</mat-label>
                                    <input matInput [(ngModel)]="fraisInsert.note" name="note" placeholder="{{'languages.affaire.Note' | translate}}" >
                                  </mat-form-field>
                                  
                            </div>
                            
                            <div class="col-6  p-2  ">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>{{'languages.listing.Statut' | translate}} </mat-label>
                                    <mat-select required name="statut" #status="ngModel" placeholder="{{'languages.listing.Statut' | translate}}"   [(ngModel)]="fraisInsert.statut">
                                        <mat-option *ngFor="let statut of statutFrais" [value]="statut.id">
                                            {{statut.libelle}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="status.invalid && (status.dirty || status.touched)">
                                        <div *ngIf="status.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.statutOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div  class="d-flex row">
                            <div *ngFor="let tax of selectedTaxes" class="col-6">
                              <mat-form-field class="w-100" appearance="fill">
                                <mat-label>{{ tax.libelle }} ({{tax.type ==="P" ? "%" : currency}}) </mat-label>
                                <input matInput 
                                [(ngModel)]="taxInputs[tax.id]"
                                  #taxField="ngModel"
                                  name="{{ tax.id }}"
                                  required
                                  type="number" 
                                  [placeholder]="tax.libelle" 
                                  min="0"
                                  (change)="convertDynamicTaxes(tax.type ,tax.id, $event)"
                                  appNotNegative>
                                <mat-error *ngIf="taxField.invalid && (taxField.dirty || taxField.touched)">
                                  <div *ngIf="taxField.errors.required">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{ tax.libelle }}{{ 'languages.affaire.isRequired' | translate }}
                                  </div>
                                </mat-error>
                              </mat-form-field>
                            </div>
                         

                      
                           
                            
                           
                            <div  *ngIf="fraisInsert.statut === 'c4ca4238a0b923820dcc509a6f75849b'" class="col-6 ">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label> {{'languages.affaire.modePaiment' | translate}}</mat-label>
                                    <mat-select name="paiement" placeholder=" {{'languages.affaire.modePaiment' | translate}}"  #Modepayemnt="ngModel"
                                    required [(ngModel)]="fraisInsert.id_mode_paiement">
                                        <mat-option *ngFor="let paiement of allPaiement" [value]="paiement.id">
                                            {{paiement.libelle}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="Modepayemnt.invalid && (Modepayemnt.dirty || Modepayemnt.touched)">
                                        <div *ngIf="Modepayemnt.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.alerts.modepaiementOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                      


                    </div>
                </div>

                <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
                    <div class="col-6">
                        <ng-container *ngIf="show_loading_adding">
                            <div class="row mb-3 mt-3">
                                <div class="text-center m-auto ">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row text-center justify-content-center w-100">
                        
                            <button type="button" class="btn-dt-save-popup mr-2" type="submit">
<i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                                {{'languages.buttons.ajouter' | translate}}
                          </button>
                            <button data-dismiss="modal" aria-label="Close"  type="button" class="btn-dt-cancel-popup ml-2" (click)="emptyFrais()">
                                <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                            {{'languages.buttons.annuler' | translate}}

                          </button>
                    
                           
                        
                    </div>
                    </div>


            </form>
        </div>
    </div>
</div>



<!-- details frais -->
<div class="modal fade" id="consultFraisModal" tabindex="-1" role="dialog" aria-labelledby="taxDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="frais">
        <div class="modal-content">
            <div class="modal-header">
                
                <h2 class="text-center title justify-content-center m-0 w-100" id="exampleModalLabel">{{'languages.affaire.detailsFrais' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-2  px-4">
                <div class="m-3">
                    <div class="row p-0" >
                        <div class="col-6 p-0" >
                           
                            <div class="d-flex flex-row">
                                <b> {{'languages.listing.Type' | translate}} : </b> 
                                <p class="ml-2"> {{selectedFrais?.type}}</p>
                            </div>
                           
                        </div>
                        <div class="col-6 p-0" *ngIf="selectedFrais?.note">
                           
                            <div class="d-flex flex-row">
                                <b> {{'languages.affaire.Note' | translate}} : </b> 
                                <p class="ml-2"> {{selectedFrais?.note}}</p>
                            </div>
                           
                        </div>
                    </div>
                    <div class="row p-0" >
                        <div class="col-6 p-0" >
                           
                            <div class="d-flex flex-row">
                                <b> {{'languages.affaire.montanHT' | translate}} ({{currency}}) : </b> 
                                <p class="ml-2"> {{selectedFrais?.montant|currency:currency:'symbol':formatCurrency:'fr-FR'}}</p>
                            </div>
                           
                        </div>
                        <div class="col-6 p-0" >
                           
                            <div class="d-flex flex-row">
                                <b> {{'languages.affaire.montantTTC' | translate}} ({{currency}}) : </b> 
                                <p class="ml-2"> {{selectedFrais?.montantTTC|currency:currency:'symbol':formatCurrency:'fr-FR'}}</p>
                            </div>
                           
                        </div>
                    </div>
                    
                    
                </div>
               
                <div class="row flex-column  justify-content-center taxDiv  " *ngIf="selectedFrais?.taxes.length >0">
                    <div class="titleDetailsFrais text-center my-3 ">
                        {{'languages.listing.Taxes' | translate}}
                    </div>
    
                    <div class="row" >
                       
    
                        <div *ngFor="let field of selectedFrais?.taxes" class="col-6 pl-3 p-0 mb-2 d-flex taxFields" >
                            <b class="mx-1">{{ field.libelle }} ({{field.type ==="P" ? "%" : currency}}) : </b>
                            <p >{{ field.value }}</p>
                          </div>
                        </div>
                </div>
              
            </div>
           
          
        </div>
    </div>
</div>