<div class="row operateurContainer">
  <div class="col-2 d-flex justify-content-center align-items-center">

      
      <button (click)="selectOptionStatut('=')" class="operateur mr-3" [ngClass]="{ 'active':filterEcheance.operateur_status_echeances === '=' }">
        <i class="fa-solid fa-equals"></i>
    </button>
    <button (click)="selectOptionStatut('!=')"  class="operateur" [ngClass]="{ 'active': filterEcheance.operateur_status_echeances=== '!=' }">
        <i class="fa-solid fa-not-equal"></i>
    </button>
      </div>
    <div class="col-8 example-full-width">
        <mat-form-field class="example-full-width w-100">
          <mat-label> {{'languages.listing.Statut' | translate}}</mat-label>
          <mat-chip-list #chipList>
              <mat-chip *ngFor="let status of ListStatus" [selectable]="selectable" [removable]="removable" (removed)="removeStatus(status)" (removed)="EmitFilterEcheance()">
                  {{status}}
                  <i matChipRemove *ngIf="removable" class="fa fa-times" aria-hidden="true"></i>
              </mat-chip>
              <input (click)="EmitFilterEcheance()" placeholder="{{'languages.opportunite.label_status_select' | translate}}" #statusInput
              [formControl]="StatusFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedStatus($event)">
              <mat-option *ngFor="let status of filteredStatus | async" [value]="status.id">
                  {{status.libelle}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
    <div class="col-12">
      <div class="form-group">
        <mat-form-field class="example-full-width w-100 fontsDate" >
        <mat-date-range-input [rangePicker]="pickerDC" >
          <input matStartDate (change)="EmitFilterEcheance()"  (click)="openDateprelevement()"  readonly [(ngModel)]="date_debut_prelevement" placeholder="{{'languages.affaire.datePrelevementDu' | translate}}" />
          <input matEndDate (change)="EmitFilterEcheance()"  (click)="openDateprelevement()" readonly [(ngModel)]="date_fin_prelevement" placeholder="{{'languages.opportunite.label_a' | translate}}" />

      </mat-date-range-input>

      <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="pickerDC">    <mat-icon matDatepickerToggleIcon>
        <img src="assets/icons/calendar.svg" alt="CalendarIcon" > 
      </mat-icon></mat-datepicker-toggle>
      <img  class="remouveDate" matSuffix alt="deleteIcon"  (click)="clearDateprelevement()" src="assets/icons/delete-left.svg">
      <mat-date-range-picker (closed)="EmitFilterEcheance()" [calendarHeaderComponent]="rangeDatePrelevement" #pickerDC></mat-date-range-picker>

  
        </mat-form-field>
      </div>
    </div>





