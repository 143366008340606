import {Injectable} from '@angular/core';

@Injectable( { providedIn: 'root' } )
export class LocalStorageService {
  constructor() {}

  setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getSavedState(localStorageKey: string): any {
 
    try {
      const savedStateJSON = localStorage.getItem(localStorageKey);
      if (savedStateJSON !== null) {
          return JSON.parse(savedStateJSON);
      } else {
          return null; 
      }
  } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
  }
  }
}
