<div class="form-group">
        <mat-checkbox
            (change)="setAll($event.checked)"
            [color]="colors.primary"
            [disabled]="formControl.disabled"
            class="example-margin"
            [formControl]="formControl"
            [formlyAttributes]="field">
            {{ field.templateOptions.label }}
        </mat-checkbox>
    
        <!-- Refresh Button -->
        <button mat-icon-button *ngIf="formControl" matSuffix (click)="updateValue()">
            <mat-icon>refresh</mat-icon>
        </button>
    
        <div class="description-wrapper" *ngIf="field.templateOptions.description">
            <span class="description-text">{{ field.templateOptions.description }}</span>
        </div>
    </div>
    