import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FieldType} from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormbuilderValidationService } from 'src/app/services/formbuilder-validation.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage ,NotificationType } from 'src/app/entity/NotificationMessage';

@Component({
  selector: 'app-time-type',
  templateUrl: './time-type.component.html',
  styleUrls: ['./time-type.component.scss']
})
export class TimeTypeComponent extends FieldType implements OnInit {
  buttons: any;
  alerts: any;
  urlIdentifiant: any;
  loader: boolean;
  tickets_id: string;
  constructor(private validationService: FormbuilderValidationService,

    private notificationsService: NotificationsService,
    private translate : TranslateService,
    private routes: Router,
    private route: ActivatedRoute,
    
  ) {
    super();
  }

  searchValue: any;

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    this.searchValue = this.field.templateOptions?.autofill;
    this.urlIdentifiant =this.field.templateOptions?.urlIdentifiant 
    // Disable or enable based on autofill option
    if (this.field.templateOptions?.autofill) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

 
  updateValue(event: Event): void {
    const currentRoute = this.routes.url;
    if (currentRoute.includes('ticketsandsupport')) {
      this.route.paramMap.subscribe((params) => {
        this.tickets_id = params.get('tickets_id');
        this.loader=true
        this.validationService.getAllInfoTicket(this.tickets_id).subscribe(
          (response) => {
            this.loader=false
            if (this.urlIdentifiant) {
              this.loader=true
              const bodyTicket=response
              this.validationService.consumeAutoFillInputs(this.urlIdentifiant , bodyTicket).subscribe(
                (response) => {
                  this.loader=false
                    this.formControl.setValue(response.response.value);
                    const notificationMessage = new NotificationMessage();
                    notificationMessage.type = NotificationType.success;
                    notificationMessage.title = this.alerts.Successoperation;
                    notificationMessage.message = response?.message;
                    this.notificationsService.sendMessage(notificationMessage);
                },
                (error) => {
                  this.loader=false
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.error;
                notificationMessage.title = this.alerts.Operationechoue;
                if (error.status != "500"){
                  notificationMessage.message = error?.error?.message;
                }
                this.notificationsService.sendMessage(notificationMessage);
                }
              );
            } else {
              this.loader=false
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.Operationechoue;
              notificationMessage.message = this.alerts.urlServiceOblig;
              this.notificationsService.sendMessage(notificationMessage);
            }
             
          },
          (error) => {
          this.loader=false
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error?.message?.message;
          this.notificationsService.sendMessage(notificationMessage);
          }
        );

      });
    }


    
    }

}
