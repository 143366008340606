import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModelObject } from '../model/modelObject';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { Prospect } from '../../entity/Prospect';
import { Parrainage } from '../../entity/Generic/Parrainage';
import { Entreprise } from '../../entity/Opportunity/Entreprise';

@Injectable()
export class ApiProspectsService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  actionUpdateProspect$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  setActionUpdateProspect(value: boolean): void {
    this.actionUpdateProspect$.next(value);
  }
  getActionUpdateProspect(): Observable<boolean> {
    return this.actionUpdateProspect$.asObservable();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sert a parinner deux  add_prospect , inserer un add_prospect s’il n’existe pas , insérer une opportunité , ajouter une action
   *
   * @param parrainage id de rendez-vous
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addParrainageGet(
    parrainage: Parrainage,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_parrainage`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: parrainage,
    });
  }

  /**
   * Sert a ajouter une affaire
   *
   * @param reportProgress flag to report request and response progress.
   */
  public addAffaire(affaire: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_affaire`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: affaire,
    });
  }

  /**
   * Sert a parinner deux  add_prospect , inserer un add_prospect s’il n’existe pas , insérer une opportunité , ajouter une action
   *
   * @param prospect id de rendez-vous
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public UpdateProspect(prospect: Prospect, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (prospect === null || prospect === undefined) {
      throw new Error('Required parameter Prospect was null or undefined.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/updateprospect?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: prospect,
    });
  }

  /**
   * Sert a parinner deux  add_prospect , inserer un add_prospect s’il n’existe pas , insérer une opportunité , ajouter une action
   *
   * @param affaire_id id affaire
   * @param id_opportunite id opportunite
   */
  public UpdateEntreprise(
    affaire_id: string,
    entreprise: Entreprise,
    id_opportunite?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entreprise === null || entreprise === undefined) {
      throw new Error('Required parameter Prospect was null or undefined.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (affaire_id !== undefined && affaire_id !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>affaire_id);
    }
    if (id_opportunite !== undefined && id_opportunite !== null) {
      queryParameters = queryParameters.set('id_opp', <any>id_opportunite);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_prospect/${entreprise.mainProspect.id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: entreprise,
    });
  }

  /**
   * Permet d&#x27;afficher les informations sur l&#x27;opportunité et l&#x27;affaire de add_prospect suivant l&#x27;identifiant de add_prospect codé en md5
   *
   * @param idAffaire Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public getInfoProspectByIdAffaire(
    idAffaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_prospect_by_id_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getProspectByIdAffaire(
    idAffaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_aff', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/V1/get_prospect?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public GetContratActuel(id_opp: string): Observable<any> {
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getCommentaireOpportuniteGet.');
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_opp !== undefined && id_opp !== null) {
      queryParameters = queryParameters.set('id_opp', id_opp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_ancien_contrat/${id_opp}?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * Sert a insérer un add_prospect
   *
   * @param prospect l'objet add_prospect avec tous ses informations
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertProspectCompGet(
    prospect: Prospect,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (prospect.users_nom === null || prospect.users_nom === undefined) {
      throw new Error('Required parameter name was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.users_prenom === null || prospect.users_prenom === undefined) {
      throw new Error('Required parameter surname was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.DN === null || prospect.DN === undefined) {
      throw new Error('Required parameter dateNaiss was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.tel === null || prospect.tel === undefined) {
      throw new Error('Required parameter tel was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.nb_enfants === null || prospect.nb_enfants === undefined) {
      throw new Error('Required parameter nbEnfant was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.ville === null || prospect.ville === undefined) {
      throw new Error('Required parameter ville was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.CP === null || prospect.CP === undefined) {
      throw new Error('Required parameter code was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.streetName === null || prospect.streetName === undefined) {
      throw new Error('Required parameter rue was null or undefined when calling insertProspectCompGet.');
    }

    if (prospect.streetNumber === null || prospect.streetNumber === undefined) {
      throw new Error('Required parameter numero was null or undefined when calling insertProspectCompGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (prospect.civilite !== undefined && prospect.civilite !== null) {
      queryParameters = queryParameters.set('civilite', <any>prospect.civilite);
    }
    if (prospect.users_nom !== undefined && prospect.users_nom !== null) {
      queryParameters = queryParameters.set('name', <any>prospect.users_nom);
    }
    if (prospect.users_prenom !== undefined && prospect.users_prenom !== null) {
      queryParameters = queryParameters.set('surname', <any>prospect.users_prenom);
    }
    if (prospect.DN !== undefined && prospect.DN !== null) {
      queryParameters = queryParameters.set('date_naiss', <any>prospect.DN);
    }
    if (prospect.tel !== undefined && prospect.tel !== null) {
      queryParameters = queryParameters.set('tel', <any>prospect.tel);
    }
    if (prospect.mobile !== undefined && prospect.mobile !== null) {
      queryParameters = queryParameters.set('mobile', <any>prospect.mobile);
    }
    if (prospect.tel2 !== undefined && prospect.tel2 !== null) {
      queryParameters = queryParameters.set('tel2', <any>prospect.tel2);
    }
    if (prospect.nb_enfants !== undefined && prospect.nb_enfants !== null) {
      queryParameters = queryParameters.set('nb_enfant', <any>prospect.nb_enfants);
    }
    if (prospect.adresse_mail !== undefined && prospect.adresse_mail !== null) {
      queryParameters = queryParameters.set('mail', <any>prospect.adresse_mail);
    }
    if (prospect.description !== undefined && prospect.description !== null) {
      queryParameters = queryParameters.set('commentaire', <any>prospect.description);
    }
    if (prospect.ville !== undefined && prospect.ville !== null) {
      queryParameters = queryParameters.set('ville', <any>prospect.ville);
    }
    if (prospect.CP !== undefined && prospect.CP !== null) {
      queryParameters = queryParameters.set('code', <any>prospect.CP);
    }
    if (prospect.streetName !== undefined && prospect.streetName !== null) {
      queryParameters = queryParameters.set('rue', <any>prospect.streetName);
    }
    if (prospect.streetNumber !== undefined && prospect.streetNumber !== null) {
      queryParameters = queryParameters.set('numero', <any>prospect.streetNumber);
    }
    if (prospect.adresses_ville !== undefined && prospect.adresses_ville !== null) {
      queryParameters = queryParameters.set('complement', <any>prospect.adresses_ville);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/insert_prospect_comp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher les informations sur un add_prospect suivant son numero de tel ou mobile s&#x27;il existe dans page blanche
   *
   * @param listProspect Numero de telephone de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fussionProspectDoublon(
    listProspect: string[],
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const listProspectStr = listProspect.join(',');

    if (listProspect === null || listProspect === undefined) {
      throw new Error('Required parameter num was null or undefined when calling listProsWithTelGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (listProspectStr !== undefined && listProspectStr !== null) {
      queryParameters = queryParameters.set('list_prospect', <any>listProspectStr);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/fusion_prospect?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher les informations sur un add_prospect suivant son numero de tel ou mobile s&#x27;il existe dans page blanche
   *
   * @param tel Numero de telephone de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listProsWithTelGet(tel: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (tel === null || tel === undefined) {
      throw new Error('Required parameter num was null or undefined when calling listProsWithTelGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (tel !== undefined && tel !== null) {
      queryParameters = queryParameters.set('tel', <any>tel);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/select_prospect_with_tel?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * insérer un prospecct et le parrainer avec un autre (prospect_id)
   *
   * @param nom nom de nouveau add_prospect
   * @param prenom prenom de nouveau add_prospect
   * @param tel telephone de nouveau add_prospect
   * @param prospectId identifiant de prospcet a parrainer avec codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public parrainageProspectsGet(
    nom: ModelObject,
    prenom: ModelObject,
    tel: ModelObject,
    prospectId: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public parrainageProspectsGet(
    nom: ModelObject,
    prenom: ModelObject,
    tel: ModelObject,
    prospectId: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public parrainageProspectsGet(
    nom: ModelObject,
    prenom: ModelObject,
    tel: ModelObject,
    prospectId: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public parrainageProspectsGet(
    nom: ModelObject,
    prenom: ModelObject,
    tel: ModelObject,
    prospectId: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (nom === null || nom === undefined) {
      throw new Error('Required parameter nom was null or undefined when calling parrainageProspectsGet.');
    }

    if (prenom === null || prenom === undefined) {
      throw new Error('Required parameter prenom was null or undefined when calling parrainageProspectsGet.');
    }

    if (tel === null || tel === undefined) {
      throw new Error('Required parameter tel was null or undefined when calling parrainageProspectsGet.');
    }

    if (prospectId === null || prospectId === undefined) {
      throw new Error('Required parameter prospectId was null or undefined when calling parrainageProspectsGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (nom !== undefined && nom !== null) {
      queryParameters = queryParameters.set('nom', <any>nom);
    }
    if (prenom !== undefined && prenom !== null) {
      queryParameters = queryParameters.set('prenom', <any>prenom);
    }
    if (tel !== undefined && tel !== null) {
      queryParameters = queryParameters.set('tel', <any>tel);
    }
    if (prospectId !== undefined && prospectId !== null) {
      queryParameters = queryParameters.set('prospect_id', <any>prospectId);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/parrainage_prospects`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la liste des add_prospect dont le nom ou le prenom similaire a la parmamétre d&#x27;entreé
   *
   * @param dataQ Une partie de nom ou prenom des add_prospect
   * @param idOrg Identifiant de l&#x27;organisme
   * @param typeOrg type de l&#x27;organisme
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheProspectGeneralGet(
    dataQ: ModelObject,
    idOrg: ModelObject,
    typeOrg: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public rechercheProspectGeneralGet(
    dataQ: ModelObject,
    idOrg: ModelObject,
    typeOrg: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public rechercheProspectGeneralGet(
    dataQ: ModelObject,
    idOrg: ModelObject,
    typeOrg: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public rechercheProspectGeneralGet(
    dataQ: ModelObject,
    idOrg: ModelObject,
    typeOrg: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheProspectGeneralGet.');
    }

    if (idOrg === null || idOrg === undefined) {
      throw new Error('Required parameter idOrg was null or undefined when calling rechercheProspectGeneralGet.');
    }

    if (typeOrg === null || typeOrg === undefined) {
      throw new Error('Required parameter typeOrg was null or undefined when calling rechercheProspectGeneralGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }
    if (idOrg !== undefined && idOrg !== null) {
      queryParameters = queryParameters.set('id_org', <any>idOrg);
    }
    if (typeOrg !== undefined && typeOrg !== null) {
      queryParameters = queryParameters.set('type_org', <any>typeOrg);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_prospect_general`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la liste des add_prospect suivant une partie de leur nom ou prenom
   *
   * @param dataQ Une partie de  nom ou prenom de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheProspectGet(dataQ: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheProspectGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_prospect`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la liste des add_prospect suivant une partie de leur nom ou prenom
   *
   * @param IdOpp id de l'opportunite
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectDoublon(IdOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (IdOpp === null || IdOpp === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheProspectGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (IdOpp !== undefined && IdOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>IdOpp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_prospect_doublon?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la liste des add_prospect suivant une partie de leur nom ou prenom
   *
   * @param CodePostal code postale de la ville
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListVille(CodePostal: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (CodePostal === null || CodePostal === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheProspectGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (CodePostal !== undefined && CodePostal !== null) {
      queryParameters = queryParameters.set('cp', <any>CodePostal);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_ville?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des information sur le add_prospect et ses tags suivant son identifiant code en md5 pour l’auto-remplissage des champs dans l’IHM
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectProspectWithIdGet(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id_prospect', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/select_prospect_with_id?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des information sur le add_prospect et ses tags suivant son identifiant code en md5 pour l’auto-remplissage des champs dans l’IHM
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectByOpprtuniteId(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('id_opp', <any>id);

    return this.httpClient.request<any>('get', `${this.basePath}/V1/get_prospect`, {
      withCredentials: this.configuration.withCredentials,
      params: queryParameters,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des information de prospecct
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectId(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('id_prospect', <any>id);
    return this.httpClient.request<any>('get', `${this.basePath}/V2/get_prospect`, {
      withCredentials: this.configuration.withCredentials,
      params: queryParameters,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des Relations de prospecct
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectRelations(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_prospects_relations/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des Tickets  de prospecct
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectTickets(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_ticket_by_prospect/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher les opportunite   de prospecct
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpportuniteByProspec(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_opportunite_affaire_pros/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher les societe   de prospecct
   *
   * @param id Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListSocieteByPros(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_relation_pro_pros/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * sert a afficher des information sur le add_prospect et ses tags suivant son nom ville ,rue, numero de rue et numero de telephone de add_prospect (Il sert a remplir les champs dans L&#x27;IHM pou l&#x27;autoremplissage dans l&#x27;IHM)
   *
   * @param ville Ville de add_prospect
   * @param rue rue de add_prospect
   * @param numero Numero de rue de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectProspectWithTelNomAdresseGet(
    ville: ModelObject,
    rue: ModelObject,
    numero: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public selectProspectWithTelNomAdresseGet(
    ville: ModelObject,
    rue: ModelObject,
    numero: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public selectProspectWithTelNomAdresseGet(
    ville: ModelObject,
    rue: ModelObject,
    numero: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public selectProspectWithTelNomAdresseGet(
    ville: ModelObject,
    rue: ModelObject,
    numero: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ville === null || ville === undefined) {
      throw new Error(
        'Required parameter ville was null or undefined when calling selectProspectWithTelNomAdresseGet.'
      );
    }

    if (rue === null || rue === undefined) {
      throw new Error('Required parameter rue was null or undefined when calling selectProspectWithTelNomAdresseGet.');
    }

    if (numero === null || numero === undefined) {
      throw new Error(
        'Required parameter numero was null or undefined when calling selectProspectWithTelNomAdresseGet.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (ville !== undefined && ville !== null) {
      queryParameters = queryParameters.set('ville', <any>ville);
    }
    if (rue !== undefined && rue !== null) {
      queryParameters = queryParameters.set('rue', <any>rue);
    }
    if (numero !== undefined && numero !== null) {
      queryParameters = queryParameters.set('numero', <any>numero);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/select_prospect_with_tel_nom_adresse`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a modifier la fiche decouverte d&#x27;un add_prospect
   *
   * @param ficheDec Fiche decouverte de add_prospect
   * @param idPros Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateFicheDecGet(
    ficheDec: ModelObject,
    idPros: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public updateFicheDecGet(
    ficheDec: ModelObject,
    idPros: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public updateFicheDecGet(
    ficheDec: ModelObject,
    idPros: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public updateFicheDecGet(
    ficheDec: ModelObject,
    idPros: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ficheDec === null || ficheDec === undefined) {
      throw new Error('Required parameter ficheDec was null or undefined when calling updateFicheDecGet.');
    }

    if (idPros === null || idPros === undefined) {
      throw new Error('Required parameter idPros was null or undefined when calling updateFicheDecGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (ficheDec !== undefined && ficheDec !== null) {
      queryParameters = queryParameters.set('fiche_dec', <any>ficheDec);
    }
    if (idPros !== undefined && idPros !== null) {
      queryParameters = queryParameters.set('id_pros', <any>idPros);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/update_fiche_dec`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public rechercheSociete(
    entreprise: Entreprise,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entreprise === null || entreprise === undefined) {
      throw new Error('Required parameter Prospect was null or undefined.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/recherche_societe`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: entreprise,
    });
  }

  public getSocieteById(_id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (_id === null || _id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling selectProspectWithIdGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // if (_id !== undefined && _id !== null) {
    //   queryParameters = queryParameters.set('_id', <any>_id);
    // }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_societe/${_id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getProspectTel(id_prospect: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id_prospect === null || id_prospect === undefined) {
      throw new Error('Required parameter id_opp was null or undefined when calling getAlertGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_prospect !== undefined && id_prospect !== null) {
      queryParameters = queryParameters.set('id_prospect', id_prospect as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_prospect_tel`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  private access$ = new BehaviorSubject<any>({});
  AllowToupdate$ = this.access$.asObservable();

  setAccess(access: any) {
    this.access$.next(access);
  }









}






