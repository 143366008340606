import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { environment } from 'src/environments/environment';
import { Qualite } from 'src/app/entity/Affaires/FilterAffaires';

@Component({
  selector: 'app-bloc-qualite',
  templateUrl: './bloc-qualite.component.html',
  styleUrls: ['./bloc-qualite.component.css'],
})
export class BlocQualiteComponent implements OnInit {
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterBlocQualite: EventEmitter<any> = new EventEmitter();
  filterQualite: Qualite = new Qualite();
  keyFormQualite: string;
  form: any;
  formdataspecif: any;
  options: {};
  model: {};
  fields: any;
  filtre: any;
  constructor(
    private chRef: ChangeDetectorRef,
    private apiConfigurationService: ApiConfigurationService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  ngOnInit(): void {
    this.keyFormQualite = environment.keyFormQuality;

    this.getFormParamConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterQualiteForm(changes.Reset.currentValue);
  }

  resetfilterQualiteForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.model = {};
      if (this.filtre && this.filtre.aff && this.filtre.aff.qualite) {
        this.filtre.aff.qualite.data = {};
      }

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterBlocQualite.emit(this.filterQualite);
    }
  }

  EmitFilterQualite() {
    this.filterQualite.data = Object.keys(this.form.value).length !== 0 ? this.form.value : this.filterQualite.data;

    for (const key in this.filterQualite.data) {
      if (!this.filterQualite.data[key] || this.filterQualite.data[key]?.length === 0) {
        delete this.filterQualite.data[key];
      }
    }
    this.onPushFilterBlocQualite.emit(this.filterQualite);
  }

  getFormParamConfig(): void {
    this.apiConfigurationService.getParamConfig(this.keyFormQualite).subscribe((Response) => {
      this.form = new FormGroup({});
      if (Response.value != null) {
        this.formdataspecif = Response.value;
        this.options = {};
        if (Response.value.schema) {
          const form: any = Response.value.schema.properties;

          Response.value.schema.properties = {};
          this.model = {};
          form.forEach((element) => {
            element.default ="";
            Response.value.schema.properties[element.name] = element;
            if (this.model[element.name]) this.model[element.name] = element.default;
          });

          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(Response.value.schema)))];
        }
      }
      this.filtre = localStorage.getItem('filtreAffaire');
      this.filtre = JSON.parse(this.filtre);
      if (this.filtre) {
        this.filterQualite.data = this.filtre.aff.qualite.data;
        this.onPushFilterBlocQualite.emit(this.filterQualite);
        for (const [key, value] of Object.entries(this.filtre.aff.qualite.data)) {
          if (value != null) this.model[key] = value;
        }
      }
    });
  }

  onModelChange($event: any) {
    this.EmitFilterQualite();
  }
}
