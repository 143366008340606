import { Component, OnInit } from '@angular/core';
// import { Reclamation } from 'src/app/entity/TicketsAndSupport/FilterTicket';

@Component({
  selector: 'app-filter-reclamation-ticket',
  templateUrl: './filter-reclamation-ticket.component.html',
  styleUrls: ['./filter-reclamation-ticket.component.css']
})
export class FilterReclamationTicketComponent implements OnInit {

  // reclamation:Reclamation=new Reclamation(); 

  constructor() { }

  ngOnInit(): void {
  }


  EmitFilterRec(){

  }


  // onItemChange() {
  //   console.log(this.reclamation);
  // }
}
