<mat-card class="mat-elevation-z3">
    <button
      *ngFor="let item of customPresets"
      mat-button
      color="primary"
      (click)="selectRange(item.key)"
    >
      {{ item.label }}
    </button>
  </mat-card>
  