import {FormField} from './FormField';

export class EmailFormly extends FormField {
  maxLength=255;
  minLength=0;
  pattern="";
  autofill="";
  format="";
  description=""
  urlValidation=""
  urlIdentifiant=""
  default=""
  constructor() {
    super();
    // super.icon="fa fa-at";
    super.icon="fa fa-at";
    this.pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$";
    this.widget.formlyConfig.templateOptions.pattern ="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$";
    super.name = 'texte_' + super.generateRandomId();
    this.type = 'email';
    this.title='E-mail';
    super.typename = 'Email';
  }
}
