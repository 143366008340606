import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private fetchDataSubject = new BehaviorSubject<boolean>(false);
  fetchData$: Observable<boolean> = this.fetchDataSubject.asObservable();

  constructor() {}

  sendFetchDataSignal(signal: boolean) {
    this.fetchDataSubject.next(signal);
  }
}
