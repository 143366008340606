
<div class="form-group">
    <mat-form-field class="full-width">
        <mat-label>{{ field.templateOptions.label }}</mat-label>
        <input
            matInput
            type="time"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [disabled]="formControl.disabled"
        />
        <!-- Refresh Button -->
        <button mat-icon-button type="button" *ngIf="searchValue " matSuffix (click)="updateValue()">
            <mat-icon *ngIf="!loader" >refresh</mat-icon>
            <mat-spinner *ngIf="loader" [diameter]="15"></mat-spinner>
          </button>

        <div class="description-wrapper" *ngIf="field.templateOptions.description">
            <span class="description-text">{{ field.templateOptions.description }}</span>
        </div>

    </mat-form-field>
</div>
