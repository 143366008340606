<div class="row" style="margin-top: 15px">
  <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>{{'languages.opportunite.createdBy' | translate }}</mat-label>
          <ng-container class="p-3" *ngIf="allCreateur?.length === 0">
            <div class="row">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #create>
            <mat-chip *ngFor="let user of ListCreateur" [selectable]="selectable" [removable]="removable"
              (removed)="removeCreateur(user)" (removed)="EmitFilterUtilisateur()">
              {{user}}
              <i class="fa fa-times" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input [formControl]="CrearteurFromCtrl" (click)="EmitFilterUtilisateur()"
              placeholder="{{'languages.ticket.selectTypeCreateur' | translate }}"  #CreateurInput [matAutocomplete]="autoCreateur"
              [matChipInputFor]="create" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
            <mat-option *ngFor="let user of filteredCreateur | async" [value]="user.id">
              {{user.nom + " "+ user.prenom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>{{'languages.alerts.affectedTo' | translate }}</mat-label>
          <ng-container class="p-3" *ngIf="allAffecte?.length === 0">
            <div class="row">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #affecte>
            <mat-chip *ngFor="let user of ListAffecte" [selectable]="selectable" [removable]="removable"
              (removed)="removeAffecte(user)" (removed)="EmitFilterUtilisateur()">
              {{user}}
              <i class="fa fa-times" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input [formControl]="AffecteFromCtrl" (click)="EmitFilterUtilisateur()" placeholder="{{'languages.alerts.affectedTo' | translate }} "
              #AffecteInput [matAutocomplete]="autoAffecte" [matChipInputFor]="affecte"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoAffecte="matAutocomplete" (optionSelected)="selectedAffecte($event)">
            <mat-option *ngFor="let user of filteredAffecte | async" [value]="user.id">
              {{user.nom + " "+ user.prenom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
