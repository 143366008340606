<div class="row" style="margin-top: 15px">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -19px;">
                <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="date_creation_action" matStartDate placeholder="Date d'action du" />
                    <input [(ngModel)]="date_fin_action" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterActionUtilisateur()" #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allUser.length > 0">
                <mat-form-field style="width: 100%;margin-top: -20px;">
                    <mat-label>Recherche Utilisateurs</mat-label>
                    <mat-chip-list #RechercheUtilisateurs>
                        <mat-chip *ngFor="let user of ListUser" [selectable]="selectable" [removable]="removable"
                            (removed)="removeUser(user)" (removed)="EmitFilterActionUtilisateur()">
                            {{user}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        
                        </mat-chip>
                        <input (click)="EmitFilterActionUtilisateur()" placeholder="Selectionner Utilisateurs..."
                            #userInput [formControl]="userFormCtrl" [matAutocomplete]="autoUser"
                            [matChipInputFor]="RechercheUtilisateurs"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selectedUser($event)">
                        <mat-option *ngFor="let filteredUser of filteredUser | async" [value]="filteredUser.id">
                            {{filteredUser.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">

    <div class="col-2">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input (click)="EmitFilterActionUtilisateur()" [(ngModel)]="filterActionUtilisateur.dernier_action"
                    class="checkbox" type="checkbox" id="lastAction" (change)="checking($event)">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-2">
        <div class="form-group">
            <label>Dérniere action</label>
        </div>
    </div>

    <div class="col-2">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input [(ngModel)]="filterActionUtilisateur.enreg_simple" (click)="EmitFilterActionUtilisateur()"
                    class="checkbox" type="checkbox" id="enregistrementSimple" (change)="simple($event)">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-2">
        <div class="form-group">
            <label for="enregistrementSimple">{{'languages.opportunite.label_simpleregistration' | translate}}</label>
        </div>
    </div>

    <div class="col-2">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input [(ngModel)]="filterActionUtilisateur.enreg_avec_action" (click)="EmitFilterActionUtilisateur()"
                    class="checkbox" type="checkbox" id="enregistrementAction" (change)="action($event)">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div> <div class="col-2">
        <div class="form-group">
            <label for="enregistrementAction">{{'languages.opportunite.label_registeraction' | translate}}</label>
        </div>
    </div>

</div>
