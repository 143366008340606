<div class="row" style="margin-top: 15px">
    <div class="col-6">
        <div class="form-group">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label> {{"languages.ticket.numTicket" | translate }}</mat-label>
                    <input type="text" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel" (change)="EmitFilterNumTickets()" name="num" [(ngModel)]="ticket.recheche_num_ticket" matInput>
                </mat-form-field>
                <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                    <div *ngIf="nombre.errors.pattern">
                        <i class="fa fa-exclamation-triangle"></i>&nbsp; {{"languages.opportunite.check_onlyNumber" | translate }}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label> {{"languages.ticket.titreTicket" | translate }} </mat-label>
                    <input type="text" (change)="EmitFilterNumTickets()" name="titre" [(ngModel)]="ticket.recheche_sujet_ticket" matInput>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDC">
                    <input matStartDate [(ngModel)]="date_debut_creation"  readonly placeholder="{{'languages.ticket.dateCreation' | translate }}"  (click)="openDateCreation()"/>
                    <input matEndDate [(ngModel)]="date_fin_creation" readonly  placeholder="{{'languages.communication.toa' | translate }}"  (click)="openDateCreation()"/>

                </mat-date-range-input>
                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="pickerDC">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterNumTickets()" [calendarHeaderComponent]="rangeDateCreation" #pickerDC></mat-date-range-picker>
                
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDT">
                    <input matStartDate [(ngModel)]="date_debut_traitement" readonly placeholder="{{'languages.ticket.dateTraitement' | translate }}"  (click)="openDateTraitement()"/>
                    <input matEndDate [(ngModel)]="date_fin_traitement" readonly placeholder="{{'languages.communication.toa' | translate }}"  (click)="openDateTraitement()" />
                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="pickerDT">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateTraitement()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterNumTickets()" [calendarHeaderComponent]="rangeDateTraitement" #pickerDT></mat-date-range-picker>


            </mat-form-field>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDL">
                    <input matStartDate [(ngModel)]="date_debut_limit" readonly placeholder="{{'languages.ticket.LimitDate' | translate }}" (click)="openDateLimit()"/>
                    <input matEndDate [(ngModel)]="date_fin_limit" readonly placeholder="{{'languages.communication.toa' | translate }}" (click)="openDateLimit()"/>
                </mat-date-range-input>
                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="pickerDL">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateLimit()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterNumTickets()" [calendarHeaderComponent]="rangeDateLimit" #pickerDL></mat-date-range-picker>


            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width" style="margin-top: -6px;">
                <mat-select [(ngModel)]="selected2" (selectionChange)="EmitFilterNumTickets()" name="status" placeholder="{{'languages.opportunite.label_status' | translate }}" >
                    <mat-option *ngFor="let item of status" [value]="item.id">
                        {{item.libelle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
      <div class="col-12">
        <label class="custem-title">  {{'languages.ticket.categoriesTicket' | translate }}</label>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.libelle">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
            {{ node.libelle }}
          </button>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: isLeaf" matTreeNodePadding>
          <mat-checkbox [checked]="isChecked(node)" [indeterminate]="false"
          id="{{node.id}}"
          [attr.id]="checkbox"
          [attr.aria-label]="'Select ' + node.libelle"
          (change)="toggleNode(node)">
            {{ node.libelle }}
          </mat-checkbox>
        </mat-tree-node>
      </mat-tree>


      </div>


</div>
