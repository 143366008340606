import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { Observable } from 'rxjs';
import { BASE_PATH } from './variables';
import { CustomHttpUrlEncodingCodec } from './encoder';

@Injectable({
  providedIn: 'root',
})
export class ProspectCLientService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public getListProspectClient(list: any, page: number,
    limit: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
if (page === null || page === undefined) {
      throw new Error('Required parameter page is null or undefined when calling listOpportunitesGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit is null or undefined when calling listOpportunitesGet.');
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('page', <any>page).set('limit', <any>limit);
    return this.httpClient.request<any>('post', `${this.basePath}/prospects/list`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: list,
    });
  }



  public getListProspectClientV2(list: any, page: any,
    limit: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
if (page === null || page === undefined) {
      throw new Error('Required parameter page is null or undefined when calling listOpportunitesGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit is null or undefined when calling listOpportunitesGet.');
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('page', <any>page).set('limit', <any>limit);
    return this.httpClient.request<any>('post', `${this.basePath}/prospects/v1/list`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: list,
    });
  }



  public getListSociete(list: any, page: any,
    limit: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
if (page === null || page === undefined) {
      throw new Error('Required parameter page is null or undefined when calling listOpportunitesGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit is null or undefined when calling listOpportunitesGet.');
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('page', <any>page).set('limit', <any>limit);
    return this.httpClient.request<any>('post', `${this.basePath}/societe/search`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: list,
    });
  }

  public GetProspectByID(id: string): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }
 
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/V1/get_prospect_by_id/${id}?`, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }










  public GetProspectProByID(id: string): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }
 
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_societe/${id}?`, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }
}
