import { FormbuilderValidationService } from './../../../services/formbuilder-validation.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-json-type',
  templateUrl: './json-type.component.html',
  styleUrls: ['./json-type.component.scss'],
})
export class JsonTypeComponent extends FieldType implements OnInit {
  jsonFormControl = new FormControl('', [this.jsonValidator()]);
  fieldName: any;
  searchValue: any;

  constructor(private validationService: FormbuilderValidationService) {
    super();
  }

  matcher = new MyErrorStateMatcher();
  pattern: string | RegExp;

  ngOnInit(): void {
    this.fieldName = this.field.key || `json_${Math.random().toString(36).substr(2, 9)}`;
    if (this.formControl.value) {
      this.jsonFormControl.setValue(this.formControl.value);
    }

    this.searchValue=this.field.templateOptions?.autofill
    if (this.field.templateOptions?.autofill) {
      this.jsonFormControl.disable();
    } else {
      this.jsonFormControl.enable();
    }
    // Update Formly form control value on changes
    this.jsonFormControl.valueChanges.subscribe((value) => {
      this.formControl.setValue(value);
      this.emitValidationStatus();
    });
    this.emitValidationStatus();
  }

  private emitValidationStatus(): void {
    const isValid = this.jsonFormControl.disabled || this.jsonFormControl.valid;
    this.validationService.updateValidationStatus(this.fieldName, isValid,this.field.templateOptions?.label);
  }

  updateValue(event: Event): void {

    this.jsonFormControl.setValue('Some update'); // Update with the logic you need
  }

  private jsonValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // If the control value is empty, do not apply the validator
      }

      try {
        const parsedJson = JSON.parse(control.value);

        // Check for the pattern
        const pattern = this.field.templateOptions.pattern;
        if (pattern && !new RegExp(pattern).test(control.value)) {
          return { invalidPattern: true }; // Invalid pattern
        }

        return null; // Valid JSON and matches pattern
      } catch (error) {
        return { invalidJson: true }; // Invalid JSON
      }
    };
  }
}
