import {FormField} from './FormField';

export class NumberFormly extends FormField {
  minimum:number=-99999;
  exclusiveMinimum:number=-99999;
  maximum:number=99999;
  exclusiveMaximum:number=99999;
  multipleOf:number;
  description : string =""
  pattern=""
  urlValidation=""
  autofill="";
  urlIdentifiant=""
   default=""
  constructor() {
    super();
    super.icon="fa fa-percent";
    super.name = 'nombre_' + super.generateRandomId();
    this.type = 'number';
    this.title = "Nombre"
    super.typename = 'Number';
  }
}
