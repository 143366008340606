<form (ngSubmit)="onSubmit(myForm.form.valid, myForm._directives)" #myForm="ngForm">
    <div class="card bg-white border-0 text-center mx-5 my-3 p-4" *ngIf="!fromDetails">
        <div class="row details-header">

      <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
        <div class="icon-position">
            <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
        </div>
    </button>
            <div class="row">
                <div class="row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col" style="margin-right: 10px">
                        <button type="button" class="btn-load" (click)="reset()">
                            <i class="fa-solid fa-arrow-rotate-right fa-lg"></i>
            </button>
                    </div>
                </div>
                <button class="btn-dt-save" type="submit">
<i class="fa-solid fa-circle-check fa-lg"></i>  {{'languages.buttons.enregistrer' | translate}}
        </button>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="fromDetails">
        <div class="col-12 modal-header" >
            <h3 class="text-center m-0 justify-content-center w-100 modal-title" >{{'languages.ticket.addTickett' | translate}}</h3>


            <button type="button" class="close" (click)="reset(myForm)" #closeModal data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <hr />
    </div>

    <div class="card bg-white border-0" [ngClass]="{ 'p-4': !fromDetails, 'mx-5': !fromDetails, 'my-3': !fromDetails ,'p-3':fromDetails }">
        <ng-container *ngIf="show_loading_Categorie">
            <div class="row mt-1 categorie-text">{{'languages.ticket.categoriesTicket' | translate}}</div>
            <div class="row mt-1" >
                <div class="col-12 "   >
                    <ul  >
                        <li class="nav-item dropdown" *ngFor="let tag of listCategorieTickets; let i = index"  >
                            <ng-container class="dropdown" *ngIf="tag.child.length > 0" >

                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [title]="tag.libelle" class="nav-link dropdown-toggle btn-style">
                                   
                
                                    <i class=" {{tag.icon}} fa-lg categoryIcon icon-style" *ngIf="tag.icon"></i>
                                    <i class="fa-solid fa-gear categoryIcon icon-style " *ngIf="!tag.icon || tag.icon==='None'"></i>
                                     <p class="categoryName">{{ expandName(tag.libelle) }}</p>
                </a>
                                <ng-container *ngTemplateOutlet="dropdownList; context: { menu: tag }"> </ng-container>
                            </ng-container>

                            <ng-container *ngIf="tag.child.length < 1">

                                <ng-container *ngTemplateOutlet="defaultBtnCategory; context: { menu: tag }"> </ng-container>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!show_loading_Categorie">
            <div class="col-12 d-flex justify-content-center">
                <div class="spinner-style">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>

        <hr />
        <div class="row" *ngIf="libelle_categorie !== ''">
            <label> {{'languages.ticket.Category' | translate}} : {{ libelle_categorie }} </label>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label> {{'languages.ticket.sujetTicket' | translate}}</mat-label>
                        <input [(ngModel)]="addTicket.title" matInput placeholder="{{'languages.ticket.sujetTicket' | translate}}"  required #sujetT="ngModel" name="sujetT" />
                        <mat-error *ngIf="sujetT.invalid && (sujetT.dirty || sujetT.touched)">
                            <div *ngIf="sujetT.errors.required" class="message-erreur">
<i class="fa-solid fa-triangle-exclamation"></i>
                                 {{'languages.ticket.obligSujetTicket' | translate}}
                            </div>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label> {{'languages.opportunite.details.affecte' | translate}} </mat-label>
                        <input type="text" matInput [(ngModel)]="affecteUser" [formControl]="affecterAFormControl" name="affecte" required [matAutocomplete]="auto" />
                        <mat-autocomplete autoActiveFirstOption [displayWith]="displayContactFn" #auto="matAutocomplete">
                            <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                                {{ option.nom + ' ' + option.prenom }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="affecterAFormControl.invalid && (affecterAFormControl.dirty || affecterAFormControl.touched)" class="message-erreur">
                            <div *ngIf="affecterAFormControl.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                 {{'languages.ticket.obligUser' | translate}}
                            </div>
                        </mat-error>

                        <mat-error class="message-erreur" *ngIf="
                affecterAFormControl.hasError('invalidAutocompleteObject') && !affecterAFormControl.hasError('required')
              ">
<i class="fa-solid fa-triangle-exclamation"></i>
 {{'languages.document.verifyAffectedUser' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label> {{'languages.ticket.limitTicket' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" name="date_Limite" readonly (click)="picker.open()" [(ngModel)]="addTicket.date_limite" style="font-size: 12px" [disabled]="isDisabled" [min]="minDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <!-- <ckeditor [config]="config" class="w-100 mt-4" name="commentaire" [(ngModel)]="addTicket.commentaire" [editor]="Editor" data=""></ckeditor> -->
            <angular-editor class="w-100 mt-4"  name="commentaire"  [(ngModel)]="addTicket.commentaire"  [config]="config2" ></angular-editor>
        </div>
    </div>

    <div class="row text-center justify-content-center w-100 mb-3 mt-2" *ngIf="fromDetails">



                <div *ngIf="show_loading" class="col-12 d-flex   mb-3 mt-2">
                    <div class="text-center m-auto">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>

            <div class="row">
            <button data-toggle="modal" data-target="#exampleModal2" data-whatever="@getbootstrap" class="btn-dt-save-popup  mr-2  " type="submit">
<i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>  {{'languages.buttons.ajouter' | translate}}
              </button>

        <button type="button" class="btn-dt-cancel-popup ml-2 " (click)="reset(myForm)" data-dismiss="modal" aria-label="Close" #closeAddExpenseModal>
            <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i> {{'languages.buttons.annuler' | translate}}
          </button>

        </div>
          </div>

</form>


<ng-template #dropdownList let-menuList="menu">
    <ul class="dropdown-menu border-0 shadow " >
        <ng-container *ngFor="let menu of menuList.child">
            <ng-container *ngIf="menu.child.length > 0">
                <ng-container *ngTemplateOutlet="subMenu; context: { menu: menu }"></ng-container>
            </ng-container>
            <ng-container *ngIf="menu.child.length < 1">
                <ng-container *ngTemplateOutlet="subElement; context: { menu: menu }"></ng-container>
            </ng-container>
        </ng-container>
    </ul>
</ng-template>

<ng-template #subElement let-element="menu">
    <li>
        <a (click)="
        closeAllDropDownElements($event); setCategorieValue(element.id, element.libelle, element.delai_traitement ,element.affect_a,element.libelle_cat_parent)
      " class="dropdown-item" [title]="element.libelle">{{expandName(element.libelle) }}
    </a>
    </li>
</ng-template>

<ng-template #subMenu let-element="menu">
    <li class="dropdown-submenu">
        <a (click)="showSubMenu($event)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle" [title]="element.libelle">{{ expandName(element.libelle) }}</a
    >
    <ng-container *ngTemplateOutlet="dropdownList; context: { menu: element }"></ng-container>
  </li>
</ng-template>

<ng-template #defaultBtnCategory let-tag="menu">
  <a
    (click)="setCategorieValue(tag.id, tag.libelle, tag.delai_traitement , tag.affect_a,tag.libelle_cat_parent)"
    aria-haspopup="true"
    aria-expanded="false"

    class="nav-link btn-style  without "

    [title]="tag.libelle"
  >
  <i class="fa-solid fa-gear categoryIcon icon-style " *ngIf="!tag.icon || tag.icon===''"></i>
  <i class=" {{tag.icon}} fa-lg categoryIcon icon-style" *ngIf="tag.icon"></i>
    <p class="categoryName">{{expandName(tag.libelle)}}</p>
  </a>
</ng-template>
