import { InformationsComplmentaire } from './../../../../entity/Opportunity/InformationsComplmentaire';
import { InformationsSpecifique } from './../../../../entity/Opportunity/InformationsSpecifique';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { tap } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ApiOpportunitService } from './../../../../services/ApiOpportunite/api-opportunite.service';
import { Component, Input, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
 
import { Subscription } from 'rxjs';
import { element } from 'protractor';

@Component({
  selector: 'app-information-avancees',
  templateUrl: './information-avancees.component.html',
  styleUrls: ['./information-avancees.component.css'],
})
export class InformationAvanceesComponent implements OnInit, OnDestroy {
   
  form = new FormGroup({});
  modelspecif: any = {};
  modelcomp: any = {};
  ListeofUnsbscribeservice: Subscription[] = [];
  options: FormlyFormOptions = {};
  fieldscomp = [];
  fieldsspecif = [];
  formdatacomp = new FormGroup({});
  formdataspecif = new FormGroup({});

  formComplementaire = new FormGroup({});
  formSpecifique = new FormGroup({});

  loading: boolean = true;
  informationsContrat: AffaireDetails;
  @Input() affaire_id: string = '';
  @Input() id_opportunite: string = '';
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  @Output() infoSpecifiqueexport: EventEmitter<any> = new EventEmitter();
  @Output() infoComplementaireexport: EventEmitter<any> = new EventEmitter();
  @Input() allowed_to_update_affaire: boolean;
  specifique = false;
  complementaire = false;

  @Output() onUpdateinformationsSpecifique: EventEmitter<InformationsSpecifique> = new EventEmitter();
  @Output() emitForminformationsSpecifique: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private apiAffairesService: ApiAffairesService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  ngOnInit(): void {
    this.getInformationsSpecifique();
    this.getInformationComplementaire();
  }

  getInfoSpec() {
    // if (this.specifique == false) {

    this.getInformationsSpecifique();
    this.specifique = true;
    this.complementaire = false;

    // }
  }

  getInfoComp() {
    // if (this.specifique == true) {

    this.getInformationComplementaire();
    this.complementaire = true;
    this.specifique = false;

    // }
  }

  getInformationsSpecifique() {
    this.loading = true;
    if (this.affaire_id !== '' && this.affaire_id !== null && this.apiOpportunitService.formdataaffairspecif === null) {
      const unsubscribegetInfoSpecifiqueAffaireGet = this.apiAffairesService
        .getInfoSpecifiqueAffaireGet(this.affaire_id)
        .pipe(
          tap(({ form }) => {
            this.form = new FormGroup({});
            this.formdataspecif = form;
            this.options = {};
            if (form.schema) {
              const params: any = form.schema.properties;
              const infoSpecifique: any[] = [];
              form.schema.properties = {};
              this.modelspecif = {};
              params?.forEach((element) => {
                infoSpecifique[element.name] = element;
                if (this.modelspecif[element.name]) this.modelspecif[element.name] = element.default;
              });
              form.schema.properties = infoSpecifique;
              this.fieldsspecif = [this.formlyJsonschema.toFieldConfig(form.schema)];
              const infoSpecif = new InformationsSpecifique();
              infoSpecif.id_opp = this.id_opportunite;
              infoSpecif.json_prod = form.data;
              infoSpecif.inf_prod = [];
              this.infoSpecifiqueexport.emit(infoSpecif);
            }
            this.modelspecif = form.data;
          })
        )
        .subscribe((data) => {
          this.loading = false;
        });
      this.ListeofUnsbscribeservice.push(unsubscribegetInfoSpecifiqueAffaireGet);
    } else if (this.apiOpportunitService.formdataaffairspecif.schema !== undefined) {
      this.formdataspecif = this.apiOpportunitService.formdataaffairspecif;
      if (this.formdataspecif !== null) {
        this.form = new FormGroup({});

        this.fieldsspecif = [
          this.formlyJsonschema.toFieldConfig(this.apiOpportunitService.formdataaffairspecif.schema),
        ];
        this.modelspecif = this.apiOpportunitService.formdataaffairspecif.data;
      }
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.pushInfo();
    this.ListeofUnsbscribeservice.forEach((element) => {
      element?.unsubscribe();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo();
  }

  pushInfo() {
    this.submitInfoSpecifique();
  }

  getInformationComplementaire() {
    this.loading = true;
    if (
      this.id_opportunite !== '' &&
      this.id_opportunite !== null &&
      this.apiOpportunitService.formdataaffaircomp === null
    ) {
      const unsubscribegetInfoComplementairesOpportuniteGet = this.apiOpportunitService
        .getInfoComplementairesOpportuniteGet(this.id_opportunite)
        .pipe(
          tap(({ form }) => {
            this.form = new FormGroup({});
            this.formdatacomp = form;
            this.options = {};
            if (form.schema) {
              const params: any = form.schema.properties;
              const infoComplementaire: any[] = [];
              form.schema.properties = {};
              this.modelcomp = {};
              params?.forEach((element) => {
                infoComplementaire[element.name] = element;
                if (this.modelcomp[element.name]) this.modelcomp[element.name] = element.default;
              });
              form.schema.properties = infoComplementaire;
              this.fieldscomp = [this.formlyJsonschema.toFieldConfig(form.schema)];
              const infoCompl = new InformationsComplmentaire();
              infoCompl.id_opp = this.id_opportunite;
              infoCompl.jsonform = form.data;
              infoCompl.infcamp = [];
              this.infoComplementaireexport.emit(infoCompl);
            }
            this.modelcomp = form.data;
          })
        )
        .subscribe((data) => {
          this.loading = false;
        });
      this.ListeofUnsbscribeservice.push(unsubscribegetInfoComplementairesOpportuniteGet);
    } else if (this.apiOpportunitService.formdataaffaircomp.schema !== undefined) {
      this.formdatacomp = this.apiOpportunitService.formdataaffaircomp;
      if (this.formdatacomp !== null) {
        this.form = new FormGroup({});

        this.fieldscomp = [this.formlyJsonschema.toFieldConfig(this.apiOpportunitService.formdataaffaircomp.schema)];
        this.modelcomp = this.apiOpportunitService.formdataaffaircomp.data;
      }
      this.loading = false;
    }
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  submitInfoSpecifique() {
    this.infoSpecifique = new InformationsSpecifique();
    this.infoSpecifique.id_opp = this.id_opportunite;
    this.infoSpecifique.json_prod = this.modelspecif;
    this.infoSpecifique.inf_prod = [];
    this.onUpdateinformationsSpecifique.emit(this.infoSpecifique);
    this.emitForminformationsSpecifique.emit(this.formSpecifique);

    this.apiOpportunitService.formdataaffairspecif = this.formdataspecif;
  }
}
