<div class="form-group">
  <mat-form-field class="full-width">
    <mat-label>{{field.templateOptions.label}}</mat-label>
    <input matInput type="password" [formControl]="formControl" [formlyAttributes]="field" placeholder="{{ 'languages.user.password' | translate }}" autocomplete="new-password">
    <button mat-icon-button *ngIf="searchValue" matSuffix (click)="updateValue()">
      <mat-icon>refresh</mat-icon>
    </button>
    <div class="description-wrapper" *ngIf="field.templateOptions.description">
      <span class="description-text">{{ field.templateOptions.description }}</span>
    </div>
    <mat-error *ngIf="formControl.hasError('pattern')">
      {{ 'languages.formbuilderTransaltion.passwordRegexError' | translate }}
    </mat-error>
  </mat-form-field>
</div>
