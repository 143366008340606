import { NotificationsService } from './../../shared/NotificationsService/notifications.service';
import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, OnInit, NgModule } from '@angular/core';
 
import { map, startWith } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';
import { Task } from 'src/app/entity/task';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css'],
})
export class TaskDetailComponent implements OnInit {
  myControl = new FormControl('');
  initialTaglist: any = [];
  @Input() ListCLientProspect: any;
  @Input() ListUser: any = [];
  @Input() priorites: any;
  typeselectedAffect: string = '';
  isDisabled: boolean = false;
  tags: any = [];
  initaskDetail: any = [];
  @Output() taskEmit = new EventEmitter<Task>();
  @Output() inexpandEmit = new EventEmitter<boolean>();
  @Input() taskDetail: Task;
  @Input() tagsList;
  @Input() loaderAjout;
  @Input() typeTache;
  dateEcheance: Date;
  loader = false; 
   
  types: Object[] = [
    { value: 'client', viewValue: 'Client/Prospect',label:'Client/Prospect' },
    { value: 'user', viewValue: 'Utilisateur' , label:'Utilisateur' },
  ];

  filteredOptions: any;
  List: any;
  alerts: any;
  buttons: any;
  labelAffectA:string =""
  ticketss: any;
  ticketTranslate: any;
  constructor(
    private datePipe: DatePipe,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService,
    private translate : TranslateService
  ) {}

  ngOnInit(): void {




    Object.assign(this.initaskDetail, this.taskDetail);
    this.initialTaglist = this.tagsList;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),

      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.List?.slice();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.ticketTranslate=object;
        this.types[0]['label']=object.ticket.prospectClient;
        this.types[1]['label']=object.opportunite.label_user;
        if (this.typeselectedAffect === 'Client/Prospect') {
          this.labelAffectA=this.ticketTranslate.ticket.prospectClient
        } else if (this.typeselectedAffect === 'Utilisateur') {
          this.labelAffectA=this.ticketTranslate.opportunite.label_user
        } else {
         this.labelAffectA=this.ticketTranslate.opportunite.label_user
        }
      });
      

    })



    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.ticketTranslate=object
      this.types[0]['label']=object.ticket.prospectClient;
      this.types[1]['label']=object.opportunite.label_user;
    });

    if (changes.taskDetail) {
      if (this.taskDetail.tags) {
        this.tags = [];
        this.taskDetail.tags?.forEach((element) => {
          this.tags.push(element.id);
        });
      }

      if (this.ListCLientProspect.length > 0) {
        this.ListCLientProspect?.forEach((element) => {
          if (element.id === this.taskDetail.prospect_id) {
            this.myControl.setValue(element.full_name);
            this.typeselectedAffect = 'Client/Prospect';
            this.labelAffectA=this.ticketTranslate.ticket.prospectClient

          }
        });
      }
      if (this.ListUser.length > 0) {
        this.ListUser?.forEach((element) => {
          if (element.id === this.taskDetail.affecte_a) {
            this.myControl.setValue(element.full_name);
            this.typeselectedAffect = 'Utilisateur';
            this.labelAffectA=this.ticketTranslate.opportunite.label_user


          }
        });
      }
      if (this.typeselectedAffect === 'Client/Prospect') {
        this.List = this.ListCLientProspect;
        this.labelAffectA=this.ticketTranslate.ticket.prospectClient
      } else if (this.typeselectedAffect === 'Utilisateur') {
        this.List = this.ListUser;
        this.labelAffectA=this.ticketTranslate.opportunite.label_user
      } else {
       this.labelAffectA=this.ticketTranslate.opportunite.label_user
        this.typeselectedAffect = 'Utilisateur';
        this.List = this.ListUser;
      }
      if (this.taskDetail.date_limit) {
        this.dateEcheance = new Date(this.taskDetail.date_limit);
      }
      if (this.taskDetail.statut === 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
  }

  displayFn(affect: any): string {
    if (typeof affect === 'string') {
      return affect || null;
    } else {
      return affect ? affect.full_name : null;
    }
  }

  //   searchTags(value: string) {
  //     const filter = value.toLowerCase();

  //     if (filter.length !== 0) {
  //       this.tagsList = this.tagsList.filter((option) => option.libelle.toLowerCase().startsWith(filter));
  //     }else{
  // this.tagsList=this.initialTaglist
  //     }
  //   }

  // changeTags(value) {
  //   value.forEach((element) => {
  //     this.tags.push(element);
  //     this.tagsList.push(element)
  //   });
  // }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.List?.filter((option) => option.full_name?.toLowerCase().includes(filterValue));
  }

  getList(typeAffect) {
    this.typeselectedAffect = typeAffect;
    this.List = [];
    this.myControl.setValue('');
    this.loader = true;
    if (typeAffect === 'Client/Prospect') {
      this.taskDetail.affecte_a = "";
      this.labelAffectA=this.ticketTranslate.ticket.prospectClient
      this.List = this.ListCLientProspect;
      this.loader = false;
    } else {
      this.labelAffectA=this.ticketTranslate.opportunite.label_user
      this.taskDetail.prospect_id = "";
      this.List = this.ListUser;
      this.loader = false;
    }
  }

  onTagSelectionChange() {
    this.taskDetail.tags = [];

    this.tags.forEach((item) => {
      const matchingItem = this.tagsList.find((i) => i.id === item);
      this.taskDetail.tags.push(matchingItem);
    });
  }

  // changerStatus() {
  //   if (this.taskDetail.statut === 0) {
  //     this.taskDetail.statut = 1;
  //     this.isDisabled = true;
  //   } else {
  //     this.taskDetail.statut = 0;
  //     this.isDisabled = false;
  //   }

  //   if (!this.taskDetail.entity) {
  //     this.taskDetail.entity = '';
  //   }
  //   this.apiTicketsServices
  //     .updateTicketNotification(
  //       this.taskDetail.id_entity,
  //       this.taskDetail.id,
  //       this.taskDetail.statut,
  //       this.taskDetail.entity
  //     )
  //     .subscribe(
  //       (data) => {
  //         const notificationMessage = new NotificationMessage();
  //         notificationMessage.type = NotificationType.success;
  //         notificationMessage.title = 'Opération réussite';
  //         notificationMessage.message = data.message;
  //         this.notificationsService.sendMessage(notificationMessage);
  //         this.loader = false;
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  clearDate(event) {
    event.stopPropagation();
    this.dateEcheance = null;
  }

  enregistrer() {
    if (!this.taskDetail.titre) {
      this.alertWarning('titre');
    } else {
      if (this.typeTache !== 'affecte') {
        if (typeof this.myControl.value === 'object' && this.myControl.value.id !== undefined) {
          if (this.typeselectedAffect === 'Client/Prospect') {
            this.taskDetail.prospect_id = this.myControl.value.id;
          } else if (this.typeselectedAffect === 'Utilisateur') {
            this.taskDetail.affecte_a = this.myControl.value.id;
          }
        }
        if (this.taskDetail.affecte_a || this.taskDetail.prospect_id) {
          this.taskDetail.tags = [];
          if (!this.taskDetail.description) {
            this.taskDetail.description = '';
          }
          this.tags?.forEach((element) => {
            this.taskDetail.tags.push(element);
          });
          this.taskDetail.id_entity = '';
          this.taskDetail.entity = '';
          this.taskDetail.date_limit = this.datePipe.transform(this.dateEcheance, 'yyyy-MM-dd H:M:S');
          this.taskEmit.emit(this.taskDetail);
        } else {
          this.alertWarning('affect');
        }
      } else {
        this.taskDetail.tags = [];
        if (!this.taskDetail.description) {
          this.taskDetail.description = '';
        }
        this.tags?.forEach((element) => {
          this.taskDetail.tags.push(element);
        });
        this.taskDetail.id_entity = '';
        this.taskDetail.entity = '';
        this.taskDetail.date_limit = this.datePipe.transform(this.dateEcheance, 'yyyy-MM-dd H:M:S');
        this.taskEmit.emit(this.taskDetail);
      }
    }
  }

  inexpand() {
    Object.assign(this.taskDetail, this.initaskDetail);
    this.inexpandEmit.emit(false);
  }

  alertWarning(data) {
    if (data === 'titre') {
      swal.fire({
        title: this.alerts.error,
        icon: 'warning',
        text: this.alerts.TitleOblig,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#e75e5e',
      });
    } else if (data === 'date') {
      swal.fire({
        title: this.alerts.error,
        icon: 'warning',
        text: this.alerts.echeanceDateVerif,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText:this.buttons.fermer,
        confirmButtonColor: '#e75e5e',
      });
    } else {
      swal.fire({
        title: this.alerts.error,
        icon: 'warning',
        text:this.alerts.affectTaskToSomeone,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#e75e5e',
      });
    }
  }
}
