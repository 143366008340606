import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
 import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Router } from '@angular/router';
import { ObjectAssurer, ObjectsAssurer } from 'src/app/entity/Opportunity/Campagne';
import { GetOpportuniteDetails } from 'src/app/entity/Opportunity/GetOpportuniteDetails';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-objet-assurer',
  templateUrl: './objet-assurer.component.html',
  styleUrls: ['./objet-assurer.component.css']
})
export class ObjetAssurerComponent implements OnInit {
  @Output() onobjetAssurer: EventEmitter<any[]> = new EventEmitter();
  opportunity_id: string = '';
  showBlockObjetAssurer: boolean = false;
  idcampagne: string = '';
   
  listObjetAssurer: any[] = [];
  showobjetAssure: boolean = false;
  objectsAssurer: ObjectsAssurer = {
    name: 'Objet(s) / Bien(s) à assurer',
    completed: false,
    color: 'primary',
    objects_Assurer: [
    ],
  };

  navbarTxt:string="";

  allComplete: boolean = false;
  constructor(private router: Router, private _eventEmiter: EventEmiterService,
    private sharedMenuObserverService: SharedMenuObserverService,
     private apiOpportunitService: ApiOpportunitService,
      private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
      private translate : TranslateService
      ) {

  }



  someComplete(): boolean {
    if (this.objectsAssurer.objects_Assurer == null) {
      return false;
    }
    const complet: boolean = this.objectsAssurer.objects_Assurer.filter(t => t.completed).length > 0 && !this.allComplete;

    this.objectsAssurer.objects_Assurer.forEach(async (objetAssure, index) => {

        let existe: boolean = false;
        this.listObjetAssurer.find((someobject) => {// eslint-disable-line
          if (someobject?.nom === objetAssure?.nom) {
            existe = true
          }
        });
        if (existe === false && objetAssure.completed===true) {
          const maxindex = Math.max(...this.listObjetAssurer.map(o => o.index));
          let newObjectAssurer:any;
          if(maxindex===-Infinity){
            this.listObjetAssurer = [];
             newObjectAssurer = new ObjectAssurer(0, objetAssure.form, objetAssure.icon, objetAssure.nom, true,false);
          }else{
             newObjectAssurer = new ObjectAssurer(maxindex + 1, objetAssure.form, objetAssure.icon, objetAssure.nom, true,false);
          }
          const newList = [...this.listObjetAssurer, newObjectAssurer];
          this.listObjetAssurer = Object.assign([], newList);
          this._eventEmiter.sendListObject(this.listObjetAssurer);
          this._eventEmiter.setUpdateListObject(true);

        }
    });

    return complet;
  }


  ngOnInit(): void {
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
    this.getOpportunite();
    this.apiOpportunitService.get_objet_assure_by_entity(this.opportunity_id, 'opportunite').subscribe((data) => {
      this.listObjetAssurer = data.objet_assure;
      for (const objet of this.listObjetAssurer) {

        objet.expand = false;
      }

    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.objectsAssurer.name=object.opportunite.details.objetBienAssure;
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.opportunite.details.objetBienAssure
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);

      });});

    this.translate.get('languages').subscribe((object: any) => {
      this.objectsAssurer.name=object.opportunite.details.objetBienAssure;
      this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.Détails +' ➜ '+ object.opportunite.details.objetBienAssure
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });

  }

  getformdata($event) {
    const newList = [...this.listObjetAssurer];
    for (let index = 0; index < this.listObjetAssurer.length; index++) {
      const element = this.listObjetAssurer[index];
      if (element.index === $event.index) {

       newList[index] = $event;
      }
    }
    
          this.listObjetAssurer = Object.assign([], newList  );
    this._eventEmiter.sendListObject(this.listObjetAssurer);
    this._eventEmiter.setUpdateListObject(true);
  }

  copieformdata(event: any) {
    const maxindex = Math.max(...this.listObjetAssurer.map(o => o.index));
    const newObjectAssurer = new ObjectAssurer(maxindex + 1, event.form, event.icon, event.nom, true,event.expand);
    this.listObjetAssurer = [...this.listObjetAssurer, newObjectAssurer];
    this._eventEmiter.sendListObject(this.listObjetAssurer);
    this._eventEmiter.setUpdateListObject(true);
  }

  deleteformdata(event: any) {
    const count: number = this.listObjetAssurer.reduce((acc: number, obj: any) => {
      if (obj.nom === event?.nom) {
        return acc + 1;
      }
      return acc;
    }, 0);
  this.listObjetAssurer = this.listObjetAssurer.filter((obj) => obj !== event);
  this.objectsAssurer.objects_Assurer.forEach(async (objetAssure, index) => {
      if (event?.nom === objetAssure?.nom && count===1) {
        objetAssure.completed=false;
      }
  });
  this._eventEmiter.sendListObject(this.listObjetAssurer);

  this._eventEmiter.setUpdateListObject(true);
  }

  addobjet() {
    if (this.showobjetAssure === false) {
      this.showobjetAssure = true;
    } else {
      this.showobjetAssure = false;
    }
  }

  getOpportunite() {
    const unsubscribeGetOpportunite = this.apiOpportunitService
      .GetOpportunite(this.opportunity_id)
      .subscribe((data: GetOpportuniteDetails) => {

        this.idcampagne = data.opportunite_details.campagne_id;
        this.apiOpportunitService.getObjecttassurer(this.idcampagne,'id_campagne').subscribe((value) => {
          if (value) {
            const data=value.object_assure;
            data.forEach(element => this.objectsAssurer.objects_Assurer.push(new ObjectAssurer(element.index,element.form, element.icon, element.nom,  false,false)));
            // this.objectsAssurer.objects_Assurer = value['object_assure'];
             this.listObjetAssurer.forEach((objetAssure) => {

               this.objectsAssurer.objects_Assurer.find(someobject => someobject.nom === objetAssure.nom).completed = true

             });
             this._eventEmiter.sendListObject(this.listObjetAssurer);
             this._eventEmiter.setUpdateListObject(true);


          }

        });
      });
  }
}
