export class EtatAffaire {

add_opp: number;
affect_all_opp: number;
agenda: number;
alert: number;
api:  string="";
blocage:  string="";
categorie: string=""; 
classe:  string="";
color: string="";
couleur_police:  string="";
cycle_vie:  string="";
dependance: string="";
etat:  string="";
explication:  string="";
fax_comm:  string="";
fiche_dec: number;
id:  string="";
id_p: string=""; 
id_contrat:String=""; 
level: string=""; 
mail_comm:  string="";
msg_conf: string="";
post_comm:  string="";
sms_comm: string=""; 
sup: number;
ticket:number; 
visa:string="";
real_id:string="";
cycle_prod :string="";
cycle_prod_md5  :string="";
  }