import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
 
import { FormControl, Validators } from '@angular/forms';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { Observable } from 'rxjs';
import { UserList } from 'src/app/entity/User/UserList';
import { map } from 'rxjs/operators';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { Status } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
export class TicketsGroupe{
  action_affect: any;
  action_statut: any;
  list_ticket: any[] = []; 
}
@Component({
  selector: 'app-action-groupee-tickets',
  templateUrl: './action-groupee-tickets.component.html',
  styleUrls: ['./action-groupee-tickets.component.css'],
})
export class ActionGroupeeTicketsComponent implements OnInit {
   
  affecterAFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator()],
  });
  listeTickets : any []= [];
  listUserFilter: Observable<UserList[]>;
  listUser: UserList[] = [];
  isLoadingResult: boolean = true;
  affecteUser: any;
  user_affecte: any;
  statusList: Status[] = [];
  selectedStatus : any;
   updatedBody : TicketsGroupe = new TicketsGroupe();
  saveLoader: boolean;
  constructor(public activeModal: NgbActiveModal, 
    private apiUsersService: ApiUsersService,
    private apiTicketsServices: ApiTicketsService,
    ) {}

  ngOnInit(): void {
    this.getUserList();
    this.getStatus();
    this.listUserFilter = this.affecterAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.nom + ' ' + value.prenom)),
      map((value) => {
        return this._affecterAfilter(value);
      })
    );
  }

  private _affecterAfilter(value: string): UserList[] {
    const filterValue = value.toLowerCase();
    return this.listUser.filter((option) => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) > -1);
  }

  getUserList() {
    this.apiUsersService.getAllUsers().subscribe((data: UserList[]) => {
      this.listUser = data.filter((item) => item.nom !== null && item.prenom !== null);
      this.isLoadingResult = false;
    });
  }

  public displayContactFn(contact?: UserList): string | undefined {
    this.user_affecte = contact?.id;
    return contact ? contact.nom + ' ' + contact.prenom : null;
  }

  getStatus() {
     this.apiTicketsServices.GetStatusTicket().subscribe(
      (data) => {
        this.statusList = data.list_statut;
      },
  
    );
  }

Enregistrer(){
  this.saveLoader= true 
  if(this.affecterAFormControl.value?.id) {
    this.updatedBody.action_affect = this.affecterAFormControl.value.id;
  }else{
    this.updatedBody.action_affect ="";
  }
  if(this.selectedStatus) {
    this.updatedBody.action_statut = this.selectedStatus;
  }else{
    this.updatedBody.action_statut ="";
  }
  this.updatedBody.list_ticket = this.listeTickets;
  this.apiTicketsServices.actionGroupeTickets(this.updatedBody).subscribe(
    (data) => {
      this.saveLoader= false 
      this.activeModal.dismiss(data);
    //  this.statusList = data.list_statut;
    }, (error) => {
      this.saveLoader= false 
      this.activeModal.dismiss('error');


    });

}
}
