import {FormField} from './FormField';

export class NullObjectFormly extends FormField {
  pattern=""
  urlValidation=""
   default=""
  autofill="";
  urlIdentifiant=""
  constructor() {
    super();
    super.icon="fa fa-caret-square-o-left";
    this.type = 'null';
    super.name = 'element_null_' + super.generateRandomId();
    super.title = '';
    super.typename = 'Null Element';
  }
}
