import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ROUTES, RouteInfo } from '../navbar/navbar.component';
import { ApiMenuService } from '../../services/ApiMenu/api-menu.service';
import { Menu } from '../../entity/Menu';
import { AuthGardConfig } from 'src/app/shared/AuthGard/AuthGardeConfig';

import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

class ChildRout {
  name: string;
  nname: string;
}
interface MenuItem {
  title: string;
  iconClass: string;
  child: MenuItem[];
  expanded?: boolean;
  isActive: boolean;
  isHovered?: boolean;
  isClicked?: boolean;
}
const TIME_TO_COLLAPSE = 500;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'visible',
          opacity: '1',
        })
      ),
      transition('collapsed => expanded', [animate('500ms ease-out')]),
      transition('expanded => collapsed', [animate('500ms ease-in')]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  collapsed: boolean = false;
  routes: RouteInfo[] = [];
  menu: Menu = new Menu();
  childRoutes: ChildRout[] = [];
  regex_special_chars = /[\W]/g;

  unchecked: boolean = false;
  activenumber: number = 0;
  test: number = 0;
  useropenbar: boolean = false;
  isNotActiveMenu: boolean = false;
  active: boolean = false;
  @Output() sidebar_status: EventEmitter<any> = new EventEmitter();
  id: string = '';
  collapsed_menu: boolean = false;
  collapseTimeout: any;
  uncollapseTimeout: any;
  collapseLogoTimeout: any;
  uncollapseLogoTimeout: any;
  acces_reclamation_ticket: any;
  lecture_transaction_financiere_client: any;
  accessList: AccessList;
  nom: string = '';
  prenom: string = '';
  role: string = '';
  alerts: any;
  @Output() authorised: EventEmitter<any> = new EventEmitter();
  buttons: any;

  constructor(
    private apiMenuService: ApiMenuService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAuthentificationService: ApiAuthentificationService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.routes = ROUTES;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });
    this.getMenu();
    this.nom = localStorage.getItem('nom');
    this.prenom = localStorage.getItem('prenom');
    this.role = localStorage.getItem('role');
    this.sharedMenuObserverService.updateIconMenu(localStorage.getItem('currentIcon'));
  }

  ActiviateSelectedElement() {
    for (const r of this.routes) {
      r.isNotActiveMenu = false;
      // this.isActiveMenu( r.menu_id) ;
    }
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return value;

    return value?.charAt(0)?.toUpperCase() + value?.slice(1);
  }

  getMenu() {
    const currentRoute = this.router.url;
    this.apiMenuService.getMenuByRoleGet().subscribe((data: Menu) => {
      this.routes = data.menu;
      this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
        if (value !== undefined) {
          this.accessList = value;
          this.acces_reclamation_ticket = this.accessList.acces_reclamation_ticket;
          this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
          if (this.acces_reclamation_ticket === false) {
            for (const i in this.routes) {
              if (this.routes[i].title === 'Tickets & Support') {
                this.routes.splice(Number(i), 1);
              }
            }
          }
          if (this.lecture_transaction_financiere_client === false) {
            for (const i in this.routes) {
              if (this.routes[i].title === 'Echéanciers') {
                this.routes.splice(Number(i), 1);
              }
            }
          }
        }
      });

      this.routes.sort(this.compare);
      this.ActiviateSelectedElement();
      if (!this.isAuthorised(this.routes, currentRoute) && currentRoute !== '/profil' && currentRoute !== '/') {
        this.unauthorizedAccess();
        this.authorised.emit(false);
      } else {
        this.authorised.emit(true);
      }
      AuthGardConfig.routes = this.routes;
    });
  }

  isAuthorised(listPaths: RouteInfo[], path: string): boolean {
    const cleanPath = path.split('?')[0].split('#')[0];

    for (let i = 0; i < listPaths.length; i++) {
      const mainPath = listPaths[i].path;

      if (mainPath && (cleanPath === mainPath || cleanPath.startsWith(`${mainPath}/`))) {
        return true;
      } else if (listPaths[i].child.length > 0) {
        if (this.isAuthorised(listPaths[i].child, cleanPath)) {
          return true;
        }
      }
    }
    return false;
  }

  unauthorizedAccess() {
    Swal.fire({
      icon: 'error',
      title: this.alerts.AccessRestricted,
      text: this.alerts.notAuthorised,
      confirmButtonText: this.buttons.fermer,
      allowOutsideClick: false,
    }).then(() => {
      this.router.navigate(['/'], { replaceUrl: true });
      this.authorised.emit(true);
    });
  }

  compare(a, b) {
    if (a.menu_order < b.menu_order) {
      return -1;
    }
    if (a.menu_order > b.menu_order) {
      return 1;
    }
    return 0;
  }

  //   openSideBar() {
  //     // OUVRIR LA SIDEBAR
  //     this.uncollapseLogoTimeout = setTimeout(() => {
  //       // attends qu'il y a une place pour le grand logo
  //       this.collapsed_menu = false;
  //     }, TIME_TO_COLLAPSE);
  //     this.collapsed = false;
  //     this.sidebar_status.emit(this.collapsed);
  //   }

  //   closeSideBar() {
  //     // FERMER LA SIDEBAR
  //     this.collapsed_menu = true;
  //     this.collapsed = true;
  //     this.sidebar_status.emit(this.collapsed);
  //   }

  //   toggleSideBar() {
  //     clearTimeout(this.uncollapseLogoTimeout);
  //     clearTimeout(this.collapseLogoTimeout);
  //     clearTimeout(this.uncollapseTimeout);
  //     clearTimeout(this.collapseTimeout);
  //     if (this.collapsed_menu) {
  //       this.openSideBar();
  //       this.useropenbar = true;
  //     } else {
  //       this.closeSideBar();
  //       this.useropenbar = false;
  //     }
  //   }

  //   leaveSideBar(event) {
  //     if (event.isTrusted && !this.collapsed_menu) {
  //       this.collapseTimeout = setTimeout(() => {
  //         this.closeSideBar();
  //       }, 5000);
  //     } else {
  //       clearTimeout(this.uncollapseTimeout);
  //     }
  //   }

  //   enterSideBar(event) {
  //     if (event.isTrusted && this.collapsed_menu) {
  //       this.uncollapseTimeout = setTimeout(() => {
  //         this.openSideBar();
  //       }, 700);
  //     } else {
  //       clearTimeout(this.collapseTimeout);
  //     }
  //   }

  //   public enabled_submenu: string = '';

  //   onchangeIcon(id) {
  //     this.id = id;
  //   }

  //   toggleEditable(event) {
  //     if (event.target.checked) {
  //       this.isNotActiveMenu = true;
  //     }
  //   }

  //   isActiveMenu(s: string, icon) {
  //     this.sharedMenuObserverService.updateIconMenu(icon);
  //     localStorage.setItem('currentIcon', icon);
  //     let test: boolean = false;
  //     let route;
  //     for (const r of this.routes) {
  //       if (r.menu_id === s) {
  //         test = true;
  //         route = r;
  //       } else {
  //         r.isNotActiveMenu = false;
  //         $('#' + r.menu_id)?.attr('unchecked');
  //       }
  //     }
  //     if (test === true) {
  //       if (route.isNotActiveMenu) {
  //         route.isNotActiveMenu = false;
  //         $('#' + route.menu_id)?.prop('checked', false);
  //         if (route.child.length > 0) {
  //           route.child.forEach((element) => {
  //             $('#' + element.menu_id)?.prop('checked', false);
  //           });
  //         }
  //         $('.labbel').on('click', function () {
  //           $('.rotate').toggleClass('up');
  //         });
  //         this.active = false;
  //         test = false;
  //         this.unchecked = true;
  //       } else {
  //         this.active = true;
  //         route.isNotActiveMenu = true;
  //         $('#' + route.menu_id)?.attr('checked');
  //         $('.labbel').on('click', function () {
  //           $('.rotate').toggleClass('up');
  //         });

  //         test = false;
  //       }
  //     }
  //   }
  // }

  isCollapsed = false;

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebar_status.emit(this.isCollapsed);
  }

  toggleExpand(menuItem: MenuItem) {
    menuItem.expanded = !menuItem.expanded;
  }

  setActiveItem(selectedItem: MenuItem) {
    this.clearActiveState(this.routes); 
    if (!selectedItem.child || selectedItem.child.length === 0) {
      selectedItem.isActive = true; 
    }
  }
  
  clearActiveState(menuItems) {
    menuItems.forEach((item) => {
      item.isActive = false;
      if (item.child && item.child.length > 0) {
        this.clearActiveState(item.child); // Recursively clear active state for children
      }
    });
  }

  onItemClicked(item: MenuItem) {
    item.isActive = !item.isActive; // Toggle the active state of the clicked item
  }

  resetActiveItem(menuItem: MenuItem, activeItem: MenuItem) {
    if (menuItem !== activeItem) {
      menuItem.isActive = false;
    } else {
      menuItem.isActive = true;
    }
    menuItem.child.forEach((child) => {
      this.resetActiveItem(child, activeItem);
    });
  }

  toggleSubMenu(menuItem: MenuItem): void {
    if (menuItem.child) {
      menuItem.expanded = !menuItem.expanded;
    }
    this.setActiveItem(menuItem); 
  }

  activeMenu: MenuItem | null = null;
  setActiveMenu(menuItem: MenuItem): void {
    this.activeMenu = menuItem;
  }

  onHover(item: MenuItem) {
    if (this.isCollapsed) {
      item.isHovered = true;
    }
  }

  onHoverOut(item: MenuItem) {
    if (this.isCollapsed) {
      item.isHovered = false;
    }
  }

  activeItem: any = null;

  onMouseEnter() {
    clearTimeout(this.collapseTimeout);
    this.isCollapsed = false;
    this.sidebar_status.emit(this.isCollapsed);
  }

  onMouseLeave() {
    this.collapseTimeout = setTimeout(() => {
      this.isCollapsed = true;
      this.sidebar_status.emit(this.isCollapsed);
    }, 60000);
  }

  ngOnDestroy() {
    clearTimeout(this.collapseTimeout); // Clear timeout on component destroy to prevent memory leaks
  }

  expandNameUser(name: string, surname: string): string {
    name = name?.trim();
    surname = surname?.trim();
    const allname = name + ' ' + surname;
    if (allname?.length < 25) {
      return allname;
    } else {
      return allname?.substring(0, 25) + '...';
    }
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 25) {
      return name;
    } else {
      return name?.substring(0, 25) + '...';
    }
  }

  logOut() {
    localStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
