import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
 
import { filter, tap, debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-form-objet-assurer',
  templateUrl: './form-objet-assurer.component.html',
  styleUrls: ['./form-objet-assurer.component.css'],
})
export class FormObjetAssurerComponent implements OnInit {
  @Input() formObjectAssurer;
  @Input() index;
  @Output() dataform = new EventEmitter<any>();
  @Output() dataformcopie = new EventEmitter<any>();
  @Output() dataformdelete = new EventEmitter<any>();
  form_objet_assurer = new FormGroup({});
  model_objet_assurer: any = {};
  options: FormlyFormOptions = {};
  model: any = {};
  form: any;
  data: any = {};
  fields = [];
  prospectFormnew: any = {};
   
  isExpanded: boolean = true;
  private ngUnsubscribe = new Subject();
  private initialModel: any;
  typeofchangedEl:any;
  constructor(private formlyJsonschema: FormlyJsonschema, private renderer: Renderer2, private el: ElementRef) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    if (this.formObjectAssurer.expand === true) {
      this.isExpanded = true;
    } else {
      this.isExpanded = false;
    }

    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
    // if(objCopy.form?.data){
    //   this.model=objCopy.form?.data;
    // }
    this.form = new FormGroup({});
    if (objCopy.form != null) {
      this.options = {};
      if (objCopy.form?.schema) {
        const form: any = objCopy.form?.schema?.properties;
        // if(objCopy.form?.schema?.properties?.length>0){
        //   this.appear = false;
        // } else {
        //   this.appear = true;
        // }
        objCopy.form.schema.properties = {};
        this.model = {};
        if (form !== undefined) {
          form.forEach((element) => {
            objCopy.form.schema.properties[element.name] = element;

            if (this.model[element.name]) {
              this.model[element.name] = element.default;
            }
          });
        }
        if (objCopy.form?.data) {
          for (const [key, value] of Object.entries(objCopy.form?.data)) {
            if (value != null) this.model[key] = value;
          }
        }
        // this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form.schema)))];
        this.fields = [
          {
            ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form.schema))),
            hooks: {
              onInit: (field: FormlyFieldConfig) => {

                this.initialModel = JSON.parse(JSON.stringify(this.model));

               return field.formControl.valueChanges.pipe( debounceTime(1500),
                tap(() => {

                  const difference = this.calculateModelDifference(this.initialModel, this.model);

                  const key = Object.keys(difference)[0];

                  JSON.parse(JSON.stringify(this.formObjectAssurer)).form.schema.properties.forEach(element => {

                    if(key ===element.name){
                      this.typeofchangedEl=element.typename;
                    }
                  });


                  if( this.typeofchangedEl==="Multi Select" || this.typeofchangedEl=== "Select"  || this.typeofchangedEl=== "Radio"){
                    this.initialModel = JSON.parse(JSON.stringify(this.model));
                    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
                    objCopy.form.data = this.model;
                    objCopy.expand = true;
                    this.formObjectAssurer = { ...objCopy };
                     this.dataform.emit(this.formObjectAssurer);


                  }

                }));
              }
            }
          }
        ];
      }
    } else if (objCopy.form != null) {
      this.options = {};

      if (objCopy.form?.schema) {
        //  this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form?.schema)))];
        this.fields = [
          {
            ...this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form.schema))),
            hooks: {
              onInit: (field: FormlyFieldConfig) => {

                this.initialModel = JSON.parse(JSON.stringify(this.model));

               return field.formControl.valueChanges.pipe( debounceTime(1500),
                tap(() => {

                  const difference = this.calculateModelDifference(this.initialModel, this.model);

                  const key = Object.keys(difference)[0];

                  JSON.parse(JSON.stringify(this.formObjectAssurer)).form.schema.properties.forEach(element => {

                    if(key ===element.name){
                      this.typeofchangedEl=element.typename;
                    }
                  });


                  if( this.typeofchangedEl==="Multi Select" || this.typeofchangedEl=== "Select"  || this.typeofchangedEl=== "Radio"){
                    this.initialModel = JSON.parse(JSON.stringify(this.model));
                    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
                    objCopy.form.data = this.model;
                    objCopy.expand = true;
                    this.formObjectAssurer = { ...objCopy };
                     this.dataform.emit(this.formObjectAssurer);
                  }

                }));
              }
            }
          }
        ];
      }
      this.model = objCopy.form?.data;
    }
  }

  private calculateModelDifference(initialModel: any, currentModel: any): any {
    const difference = {};

    for (const key in initialModel) {
      if (Object.prototype.hasOwnProperty.call(initialModel, key) && !this.isEqual(initialModel[key], currentModel[key])) {
        difference[key] = currentModel[key];
      }
    }

    return difference;
  }

  private isEqual(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  changeValue(event: any) {

    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
    objCopy.form.data = this.model;
    objCopy.expand = true;
    this.formObjectAssurer = { ...objCopy };
    this.dataform.emit(this.formObjectAssurer);
  }

  senddata($event) {
    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
    objCopy.form.data = this.model;
    objCopy.expand = true;
    this.formObjectAssurer = Object.assign({}, objCopy);
    this.dataform.emit(this.formObjectAssurer);
  }

  duplicateobjet(formObjectAssurer) {
    this.dataformcopie.emit(formObjectAssurer);
  }

  deleteobjet(formObjectAssurer) {
    this.dataformdelete.emit(formObjectAssurer);
  }
}
