import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup,AbstractControl, ValidatorFn, ValidationErrors ,Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ArrayFormly } from 'src/app/entity/Formbuilder/ArrayFormly';
import { CheckboxFormly } from 'src/app/entity/Formbuilder/CheckboxFormly';
import { DateFormly } from 'src/app/entity/Formbuilder/DateFormly';
import { EmailFormly } from 'src/app/entity/Formbuilder/EmailFormly';
import { FormField } from 'src/app/entity/Formbuilder/FormField';
import { JsonFormly } from 'src/app/entity/Formbuilder/JsonFormly';
import { MonetiqueFormly } from 'src/app/entity/Formbuilder/monetiqueFormly';
import { MultiselectFormly } from 'src/app/entity/Formbuilder/MultiselectFormly';
import { NumberFormly } from 'src/app/entity/Formbuilder/NumberFormly';
import { RadioFormly } from 'src/app/entity/Formbuilder/RadioFormly';
import { SelectFormly } from 'src/app/entity/Formbuilder/SelectFormly';
import { CustomOptions } from 'src/app/entity/Formbuilder/SharedElementsFormly';
import { TextAreaFormly } from 'src/app/entity/Formbuilder/TextAreaFormly';
import { TextFormly } from 'src/app/entity/Formbuilder/TextFormly';
import { TimeFormly } from 'src/app/entity/Formbuilder/TimeFormly';


import swal from 'sweetalert2';
@Component({
  selector: 'shared-form-params',
  templateUrl: './form-params.component.html',
  styleUrls: ['./form-params.component.css'],
})
export class SharedFormParamsComponent implements OnInit {
  @Input() item: FormField;
  @Input() dropZoneCreatedModel: FormField[] = [];
  @Output() onChangeParams = new EventEmitter<any>();
  @Output() onErrorParams = new EventEmitter<boolean>();
  disableId: boolean = false;
  formFieldParams: FormGroup;
  fieldOptions: FormGroup;
  count = 0;
  public editorOptions: JsonEditorOptions;
  public data: any;

  jsonArray: any;

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  existetitle: boolean = false;
  buttons: any;
  alerts: any;

  constructor(private translate : TranslateService,) {
    
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
  
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      
      });});
    this.count=0;
    for (let i = 0; i < this.dropZoneCreatedModel.length; i++) {
      const obj = this.dropZoneCreatedModel[i];
      if (obj.name === this.item.name) {
        this.count++;
      }
    }
    if (this.count > 1) {
      this.existetitle = true;
      this.onErrorParams.emit(this.existetitle);
    }else{
      this.existetitle = false;
      this.onErrorParams.emit(this.existetitle);
    }
    this.initFormFields();
  }

  initFormFields() {
    let item: any;

    /*
     * Init radio and select field params form
     * */

    this.fieldOptions = new FormGroup({
      label: new FormControl(''),
      value: new FormControl(''),
    });

    /*
     * Init Full item config form
     * */
    switch (this.item.typename) {
      
      case 'Text':
        item = this.item as TextFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            minLength: new FormControl(item.minLength),
            maxLength: new FormControl(item.maxLength),
            pattern: new FormControl(item.pattern),
            autofill: new FormControl(item.autofill),
            urlIdentifiant: new FormControl(item.urlIdentifiant),
            urlValidation: new FormControl(item.urlValidation),
            format: new FormControl(item.format),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Text Area':
        item = this.item as TextAreaFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
            placeholder: new FormControl(item.widget.formlyConfig.templateOptions.placeholder),
            pattern: new FormControl(item.pattern),
            autofill: new FormControl(item.autofill),
            urlIdentifiant: new FormControl(item.urlIdentifiant),
            urlValidation: new FormControl(item.urlValidation),
            rows: new FormControl(item.widget.formlyConfig.templateOptions.rows),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Number':
        item = this.item as NumberFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
            minimum: new FormControl(item.minimum),
            exclusiveMinimum: new FormControl(item.exclusiveMinimum),
            maximum: new FormControl(item.maximum),
            exclusiveMaximum: new FormControl(item.exclusiveMaximum),
            multipleOf: new FormControl(item.multipleOf),
            pattern: new FormControl(item.pattern),
            autofill: new FormControl(item.autofill),
            urlIdentifiant: new FormControl(item.urlIdentifiant),
            urlValidation: new FormControl(item.urlValidation),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Array':
        item = this.item as ArrayFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            pattern: new FormControl(item.pattern),
            autofill: new FormControl(item.autofill),
            urlIdentifiant: new FormControl(item.urlIdentifiant),
            urlValidation: new FormControl(item.urlValidation),
            minItems: new FormControl(item.minItems),
            default: new FormControl(item.default),
            maxItems: new FormControl(item.maxItems),
            description: new FormControl(item.description),
          },
          { updateOn: 'change' }
        );
        this.data = item.items;
        break;
        case 'Json':
          item = this.item as JsonFormly;
          this.formFieldParams = new FormGroup(
            {
              col: new FormControl(item.widget.formlyConfig.templateOptions.col),
              name: new FormControl(item.name),
              label: new FormControl(item.title),
              description: new FormControl(item.description),
              pattern: new FormControl(item.pattern),
              autofill: new FormControl(item.autofill),
              urlIdentifiant: new FormControl(item.urlIdentifiant),
              default: new FormControl(item.default, [this.jsonValidator(item.pattern)]),
            urlValidation: new FormControl(item.urlValidation),

            },
            { updateOn: 'change' }
          );
          break;
          case 'Email':
          item = this.item as EmailFormly;
          item.widget.formlyConfig.templateOptions.pattern = item.pattern;
          this.formFieldParams = new FormGroup(
            {
              col: new FormControl(item.widget.formlyConfig.templateOptions.col),
              name: new FormControl(item.name),
              label: new FormControl(item.title),
              description: new FormControl(item.description),
              pattern: new FormControl(item.pattern),
              autofill: new FormControl(item.autofill),
              urlIdentifiant: new FormControl(item.urlIdentifiant),
            urlValidation: new FormControl(item.urlValidation),
            
            default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
            },
            { updateOn: 'change' }
          );
          break;
          case 'monetique':
          item = this.item as MonetiqueFormly;
          item.widget.formlyConfig.templateOptions.pattern = item.pattern;
          this.formFieldParams = new FormGroup(
            {
              name: new FormControl(item.name),
              label: new FormControl(item.title),
              accDebit: new FormControl(item.accDebit),
              accCredit: new FormControl(item.accCredit),
              col: new FormControl(item.widget.formlyConfig.templateOptions.col),
              description: new FormControl(item.description),
              default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
              pattern: new FormControl(item.pattern),
              autofill: new FormControl(item.autofill),
              urlIdentifiant: new FormControl(item.urlIdentifiant),
              urlValidation: new FormControl(item.urlValidation),

            },
            { updateOn: 'change' }
          );
          break;
          case 'Password':
            item = this.item as EmailFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
                maxLength: new FormControl(item.maxLength),
                minLength: new FormControl(item.minLength),
              default: new FormControl(this.item.default, [this.patternValidator(this.item.pattern)]),
              },
              { updateOn: 'change' }
            );
            break;
            case 'Radio':
            item = this.item as RadioFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                default: new FormControl(this.item.default),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
                
              },
              { updateOn: 'change' }
            );
            break;
            case 'Time':
            item = this.item as TimeFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                default: new FormControl(this.item.default),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
              
              },
              { updateOn: 'change' }
            );
            break;
            case 'Date':
            item = this.item as DateFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                default: new FormControl(this.item.default),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
              
              },
              { updateOn: 'change' }
            );
            break;
            case 'Select':
            item = this.item as SelectFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                default: new FormControl(this.item.default),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
              
              },
              { updateOn: 'change' }
            );
            break;
            case 'Multi Select':
            item = this.item as MultiselectFormly;
            item.widget.formlyConfig.templateOptions.pattern = item.pattern;
            this.formFieldParams = new FormGroup(
              {
                col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                name: new FormControl(item.name),
                label: new FormControl(item.title),
                description: new FormControl(item.description),
                pattern: new FormControl(item.pattern),
                autofill: new FormControl(item.autofill),
                default: new FormControl(this.item.default),
                urlIdentifiant: new FormControl(item.urlIdentifiant),
                urlValidation: new FormControl(item.urlValidation),
              
              },
              { updateOn: 'change' }
            );
            break;
            case 'Check box':
              item = this.item as CheckboxFormly;
              item.widget.formlyConfig.templateOptions.pattern = item.pattern;
              this.formFieldParams = new FormGroup(
                {
                  col: new FormControl(item.widget.formlyConfig.templateOptions.col),
                  name: new FormControl(item.name),
                  label: new FormControl(item.title),
                  description: new FormControl(item.description),
                  pattern: new FormControl(item.pattern),
                  autofill: new FormControl(item.autofill),
                  default: new FormControl(this.item.default),
                  urlIdentifiant: new FormControl(item.urlIdentifiant),
                  urlValidation: new FormControl(item.urlValidation),
                
                },
                { updateOn: 'change' }
              );
              break;
      default:
        this.formFieldParams = new FormGroup({
          col: new FormControl(this.item.widget.formlyConfig.templateOptions.col),
          name: new FormControl(this.item.name),
          label: new FormControl(this.item.title),
          description: new FormControl(this.item.description),

          pattern: new FormControl(this.item.pattern),
          autofill: new FormControl(item.autofill),
          urlIdentifiant: new FormControl(item.urlIdentifiant),
          urlValidation: new FormControl(this.item.urlValidation),
        });
    }

    if (this.item.name === 'idObject') {
      this.formFieldParams.controls.name.disable();
      this.disableId = true;
    }
    
  }

 
  updateItem() {
    this.count=0;
    this.existetitle = false;

    this.onErrorParams.emit(this.existetitle);
    let item: any;

    const formValue = this.formFieldParams.getRawValue();
    this.item.name = formValue.name;
    this.item.title = formValue.label;
    for (let i = 0; i < this.dropZoneCreatedModel.length; i++) {
      const obj = this.dropZoneCreatedModel[i];
      if (obj.name === formValue.name) {
        this.count++;
      }
    }
    if (this.count > 1) {
      this.existetitle = true;
      this.onErrorParams.emit(this.existetitle);
    }else{
      this.existetitle = false;
      this.onErrorParams.emit(this.existetitle);
    }

    this.item.description = formValue.description;
    this.item.default = formValue.default;
    this.item.pattern = formValue.pattern;
    this.item.autofill = formValue.autofill;
    this.item.urlValidation = formValue.urlValidation;
    this.item.widget.formlyConfig.templateOptions.col = formValue.col;
    this.item.widget.formlyConfig.templateOptions.pattern = formValue.pattern;
    this.item.widget.formlyConfig.templateOptions.autofill = formValue.autofill;
    this.item.widget.formlyConfig.templateOptions.urlIdentifiant = formValue.urlIdentifiant;
    switch (this.item.typename) {
      case 'Text Area':
        item = this.item as TextAreaFormly;
        item.widget.formlyConfig.templateOptions.placeholder = this.formFieldParams.value.placeholder;
        item.widget.formlyConfig.templateOptions.rows = this.formFieldParams.value.rows;
        item.default = this.formFieldParams.value.default;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        break;
      case 'Number':
        item = this.item as NumberFormly;
        item.minimum = this.formFieldParams.value.minimum;
        item.exclusiveMinimum = this.formFieldParams.value.exclusiveMinimum;
        item.maximum = this.formFieldParams.value.maximum;
        item.exclusiveMaximum = this.formFieldParams.value.exclusiveMaximum;
        item.multipleOf = this.formFieldParams.value.multipleOf;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        break;
      case 'Text':
        item = this.item as TextAreaFormly;
        item.minLength = this.formFieldParams.value.minLength;
        item.maxLength = this.formFieldParams.value.maxLength;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.format = this.formFieldParams.value.format;
        item.default = this.formFieldParams.value.default;
        break;
      case 'Array':
        item = this.item as ArrayFormly;
        item.minItems = this.formFieldParams.value.minItems;
        item.maxItems = this.formFieldParams.value.maxItems;
        item.default = this.formFieldParams.value.default;
        item.items = this.jsonArray;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        break;
      case 'Json':
        item = this.item as JsonFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        break;
        case 'Email':
        item = this.item as EmailFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.widget.formlyConfig.templateOptions.pattern = formValue.pattern;
        break;
        case 'Password':
        item = this.item as EmailFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        item.widget.formlyConfig.templateOptions.pattern = formValue.pattern;
        break;
        case 'monetique':
          item = this.item as MonetiqueFormly;
          item.accDebit = this.formFieldParams.value.accDebit;
          item.accCredit = this.formFieldParams.value.accCredit;
          item.pattern = this.formFieldParams.value.pattern;
          item.autofill = this.formFieldParams.value.autofill;
          item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
          item.urlValidation = this.formFieldParams.value.urlValidation;
          item.format = this.formFieldParams.value.format;
          item.default = this.formFieldParams.value.default;
        break;
        case 'Radio':
        item = this.item as RadioFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;
        case 'Time':
        item = this.item as TimeFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;

        case 'Date':
        item = this.item as DateFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;
        case 'Select':
        item = this.item as SelectFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;

        case 'Multi Select':
        item = this.item as MultiselectFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;
        case 'Check box':
        item = this.item as CheckboxFormly;
        item.default = this.formFieldParams.value.default;
        item.pattern = this.formFieldParams.value.pattern;
        item.autofill = this.formFieldParams.value.autofill;
        item.urlValidation = this.formFieldParams.value.urlValidation;
        item.urlIdentifiant = this.formFieldParams.value.urlIdentifiant;
        
        break;
  
    }
    // if(this.item.name === 'idObject' && this.item.title ===""){
    //  this.alertWarning()
    // }else
    this.onChangeParams.emit(this.item);
  }

  onChangeJson($event: any) {
    this.jsonArray = $event;
    this.updateItem();
  }

  deletePossibleOption(item: FormField, index: number) {
    if (item instanceof RadioFormly || item instanceof SelectFormly) {
      item.widget.formlyConfig.templateOptions.options.splice(index, 1);
    }
    if (item instanceof MultiselectFormly) {
      item.widget.formlyConfig.templateOptions.options.splice(index, 1);
    }
    this.updateItem();
  }

  addPossibleOpt(item: FormField) {
    if (item instanceof RadioFormly || item instanceof SelectFormly) {
      item.widget.formlyConfig.templateOptions.options.push(
        new CustomOptions(this.fieldOptions.value.label, this.fieldOptions.value.value)
      );
    }

    if (item instanceof MultiselectFormly) {
      item.widget.formlyConfig.templateOptions.options.push(
        new CustomOptions(this.fieldOptions.value.value, this.fieldOptions.value.value)
      );
    }
    this.fieldOptions.reset();
    this.updateItem();
  }


   jsonValidator(pattern: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // No value to validate
      }
  
      try {
        // Try to parse the JSON
        JSON.parse(control.value);
      } catch (e) {
        return { jsonInvalid: true }; // Invalid JSON
      }
  
      // Check the pattern if one is provided
      if (pattern) {
        const regex = new RegExp(pattern);
        return regex.test(control.value) ? null : { pattern: true };
      }
  
      return null; // Valid JSON and no pattern to validate against
    };
  }

  private patternValidator(pattern: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!pattern) {
        return null; // No pattern to validate against
      }
      const regex = new RegExp(pattern);
      return regex.test(control.value) ? null : { pattern: true };
    };
  }


  alertWarning() {
    swal.fire({
      title: this.alerts.error,
      text: this.alerts.nomIdentifiantOblig,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }
}
